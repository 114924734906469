import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { disableRefresh } from '../home/homeSlice';

import { loadMyCoach } from './coachesSlice';
import { SportPicker } from '../Posts/SportPicke';

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function EditCoachProfile({ coach, setOpenCoachProfileEdit, myMainid }) {
    const [creating, setCreating] = useState(false)
    const [mediaType, setMediaType] = useState('image')
    const [selectedPhoto, setSelectedPhoto] = useState(coach.picture_url || localStorage.getItem('plus'));
    const [photo64, setPhoto64] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [thumb, setThumb] = useState(coach.thumb || '')
    const [selectedSport, setSelectedSport] = useState(coach.sport || null);
    const [bio, setBio] = useState(coach.bio || '');
    const dispatch = useDispatch()

    function handleMessageEvent(event) {
        const data = event.data;
        try {
            if (data.length > 0 && data.startsWith('SelectedPhoto:')) {
                const data64 = data.substring(14);
                setSelectedPhoto(`data:image/jpeg;base64,${data64}`)
                setSelectedVideo(null)
                setPhoto64(data64)
                setMediaType('image')
            }
        } catch (e) {

        }
        try {
            if (data.length > 0 && data.startsWith('SelectedVideo:')) {
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Photos Only`);
                } catch (e) {

                }
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        // Add event listeners when component mounts
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessageEvent);
        }
        if (typeof document !== 'undefined') {
            document.addEventListener('message', handleMessageEvent);
        }
        // Clean up event listeners when component unmounts
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessageEvent);
            }
            if (typeof document !== 'undefined') {
                document.removeEventListener('message', handleMessageEvent);
            }
        };
    }, []);

    const openImagePicker = () => {
        try {
            window.ReactNativeWebView.postMessage("PickPhoto");
            if (/Android/.test(navigator.userAgent)) {
                dispatch(disableRefresh());
            }
        } catch (e) {

        }
    };

    const handleSubmit = async () => {
        setCreating(true)
        if (!selectedSport || !bio) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Make Sure you have selected Sport Speciality and a Bio `);
            } catch (e) {

            }
            setCreating(false)
            return;
        }

        const formData = new FormData();
        try {
            if (mediaType === 'image') {
                if (selectedPhoto.includes('https')) {
                    formData.append('image', selectedPhoto);
                } else {
                    const byteCharacters = window.atob(photo64);
                    const byteArrays = [];
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteArrays.push(byteCharacters.charCodeAt(i));
                    }
                    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' });
                    formData.append('image', blob, 'coachprofile.jpg');
                }
            }
        } catch (e) {

        }

        formData.append('mediaType', mediaType);
        formData.append('selectedSport', selectedSport);
        formData.append('bio', bio);

        try {
            // Send the FormData to the server using the fetch API
            const response = await fetch('/api/coaches/editprofile', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                dispatch(loadMyCoach())
                setCreating(false)
                setOpenCoachProfileEdit(null)
            } else {
                setCreating(false)
                console.error('Post creation failed:', response.status);
            }
        } catch (error) {
            setCreating(false)
            console.error('Error creating post:', error);
        }
    };

    const clearMedia = () => {
        setSelectedVideo(null)
        setThumb(null)
        setSelectedPhoto(null)
        setPhoto64(null)
        setMediaType('none')

    }

    return (
        <div style={{ zIndex: '2000', left: '-10px' }} className='CreateEditExpPage'>
            {creating ? <div style={{ zIndex: '2000', alignItems: 'center', display: 'flex' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Applying . . .</p>
            </div> : ""}
            <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">EDIT COACH PROFILE</label>
            </div>
            <div style={{ gridTemplateRows: '80px auto auto 50px', width: '100%', padding: '10px 10px', backgroundColor: "hsla(0, 0%, 25%, 0.6)" }} className='coachExpCardCont'>
                <div className='coachExpCardHead'>
                    <img onClick={() => openImagePicker()} className='expImage' src={mediaType === 'video' ? thumb : selectedPhoto ? selectedPhoto : localStorage.getItem('plus')} />
                    <div className='expInfo'>
                        <h3 style={{ marginLeft: '0px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>{coach.name}</span></h3>
                        <h4 style={{ marginLeft: '0px', display: 'flex', gap: '15px' }}>
                            <span style={{ color: 'hsl(72, 100%, 47%)' }}>Sport Speciality: </span>
                            <SportPicker selectedSport={selectedSport} setSelectedSport={setSelectedSport} />
                        </h4>
                    </div>
                </div>
                <div className='expCardDescriptionCont'>
                    <h5 style={{ marginLeft: '0px', textAlign: 'left', marginBottom: '5px' }}>
                        <span style={{ color: 'hsl(72, 100%, 47%)' }}>Bio: </span>
                    </h5>
                    <textarea
                        style={{ marginLeft: '0px', textAlign: 'left', height: '250px' }}
                        className='expCardDescription'
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                        maxLength={500}
                    // Additional styling or attributes as needed
                    />
                </div>
                <div className='clearMediaOrEndDate'  >
                    <p style={{ color: "white", fontSize: '12px', fontWeight: '500' }}>As a Coach you can add a different coach profile picture</p>
                </div>
                <div className='submitOrCancelExp'  >
                    <p onClick={() => { handleSubmit() }} style={{ height: '20px' }} className='activeBlack singleButton'>Submit</p>
                    <p onClick={() => { setOpenCoachProfileEdit(null) }} style={{ width: '105px', height: '20px' }} className='activeBlack singleButton2'>Discard</p>
                </div>
            </div>
            <div style={{ height: '300px' }} />
        </div >
    );
}
