import { configureStore } from "@reduxjs/toolkit";
import homeReducer from "../features/home/homeSlice";
import intButtonsReducer from '../features/intButtons/intButtonsSlice'
import userReducer from '../features/user/userSlice'
import reqsReducer from '../features/reqs/reqsSlice'
import friendsReducer from '../features/friends/friendsSlice'
import chatReqsReducer from '../features/chatreqs/chatReqsSlice'
import repChoiceReducer from "../features/root/repChoiceSlice";
import tempProfileReducer from "../features/list/tempProfileSlice"
import adminSliceReducer from "../features/admin/adminSlice";
import chatsSliceReducer from "../features/chats/chatsSlice"
import tempMeetingPageSliceReducer from "../features/workoutsManager/tempMeetingPageSlice"
import achievementsSliceReducer from "../features/achievements/achievementsSlice"
import workoutsSliceReducer from "../features/workoutsManager/workoutsSlice"
import coachesSliceReducer from '../features/coaches/coachesSlice'
import groupsSliceReducer from '../features/groups/groupsSlice'

export const store = configureStore({
  reducer: {
    home: homeReducer,
    intButtons: intButtonsReducer,
    user: userReducer,
    friends: friendsReducer,
    reqs: reqsReducer,
    chatReqs: chatReqsReducer,
    repChoice: repChoiceReducer,
    tempProfile: tempProfileReducer,
    admin: adminSliceReducer,
    chats: chatsSliceReducer,
    tempMeetingPage: tempMeetingPageSliceReducer,
    achievements: achievementsSliceReducer,
    workouts: workoutsSliceReducer,
    coaches: coachesSliceReducer,
    groups: groupsSliceReducer
  },
  devTools: process.env.REACT_APP_DEPLOY_MODE !== "production",
});
