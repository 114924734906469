import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import deleteImg from '../../Pics/delete.png'
import greentick from '../../Pics/greentick.png'
import { addToConfirmedFeatured } from './workoutsSlice';
import { editData, selectData } from '../home/homeSlice';
import { StartSetTimer } from './StartSetTimer';

export function FeaturedWorkoutCard({ workout, setTrackedSoloWorkouts, setShowWorkoutInfo, selectedFilter }) {
    const [showStartSetTimer, setShowStartSetTimer] = useState(null)
    const myData = useSelector(selectData)
    const [count, setCount] = useState(0)
    const [isState, setIsState] = useState(null)
    const [updating, setUpdating] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        try {
            setCount(workout.sets)
        } catch (e) {

        }
    }, [])


    const handleDeleteWorkout = async () => {
        try {
            const workoutId = workout.id
            const trackedSoloWorkouts = JSON.parse(localStorage.getItem('trackedSoloWorkouts')) || [];
            const indexToDelete = trackedSoloWorkouts.findIndex((workout) => workout.id === workoutId);

            if (indexToDelete !== -1) {
                trackedSoloWorkouts.splice(indexToDelete, 1);

                localStorage.setItem('trackedSoloWorkouts', JSON.stringify(trackedSoloWorkouts));

                setTrackedSoloWorkouts(trackedSoloWorkouts); // If you're using functional components with useState.
            }
        } catch (e) {

        }
    };

    const handleIncreaseCount = () => {
        setCount((prevCount) => {
            if (prevCount === 10) {
                try {
                    window.ReactNativeWebView.postMessage("Alert: Well Played Champ! Try another workout");
                } catch (e) {
                    // Handle the error if needed
                    console.error(e);
                }
                return prevCount; // Don't forget to return the count here
            } else {
                // Increment the count
                const newCount = prevCount + 1;

                // Update the sets count of the workout with the same ID in local storage
                const trackedSoloWorkouts = JSON.parse(localStorage.getItem('trackedSoloWorkouts')) || [];
                const updatedTrackedSoloWorkouts = trackedSoloWorkouts.map((workoutItem) => {
                    if (workoutItem.id === workout.id) {
                        workoutItem.sets++;
                    }
                    return workoutItem;
                });

                // Save the updated array back to local storage
                localStorage.setItem('trackedSoloWorkouts', JSON.stringify(updatedTrackedSoloWorkouts));

                return newCount; // Return the updated count
            }
        });
    };




    const handleDecreaseCount = () => {
        setCount((count) => {
            if (count === 0) {
                return count;
            } else {
                return count - 1;
            }
        });
    }

    const handleCompleteWorkout = async () => {
        setUpdating(true)
        if (count === 0) {
            try {
                window.ReactNativeWebView.postMessage("Alert:At least finsih 1 set");
            } catch (e) {

            }
            setUpdating(false)
            return
        }
        function getCurrentTime() {
            const timestamp = Date.now(); // Get the current timestamp in milliseconds
            const date = new Date(timestamp);

            // Format the timestamp as HH:MM am/pm
            const formattedTime = date.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true, // Use 12-hour time format
                day: '2-digit',
                month: 'short', // Use abbreviated month name (e.g., "Jan")
                year: '2-digit',
            });

            return formattedTime;
        }

        const currentTime = getCurrentTime()

        fetch(`/api/workoutsbodyweight/confirmfeaturedworkout`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ workoutid: workout.id, sets: count, date: currentTime }),
        })
            .then((response) => {
                if (response.ok) {
                    try {
                        window.ReactNativeWebView.postMessage("Alert:Confirmed");
                    } catch (e) {

                    }
                    const newWorkout = { ...workout, sets: count, date: currentTime }
                    dispatch(addToConfirmedFeatured(newWorkout))
                    try {
                        if (myData) {
                            const updatedCount = parseInt(myData.featuredsets, 10) + parseInt(count, 10);
                            dispatch(editData({ featuredsets: updatedCount }));
                        }
                    } catch (e) {

                    }
                    handleDeleteWorkout()
                    setUpdating(false)
                } else {
                    setUpdating(false)

                }
            })
            .catch((error) => {
                console.error("Error confirming");
                setUpdating(false)
            });
    }

    return (
        <div style={{ border: isState === 'confirmed' ? '1px solid hsl(72, 100%, 47%)' : '1px solid grey' }} className='FeaturedCardCont' >
            {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                <p className='fake'> Confirming...</p>
            </div> : ""}
            {showStartSetTimer ?
                <div style={{ zIndex: '700', position: 'fixed', right: '0px' }} className="tempMeetingPage">
                    <div style={{ height: '100%', zIndex: '900', backgroundColor: 'rgba(17, 17, 17, 0.99)' }} className="meetingPageCont">
                        <StartSetTimer workout={showStartSetTimer} setShowStartSetTimer={setShowStartSetTimer} count={count} handleCompleteWorkout={handleCompleteWorkout} handleIncreaseCount={handleIncreaseCount} />
                    </div>
                </div>
                : ""
            }
            <img onClick={() => { setShowWorkoutInfo(workout) }} className='smallWorkoutFeatured' src={workout.gifurl} />
            <h5 onClick={() => { setShowWorkoutInfo(workout) }}>{workout.name}</h5>
            {selectedFilter === 'Tracked' ?
                <div className='smallCardCountCont'>
                    <h6><span style={{ color: 'white', fontSize:'14px' }} >Sets:</span> {count}</h6>
                    <p onClick={() => setShowStartSetTimer(workout)} className='activeBlack'>{count === 0 ? 'Start' : 'Countinue'}</p>
                </div>
                : ""
            }
            {selectedFilter === 'Tracked' ?
                <img onClick={handleDeleteWorkout} className='deleteFeaturedWorkout' src={deleteImg} />

                : ""
            }
            {selectedFilter === 'Tracked' ? <img onClick={handleCompleteWorkout} className='confirmFeaturedWorkout' src={greentick} />

                : ""
            }
        </div>
    )
}