import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNearByGroups } from './groupsFuncs';

export const loadMyGroups = createAsyncThunk(
  'groups/loadMyGroups',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadmygroups`);
    const json = await data.json();
    return json;
  }
);

export const loadMyCoachBigData = createAsyncThunk(
  'groups/loadMyCoachBigData',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadmycoachbigdata`);
    const json = await data.json();
    return json;
  }
);

export const loadNearByGroups = createAsyncThunk(
  'groups/loadNearByGroups',
  async ({ sport, radius }) => {
    const data = await fetchNearByGroups(sport, radius)
    return data;
  }
);

export const loadMyCoaches = createAsyncThunk(
  'groups/loadMyCoaches',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadmycoaches`);
    const json = await data.json();
    return json;
  }
);

export const groupsSlice = createSlice({
  name: 'groups',
  initialState: {
    myGroup: null,
    myJoinedGroups: null,
    myGroupsStatus: 'idle',
    myStudents: null,
    myExperiences: null,
    myCoachBigDataStatus: 'idle',
    groupsNearBy: null,
    groupsNearByStatus: 'idle',
    myCoaches: null,
    myCoachesStatus: 'idle'
  },
  reducers: {
    editMyCoachProfileData: (state, action) => {
      try {
        state.myGroup = { ...state.myGroup, ...action.payload };
      } catch (e) {

      }
    },
    editStudentsData: (state, action) => {
      try {
        const parentArray = [...state[action.payload.parent]];
        const indexToUpdate = parentArray.findIndex(student => student.studentid === action.payload.info.studentid);

        if (indexToUpdate !== -1) {
          parentArray[indexToUpdate] = { ...action.payload.info };
        }

        state[action.payload.parent] = parentArray;
      } catch (e) {
        // Handle the error if needed
      }
    },
    deleteFromStudents: (state, action) => {
      try {
        const parentArray = state[action.payload.parent].filter(student => student.studentid !== action.payload.info.studentid);
        state[action.payload.parent] = parentArray;
      } catch (e) {
        // Handle the error if needed
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMyGroups.pending, (state) => {
        if (state.myGroup === null) {
          state.myGroupsStatus = 'loading';
        }
      })
      .addCase(loadMyGroups.fulfilled, (state, action) => {
        state.myGroup = action.payload.myGroup[0];
        state.myJoinedGroups = action.payload.myJoinedGroups;
        state.myGroupsStatus = 'done';
      })
      .addCase(loadMyGroups.rejected, (state, action) => {
        state.myGroupsStatus = 'failed';
        state.myGroup = null;
        state.myJoinedGroups = null
      })
      //loadMyCoachBigData
      .addCase(loadMyCoachBigData.pending, (state) => {
        if (state.myExperiences === null) {
          state.myCoachBigDataStatus = 'loading';
        }
      })
      .addCase(loadMyCoachBigData.fulfilled, (state, action) => {
        state.myExperiences = action.payload.experiences;
        state.myStudents = action.payload.students;
        state.myJoinedGroups = action.payload.reviews;
        state.myCoachBigDataStatus = 'done';
      })
      .addCase(loadMyCoachBigData.rejected, (state, action) => {
        state.myCoachBigDataStatus = 'failed';
        state.myJoinedGroups = null
        state.myStudents = null
        state.myExperiences = null
      })
      //loadNearByGroups
      .addCase(loadNearByGroups.pending, (state) => {

        state.groupsNearByStatus = 'loading';

      })
      .addCase(loadNearByGroups.fulfilled, (state, action) => {
        state.groupsNearBy = action.payload
        state.groupsNearByStatus = 'done';
      })
      .addCase(loadNearByGroups.rejected, (state, action) => {
        state.groupsNearByStatus = 'failed';
        state.groupsNearBy = null
      })
      //loadMyCoaches
      .addCase(loadMyCoaches.pending, (state) => {
        if (state.myCoaches === null) {
          state.myCoachesStatus = 'loading';
        }
      })
      .addCase(loadMyCoaches.fulfilled, (state, action) => {
        state.myCoaches = action.payload
        state.myCoachesStatus = 'done';
      })
      .addCase(loadMyCoaches.rejected, (state, action) => {
        state.myCoachesStatus = 'failed';
        state.myCoaches = null
      })
  },
});

export const selectMyGroup = (state) => state.groups.myGroup;
export const selectMyJoinedGroups = (state) => state.groups.myJoinedGroups;
export const selectMyGroupsStatus = (state) => state.groups.myGroupsStatus;
export const selectMyStudents = (state) => state.groups.myStudents;
export const selectMyExperiences = (state) => state.groups.myExperiences;
export const selectMyCoachBigDataStatus = (state) => state.groups.myCoachBigDataStatus;
export const selectGroupsNearBy = (state) => state.groups.groupsNearBy;
export const selectGroupsNearByStatus = (state) => state.groups.groupsNearByStatus;
export const selectMyCoaches = (state) => state.groups.myCoaches;
export const selectMyCoachesStatus = (state) => state.groups.myCoachesStatus;


export const { editMyCoachProfileData, editStudentsData, deleteFromStudents } = groupsSlice.actions;
export default groupsSlice.reducer;


