import { disableRefresh } from "../home/homeSlice";

export function updateInterest(selectedInt, time, comment, preflocation, radius, dispatch, editData, loadCommon) {
  //dispatch(disableRefresh())
  //try {
  //  window.ReactNativeWebView.postMessage(`showFullScreenAd`);
  //} catch (e) {
  //}  
  window.selectedDuoSport = selectedInt
  function formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';

    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0:00) as 12:00 AM
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutes} ${ampm} ${date.getDate()}/${date.getMonth() + 1}`;
  }

  const currentDate = new Date();
  const formattedDate = formatAMPM(currentDate);
  fetch(`/api/profile/updateinterest`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ interest: selectedInt, time: time, comment: comment, preflocation: preflocation, radius: radius, now: formattedDate }),
  })
    .then((response) => {
      if (response.ok) {
        dispatch(editData({ interest: selectedInt }));
        dispatch(editData({ time: time.selectedTime }));
        dispatch(editData({ comment: comment }));
        dispatch(editData({ preflocation: preflocation }));
        dispatch(editData({ radius: radius }));
        dispatch(loadCommon({ interest: selectedInt, radius }));
        try {
          window.ReactNativeWebView.postMessage(`Alert:Confirmed,Now others will be able to find you in ${selectedInt} list. `);

        } catch (e) {

        }
        console.log("Interest updated");
      } else {
        console.error("Failed to save interest");
      }
    })
    .catch((error) => {
      console.error("Error saving interest:", error);
    });
}

export const notifyNearBy = async (interest, type) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/notifynearby`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ interest: interest, type: type }),
      }
    );
    if (response.ok) {
      console.log('Notified Near by function Complete')
    }
  } catch (error) {
    console.error(error);
  }
};

export function getInitialTime() {
  const date = new Date();
  console.log(date)
  const currentHours = date.getHours();
  const currentMinutes = date.getMinutes();
  const roundedMinutes = Math.round(currentMinutes / 30) * 30;
  const timeString = `${currentHours}:${roundedMinutes.toString().padStart(2, '0')}`;
  const dateString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  const dateTimeString = `${timeString} ${dateString}`;
  return dateTimeString;
}

export function getTimeOptions(maxTime) {
  const options = [];
  const optionsJSX = [];
  const now = new Date();
  const maxHours = now.getHours() + maxTime;

  for (let hour = now.getHours(); hour <= maxHours; hour++) {
    for (let minute = 0; minute <= 30; minute += 30) {
      const date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hour, minute);
      if (now.getTime() <= date.getTime()) {
        const timeString24 = `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;

        // Convert to 12-hour format (am/pm)
        const period = date.getHours() >= 12 ? 'pm' : 'am';
        const hour12 = date.getHours() % 12 || 12;
        const timeString12 = `${hour12}:${date.getMinutes().toString().padStart(2, '0')} ${period}`;

        const year = date.getFullYear().toString().slice(-2); // Extract the last two digits of the year
        const dateString = `${date.getDate()}/${date.getMonth() + 1}/${year}`; // Use the extracted year
        const dateTimeString = `${timeString24} ${dateString}`;

        optionsJSX.push(
          <option key={dateTimeString} value={dateTimeString}>
            {timeString12} {dateString}
          </option>
        );
        options.push({ dateTimeString, delay: calculateDelay(dateTimeString) });
      }
    }
  }
  return { options, optionsJSX };
}


export function calculateDelay(dateTimeString) {
  const [timeString, dateString] = dateTimeString.split(' ');
  const [hours, minutes] = timeString.split(':');
  const [day, month, year] = dateString.split('/');

  // Assuming the year is in 2-digit format, convert it to 4-digit
  const fullYear = parseInt(year) + 2000;

  const deletionTime = new Date(fullYear, month - 1, day, hours, minutes);

  // Add 1 hour to the deletion time
  deletionTime.setHours(deletionTime.getHours() + 1);

  // Calculate the delay in milliseconds
  const currentTime = Date.now();
  const deletionTimeMillis = deletionTime.getTime();

  return deletionTimeMillis - currentTime;
}

export const fetchCommonInt = async (interest, radius) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/loadcommon`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ interest: interest, radius: radius }),
    });
    const data = await response.json();
    return data
  } catch (error) {
    console.error(error);
  }
};
