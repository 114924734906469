import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectData, selectLog } from "../home/homeSlice";
import { OtherProfile } from '../user/OtherProfile';


export function MyProfile({ }) {
    const [info, setInfo] = useState({ user: [null] });
    const [friend, setFriend] = useState(false);
    const navigate = useNavigate();
    const data = useSelector(selectData);
    const log = useSelector(selectLog);
    const back = () => {
        navigate(-1)
    }

    const openChat = () => {

    }

    useEffect(() => {
        if (data) {
            setInfo({
                user: [data]
            });
        }
    }, []);

    if (info.user[0]) {
        return (
            <div className="tempProfilePage">
                <OtherProfile back={back} info={info} openChat={openChat} friend={friend} setFriend={setFriend} />
            </div>
        )
    } else {

    }
}