export const emptyExp = {
    id: 'new',
    title: '',
    image: '',
    description: '',
    startdate: '',
    enddate: '',
    thumb: ''
}

export const fetchNearByCoaches = async (sport, radius) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/loadnearbycoaches`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sport: sport, radius: radius }),
        });
        const data = await response.json();
        return data
    } catch (error) {
        console.error(error);
    }
};

export const applyAsCoach = async (navigate, setCreating, dispatch, loadMyCoach) => {
    setCreating(true)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/applyascoach`);
        if (response.ok) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:You are now registerd as coach`);
                dispatch(loadMyCoach())
                setCreating(false)
            } catch (e) {
                setCreating(false)
            }
        } else {
            setCreating(false)
        }
    } catch (error) {
        navigate('/home/coaches')
    }
};

