import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { disableRefresh, handleShowBottomNav, handleShowTopNav, selectData } from "../home/homeSlice";
import { joinEvent, loadEventPage } from './eventsFuncs'
import { useSwipeable } from "react-swipeable";
import { FakeEventCard } from "./FakeEventCard";
import stopwatch from '../../Pics/stopwatch.png'
import nearByPic from '../../Pics/nearBy.png'
import oneuserGreen from '../../Pics/oneuserGreen.png'
import connects from "../../Pics/connects.png";
import tickets from "../../Pics/tickets.png";
import callgreen from "../../Pics/callgreen.png";
import repImg from '../../Pics/repImg.png'
import search from "../../Pics/search.png";


import { EventUsersList } from "./EventUsersList";
import { setMainId } from "../list/tempProfileSlice";
import { ReportEvent } from "./ReportEvent";
import { EventComments } from "./EventComments";

//import { FakeGroupCard } from "./FakeGroupCard";
//import { SmallGroupCard } from "./SmallGroupCard";
//import { GroupChat } from "./GroupChat";
//import { GroupMeetingTime } from "./GroupMeetingTime";

export function EventPage({ }) {
  const [myStatus, setMyStatus] = useState('NotGoing')
  const [eventState, setEventState] = useState('pending')
  const [commingCount, setCommingCount] = useState(0)
  const [showComingList, setShowComingList] = useState(null)
  const [filter, setFilter] = useState('overview');
  const [dynamicCommentsCount, setDynamicCommentsCount] = useState(0)
  const [eventDeleted, setEventDeleted] = useState(false)
  const [showReport, setShowReport] = useState(false)
  const [eventStatus, setEventStatus] = useState('none')
  const myInfo = useSelector(selectData)
  const [creating, setCreating] = useState(false)
  const [event, setEvent] = useState(null)
  const [loadingEvent, setLoadingEvent] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  let { eventid } = useParams();
  const scrollRef = useRef(0);
  const previousScrollTopRef = useRef(0);

  const photoPicker = (int) => {
    const picked = int.toLowerCase()
    return localStorage.getItem(picked)
  }

  useEffect(() => {
    let id = eventid.replace(":", "");
    loadEventPage(id, setEvent, setLoadingEvent)
  }, [dispatch, eventid]);

  useEffect(() => {
    try {
      if (event && loadingEvent === 'done' && new Date(event.eventtime).getTime() < Date.now()) {
        setEventState('completed')
      }
    } catch (error) {

    }
  }, [loadingEvent])

  useEffect(() => {
    try {
      if (loadingEvent === 'done' && event && event.users) {
        setCommingCount(event.users.length)
        const myMainid = myInfo.mainid
        const member = event.users.find((member) => member.mainid === myMainid);
        if (member) {
          setMyStatus('Going')
        }
      }
    } catch (e) {

    }
    try {
      if (loadingEvent === 'done' && event && event.commentscount) {
        setDynamicCommentsCount(event.commentscount)
      }
    } catch (e) {

    }
  }, [event, loadingEvent]);


  const openLinkOnMob = (link) => {
    dispatch(disableRefresh());
    try {
      window.ReactNativeWebView.postMessage(`link:${link}`);
    } catch (e) {
    }
  };

  const callMobile = (num) => {
    try {
      dispatch(disableRefresh())
      window.ReactNativeWebView.postMessage(`link:tel:${num}`);
    } catch (e) {
    }
  }


  const handleShowGoingList = () => {
    try {
      if (event && event.users) {
        setShowComingList(event.users)
      }
    } catch (e) {

    }
  }

  const handleSwipeRight = () => {
    navigate(-1);
  };

  const handleSwipeLeft = () => {
    navigate(+1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    delta: 50, // 20px threshold
  });

  useEffect(() => {
    if (eventDeleted === true) {
      navigate(-1)
    }
  }, [eventDeleted])

  const handleShowComment = () => {
    setFilter('comments');
    try {
      if (scrollRef) {
        scrollRef.current.scrollTop = 400;
      }

    } catch (e) {

    }

  }

  useEffect(() => {
    try {
      //const scrollRef = document.getElementById('feedsCont');
      const scrollElement = scrollRef.current;
      if (!scrollElement) return;
      const handleScroll = () => {
        const scrollHeight = scrollElement.scrollHeight;
        const scrollTop = scrollElement.scrollTop;
        const clientHeight = scrollElement.clientHeight;
        console.log(scrollHeight)
        console.log(scrollTop)
        console.log(clientHeight)


        const previousScrollTop = previousScrollTopRef.current;
        const isScrollingDown = scrollTop > previousScrollTop;
        const isScrollingUp = scrollTop < previousScrollTop;

        if (isScrollingUp) {
          dispatch(handleShowBottomNav(true));
          dispatch(handleShowTopNav(true));
        } else if (isScrollingDown && (scrollTop > (10))) {
          dispatch(handleShowBottomNav(false));
          dispatch(handleShowTopNav(false));

        }

        previousScrollTopRef.current = scrollTop;
      };

      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        // Cleanup: Remove the event listener when the component unmounts
        scrollElement.removeEventListener('scroll', handleScroll);
      };

    } catch (e) {

    }
  }, [event]);

  useEffect(() => {
    return () => {
      dispatch(handleShowBottomNav(true))
      dispatch(handleShowTopNav(true))
    }
  })

  if (loadingEvent === 'failed') {
    <div style={{ marginTop: '0px', width: '100%' }}>
      <h2 style={{ margin: '20px auto', color: 'white' }}>This Event is not alvailable</h2>
    </div>
  }

  if (loadingEvent === 'done') {
    return (

      <div style={{ top: '0px', height: '100vh' }} id="profileCont" className="feedPostsCont">
        <div ref={scrollRef} id="feedsCont" style={{ paddingTop: '45px', marginTop: '0px', marginBottom: '0px' }} className="scrollList">
          {/*
          creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
            <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
          </div> : ""
          */}
          {showReport ?
            <div className="fullScreenBlack" style={{ zIndex: '500' }} onClick={() => setShowReport(false)} >
              <ReportEvent eventname={event.eventname} eventid={event.eventid} creatorid={event.creatorid} setShowReport={setShowReport} setEventDeleted={setEventDeleted} myMainid={myInfo.mainid} />
            </div>
            : ""
          }
          {event && event.users && showComingList ?
            <EventUsersList users={event.users} eventName={event.eventname} setShowComingList={setShowComingList} />
            : ""
          }
          <div className="eventPageMainImgContainer">
            <img className="eventPageMainImg" src={event.image} alt="Event" />
          </div>
          <div className="eventPageBadge">
            <h1 style={{ marginBottom: '5px' }} className="eventPageTitle">{event.eventname}</h1>
            <p style={{ fontSize: "14px", marginBottom: '5px' }} >{event.eventtime ? (new Date(event.eventtime)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric' }) : ""} </p>
          </div>
          <div style={{ marginTop: "0px", display: 'flex' }} className="eventPageBadge">
            {eventState !== 'completed' ?
              <div>
                {myStatus === 'NotGoing' ?
                  <h6 onClick={() => joinEvent(event.eventid, setMyStatus, setCreating, commingCount, setCommingCount, setEvent, myInfo, event.creatorid)} style={{ width: '120px' }} className=" smallGreenButton activeBlack">{creating ? ". . ." : "Join Event"}</h6>
                  :
                  <h6 onClick={() => joinEvent(event.eventid, setMyStatus, setCreating, commingCount, setCommingCount, setEvent, myInfo, event.creatorid)} style={{ width: '120px', backgroundColor: 'grey' }} className=" smallGreenButton activeBlack">{creating ? ". . ." : "Leave Event"}</h6>
                }
              </div> :
              <p style={{ color: 'hsl(72, 100%, 47%)' }}>Completed</p>
            }
            <div onClick={handleShowGoingList} className="goingEventCount">
              <img src={localStorage.getItem('friendsPic')} />
              <h5><span style={{ marginRight: '5px', color: 'hsl(72, 100%, 47%)' }}>{commingCount}</span>{eventState === 'completed' ? "Went" : "Going"}</h5>
            </div>
            <div onClick={() => handleShowComment()} className="goingEventCount">
              <img style={{ filter: "invert(1)" }} src={localStorage.getItem('commentPic')} />
              <h5><span style={{ marginRight: '5px', color: 'hsl(72, 100%, 47%)' }}>{dynamicCommentsCount}</span></h5>
            </div>
            {event.creatorid === myInfo.mainid && eventState !== 'completed' && (<img onMouseUp={() => navigate(`/home/events/createevent/:${event.eventid}`)} className='editEventInPage' src={localStorage.getItem('edit')} />)}
            {event.creatorid !== myInfo.mainid && (<img onClick={() => setShowReport(true)} className='editEventInPage' style={{ filter: 'invert(1)' }} src={repImg} />)}

          </div>
          <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 28%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter" />

          <div className="eventPageBadge">
            <div style={{ display: "flex", position: 'absolute', bottom: '5px', right: '10px' }} >
              <div style={{ padding: '0px 0px', marginBottom: '5px', fontSize: '12px' }} className="setSportCreatePost fav1" >
                <img style={{ width: "85%", height: '85%' }} src={photoPicker(event.sport)} />
              </div>
            </div>
            <div onClick={() => openLinkOnMob(event.locationlink)} className="imgAndTextEvent activeBlack">
              <img src={nearByPic} />
              <p style={{
                textAlign: 'left',
                textDecoration: "underline",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: '90%'
              }}>{event.locationdisplay}</p>
            </div>
            <div className="imgAndTextEvent">
              <img src={stopwatch} />
              <p>{event.duration} Hours</p>
            </div>
            <div onClick={() => dispatch(setMainId(event.creatorid))} className="imgAndTextEvent activeBlack">
              <img src={oneuserGreen} />
              <p style={{ textDecoration: "underline" }}>{event.creatorname}</p>
            </div>
          </div>
          <div className="eventPageBadge">
            <div onClick={() => openLinkOnMob(event.ticket)} className="imgAndTextEvent activeBlack">
              <img src={tickets} />
              <p style={{
                textAlign: 'left',
                textDecoration: "underline",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: '90%'
              }}>{event.ticket}</p>
            </div>
            <div onClick={() => callMobile(event.phone_number)} className="imgAndTextEvent activeBlack">
              <img src={callgreen} />
              <p>{event.phone_number}</p>
            </div>
          </div>
          <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 28%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter" />
          <div style={{ marginTop: '-10px', marginBottom: '0px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
            <label className="activeBlack" onClick={() => setFilter('overview')} style={{ color: filter === 'overview' ? "" : '#f5f5f59c', fontSize: '12px' }} htmlFor="comment">OVERVIEW</label>
            <label className="activeBlack" onClick={() => setFilter('comments')} style={{ color: filter === 'comments' ? "" : '#f5f5f59c', fontSize: '12px' }} htmlFor="comment">DISCUSSION</label>
          </div>
          {filter === 'overview' && (
            <div className="eventPageBadge">
              <p className="overviewText">{event.caption}</p>
            </div>
          )}
          {filter === 'comments' && (
            <div className="eventPageBadge">
              <EventComments eventid={event.eventid} creatorid={event.creatorid} creating={creating} setCreating={setCreating} dynamicCommentsCount={dynamicCommentsCount} setDynamicCommentsCount={setDynamicCommentsCount} />
            </div>
          )}
          <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 28%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter" />

          <div onMouseUp={() => navigate('/home/groups')} className='noPrevWorkout'>
            <p>
              Don't Go Alone<br /> Create or join a Group?
            </p>
            <img src={localStorage.getItem('friends2')} />
          </div>
          <div style={{ height: "150px" }}></div>
        </div>
      </div >
    )
  } else {
    return (
      <div style={{ marginTop: '0px', width: '100%' }}>
        <FakeEventCard />
      </div>
    )
  }

}
