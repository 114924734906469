import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { disableRefresh, handleShowBottomNav, selectData } from "../home/homeSlice";
import { defEvent, loadEventPage } from './eventsFuncs'
import { useSwipeable } from "react-swipeable";
import { FakeEventCard } from "./FakeEventCard";
import stopwatch from '../../Pics/stopwatch.png'
import nearByPic from '../../Pics/nearBy.png'
import oneuserGreen from '../../Pics/oneuserGreen.png'
import connects from "../../Pics/connects.png";
import tickets from "../../Pics/tickets.png";
import callgreen from "../../Pics/callgreen.png";
import plus from "../../Pics/plus.png";
import search from "../../Pics/search.png"


import { EventUsersList } from "./EventUsersList";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import LocationPicker from "./LocationPicker";
import { SportPickerNew } from "../groups/SportPickerNew";
import { EventCard } from "./EventCard";

export function EventCreatorOrEditor({ event, setEvent }) {
  const [myStatus, setMyStatus] = useState('NotGoing')
  const [showMap, setShowMap] = useState(false)
  const [commingCount, setCommingCount] = useState(0)
  const [showComingList, setShowComingList] = useState(null)
  const [eventStatus, setEventStatus] = useState('none')
  const [editingField, setEditingField] = useState(null)
  const [fieldValue, setFieldValue] = useState("")
  const myInfo = useSelector(selectData)
  const [creating, setCreating] = useState(false)
  const [loadingEvent, setLoadingEvent] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  let { eventid } = useParams();
  const textareaRef = useRef(null);
  console.log(eventid)
  console.log(event)

  const openImagePicker = () => {
    try {
      dispatch(disableRefresh());
      window.ReactNativeWebView.postMessage("PickPhoto");
      if (/Android/.test(navigator.userAgent)) {

      }
    } catch (e) {

    }
  };

  useEffect(() => {
    if (eventid) {
      let id = eventid.replace(":", "");
      loadEventPage(id, setEvent, setLoadingEvent)
    } else {
      setLoadingEvent('done')
    }
  }, [dispatch, eventid]);

  useEffect(() => {
    try {
      if (loadingEvent === 'done' && event && event.users) {
        setCommingCount(event.users.length)
        const myMainid = myInfo.mainid
        const member = event.users.find((member) => member.mainid === myMainid);
        if (member) {
          setMyStatus('Going')
        }
      }
    } catch (e) { }
  }, [event, loadingEvent]);

  const openLinkOnMob = (link) => {
    dispatch(disableRefresh());
    try {
      window.ReactNativeWebView.postMessage(`link:${link}`);
    } catch (e) { }
  };

  const callMobile = (num) => {
    try {
      dispatch(disableRefresh())
      window.ReactNativeWebView.postMessage(`link:tel:${num}`);
    } catch (e) { }
  };

  const handleShowGoingList = () => {
    try {
      if (event && event.users) {
        setShowComingList(event.users)
      }
    } catch (e) { }
  };

  const handleSwipeRight = () => {
    navigate(-1);
  };

  const handleSwipeLeft = () => {
    navigate(+1);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    delta: 50, // 20px threshold
  });

  const handleEdit = (field, value) => {
    setEditingField(field);
    setFieldValue(value);
  };

  const handleSave = (field) => {
    // Check if the field value is empty or just whitespace
    if (!fieldValue) {
      // Reset to default value from defTemp if no value is provided
      setEvent({ ...event, [field]: defEvent[field] });
    } else {
      // Update with new value
      if (field === "eventtime") {
        setEvent({ ...event, [field]: new Date(fieldValue).toISOString() });
      } else {
        setEvent({ ...event, [field]: fieldValue });
      }
    }
    setEditingField(null);
  };

  const handleEditEvent = async () => {
    setCreating(true)
    if (event.image === defEvent.image || event.sport === null || event.eventname === defEvent.eventname || event.caption === defEvent.caption || event.locationdisplay === defEvent.locationdisplay) {
      setCreating(false)
      try {
        window.ReactNativeWebView.postMessage(`Alert:Make sure u updated all required info. Event Cover Photo, name, sport, time, location, description`);
      } catch (e) {

      }
      console.error('Please select a photo and add a caption');
      return;
    }
    const formData = new FormData();

    formData.append('eventid', event.eventid);
    if (event.image && event.image.includes('https://') && !event.image64) {
      formData.append('image', event.image);
    } else {
      const byteCharacters = window.atob(event.image64);
      const byteArrays = [];
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }
      let type
      type = 'image/jpeg'
      const blob = new Blob([new Uint8Array(byteArrays)], { type: type });
      formData.append('image', blob, 'event.jpg');
    }
    formData.append('eventname', event.eventname);
    formData.append('eventtime', event.eventtime); // Use ISO format for sending timestamp
    formData.append('caption', event.caption);
    formData.append('sport', event.sport);
    formData.append('location', event.location);
    formData.append('locationdisplay', event.locationdisplay);
    formData.append('locationlink', event.locationlink);
    formData.append('duration', event.duration);
    if (event.ticket === defEvent.ticket) {
      formData.append('ticket', 'Join through Fit-Buddy');
    } else {
      formData.append('ticket', event.ticket);
    }
    formData.append('phone_number', event.phone_number);



    try {
      // Send the FormData to the server using the fetch API
      const response = await fetch('/api/events/editevent', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const data = await response.json()
        setCreating(false)
        navigate(`/home/event/:${data.eventid}`)
        setEvent(defEvent)
      } else {
        setCreating(false)
        console.error('Post creation failed:', response.status);
      }
    } catch (error) {
      setCreating(false)
      console.error('Error creating post:', error);
    }
  };


  const handleDeleteEvent = async () => {
    setCreating(true);
    if (event.eventid === null) {
      setCreating(false);
      return;
    }
    try {
      // Send the JSON data to the server using the fetch API
      const response = await fetch('/api/events/deleteevent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ eventid: event.eventid }),
      });

      if (response.ok) {
        setCreating(false);
        navigate('/home/events');
        setEvent(defEvent)
      } else {
        setCreating(false);
        console.error('Event deletion failed:', response.status);
      }
    } catch (error) {
      setCreating(false);
      console.error('Error deleting event:', error);
    }
  };



  const renderField = (field, value) => {
    if (editingField === field) {
      if (field === "eventtime") {
        return (
          <input
            type="datetime-local"
            value={new Date(event.eventtime).toISOString().slice(0, 0)}
            onChange={(e) => {
              if (new Date(e.target.value).getTime() < Date.now()) {
                try {
                  window.ReactNativeWebView.postMessage(`Alert:Please choose a time in the future `);
                } catch (e) {

                }
              } else {
                setEvent({ ...event, eventtime: new Date(e.target.value).getTime() })
              }
            }}
            onBlur={() => setEditingField(null)}
            autoFocus
            className="editableInput"
          />
        );
      } else if (field === 'phone_number') {
        return (

          <PhoneInputWithCountrySelect
            placeholder="Enter phone number"
            value={fieldValue || ""}
            onChange={(value) => setFieldValue(value)}
            onBlur={() => handleSave(field)}
            autoFocus
            className="editableInput"
            international
            defaultCountry="US"
          />
        );
      } else if (field === 'duration') {
        return (
          <div>
            <input
              inputMode="numeric"
              style={{ width: '100px' }}
              type="number"
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={() => handleSave(field)}
              className="editableInput"
              autoFocus
            />
            <button onClick={() => setEditingField(null)}>Confirm</button>
          </div>

        );
      } else if (field === 'caption') {
        return (
          <div>
            <textarea
              ref={textareaRef}
              value={fieldValue}
              onChange={(e) => {
                setFieldValue(e.target.value);
                e.target.style.height = 'auto';
                e.target.style.height = e.target.scrollHeight + 'px';
              }}
              onBlur={() => handleSave(field)}
              autoFocus
              className="editableInput"
              style={{ textAlign: 'left', resize: 'none', overflow: 'hidden' }}
            />
            <button onClick={() => setEditingField(null)}>Confirm</button>
          </div>
        );
      } else {

        return (

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
              style={{ width: "90%", margin: 'auto', marginBottom: '5px' }}
              type="text"
              value={fieldValue || ""}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={() => handleSave(field)}
              autoFocus
              className="editableInput"
            />
            <button onClick={() => setEditingField(null)}>Confirm</button>
          </div>

        );
      }
    }
    return <span style={{ width: '100%' }} onClick={() => {
      handleEdit(field, value);
      if (event[field] === defEvent[field]) {
        setFieldValue("")
      }
    }
    }> {value}</span >;
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [fieldValue, editingField]);

  if (loadingEvent === 'failed') {
    navigate('/home/events/createevent')
  }

  useEffect(() => {
    try {
      if (event.eventtime.startsWith('20')) {
        setEvent({ ...event, eventtime: new Date(event.eventtime).getTime() })
      }
    } catch (e) {

    }
  }, [event.eventtime]);


  if (loadingEvent === 'done') {
    return (
      <div>
        <div style={{ color: 'white', marginTop: '10px', marginBottom: '10px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="">
          <h3 style={{ textAlign: 'left' }}>Event Page preview:</h3>
          <h5 style={{ textAlign: 'left', marginLeft: '10px' }}>- Click on the photo to update the event cover image</h5>
          <h5 style={{ textAlign: 'left', marginLeft: '10px' }}>- Set event name, sport, time, location, duration, description, tickts if any & contact</h5>
        </div>
        <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px' }} className="feedFilter" />

        {showMap && (
          <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
            <LocationPicker event={event} setEvent={setEvent} setShowMap={setShowMap} mylocation={myInfo.location} />
          </div>

        )}
        {creating ? <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
          <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
        </div> : ""}
        {event && event.users && showComingList ? (
          <EventUsersList users={event.users} eventName={event.eventname} setShowComingList={setShowComingList} />
        ) : (
          ""
        )}
        <div className="eventPageMainImgContainer">
          <img onMouseUp={openImagePicker} className="eventPageMainImg" src={event.image} alt="Event" />
        </div>
        <div className="eventPageBadge">
          <h1 style={{ textDecoration: "underline", marginBottom: '5px' }} className="eventPageTitle">
            {renderField("eventname", event.eventname)}
          </h1>
          <p style={{ width: 'fit-content', minWidth: '100px', textDecoration: "underline", fontSize: "14px", marginBottom: '5px' }}>
            {renderField("eventtime", event.eventtime ? (new Date(event.eventtime)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric' }) : "")}
          </p>
        </div>
        <div style={{ marginTop: "0px", display: 'flex' }} className="eventPageBadge">
          {myStatus === 'NotGoing' ? (
            <h6 className="smallGreenButton activeBlack">{creating ? ". . ." : "Join Event"}</h6>
          ) : (
            <h6 style={{ backgroundColor: 'grey' }} className="smallGreenButton activeBlack">{creating ? ". . ." : "Leave Event"}</h6>
          )}
          <div className="goingEventCount">
            <img src={connects} alt="Connects" />
            <h5>
              <span style={{ marginRight: '5px', color: 'hsl(72, 100%, 47%)' }}>{commingCount}</span>Going
            </h5>
          </div>
          <div className="goingEventCount">
            <img style={{ filter: "invert(1)" }} src={localStorage.getItem('commentPic')} />
            <h5><span style={{ marginRight: '5px', color: 'hsl(72, 100%, 47%)' }}>{10}</span></h5>
          </div>
        </div>
        <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 28%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter" />
        <div className="eventPageBadge">
          <div style={{ display: "flex", position: 'absolute', bottom: '5px', right: '10px' }} >
            <SportPickerNew selectedSport={event.sport} setSelectedSport={(sport) => setEvent({ ...event, sport: sport })} />
          </div>
          <div className="imgAndTextEvent ">
            <img src={nearByPic} alt="Nearby" />
            <p onClick={() => setShowMap(true)} style={{
              textAlign: 'left',
              textDecoration: "underline",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: '90%'
            }}>
              {event.locationdisplay}
            </p>
          </div>
          <div className="imgAndTextEvent">
            <img src={stopwatch} alt="Stopwatch" />
            <p style={{ textDecoration: "underline" }} onClick={() => handleEdit('duration', event.duration)}>{renderField("duration", event.duration)} Hours</p>
          </div>
          <div className="imgAndTextEvent ">
            <img src={oneuserGreen} alt="Creator" />
            <p>
              {myInfo.name}
            </p>
          </div>
        </div>
        <div className="eventPageBadge">
          <div className="imgAndTextEvent " >
            <img src={tickets} alt="Tickets" />
            <p style={{
              textAlign: 'left',
              textDecoration: "underline",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              width: '90%'
            }}>{renderField("ticket", event.ticket)}</p>
          </div>
          <div className="imgAndTextEvent  phoneNumberEventEdit" >
            <img src={callgreen} alt="Call" />
            <PhoneInputWithCountrySelect
              placeholder="Enter phone number"
              value={event.phone_number}
              onChange={(value) => setEvent({ ...event, phone_number: value })}
              //onBlur={() => handleSave(field)}
              style={{ width: '100%' }}
              international
            />
          </div>
        </div>
        <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 28%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter" />
        <div style={{ marginTop: '-10px', marginBottom: '0px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
          <label className="activeBlack" style={{ fontSize: '12px' }} htmlFor="comment">OVERVIEW</label>
          <label className="activeBlack" style={{ color: '#f5f5f59c', fontSize: '12px' }} htmlFor="comment">DISCUSSION</label>
        </div>
        <div style={{ minHeight: '100px' }} className="eventPageBadge">
          <p style={{ textDecoration: 'underline' }} className="overviewText">{renderField("caption", event.caption)}</p>
        </div>
        <div style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px' }} className="feedFilter" />
        <div style={{ color: 'white', marginTop: '20px', marginBottom: '20px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="">
          <h3 style={{ textAlign: 'left' }}>Event Card preview:</h3>
        </div>
        <div className="eventCardHider" />
        <EventCard eventid={event.eventid} image={event.image} eventname={event.eventname} location={event.location} eventtime={event.eventtime} sport={event.sport} caption={event.caption} locationdisplay={event.locationdisplay} creatorid={myInfo.mainid} hiddenby={event.hiddenby} goingcount={commingCount} commentscount={10} />
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', height: '100px', width: 'fit-content' }} className="">
          <p onClick={handleEditEvent} className="singleButton">Submit</p>
          {event.eventid !== null && event.status !== 'completed' && (<p style={{ color: 'red', height: '30px', width: '100px' }} onClick={handleDeleteEvent} className="singleButton2">Delete Event</p>)}
        </div>
        <div style={{ height: "150px" }}></div>
      </div>
    )
  } else {
    return (
      <div style={{ marginTop: '0px', width: '100%' }}>
        <FakeEventCard />
      </div>
    )
  }
}
