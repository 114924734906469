import React, { useState, useCallback, useRef } from 'react';
import { LoadScript, GoogleMap, Marker, useJsApiLoader, Autocomplete } from '@react-google-maps/api';

const libraries = ["places"];
const mapContainerStyle = {
    height: "400px",
    width: "100%"
};

const LocationPicker = ({ event, setEvent, setShowMap, mylocation }) => {
    const [map, setMap] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [autocomplete, setAutocomplete] = useState(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries
    });

    const initialCenter = (() => {
        try {
            const parsedLocation = JSON.parse(mylocation);
            const lat = parseFloat(parsedLocation.latitude);
            const lng = parseFloat(parsedLocation.longitude);

            if (isNaN(lat) || isNaN(lng)) {
                throw new Error("Invalid latitude or longitude");
            }

            return { lat, lng };
        } catch (e) {
            console.error(e);
            return { lat: 0, lng: 0 };
        }
    })();

    const [center, setCenter] = useState(initialCenter);

    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
    }, [center]);

    const onUnmount = useCallback(function callback(map) {
        setMap(null);
    }, []);

    const onMapClick = useCallback((event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK' && results[0]) {                
                setSelectedLocation({
                    name: results[0].formatted_address,
                    latitude: lat,
                    longitude: lng
                });
                setCenter({ lat, lng }); // Update the center to the new selected location
            }
        });
    }, []);

    const onPlaceChanged = () => {
        if (autocomplete !== null) {
            const place = autocomplete.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                setCenter({ lat, lng }); // Update the center to the selected place
                map.panTo({ lat, lng });
                map.setZoom(15);
            }
        }
    };

    const onAutocompleteLoad = (autocomplete) => {
        setAutocomplete(autocomplete);
    };

    const confirmLocation = () => {
        if (selectedLocation) {
            const { latitude, longitude, name } = selectedLocation;
            const locationLink = `https://www.google.com/maps?q=${latitude},${longitude}`;
            setEvent((prevEvent) => ({
                ...prevEvent,
                locationdisplay: name,
                location: JSON.stringify({
                    latitude,
                    longitude
                }),
                locationlink: locationLink
            }));
            setShowMap(false);
        }
    };

    const closeMap = () => {
        setShowMap(false);
    };

    return isLoaded ? (
        <div style={{ marginTop: '0px', width: '100%' }}>
            <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
                <input
                    type="text"
                    placeholder="Search for a location"
                    style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
                />
            </Autocomplete>
            <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={center}
                zoom={10}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onMapClick}
            >
                {selectedLocation && (
                    <Marker
                        position={{ lat: selectedLocation.latitude, lng: selectedLocation.longitude }}
                    />
                )}
            </GoogleMap>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                <button onClick={confirmLocation} style={{ padding: '10px', marginRight: '10px' }}>Confirm Location</button>
                <button onClick={closeMap} style={{ padding: '10px' }}>Close Map</button>
            </div>
        </div>
    ) : <></>;
};

export default LocationPicker;
