import React, { useEffect, useState } from "react";
import socket from "../../app/io";
import { ReportButton } from "./ReportButton";
import { useDispatch } from "react-redux";
import { setInfo } from "../root/repChoiceSlice";
import repImg from '../../Pics/repImg.png';
import TypingIndicator from "./TypingIndicator";

export function OnChatCard({ user, isTyping, isRecording }) {
    const [online, setOnLine] = useState('loading');
    const dispatch = useDispatch();

    const openReport = () => {
        dispatch(setInfo(user));
    };

    useEffect(() => {
        try {
            socket.emit('checkUserOnline', { friendId: user.mainid }, (response) => {
                setOnLine(response);
            });
        } catch (error) {

        }
    }, []);


    useEffect(() => {
        // Listen for typing events from the server
        socket.on('friendJoined', ({ userId }) => {
            console.log(userId)
            try {
                if (userId === user.mainid) {
                    setOnLine(true)
                }
            } catch (e) {

            }
        });

        // Clean up the event listener on component unmount
        return () => {
            socket.off('friendJoined');
        };
    }, []);

    useEffect(() => {
        // Listen for typing events from the server
        socket.on('friendLeft', ({ userId }) => {
            console.log(userId)
            try {
                if (userId === user.mainid) {
                    setOnLine(false)
                }
            } catch (e) {

            }
        });
        // Clean up the event listener on component unmount
        return () => {
            socket.off('friendLeft');
        };
    }, []);

    return (
        <div className="onChatCard">
            <img src={user.picture_url} alt={`${user.name}'s profile`} className="user-picture" />
            <div className="info">
                <div className="name-status">
                    {online === 'loading' && <img className="loading-icon" src="https://i.gifer.com/ZKZg.gif" alt="Loading..." />}
                    {online === true && <div className="status-indicator online" />}
                    {online === false && <div className="status-indicator offline" />}
                    <p className="user-name">{user.name}</p>
                </div>
                <div style={{ minHeight: '20px', width: '100%' }}>
                    {online === true && (isTyping || isRecording) && <TypingIndicator isTyping={isTyping} isRecording={isRecording} />}
                </div>
            </div>
            <img className="report-icon" onClick={openReport} src={repImg} alt="Report" />
        </div>
    );
}
