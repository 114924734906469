import React, { useEffect, useRef, useState } from 'react';
import socket from "../../app/io";

const SingleChatInput = ({
    record, uploadingRecord, photo64, selectedVideo, inputText, setInputText, adjustTextareaHeight,
    scrollToBottom, handleSendAudio, handleStartRecording, handleSendMessage, handleStopRecording,
    handleCancel, handleSendPhoto, handleSendVideo, openImagePicker, recording, selectedPhoto, uploadingPhoto,
    closePic, changePP, stopRecordPic, inputBox, friendid
}) => {
    const typingTimeoutRef = useRef(null);
    const lastTextRef = useRef(inputText);
    const iamTypingRef = useRef(false);

    useEffect(() => {
        try {
            // Clear previous timeout if input changes
            if (typingTimeoutRef.current) {
                clearTimeout(typingTimeoutRef.current);
            }

            // If there is text, start typing
            if (inputText.trim() !== '') {
                if (!iamTypingRef.current) {
                    socket.emit('typing', friendid);
                    iamTypingRef.current = true;
                }

                // Set timeout to detect when the user has stopped typing
                typingTimeoutRef.current = setTimeout(() => {
                    if (iamTypingRef.current) {
                        socket.emit('stopTyping', friendid);
                        iamTypingRef.current = false;
                    }
                }, 2000);
            } else if (iamTypingRef.current) {
                // If input is cleared, emit 'stopTyping' immediately
                socket.emit('stopTyping', friendid);
                iamTypingRef.current = false;
            }

            // Update lastTextRef to the current inputText
            lastTextRef.current = inputText;

            // Cleanup function to clear the timeout
            return () => clearTimeout(typingTimeoutRef.current);
        } catch (error) {

        }
    }, [inputText]);

    useEffect(() => {
        return () => {
            try {
                if (iamTypingRef.current === true) {
                    socket.emit('stopTyping', friendid);
                }
            } catch (error) {

            }
        }
    }, [])

    return (
        <div className="input-container">
            {/*
        <img className="backArrow2" src={backArrow} onClick={back} />
        */}

            <div style={{ display: !record ? 'none' : "", width: "70%" }}>
                {
                    uploadingRecord ?
                        <h5 className="uploadingAudio">Uploading ....</h5>
                        :
                        <audio controlsList="nodownload noplaybackrate" className="audioChat" src={record} controls />

                }
            </div>
            {photo64 || selectedVideo ?
                <div></div>
                :
                <textarea
                    style={{ position: record || selectedVideo ? 'absolute' : '', bottom: record ? '-100px' : '' }}
                    ref={inputBox}
                    value={inputText}
                    onChange={(e) => {
                        setInputText(e.target.value);
                        adjustTextareaHeight(); // Adjust the height as the user types                        
                    }}
                    onFocus={() => {
                        setTimeout(() => {
                            scrollToBottom()
                        }, 150);
                    }}
                    inputMode="text"
                    enterKeyHint="Return"
                    placeholder="send message"
                    rows="1" // Start with a single row
                    className="auto-adjusting-textarea"
                />
            }
            {record ?
                <img className="activeBlack chatButtons" onClick={handleSendAudio} style={{ transform: 'rotate(180deg)' }} src={localStorage.getItem('thinback')} />
                : ""
            }
            {recording === false ?
                (inputText === "" ?
                    <div className="sendTextMsgButtonCont">
                        <img className="activeBlack chatButtons" onClick={openImagePicker} style={{ display: record ? "none" : "", filter: 'invert(1)' }} src={changePP} />
                        <img className="activeBlack chatButtons" onClick={handleStartRecording} style={{ filter: 'invert(1)' }} src={localStorage.getItem('recordPic')} />
                    </div>
                    :
                    <div className="sendTextMsgButtonCont">
                        <img onClick={handleSendMessage} className=" activeBlack chatButtons" style={{ zIndex: '100', transform: 'rotate(180deg)', zIndex: '50' }} src={localStorage.getItem('thinback')} />
                        <div style={{ height: inputBox && inputBox.current.style.height ? inputBox.current.style.height : '50px', maxHeight: '150px', zIndex: '500', minHeight: '100px' }} onClick={handleSendMessage} className="sendTextMsgButtonHelper chatButtons" />
                    </div>
                )
                :
                <img style={{}} className="activeBlack chatButtons" onClick={handleStopRecording} src={stopRecordPic} />
            }
            {record ?
                <img className="activeBlack chatButtons" onClick={handleCancel} style={{ filter: 'invert(1)' }} src={closePic} />
                : ""
            }
            {photo64 ?
                <div className="picHandlerCont">
                    <img className="prevPicChat" src={selectedPhoto} />
                    <div style={{ width: '100%' }}>
                        {uploadingPhoto ?
                            <div>
                                <p style={{ height: "32px", color: 'white' }}>Uploading...</p>
                            </div>
                            :
                            <div className="picButtonsCont">
                                <img className="activeBlack chatButtons" onClick={handleCancel} style={{ filter: 'invert(1)' }} src={closePic} />
                                <img onClick={handleSendPhoto} className=" activeBlack chatButtons" style={{ zIndex: '100', transform: 'rotate(180deg)' }} src={localStorage.getItem('thinback')} />
                            </div>

                        }
                    </div>
                </div>
                : ""
            }
            {selectedVideo ?
                <div className="picHandlerCont">
                    <video
                        onLoadedData={(e) => e.target.play()}
                        style={{ maxHeight: '40vh', minHeight: '40vh', backgroundColor: "black" }}
                        loop
                        src={selectedVideo}
                        controls
                        autoPlay
                        playsInline
                    />
                    <div style={{ width: '100%' }}>
                        {uploadingPhoto ?
                            <div>
                                <p style={{ height: "32px", color: 'white' }}>Uploading...</p>
                            </div>
                            :
                            <div className="picButtonsCont">
                                <img className="activeBlack chatButtons" onClick={handleCancel} style={{ filter: 'invert(1)' }} src={closePic} />
                                <img onClick={handleSendVideo} className=" activeBlack chatButtons" style={{ zIndex: '100', transform: 'rotate(180deg)' }} src={localStorage.getItem('thinback')} />
                            </div>

                        }
                    </div>
                </div>
                : ""
            }
        </div>
    );
};

export default SingleChatInput;
