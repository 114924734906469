import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMyCoaches, selectMyCoaches, selectMyCoachesStatus } from './coachesSlice';
import { CoachCard } from './CoachCard';
import { FakeCoachCard } from './FakeCoachCard';
import { useNavigate } from 'react-router-dom';


export const MyCoaches = ({ myMainid }) => {
    const [updating, setUpdating] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState("Confirmed");
    const myCoaches = useSelector(selectMyCoaches)
    const myCoachesStatus = useSelector(selectMyCoachesStatus)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        dispatch(loadMyCoaches())
    }, [])

    const setOpenCoachProfileEdit = () => {
        console.log('not my profile')
    }

    return (
        <div>
            <div style={{ height: '45px', padding: '0px 50px', gap: '25px', borderRadius: '0px' }} className="feedFilter">
                <label className="activeBlack" onClick={() => setSelectedFilter('Confirmed')} style={{ color: selectedFilter === 'Confirmed' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Accepted</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Requests')} style={{ color: selectedFilter === 'Requests' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Pending</label>
            </div>
            {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                <p className='fake'> Confirming...</p>
            </div> : ""}
            {myCoachesStatus === 'loading' ?
                <FakeCoachCard />
                : ""
            }
            {myCoachesStatus === 'done' && selectedFilter === 'Confirmed' && myCoaches ? (
                <div className='studentsList'>
                    {myCoaches.map((coach) => {
                        if (coach.status === 'accepted') {
                            return (
                                <div key={coach.coachid} onMouseUp={() => navigate(`/home/coaches/coach/:${coach.coachid}`)}>
                                    < CoachCard coach={coach} myMainid={myMainid} setOpenCoachProfileEdit={setOpenCoachProfileEdit} />
                                </div>)
                        }
                    })}
                </div>
            ) : null}
            {myCoachesStatus === 'done' && selectedFilter === 'Requests' && myCoaches ?
                <div className='studentsList'>
                    {myCoaches.map((coach) => {
                        if (coach.status === 'request') {
                            return (
                                <div key={coach.coachid} onMouseUp={() => navigate(`/home/coaches/coach/:${coach.coachid}`)}>
                                    < CoachCard coach={coach} myMainid={myMainid} setOpenCoachProfileEdit={setOpenCoachProfileEdit} />
                                </div>
                            )
                        }
                    })}
                </div>
                :
                ''
            }

        </div>
    );
};
