import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoachCard } from './CoachCard';
import { loadNearByCoaches, selectcoachesNearBy, selectcoachesNearByStatus } from './coachesSlice';
import { useNavigate } from 'react-router-dom';
import { FakeCoachCard } from './FakeCoachCard';
import { SportPickerNew } from '../groups/SportPickerNew';
import Radius from '../intButtons/Radius';


export const DiscoverCoaches = ({ myMainid }) => {
    const [selectedSport, setSelectedSport] = useState(localStorage.getItem('FindCoachSport') || null);
    const [coachRadius, setCoachRadius] = useState(localStorage.getItem('Radius') || 40)
    const dispatch = useDispatch()
    const coachesNearBy = useSelector(selectcoachesNearBy)
    const coachesNearByStatus = useSelector(selectcoachesNearByStatus)
    const navigate = useNavigate()


    useEffect(() => {
        if (selectedSport) {
            const radius = coachRadius
            const sport = selectedSport
            dispatch(loadNearByCoaches({ sport, radius }))
            localStorage.setItem('FindCoachSport', sport);
        }
    }, [selectedSport])

    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    const setOpenCoachProfileEdit = () => {
        console.log('Not Your Profile')
    }

    return (
        <div>
            <div className='findGroupsHeader'>
                <Radius radius={coachRadius} setRadius={setCoachRadius} />
                <div style={{ width: '50px', height: '50px' }}>
                    <SportPickerNew selectedSport={selectedSport} setSelectedSport={setSelectedSport} />
                </div>
            </div>
            {coachesNearByStatus === 'done' && coachesNearBy.length === 0 && selectedSport ?
                <div style={{ display: 'flex', height: '100px' }}>
                    <h3 style={{ color: 'white' }}>No Available {selectedSport} Coaches NearBy</h3>
                </div>
                :
                ""
            }
            {coachesNearByStatus === 'done' && coachesNearBy ?
                coachesNearBy.map((coach) => {
                    return (
                        <div key={coach.coachid} onMouseUp={() => navigate(`/home/coaches/coach/:${coach.coachid}`)}>
                            < CoachCard coach={coach} myMainid={myMainid} setOpenCoachProfileEdit={setOpenCoachProfileEdit} />
                        </div>
                    )
                })
                :
                < div >
                    {coachesNearByStatus === 'loading' ?
                        <div>
                            <FakeCoachCard />
                            <FakeCoachCard />
                            <FakeCoachCard />
                        </div>
                        :
                        ""
                    }
                </div>
            }
        </div >
    );
};
