import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectData } from "../home/homeSlice";
import html2canvas from 'html2canvas';
import AppleDownload from '../../Pics/AppleDownload.png'
import GoogleDownload from '../../Pics/GoogleDownload.png'
import Logos from '../../Pics/Logos.png'
import TimeView from "./TimeView";
import './share.css'

export function NoPlayers({ time, preflocation, interest }) {
    const [generatedImageURL, setGeneratedImageURL] = useState(null); // Add state for the generated image URL
    const info = useSelector(selectData);
    const [generating, setGenerating] = useState(false)
    const imageRef = useRef(null);

    const photoPicker = (int) => {
        try {
            const picked = int.toLowerCase()
            return localStorage.getItem(picked)
        } catch (e) {

        }
    }

    const handleImageShare = async () => {
        setGenerating(true)
        setTimeout(() => {
            setGenerating(false)
        }, /Android/.test(navigator.userAgent) ? 6000 : 2500);
        try {
            const canvas = await html2canvas(imageRef.current, {
                useCORS: true,
                allowTaint: true,
            });
            const imageBlob = await new Promise((resolve) => {
                canvas.toBlob(resolve, 'image/png');
            });
            //const imageURL = URL.createObjectURL(imageBlob);
            //setGeneratedImageURL(imageURL);
            if (/Android/.test(navigator.userAgent)) {
                const reader = new FileReader();
                reader.readAsDataURL(imageBlob);
                reader.onloadend = () => {
                    const base64Data = reader.result.split(',')[1]; // Get the base64 data part
                    try {
                        window.ReactNativeWebView.postMessage(`ShareImage:${base64Data}`);
                    } catch (e) {

                    }
                };
            } else {
                const data = {
                    files: [
                        new File([imageBlob], 'Fit-Buddy.png', {
                            type: imageBlob.type
                        }),
                    ]
                };
                try {
                    if (navigator.share && navigator.canShare(data)) {
                        await navigator.share(data);
                    } else {
                        // Do something else like copying the data to the clipboard
                    }
                } catch (e) {
                    // Handle share error
                }
            }

        } catch (e) {
            // Handle error
        }
    }

    const handleLinkShare = async () => {
        setGenerating(true)
        setTimeout(() => {
            setGenerating(false)
        }, /Android/.test(navigator.userAgent) ? 2000 : 1000);
        if (/Android/.test(navigator.userAgent)) {
            try {
                window.ReactNativeWebView.postMessage(`ShareLink:https://fitbuddy.fit`);
            } catch (e) {
            }
        } else {
            try {
                const data = {
                    title: 'Fit-Buddy',
                    text: 'Join me on Fit-Buddy',
                    url: `https://fitbuddy.fit`,
                };

                try {
                    if (navigator.share && navigator.canShare(data)) {
                        await navigator.share(data);
                    } else {
                        // Do something else like copying the data to the clipboard
                    }
                } catch (e) {
                    // Handle share error
                }
            } catch (e) {
                // Handle error
            }
        }

    }

    return (
        <div className="NoPlayersCont">
            {generating ? <div className='CreatingPost'>
                <p className='fake'> Generating ...</p>
            </div> : ""}

            {window.location.pathname === '/home/workout/announce' ?
                <div id="sharedIMG" ref={imageRef} className="shareCont">
                    {/*<img src={`${process.env.REACT_APP_API_URL}/proxy-image?url=` + info.picture_url} alt="User Profile Picture" className="shareContProfileImage" />
                    <img className="shareContInterestImg" src={photoPicker(interest)} alt="Interested Sport" />
                    <p className="shareContInterest">Interested in {interest}</p>
                    <h2 className="shareContName">{info.name}</h2>
                    <div className="shareContTime"> <TimeView time={time} /></div>
                    <p className="shareContLocation">Location: {preflocation ? preflocation : 'Any Where'}</p>
                    <img className="shareContLogo" src={Logos} />
                    <p className="shareContJoinMe">Join me on Fit-Buddy and Find like minded Fit-Buddies</p>
                    <div className="join-button" >
                        <img style={{ height: '30px' }} src={AppleDownload} />
                        <img style={{ height: '30px' }} src={GoogleDownload} />
                    </div>*/}
                </div>
                : ""
            }
            <div style={{ display: 'flex', gap: '15px' }}>
                {/*window.location.pathname === '/home/workout/announce' ? <button style={{ height: '20px', width: '140px' }} className="shareButton singleButton2" onClick={handleImageShare}>Share This Image</button> : ""*/}
                <button style={{ height: '20px', width: '100px' }} className="shareButton singleButton" onClick={handleLinkShare}>Share</button>
            </div>
            {
                generatedImageURL && ( // Render the img element if generatedImageURL is not null
                    <img src={generatedImageURL} alt="Generated Image" />
                )
            }
        </div>
    );
}
