import { isMobile } from 'react-device-detect';

export default async function subToAPP(setSubColor) {
  try {
    if (isMobile) {
      window.ReactNativeWebView.postMessage("SubscribeNotification");
      return 'hsla(72, 100%, 47%, 65%)'
    }
  } catch (error) {

  }
  try {
    const response = await fetch('/api/publicKey');
    const data = await response.json();
    const vapidPublicKey = await data.publicKey;

    const registration = await navigator.serviceWorker.ready;
    const subscription = await registration.pushManager.getSubscription();

    if (subscription) {
      // Unsubscribe the user from the current subscription
      await subscription.unsubscribe();
    }

    const expirationTime = Math.floor(Date.now() / 1000) + (7 * 24 * 60 * 60); // 1 week in seconds

    const newSubscriptionOptions = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidPublicKey),
      expirationTime: expirationTime,
    };

    const newSubscription = await registration.pushManager.subscribe(newSubscriptionOptions);

    // Send the newSubscription to the server
    await fetch('/api/subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newSubscription),
    });

    return 'hsla(72, 100%, 47%, 65%)';
  } catch (error) {
    console.error('Failed to subscribe to notifications:', error);
    throw error;
  }
}



function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

