import React, { useEffect, useState } from "react";
import "./App.css";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  useLocation
} from "react-router-dom";
import { motion } from "framer-motion"
import { DownloadNow } from "./features/root/DownloadNow";
import { UpdateNow } from './features/root/UpdateNow'
import { Founders } from './features/founders/Founders'
import { Admin } from './features/admin/Admin'
import { Root } from "./features/root/Root";
import { Help } from './features/root/Help'
import { Policy } from './features/root/Policy'
import { EULA } from './features/root/EULA'
import { Login } from "./features/login/Login";
import { Home } from "./features/home/Home";
import { MyProfile } from "./features/root/MyProfile";
import { Friends } from './features/friends/Friends'
import { User } from "./features/user/User";
import { IntButtons } from "./features/intButtons/IntButtons";
import { PlayersList } from './features/list/PlayersList'
import { Chats } from './features/chats/Chats'
import { PostCreator } from "./features/Posts/PostCreator";
import { SoloPost } from "./features/Posts/SoloPost";
import { MyPage } from "./features/myPage/MyPage";
import { Feed } from "./features/feed/Feed";
import { Search } from "./features/feed/Search";
import { PromoEvent } from "./features/reqs/PromoEvent";
import { Events } from "./features/events/Events"
import { Achievements } from "./features/achievements/Achievements";
import { MyWorkouts } from "./features/workoutsManager/MyWorkouts";
import { StopWatchOnly } from "./features/workoutsManager/StopWatchOnly";
import { Coaches } from "./features/coaches/Coaches";
import { selectData } from "./features/home/homeSlice";
import { useSelector } from "react-redux";
import { CoachAccount } from "./features/coaches/CoachAccount";
import { CoachesNearby } from "./features/coaches/CoachesNearby";
import { CoachPage } from "./features/coaches/CoachPage";
import { UserPosts } from "./features/user/UserPosts";
import { Groups } from "./features/groups/Groups";
import { CreateGroup } from "./features/groups/CreateGroup";
import { DiscoverGroups } from "./features/groups/DiscoverGroups";
import { GroupPage } from "./features/groups/GroupPage";
import { EventPage } from "./features/events/EventPage";
import { Duo } from "./features/intButtons/Duo";
import { picsToSave } from "./PicsToSave";
import { Subscriptions } from "./features/subscriptions/Subscriptions";
import { Test } from "./features/test/Test";
import MobileVerification from "./features/root/MobileVerification";
import { MobileVerification_ } from "./features/root/MobileVerification_";
import { DiscoverEvents } from "./features/events/DiscoverEvents";
import { LeadBoards } from "./features/leadboards/LeadBoards";
import { RefProg } from "./features/refprog/RefProg";
import RootContextProvider from "./features/root/RootContextProvider";
import { Profile } from "./features/profile/Profile";
import { CreateEvent } from "./features/events/CreateEvent";

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'decay',
  ease: 'linear',
  duration: .5
};

//type?: "decay" | "spring" | "keyframes" | "tween" | "inertia";

if (process.env.REACT_APP_DEPLOY_MODE === "production") {
  disableReactDevTools();
}

const AnimationLayout = () => {
  const { pathname } = useLocation();
  return (
    <div style={{ position: "absolute", }}>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
      >
        <Outlet />
      </motion.div>
    </div>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    /* Wrap this Root Route to create Router here */
    <Route path="/" element={<Root />}>
      <Route path="download" element={<DownloadNow />} />
      <Route path="updatenow" element={<UpdateNow />} />
      <Route path="login" element={<Login />} />
      <Route path="admin" element={<Admin />} />
      <Route path="founders" element={<Founders />} />
      <Route path="help" element={<Help />} />
      <Route path="policy" element={<Policy />} />
      <Route path="eula" element={<EULA />} />
      <Route path="home" element={<Home />}>
        <Route path="profile" element={<Profile />} />
        <Route path="test" element={<Test />} />
        <Route path="verifynumber" element={<MobileVerification_ />} />
        <Route path="feed" element={<Feed />} />
        <Route path="search" element={<Search />} />
        <Route path="leadboards" element={<LeadBoards />} />
        <Route path="stopwatchonly" element={<StopWatchOnly />} />
        <Route path="postcreator" element={<PostCreator />} />
        <Route path="mypage" element={<MyPage />} />
        <Route path="achievements" element={<Achievements />} />
        <Route path="myworkouts" element={<MyWorkouts />} />
        <Route path="myprofile" element={<MyProfile />} />
        <Route path="workout" element={<Duo />} />
        <Route path="workout/announce" element={<IntButtons />} />
        <Route path="workout/findduo" element={<PlayersList />} />
        <Route path="friends" element={<Friends />} />
        <Route path="chats" element={<Chats />} />
        <Route path=":mainid" element={<User />} />
        <Route path="posts/:mainid" element={<UserPosts />} />
        <Route path="post/:postid" element={<SoloPost />} />
        <Route path="promoevent" element={<PromoEvent />} />
        <Route path="coaches" element={<Coaches />} />
        <Route path="coaches/coach/:coachid" element={<CoachPage />} />
        <Route path="coaches/coachaccount" element={<CoachAccount />} />
        <Route path="coaches/coachesnearby" element={<CoachesNearby />} />
        <Route path="groups" element={<Groups />} />
        <Route path="groups/group/:groupid" element={<GroupPage />} />
        <Route path="groups/creategroup" element={<CreateGroup />} />
        <Route path="groups/findgroups" element={<DiscoverGroups />} />
        <Route path="events" element={<Events />} />
        <Route path="events/discoverevents" element={<DiscoverEvents />} />
        <Route path="events/createevent" element={<CreateEvent />} />
        <Route path="events/createevent/:eventid" element={<CreateEvent />} />
        <Route path="event/:eventid" element={<EventPage />} />
        <Route path="subs" element={<Subscriptions />} />
        <Route path="refprog" element={<RefProg />} />
      </Route>
    </Route >
  )
);


function App() {
  const [initialHeight, setInitialHeight] = useState(0);
  const myData = useSelector(selectData)

  useEffect(() => {
    const saveImagesAndRemoveSplash = () => {
      Object.keys(picsToSave).forEach((key) => {
        const testImg = localStorage.getItem(key)
        if (testImg) {
          console.log(`${key} Already saved`)
          return
        }
        const imageUrl = picsToSave[key];
        fetch(imageUrl)
          .then(response => response.blob())
          .then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
              localStorage.setItem(key, reader.result);
              console.log("Image", key, "saved to local storage.");
            };
            reader.readAsDataURL(blob);
          })
          .catch(error => console.error("Error fetching image:", error));
      });
    };
    try {
      window.ReactNativeWebView.postMessage(`Random`);
      saveImagesAndRemoveSplash();
    } catch (e) {

    }
  }, [])

  useEffect(() => {
    if (myData) {
      setTimeout(() => {
        const infoContElement = document.getElementById('profileCont');
        if (infoContElement) {
          setInitialHeight(infoContElement.clientHeight);
        }
      }, 830);
    }
  }, [myData]);

  return (
    <RootContextProvider>
      <div id="app" className="App">
        <RouterProvider router={router} />
        <div style={{ minHeightheight: initialHeight !== 0 ? initialHeight + 90 : '' }} id="BackGroundImage" className="mainBackGround" />
      </div>
    </RootContextProvider>
  );
}

export default App;
