import React, { useState, useEffect } from 'react';


export function ConfirmedFeaturedWorkoutCard({ workout, setTrackedSoloWorkouts, setShowWorkoutInfo, selectedFilter }) {

    return (
        <div style={{ border: '1px solid hsla(72, 100%, 47%, 50%)' }} className='FeaturedCardCont' >
            <img onClick={() => { setShowWorkoutInfo(workout) }} className='smallWorkoutFeatured' src={workout.stillurl} />
            <h5 onClick={() => { setShowWorkoutInfo(workout) }}>{workout.name}</h5>
            <h5 style={{ fontWeight: '500', fontSize: '12px', marginTop: '25px' }} >{workout.date}</h5>
            <h5 style={{ marginTop: '13px', right: '40px' }} > Sets: <span style={{ fontSize: "16px", color: 'hsl(72, 100%, 47%)' }}>{workout.sets}</span></h5 >
        </div >
    )
}