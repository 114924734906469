import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { playAdInt, selectData } from "../home/homeSlice";
import { List } from "./List";
import { SmallProfiel } from "../profile/SmallProfile";
import { loadCommon, setLoading } from "../intButtons/intButtonsSlice";
import Radius from "../intButtons/Radius";
import { SportPickerNew } from "../groups/SportPickerNew";
import filters from '../../Pics/filters.png'
import { RootContext } from "../root/RootContextProvider";


export function PlayersList({ }) {
    const {
        radius,
        setRadius
    } = useContext(RootContext);
    const data = useSelector(selectData);
    const [currentInterest, setCurrentInterest] = useState(null)
    const [selectedSport, setSelectedSport] = useState(window.selectedDuoSport || null);
    const [showFilterButton, setShowFilterButton] = useState(false)
    const [filterOpened, setFilterOpened] = useState(false)
    const [creating, setCreating] = useState(false)
    const [bigPic, setBigPic] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (data && data.interest) {
            //setCurrentInterest(data.interest)
        }
    }, [data])

    useEffect(() => {
        try {
            setCurrentInterest(selectedSport)
        } catch (e) {

        }
    }, [selectedSport])

    useEffect(() => {
        try {
            if (window.location.search !== "") {
                const tobeEdited = window.location.search
                if (tobeEdited.startsWith('?Load:')) {
                    const selectedShortCut = tobeEdited.substring(6)
                    setCurrentInterest(selectedShortCut)
                    setSelectedSport(selectedShortCut)
                    window.selectedDuoSport = selectedSport
                } else {
                    const selectedShortCut = tobeEdited.substring(1)
                    setCurrentInterest(selectedShortCut)
                    setSelectedSport(selectedShortCut)
                    window.selectedDuoSport = selectedSport
                }

            }
        } catch (e) {

        }
    }, [window.location.pathname])

    useEffect(() => {
        if (selectedSport) {
            window.selectedDuoSport = selectedSport
            dispatch(loadCommon({ interest: selectedSport, radius }));
            dispatch(playAdInt())
        }
    }, [selectedSport])

    useEffect(() => {

        return () => {
            dispatch(setLoading())
        };
    }, []);

    return (
        <div className="homeTrans" style={{ width: '100%', display: 'grid' }}>
            {bigPic !== null ?
                <div style={{ zIndex: '1000' }} onClick={() => setBigPic(null)} className='fullScreenBlack'>
                    <img style={{borderRadius:'10px', maxWidth: '90%' }} src={bigPic} />
                </div>
                :
                ''
            }
            <SmallProfiel data={data} currentInterest={currentInterest} setCurrentInterest={setCurrentInterest} />
            <div className='findDuoHeader'>
                <Radius radius={radius} setRadius={setRadius} />
                <div style={{ width: '50px', height: '50px' }}>
                    <SportPickerNew selectedSport={selectedSport} setSelectedSport={setSelectedSport} height={'50px'} theText="PICK SPORT" />
                </div>
                <div style={{ width: '50px', height: '50px', display: 'flex' }}>
                    <div style={{ backgroundColor: showFilterButton ? "" : "black", padding: '0px 0px', width: '40px', height: '40px', marginBottom: '5px', fontSize: '12px' }} className="setSportCreatePost fav1" onClick={() => { setFilterOpened(!filterOpened) }}>
                        <img style={{ width: "85%", height: '85%' }} src={filters} />
                    </div>
                </div>
            </div>
            <List profID={data.mainid} currentInterest={currentInterest} filterOpened={filterOpened} radius={radius} setShowFilterButton={setShowFilterButton} setBigPic={setBigPic} />
        </div >
    )

}