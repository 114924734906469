import React, { useState, useEffect } from 'react';
import { FakeChatCard } from '../chats/FakeChatCard'
import { useSwipeable } from "react-swipeable";
import { useDispatch, useSelector } from 'react-redux';
import { playAdInt, selectData } from '../home/homeSlice';
import { useNavigate } from 'react-router-dom';
import search from '../../Pics/search.png'
import { SearchCard } from './SearchCard';

export function Search({ }) {
    const [initialHeight, setInitialHeight] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchListStatus, setSearchListStatus] = useState('idle');
    const [searchList, setSearchList] = useState(null);
    const [lastActiveHelper, setLastActiveHelper] = useState(null)
    const [moreSearchListStatus, setMoreSearchListStatus] = useState('idle')
    const data = useSelector(selectData)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const fetchUsersBySearch = async () => {
        const trimmedSearchTerm = searchTerm.trim();
        if (trimmedSearchTerm.length <= 3) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Enter more characters`);
                return Promise.reject(new Error('Search term too short'));
            } catch (e) {
                console.error('Error:', e);
                return Promise.reject(e);
            }
        }
        setSearchListStatus('loading');

        const requestBody = {
            name: searchTerm,
            fav: null // Update with your preferred way to get fav data
        };
        try {
            const response = await fetch('/api/discover/search',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            ); // Adjust the API endpoint as needed
            if (response.ok) {
                const data = await response.json();
                setSearchList(data);
                setSearchListStatus('done')
                try {
                    if (data.length > 0) {
                        if (data.length < 10) {
                            setLastActiveHelper(0)
                        } else {
                            setLastActiveHelper(data[data.length - 1].lastactivity)
                        }
                    } else {
                        setLastActiveHelper(0)
                    }
                } catch (e) {
                    setLastActiveHelper(0)
                }
            } else {
                console.error('Failed to fetch posts');
                setSearchListStatus(null)
            }
        } catch (error) {
            setSearchListStatus(null)
            console.error('Error fetching posts:', error);
        }
    };

    async function fetchMoreSeachList(setMoreSearchListStatus, setSearchList, lastActiveHelper, setLastActiveHelper) {
        const trimmedSearchTerm = searchTerm.trim();
        if (trimmedSearchTerm.length <= 3) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Enter more characters`);
                return Promise.reject(new Error('Search term too short'));
            } catch (e) {
                console.error('Error:', e);
                return Promise.reject(e);
            }
        }
        const requestBody = {
            name: searchTerm,
            lastActiveHelper: lastActiveHelper // Update with your preferred way to get fav data
        };
        setMoreSearchListStatus('loading')
        try {
            const response = await fetch('/api/discover/loadmoresearch',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(requestBody),
                }
            ); // Adjust the API endpoint as needed
            if (response.ok) {
                const data = await response.json();
                setSearchList(prevList => [...prevList, ...data]);
                try {
                    if (data.length > 0) {
                        if (data.length < 10) {
                            setLastActiveHelper(0)
                        } else {
                            setLastActiveHelper(data[data.length - 1].lastactivity)
                        }
                    } else {
                        setLastActiveHelper(0)
                    }
                } catch (e) {
                    setLastActiveHelper(0)
                }
                setMoreSearchListStatus('done')
            } else {
                console.error('Failed to fetch posts');
                setMoreSearchListStatus(null)
            }
        } catch (error) {
            setMoreSearchListStatus(null)
            console.error('Error fetching posts:', error);
        }
    }


    const handleSearchInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchButtonClick = () => {
        dispatch(playAdInt())
        fetchUsersBySearch();
    };

    const loadMoreSearchList = async () => {
        if (lastActiveHelper !== null && lastActiveHelper !== 0) {
            dispatch(playAdInt())
            fetchMoreSeachList(setMoreSearchListStatus, setSearchList, lastActiveHelper, setLastActiveHelper)
        }
    }

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };
    const swipeBackHandlersBack = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });


    useEffect(() => {
        if (data) {
            const infoContElement = document.getElementById('profileCont');
            if (infoContElement) {
                setInitialHeight(infoContElement.clientHeight);
            }
        }
    }, [data]);

    return (
        <div  {...swipeBackHandlersBack} style={{ zIndex: '12', padding: '10px 0px 20px 0px', minHeight: initialHeight !== 0 ? initialHeight : '' }} id="profileCont" className="feedPostsCont">
            <div style={{ position: 'sticky', top: '0px', height: '50px', display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '0px' }} className="hintCont">
                <input
                    className='hint bigSearch'
                    style={{ height: '20px', fontWeight: '600', width: 'calc(100% - 70px)', marginLeft: '0px', fontSize: '16px' }}
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                    min="3"
                />
                <img className='activeBlack' onClick={handleSearchButtonClick} style={{ marginLeft: '10px', marginRight: '10px', height: '30px' }} src={localStorage.getItem('search')} />
            </div>
            <div style={{ marginTop: '10px', marginBottom: '25px', height: '100%' }} className="scrollList">
                <div style={{ padding: '0px 10px' }}>
                    {searchListStatus === 'loading' ?
                        <div>
                            <FakeChatCard />
                            <FakeChatCard />
                            <FakeChatCard />
                        </div>
                        :
                        ""
                    }
                </div>
                <div style={{ padding: '0px 10px' }}>
                    {searchListStatus === 'done' && searchList ?
                        searchList.map((user) => (
                            <SearchCard key={user.mainid} mianid={user.mainid} name={user.name} picture_url={user.picture_url} fav1={user.fav1} fav2={user.fav2} />
                        ))
                        :
                        ""
                    }
                </div>
                {searchListStatus === 'done' && lastActiveHelper !== null && lastActiveHelper !== 0 ?
                    <div>
                        {moreSearchListStatus === 'loading' ?
                            <p style={{ backgroundColor: 'hsla(0, 0%, 15%, 0.5)' }} className="loadMoreByFav fake">Loading . . .</p>
                            :
                            <p className="loadMoreByFav activeBlack" onClick={loadMoreSearchList}>Load More</p>}
                    </div>
                    :
                    ""
                }
                <div style={{ height: '150px' }} />
            </div>
        </div>

    );
}
