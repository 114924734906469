import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { pics } from "../intButtons/sportPics";

export function FavShortCut({ fav, radius, favoriteShortcutHandle }) {

    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    return (
        <div className="favShortCutCont">
            <img loading="lazy" src={localStorage.getItem(fav.toLocaleLowerCase()) || photoPicker(fav)} onClick={() => favoriteShortcutHandle(fav, radius)} />
        </div>
    )
}