import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const loadTodayFeatured = createAsyncThunk(
    'workouts/loadTodayFeatured',
    async () => {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/workoutsbodyweight/loadtodayworkouts`);
        const json = await data.json();
        return json;
    }
);

export const loadConfirmedFeatured = createAsyncThunk(
    'workouts/loadConfirmedFeatured',
    async () => {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/workoutsbodyweight/loadConfirmedFeatured`);
        const json = await data.json();
        return json;
    }
);

export const loadConfirmedCustom = createAsyncThunk(
    'workouts/loadConfirmedCustom',
    async () => {
        const data = await fetch(`${process.env.REACT_APP_API_URL}/profile/loadcustomworkouts`);
        const json = await data.json();
        return json;
    }
);

export const workoutsSlice = createSlice({
    name: 'workouts',
    initialState: {
        TodayFeatured: null,
        TodayFeaturedStatus: 'idle',
        ConfirmedFeatured: null,
        ConfirmedFeaturedStatus: 'idle',
        ConfirmedCustom: null,
        ConfirmedCustomStatus: 'idle',
    },
    reducers: {
        addToConfirmedFeatured: (state, action) => {
            try {
                state.ConfirmedFeatured = [action.payload, ...state.ConfirmedFeatured];
            } catch (e) {
                console.error('Error adding to confirmed:', e);
            }
        },
        addToConfirmedCustom: (state, action) => {
            try {
                state.ConfirmedCustom = [action.payload, ...state.ConfirmedCustom];
            } catch (e) {
                console.error('Error adding to confirmed:', e);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadTodayFeatured.pending, (state) => {
                if (state.TodayFeatured === null) {
                    state.TodayFeaturedStatus = 'loading';
                }
            })
            .addCase(loadTodayFeatured.fulfilled, (state, action) => {
                state.TodayFeatured = action.payload;
                state.TodayFeaturedStatus = 'done';
            })
            .addCase(loadTodayFeatured.rejected, (state, action) => {
                state.TodayFeaturedStatus = 'failed';
                state.TodayFeatured = null;
            })
            .addCase(loadConfirmedFeatured.pending, (state) => {
                if (state.ConfirmedFeatured === null) {
                    state.ConfirmedFeaturedStatus = 'loading';
                }
            })
            .addCase(loadConfirmedFeatured.fulfilled, (state, action) => {
                state.ConfirmedFeatured = action.payload;
                state.ConfirmedFeaturedStatus = 'done';
            })
            .addCase(loadConfirmedFeatured.rejected, (state, action) => {
                state.ConfirmedFeatured = 'failed';
                state.ConfirmedFeaturedStatus = null;
            })
            .addCase(loadConfirmedCustom.pending, (state) => {
                if (state.ConfirmedCustom === null) {
                    state.ConfirmedCustomStatus = 'loading';
                }
            })
            .addCase(loadConfirmedCustom.fulfilled, (state, action) => {
                state.ConfirmedCustom = action.payload;
                state.ConfirmedCustomStatus = 'done';
            })
            .addCase(loadConfirmedCustom.rejected, (state, action) => {
                state.ConfirmedCustom = 'failed';
                state.ConfirmedCustomStatus = null;
            })
    },
});

export const selectTodayFeatured = (state) => state.workouts.TodayFeatured;
export const selectTodayFeaturedStatus = (state) => state.workouts.TodayFeaturedStatus;
export const selectConfirmedFeatured = (state) => state.workouts.ConfirmedFeatured;
export const selectConfirmedFeaturedStatus = (state) => state.workouts.ConfirmedFeaturedStatus;
export const selectConfirmedCustom = (state) => state.workouts.ConfirmedCustom;
export const selectConfirmedCustomStatus = (state) => state.workouts.ConfirmedCustomStatus;
export const { addToConfirmedFeatured, addToConfirmedCustom } = workoutsSlice.actions;
export default workoutsSlice.reducer;


