import React, { useState } from "react"
import { useNavigate } from "react-router-dom";

import { headerItems } from "./newHeaderFuncs";
import './newHeader.css'

export const NewHeader = ({ appVersion }) => {
    const navigate = useNavigate()
    const [showDesc, setShowDesc] = useState(null)


    return (
        <div className="newHeaderCont">
            {/*<h3>APP SERVICES</h3> */}
            {showDesc ?
                <div onClick={() => setShowDesc(null)} className="descContPage">
                    <div style={{ position: 'fixed' }} className="descCont">
                        <p style={{ color: 'white', textAlign: "left" }}>{showDesc}</p>
                    </div>
                </div>
                : ""
            }
            {headerItems.map((headerItem) => {
                return (
                    <div className="activeBlack subHeader" key={headerItem.name}>
                        {headerItem.desc && (
                            <img onClick={() => setShowDesc(headerItem.desc ? headerItem.desc : null)} className="subHeaderButtonInfoImg" src={localStorage.getItem('infoImg')} />
                        )}
                        <img className="subHeaderMainIMG" onMouseUp={() => navigate(headerItem.link)} src={localStorage.getItem(headerItem.localName)} />
                        <h3 onMouseUp={() => navigate(headerItem.link)}>{headerItem.name}</h3>
                    </div>
                )
            })}
        </div>
    )

}