import React, { useState } from 'react';

export const SendToAll = () => {
    const [message, setMessage] = useState('');

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const sendMessage = () => {
        const url = '/api/admin/sendtoall'; // Replace with your API endpoint

        // Data to be sent in the POST request
        const data = {
            message: message
        };

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                // Handle the response data
                console.log('Message sent:', data);
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    };

    return (
        <div>
            <input
                type="text"
                value={message}
                onChange={handleInputChange}
                placeholder="Type something..."
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};


