import { notifyNearBy } from "../intButtons/intFuncs";

export const emptyExp = {
    id: 'new',
    title: '',
    image: '',
    description: '',
    startdate: '',
    enddate: '',
    thumb: ''
}

export const fetchNearByGroups = async (sport, radius) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadnearbygroups`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ sport: sport, radius: radius }),
        });
        const data = await response.json();
        return data
    } catch (error) {
        console.error(error);
    }
};

export function updateGroupInterest(myGroup, groupName, selectedInt, time, comment, preflocation, navigate, dispatch, loadMyGroups, setCreating, editData) {
    //dispatch(disableRefresh())
    //try {
    //  window.ReactNativeWebView.postMessage(`showFullScreenAd`);
    //} catch (e) {
    //}  
    if (!selectedInt) {
        try {
            window.ReactNativeWebView.postMessage(`Alert:make sure you have selected a sport`);
            return
        } catch (e) {
            return
        }
    }
    setCreating(true)
    function formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0:00) as 12:00 AM
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return `${hours}:${minutes} ${ampm} ${date.getDate()}/${date.getMonth() + 1}`;
    }

    const currentDate = new Date();
    const formattedDate = formatAMPM(currentDate);
    fetch(`/api/profile/updategroupinterest`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ name: groupName, interest: selectedInt, time: time, comment: comment, preflocation: preflocation, now: formattedDate }),
    })
        .then((response) => {
            if (response.ok) {
                dispatch(loadMyGroups())
                setTimeout(() => {
                    try {
                        notifyNearBy(selectedInt, 'group')
                    } catch (e) {

                    }
                }, 3000);
                navigate('/home/groups')
                dispatch(editData({ groupinterest: selectedInt }))
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Confirmed,Now others will be able to find your ${selectedInt} Group `);
                } catch (e) {

                }
                console.log("Interest updated");
                setCreating(false)
            } else {
                setCreating(false)
                console.error("Failed to save interest");
            }
        })
        .catch((error) => {
            setCreating(false)
            console.error("Error saving interest:", error);
        });
}

export const disbandMyGroup = async (dispatch, loadMyGroups, editData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/disbandmygroup`);

        // Check if the response is OK (status code 200-299)
        if (response.ok) {
            dispatch(loadMyGroups());
            dispatch(editData({ groupinterest: null }))
        } else {
            // Log the error and handle it accordingly
            console.error(`Error: HTTP Status Code ${response.status}`);
            // Dispatch an action or handle the error in some way if needed
        }
    } catch (error) {
        // Log the error and handle it accordingly
        console.error("An error occurred:", error);
        // Dispatch an action or handle the error in some way if needed
    }
};

export const ApplyToGroup = async (id, setGroupStatus, setCreating) => {
    setCreating(true)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/applytogroup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ groupid: id }),
        });
        if (response.ok) {
            setGroupStatus('request')
            setCreating(false)
        } else {
            setCreating(false)
        }
    } catch (error) {
        setCreating(false)
    }
}


export const InvitetoGroup = async (taggedFriends, setTaggedFriends, setCreating, dispatch, loadMyGroups) => {
    setCreating(true)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/invitetogroup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ taggedFriends: taggedFriends }),
        });
        if (response.ok) {
            setCreating(false)
            setTaggedFriends([])
        } else {
            setCreating(false)
        }
    } catch (error) {
        setCreating(false)
    }
}

export const handleAcceptGroupInvite = async (groupid, setGroupStatus, setUpdating) => {
    setUpdating(true)
    try {
        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/groups/acceptgroupinvite`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ groupid: groupid }),
            }
        );
        if (response.ok) {
            setUpdating(false)
            setGroupStatus('accepted')
        } else {
            setUpdating(false)
        }
    } catch (error) {
        console.error(error);
        setUpdating(false)

    }
}

export const WithDrawFromGroup = async (id, setGroupStatus, setCreating, navigate) => {
    setCreating(true)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/withdrawfromgroup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ groupid: id }),
        });
        if (response.ok) {
            setGroupStatus('none')
            setCreating(false)
            if (window.location.pathname === `/home/groups/group/:${id}`) {
                navigate(-1)
            }
        }
    } catch (error) {
        setCreating(false)
        console.error(error);
    }
}

export const loadGroupPage = async (id, group, setGroup, setLoadingGroup) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadgrouppage`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        });
        if (response.ok) {
            const data = await response.json();
            setGroup(data)
            setLoadingGroup('done')
        } else {
            setLoadingGroup(false)
        }
    } catch (error) {
        console.error(error);
        setLoadingGroup(false)
    }
}

export const sendGroupMessage = async (content, groupid, type) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/sendgroupmessage`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ content: content, groupid: groupid, type: type }),
        });
        if (response.ok) {
            console.log('Group Message Sent')
        } else {
            console.log('Failed to send Group Message')
        }
    } catch (error) {
        console.error(error);
    }
}

export const setGroupMeetingTime = async (time, setCreating, setShowEditTime) => {
    try {
        setCreating(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/setgroupmeetingtime`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ time: time }),
        });
        if (response.ok) {
            setCreating(false)
            setShowEditTime(false)
        } else {
            setCreating(false)
        }
    } catch (error) {
        setCreating(false)

    }
}


export const confirmGroupMeeting = async (setCreating, navigate) => {
    try {
        setCreating(true)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/confirmgroupmeeting`)
        if (response.ok) {
            navigate('/home/myworkouts?Load:group')
            await fetch(`${process.env.REACT_APP_API_URL}/groups/disbandmygroup`)
            setCreating(false)
        } else {
            setCreating(false)
        }
    } catch (error) {
        setCreating(false)

    }
}


