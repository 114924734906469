import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { VideoPlayer } from './VideoPlayer';
import { handleShowBottomNav, handleShowTopNav } from '../home/homeSlice';


export function PostMedia({ image, thumb, setBigPic }) {
    const [mediaHeight, setMediaHeight] = useState(image && !image.includes('.mp4') ? '300px' : '')
    const [didVidFail, setDidVidFail] = useState(false)

    const dispatch = useDispatch()

    return (
        <div style={{ userSelect: 'none', display: 'flex', minHeight: mediaHeight, padding: '0px', width: '100%' }} >
            {image && image.includes('.mp4') && didVidFail === false ?
                <VideoPlayer link={image} thumb={thumb} setMediaHeight={setMediaHeight} didVidFail={didVidFail} setDidVidFail={setDidVidFail} />
                : ""
            }
            {image && !image.includes('.mp4') && (
                <img
                    style={{ width: '100%', marginBottom: '10px' }}
                    onLoad={(event) => setMediaHeight(event.target.clientHeight)}
                    onClick={() => {
                        setBigPic(true);
                        dispatch(handleShowBottomNav(false));
                        dispatch(handleShowTopNav(false));
                    }}
                    src={image}
                />
            )}

        </div>
    )
}