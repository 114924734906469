import React, { useEffect, useState } from 'react';
import { pics } from '../intButtons/sportPics'; // Import the images for different sports
import { useDispatch } from "react-redux";
import { editData } from "../home/homeSlice";
import { interestData } from '../intButtons/sportPics'
import publicPhoto from "../../Pics/public.png";
import publicPhotoOff from "../../Pics/publicoff.png";


export function PublicProfEdit({ public_profile }) {
  const [loading, setLoading] = useState('idle')
  const dispatch = useDispatch()


  const handlePublicStatus = async () => {
    if (loading === 'loading') {
      return
    }
    setLoading('loading')
    try {
      const response = await fetch(`/api/profile/updatepublicprofile`);
      if (response.ok) {
        const data = await response.json();
        dispatch(editData({ public_profile: data.public_profile }));
        if (data.public_profile === true) {
          try {
            window.ReactNativeWebView.postMessage(`Alert:Others can find you by your name or favorite sport even if NO workout planned. Note that Gender visibilty still APPLY`);
          } catch (e) {

          }
        } else if (data.public_profile === false) {
          try {
            window.ReactNativeWebView.postMessage(`Alert:Others can't find you UNLESS you planned a workout`);
          } catch (e) {

          }
        }
        setLoading('done')
      } else {
        setLoading('done')
        alert("Failed to update public profile");
      }
    } catch (error) {
      setLoading('done')
      console.error("Error updating public profile:", error);
    }
  };

  return (
    <img style={{ borderRadius: "15px", backgroundColor: loading === 'loading' ? 'grey' : "" }} onClick={handlePublicStatus} loading="lazy" className="activeBlack" src={public_profile ? (localStorage.getItem('publicPhoto') || publicPhoto) : (localStorage.getItem('publicPhotoOff') || publicPhotoOff)} />
  );
};

