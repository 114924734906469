import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import search from '../../Pics/search.png'
import { editData, selectData } from '../home/homeSlice';
import { defEvent, loadMyEvents } from './eventsFuncs';
import { FakeEventCard } from './FakeEventCard';
import { EventCard } from './EventCard';
import Slider from 'react-slick';
import { RootContext } from '../root/RootContextProvider';

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    dots: true,
};


export function Events({ }) {
    const { setDraftEvent } = useContext(RootContext);
    const [creating, setCreating] = useState(false)
    const [type, setType] = useState(window.eventType || 'Joined')
    const [myEvents, setMyEvents] = useState(null)
    const [filteredEvents, setFilteredEvents] = useState(null)
    const [loadingMyEvents, setLoadingMyEvents] = useState('idle')
    const myInfo = useSelector(selectData)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        loadMyEvents(setMyEvents, setLoadingMyEvents)
    }, [])

    useEffect(() => {
        try {
            if (myEvents && type) {
                if (type === 'Created') {
                    setFilteredEvents(myEvents.filter(event => event.creatorid === myInfo.mainid && event.state === 'pending'));
                } else if (type === 'Joined') {
                    setFilteredEvents(myEvents.filter(event => event.creatorid !== myInfo.mainid && event.state === 'pending'));
                } else if (type === 'Completed') {
                    setFilteredEvents(myEvents.filter(event => event.state === 'completed'));
                }
            }
        } catch (error) {
            console.error('Error filtering events:', error);
        }
    }, [myEvents, type]);



    return (

        <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
            {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
            </div> : ""}
            <div style={{ zIndex: '20', marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                <div style={{ display: 'grid', gridTemplateColumns: '7fr 1fr 1fr', borderRadius: '0px', marginTop: '10px', padding: '10px 20px' }} className="hintCont">
                    <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-EVENTS</label>
                    <label className="activeBlack" onMouseUp={() => { navigate('/home/events/createevent'); setDraftEvent(defEvent) }} style={{ fontSize: '13px', width: '50px' }} htmlFor="comment"><img style={{ width: '30px' }} src={localStorage.getItem('plus')} /></label>
                    <label className="activeBlack" onMouseUp={() => navigate('/home/events/discoverevents')} style={{ fontSize: '13px' }} htmlFor="comment"><img style={{ width: '30px' }} src={localStorage.getItem('search')} /></label>
                </div>
                <div style={{ marginTop: '10px', marginBottom: '0px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
                    <label className="activeBlack" onClick={() => { setType('Created'); window.eventType = 'Created' }} style={{ color: type === 'Created' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">Created</label>
                    <label className="activeBlack" onClick={() => { setType('Joined'); window.eventType = 'Joined' }} style={{ color: type === 'Joined' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">Joined</label>
                    <label className="activeBlack" onClick={() => { setType('Completed'); window.eventType = 'Completed' }} style={{ color: type === 'Completed' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">Completed</label>
                </div>
                {loadingMyEvents === 'done' && myEvents && filteredEvents ? (
                    <Slider {...settings}>
                        {filteredEvents.sort((a, b) => new Date(a.eventtime) - new Date(b.eventtime))
                            .map((event) => (
                                <EventCard key={event.eventid} eventid={event.eventid} image={event.image} eventname={event.eventname} location={event.location} eventtime={event.eventtime} sport={event.sport} caption={event.caption} locationdisplay={event.locationdisplay} creatorid={event.creatorid} hiddenby={event.hiddenby} setEvents={setMyEvents} goingcount={event.goingcount} commentscount={event.commentscount} />
                            ))}
                        <div onClick={() => { }} className='noPrevWorkout'>
                            <p>
                                No {filteredEvents.length > 0 && "More"} {type} Events <br />Create Event or Discover More events?
                            </p>
                            <div style={{ display: 'flex', gap: '10px', width: 'fit-content' }}>
                                <img className='activeBlack' onMouseUp={() => navigate('/home/events/createevent')} style={{ width: '60px' }} src={localStorage.getItem('plus')} />
                                <img className='activeBlack' onMouseUp={() => navigate('/home/events/discoverevents')} style={{ width: '60px' }} src={localStorage.getItem('search')} />
                            </div>

                        </div>

                    </Slider>
                ) : (
                    <div>
                        <FakeEventCard />
                    </div>
                )}
            </div>
        </div>
    )
}
