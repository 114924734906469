import React, { useEffect, useState } from "react";
import "./nav.css";
import { Reqs } from "../reqs/Reqs";
import { InfoTab } from "./InfoTab";
import { useSelector, useDispatch } from "react-redux";
import { ChatReqs } from "../chatreqs/ChatReqs";
import { selectData, selectLog, loadHome } from "../home/homeSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { clearInfo } from "../user/userSlice";
import homeLogo from '../../homeLogo.png'

export function Nav({ data, appVersion, canShare, handleShowShare, subColor, showTopNav }) {
  const [showEvent, setShowEvent] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const log = useSelector(selectLog);
  const page = useLocation();
  const pagePath = page.pathname;

  const navClean = () => {

    if (log !== "logged") {
      //navigate("/login");
    } else {
      dispatch(clearInfo());
      //dispatch(loadHome())      
      if (window.location.pathname === '/home/feed') {
        window.postMessage('backToTop')
        setTimeout(() => {
          window.postMessage('backToTop')
        }, 100);
      } else {
        navigate("/home/feed");
      }
    }
  };

  useEffect(() => {
    if (data) {
      try {
        if (!data.fav1 && !data.fav2 && data.showevent === true && data.gender) {
          setShowEvent(true)
        }
      } catch (e) {

      }
    }
  }, [data])

  return (
    <nav style={{ top: showTopNav && window.location.pathname !== '/download' ? "0px" : '-50px', zIndex: '500' }}>
      {pagePath === '/login' || pagePath === '/download' || pagePath !== '/home/feed' ? '' : <div onClick={navClean} className="homeIMG"></div>}
      {pagePath === '/login' || pagePath === '/download' || pagePath !== '/home/feed' ? '' : <img className="homeLogo" src={localStorage.getItem('homeLogo')} alt="Fit-Buddy" />}
      <InfoTab subColor={subColor} />
      {log === "logged" && data && data.activesub === null ?
        < div className="encRemAdsCont">
          <div onMouseUp={() => navigate('/home/subs')} className="activeBlack encRemAds">
            <p>Remove Ads</p>
            <img src={localStorage.getItem('NoAds')} />
          </div>
        </div>
        : ""
      }
      {canShare ? <div onClick={handleShowShare} className="shareHelper" /> : ""}
      {log === "logged" ? <ChatReqs id={data.mainid} /> : ''}
      {log === "logged" ? <Reqs id={data.mainid} showEvent={showEvent} setShowEvent={setShowEvent} /> : ''}
      {log === "logged" && pagePath !== '/home/feed' ? <img onClick={() => { navigate(-1) }} className="mainBackArrow" src={localStorage.getItem('thinback')} /> : ''}
    </nav >
  );
}
