import React, { useState, useEffect } from 'react';
import { MutualCard } from './MutualCard'
import './mutual.css'
import { FakeChatCard } from '../chats/FakeChatCard'
import { SwipeInUser } from './SwipeInUser'

export function MutualFriends({ myFriends, userFriends, userName }) {
    const [mutualFriendsCount, setMutualFriendsCount] = useState(0);
    const [mutualFriends, setMutualFriends] = useState(new Set());
    const [loading, setLoading] = useState(null);
    const [mutualFriendsList, setMutualFriendsList] = useState([]);

    useEffect(() => {
        try {
            const calculateMutualFriends = (friends1, friends2) => {
                const mutualFriends = new Set();
                for (const mainId in friends1) {
                    if (friends1[mainId].status === "friends" && friends2[mainId] && friends2[mainId].status === "friends") {
                        mutualFriends.add(mainId);
                    }
                }

                for (const mainId in friends2) {
                    if (friends2[mainId].status === "friends" && friends1[mainId] && friends1[mainId].status === "friends") {
                        mutualFriends.add(mainId);
                    }
                }
                return mutualFriends;
            };

            const calculatedMutualFriends = calculateMutualFriends(myFriends, userFriends);
            setMutualFriendsCount(calculatedMutualFriends.size);
            setMutualFriends(calculatedMutualFriends);
        } catch (e) {

        }
    }, [myFriends, userFriends]);

    const fetchMutualFriendsList = async () => {
        if (Array.from(mutualFriends).length === 0) {
            return
        }
        setLoading('loading');
        const response = await fetch('/api/mutualfriends', {
            method: 'POST',
            body: JSON.stringify(Array.from(mutualFriends)),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            const data = await response.json();
            setMutualFriendsList(data);
            console.log(data)
            setLoading('done');
        } else {
            setLoading(null);
        }

    };

    const back = () => {
        setLoading(null);
    }

    return (
        <div >
            <p className='MutualFriends' onClick={fetchMutualFriendsList}>
                ({mutualFriendsCount}) Mutual{' '}
            </p>
            {loading === 'loading' || loading === 'done' ?
                <SwipeInUser back={back} /> : ''}
            {loading === 'loading' ? (
                <div className="mutualPage">
                    <div className="mutuallist">
                        <h2>{userName} <br /> mutual Friends: </h2>
                        {Array.from({ length: mutualFriendsCount }).map((_, index) => (
                            <FakeChatCard key={index} />
                        ))}
                    </div>
                </div>
            ) : null}
            {loading === 'done' && mutualFriendsList.length > 0 ? (
                <div className="mutualPage">
                    <div className="mutuallist">
                        <h2 style={{ marginBottom: '10px' }}>{userName} <br /> mutual Friends: </h2>
                        {mutualFriendsList.map((friend) => (
                            <MutualCard setLoading={setLoading} key={friend.mainid} friend={friend} />
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
}
