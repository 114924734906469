import React, { useEffect, useRef } from "react";

export function AskPrompet({ text, handleYes, handleNo, buttonY, buttonN }) {
    const componentRef = useRef(null);

    useEffect(() => {
        try {
            const handleOutsideClick = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    handleNo()
                }
            };

            const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

            document.addEventListener(clickEvent, handleOutsideClick);

            return () => {
                document.removeEventListener(clickEvent, handleOutsideClick);
            };
        } catch (e) {

        }
    }, []);
    return (
        <div className="otherBigPic" style={{ color: 'black', backgroundColor: 'none', zIndex: '13', width: '100%', display: 'flex' }}>
            <div ref={componentRef} className="doYouWatToSetCont" >
                <p >{text}</p>
                <div className="yesAndNo" style={{ display: 'flex', marginTop: '20px' }}>
                    <p onClick={() => handleYes()} style={{ textAlign: 'center', width: '130px' }} className="activeBlack">{buttonY ? buttonY : "Confirm"}</p>
                    <p onClick={() => handleNo()} style={{ textAlign: 'center', width: '130px' }} className="activeBlack">{buttonN ? buttonN : "Cancel"}</p>
                </div>
            </div>
        </div>
    )

}