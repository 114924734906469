import React, { useEffect, useRef } from "react";
import './allPrompets.css'; // Your existing CSS file
import { useNavigate } from "react-router-dom";

export function RefAdsPrompet({ setShowRefPromp }) {
    const navigate = useNavigate();

    const handleLearnMore = () => {
        navigate('/home/refprog');
        window.didSeeRef = true;
    }

    const handleRemindMeLater = () => {
        setShowRefPromp(false);
        window.didSeeRef = true;
    }

    const handleDontShowAgain = () => {
        localStorage.setItem('showRefPromp', JSON.stringify(false));
        setShowRefPromp(false)
        try {
            window.ReactNativeWebView.postMessage(`Alert:You can always learn more by visiting the Subscription page and scroll to the bottom`);
        } catch (e) {

        }
    };

    const handleHideFor1Week = () => {
        const hideUntil = new Date();
        hideUntil.setHours(hideUntil.getHours() + 168); // Set 48 hours ahead
        localStorage.setItem('hideRefPrompUntil', hideUntil.toISOString());
        setShowRefPromp(false);
    };


    return (
        <div className="descContPage">
            <div className="refPrompCont">
                {/*<h3 className="promptHeader">
                    Save Your Workouts,<br /> Remove the Ads!
                </h3>*/}
                <div style={{ marginBottom: '10px' }} className="yesAndNo">
                    <img style={{ width: '70px', marginRight: '20px' }} src={localStorage.getItem('NoAds')} />
                    <img style={{ width: '70px' }} onMouseUp={() => navigate('/home/myworkouts?Load:custom')} src={localStorage.getItem('logWorkouts')} />
                </div>
                <p className="subscriptionDetails">
                    Get Premium Access for just<br /> <strong style={{ fontSize: '22px' }}>$1.99 / month</strong><br />Enjoy a Pure Experience.
                </p>
                <div className="subscriptionButtons">
                    <button onMouseUp={() => navigate('/home/subs')} className="primaryButton">Remove Ads & Save Workouts</button>
                    <button onMouseUp={handleHideFor1Week} className="secondaryButton">Hide This For 1 Week</button>
                    <button onMouseUp={handleRemindMeLater} className="tertiaryButton">Maybe Later</button>
                </div>

                <div className="freeOption">
                    <p style={{ color: 'white' }}>Want it for free? <span className="learnMoreLink" onClick={handleLearnMore}>Learn how</span></p>
                </div>
            </div>
        </div>
    );
}
