import React, { useContext, useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import { RootContext } from "../root/RootContextProvider";

export function SwipeInUser({ back }) {
    const { setIsinSwipeUser } = useContext(RootContext);

    useEffect(() => {
        setIsinSwipeUser(true)
    }, [])

    useEffect(() => {
        return () => setIsinSwipeUser(false)
    }, [])


    const handleSwipeRight = () => {
        back()
    };

    const swipeHandlers = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    return (
        <div className="swipeBackInUser" {...swipeHandlers}>

        </div>
    );
}