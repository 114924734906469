export const sendFriendRequest = async (receiverId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/friend-request/send`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ receiverId }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const acceptFriendRequest = async (receiverId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/friend-request/accept`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ receiverId }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const rejectFriendRequest = async (receiverId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/friend-request/reject`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ receiverId }),
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};

export async function sendFriendNotification(receiverId) {
  const response = await fetch('/api/send-friend-notification', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ receiverId }),
  });

  if (response.ok) {
    const data = await response.json();
    console.log('Friend notification sent successfully:', data.message);
  } else {
    console.error('Failed to send friend notification');
  }
}

export async function acceptFriendNotification(receiverId) {
  const response = await fetch('/api/accept-friend-notification', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ receiverId }),
  });

  if (response.ok) {
    const data = await response.json();
    console.log('Friend notification sent successfully:', data.message);
  } else {
    console.error('Failed to send friend notification');
  }
}