export const defEvent = {
    eventid: null,                 // auto increment
    image: 'https://fitbuddy.fit/Logos.png',                    // varchar(255)
    eventname: 'Enter Event Name',                 // varchar(255)
    sport: null,                     // varchar(255)
    location: '{}',                // text with default '{}'
    eventtime: (Date.now() + 7 * 24 * 60 * 60 * 1000), // timestampz
    caption: 'Event Overview or Description: Provide details and let people know more about your event',
    state: '',                     // varchar(255)
    locationdisplay: 'Pick Location',           // varchar(255)
    duration: 0.5,                   // integer with default 2
    locationlink: '',              // text
    creatorname: '',               // text
    creatorid: '',                 // text
    ticket: 'Insert ticket link if any',                    // text
    phone_number: '',              // text
};

export const fetchEvents = (events, setEvents, setLoadingEvents, sport, radius) => {
    setLoadingEvents(true);
    let SpotHelper
    if (window.location.pathname === '/home/feed') {
        SpotHelper = undefined
    } else {
        SpotHelper = sport
    }
    //if (events === null && window.location.pathname === '/home/feed') {
    //    setLoadingEvents(true);
    //} else if (window.location.pathname === '/home/events/discoverevents') {
    //    setLoadingEvents(true);
    //} 

    fetch('/api/events', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sport: SpotHelper, radius: radius }),
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setEvents(data); // Assuming events will be stored in state
            setLoadingEvents('done');
        })
        .catch(error => {
            console.error('Error fetching events:', error);
            setLoadingEvents(false);
        });
};

export const loadMyEvents = async (setMyEvents, setLoadingMyEvents) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events/loadmyevents`);
        if (response.ok) {
            const data = await response.json();
            setMyEvents(data)
            setLoadingMyEvents('done')
        } else {
            setLoadingMyEvents(false)
        }
    } catch (error) {
        console.error(error);
        setLoadingMyEvents(false)
    }
}

export const loadEventPage = async (id, setEvent, setLoadingEvent) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events/loadeventpage`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ id }),
        });
        if (response.ok) {
            const data = await response.json();
            setEvent(data)
            setLoadingEvent('done')
        } else {
            setLoadingEvent('failed')
        }
    } catch (error) {
        console.error(error);
        setLoadingEvent('failed')
    }
}

export const joinEvent = async (eventid, setMyStatus, setCreating, commingCount, setCommingCount, setEvent, myInfo, creatorid) => {
    setCreating(true)
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/events/applytoevent`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ eventid: eventid, creatorid: creatorid }),
        });
        if (response.ok) {
            const data = await response.json()
            console.log(data)
            if (data.applied === true) {
                setMyStatus('Going')
                setCommingCount((commingCount + 1))
                setEvent((prevEvent) => ({
                    ...prevEvent,
                    users: [...prevEvent.users, { mainid: myInfo.mainid, name: myInfo.name, picture_url: myInfo.picture_url }]
                }));
            } else if (data.applied === false) {
                setMyStatus('NotGoing')
                setCommingCount((commingCount - 1))
                setEvent((prevEvent) => ({
                    ...prevEvent,
                    users: prevEvent.users.filter(user => user.mainid !== myInfo.mainid)
                }));
            }
            setCreating(false)
        } else {
            setCreating(false)
        }
    } catch (error) {
        setCreating(false)
    }
}

