import React from 'react';
import './test.css';

export const Test = () => {
  return (
    <div className="container">
      <div className="header">
        <div className="profile">
          <img src="profile_image_url" alt="Profile" className="profile-image" />
        </div>
        <div className="header-text">Welcome to Sportify</div>
      </div>

      <div className="feature-container">
        <div className="feature-card" onClick={() => {/* Navigate to Find Duo */}}>
          <img src="find_duo_icon_url" alt="Find Duo" className="feature-icon" />
          <span>Find Duo</span>
        </div>
        <div className="feature-card" onClick={() => {/* Navigate to Groups */}}>
          <img src="groups_icon_url" alt="Groups" className="feature-icon" />
          <span>Groups</span>
        </div>
        <div className="feature-card" onClick={() => {/* Navigate to Coaches */}}>
          <img src="coaches_icon_url" alt="Coaches" className="feature-icon" />
          <span>Coaches</span>
        </div>
      </div>

      <div className="info-section">
        <h2 className="info-header">Users Interested in Your Favorite Sports Nearby</h2>
        <div className="favorite-container">
          <img src="link_to_favorite_sport1_image" alt="Favorite Sport 1" className="favorite-image" />
          <span className="user-count">15 Users</span>
        </div>
        <div className="favorite-container">
          <img src="link_to_favorite_sport2_image" alt="Favorite Sport 2" className="favorite-image" />
          <span className="user-count">10 Users</span>
        </div>
      </div>

      <div className="info-section">
        <h2 className="info-header">Groups for Your Favorite Sports Nearby</h2>
        <div className="favorite-container">
          <img src="link_to_favorite_sport1_image" alt="Favorite Sport 1" className="favorite-image" />
          <span className="group-count">3 Groups</span>
        </div>
        <div className="favorite-container">
          <img src="link_to_favorite_sport2_image" alt="Favorite Sport 2" className="favorite-image" />
          <span className="group-count">5 Groups</span>
        </div>
      </div>
    </div>
  );
}
