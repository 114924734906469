import React from "react";

export function Location({ }) {
    return (
        <div style={{margin: '20px auto'}} className="boxLogin">           
            <p className="disclaimer">
                To continue using our app please enable location servecies to find people around you.
            </p>
        </div>
    )
}