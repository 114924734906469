import React, { useState, useEffect } from 'react';
import { MutualCard } from '../user/MutualCard';
import { FakeChatCard } from '../chats/FakeChatCard';
import { SwipeInUser } from '../user/SwipeInUser';
import { MiniFriendCard } from './MiniFriendCard';

export function MiniFriendsMultiSelect({ setTaggedFriends, setShowFriendslist, members }) {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(null);
  const [friendsList, setFriendsList] = useState([]);
  const [filteredFriendsList, setFilteredFriendsList] = useState([]);
  const [selectedFriends, setSelectedFriends] = useState([]); // For multiple selection

  const fetchMutualFriendsList = async () => {
    setLoading('loading');
    try {
      const response = await fetch('/api/minifriends');
      if (response.ok) {
        const data = await response.json();
        setFriendsList(data.friendDetailsWithStatus);
        setLoading('done');
      } else {
        setLoading(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(null);
    }
  };

  useEffect(() => {
    fetchMutualFriendsList(); // This will run when the component mounts
  }, []);

  useEffect(() => {
    try {
      if (loading === 'done' && friendsList.length > 0) {
        const filteredList = friendsList.filter(friend =>
          friend.name.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredFriendsList(filteredList);
      }
    } catch (error) {

    }
  }, [friendsList, search]);

  const handleSelectFriend = (friend) => {
    try {
      if (selectedFriends.includes(friend)) {
        setSelectedFriends(selectedFriends.filter(f => f !== friend));
      } else {
        setSelectedFriends([...selectedFriends, friend]);
      }
    } catch (error) {

    }
  };

  const handleDone = () => {
    setTaggedFriends(selectedFriends);
    setShowFriendslist(false);
    setLoading(null);
  };

  const handleCancel = () => {
    setTaggedFriends([]);
    setShowFriendslist(false);
    setLoading(null);
  };

  const back = () => {
    setLoading(null);
    setShowFriendslist(false);
  };

  const checkMemberStatus = (friend) => {
    try {
      const member = members.find(member => member.mainid === friend.mainid);
      if (member) {
        if (member.status === 'accepted') return 'Joined';
        if (member.status === 'invited') return 'Invited';
        if (member.status === 'request') return 'Request';
      }
      return null;
    } catch (error) {

    }
  };

  // Check if all non-member friends are selected
  const areAllNonMemberFriendsSelected = () => {
    try {
      const nonMemberFriends = friendsList.filter(friend =>
        !members.some(member => member.mainid === friend.mainid)
      );
      return nonMemberFriends.every(friend => selectedFriends.includes(friend));
    } catch (error) {
      console.log(error)
    }
  };

  // Select or deselect all non-member friends
  const handleSelectAllNonMembers = () => {
    try {
      const nonMemberFriends = friendsList.filter(friend =>
        !members.some(member => member.mainid === friend.mainid)
      );
      if (areAllNonMemberFriendsSelected()) {
        // If all non-member friends are selected, deselect them
        setSelectedFriends(selectedFriends.filter(friend =>
          members.some(member => member.mainid === friend.mainid)
        ));
      } else {
        // Otherwise, select all non-member friends
        setSelectedFriends([...selectedFriends, ...nonMemberFriends.filter(friend => !selectedFriends.includes(friend))]);
      }
    } catch (error) {
      console.log(error)
    }
  };


  if (loading === 'done' && friendsList.length === 0) {
    return (
      <div>
        {loading === 'loading' || loading === 'done' ? <SwipeInUser back={back} /> : ''}
        <div className="mutualPage">
          <div className="mutuallist">
            <h2>No Friends Found</h2>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {loading === 'loading' || loading === 'done' ? <SwipeInUser back={back} /> : ''}
      {loading === 'loading' ? (
        <div className="mutualPage">
          <div className="mutuallist">
            <div style={{ backgroundColor: 'rgba(15, 15, 15, 0.95)' }} className='' >
              <h2>FRIENDS</h2>
            </div>
            <div className='miniFriendWithCheckBoxAndSearch' >
              <input
                style={{ margin: '20px 10px ', width: '50%' }}
                className="miniSerchInput"
                type="text"
                id="search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                maxLength={17}
                placeholder="search"
              />
              <p className='singleButton activeBlack' onClick={handleDone} style={{ height: '25px', marginBottom: '17px', width: '60px' }}>Done</p>
              <label style={{ marginBottom: '15px', color: 'white', fontSize: '12px' }} className='minicardCheckBox'>
                <input
                  style={{ width: '25px', height: '25px' }}
                  type="checkbox"
                  checked={false}
                  onChange={() => { }}
                />
              </label>
            </div>
            <FakeChatCard />
          </div>
        </div>
      ) : null}
      {loading === 'done' && friendsList.length > 0 && filteredFriendsList.length > 0 ? (
        <div style={{ paddingBottom: '10px' }} className="mutualPage">
          <div className="mutuallist">
            <div style={{ backgroundColor: 'rgba(15, 15, 15, 0.95)' }} className='' >
              <h2>FRIENDS</h2>
            </div>
            <div className='miniFriendWithCheckBoxAndSearch' >
              <input
                style={{ margin: '20px 10px ', width: '50%' }}
                className="miniSerchInput"
                type="text"
                id="search"
                value={search}
                onChange={(event) => setSearch(event.target.value)}
                maxLength={17}
                placeholder="search"
              />
              <p className='singleButton activeBlack' onClick={handleDone} style={{ height: '25px', marginBottom: '17px', width: '60px' }}>Done</p>
              <label style={{ marginBottom: '15px', color: 'white', fontSize: '12px' }} className='minicardCheckBox'>
                <input
                  style={{ width: '25px', height: '25px' }}
                  type="checkbox"
                  checked={areAllNonMemberFriendsSelected()}
                  onChange={handleSelectAllNonMembers}
                />
              </label>
            </div>
            {filteredFriendsList.map((friend) => (
              <div className='miniFriendWithCheckBox' key={friend.mainid} onClick={() => handleSelectFriend(friend)}>
                <MiniFriendCard setTaggedFriend={() => { }} setShowFriendslist={() => { }} setLoading={() => { }} friend={friend} />
                <div>
                  {checkMemberStatus(friend) ? (
                    <p className='minicardStatus'>{checkMemberStatus(friend)}</p>
                  ) : (
                    <label className='minicardCheckBox'>
                      <input
                        type="checkbox"
                        checked={selectedFriends.includes(friend)}
                        onChange={() => handleSelectFriend(friend)}
                      />
                    </label>
                  )}
                </div>
              </div>
            ))}
            <div style={{ justifyContent: 'space-between', marginTop: '20px' }}>
              <p className='singleButton activeBlack' onClick={handleDone} style={{ height: '25px', marginBottom: '20px' }}>Send Invite</p>
              <p className='singleButton2 activeBlack' onClick={handleCancel} style={{ height: '20px', marginBottom: '20px' }}>Cancel</p>
            </div>
            <div style={{height:'100px'}}/>
          </div>
        </div>
      ) : null}
    </div>
  );
}
