import React, { useState, useEffect } from "react";
import { handleEditShowTo } from "./proFuncs";
import { useDispatch } from "react-redux";
import { editData } from "../home/homeSlice";
import male from '../../Pics/male.png'
import female from '../../Pics/female.png'

export function ShowTo({ data }) {
  const dispatch = useDispatch();
  const [selectedShowTo, setSelectedShowTo] = useState("all");

  useEffect(() => {
    if (data) {
      setSelectedShowTo(data.showto);
    }
  }, [data]);

  const handleShowToChange = (selectedValue) => {
    if (selectedValue !== selectedShowTo) {
      setSelectedShowTo(selectedValue);
      handleEditShowTo(selectedValue, dispatch, editData);
    }
  };

  return (
    <div className="showToCont">
      <p>VISIBLE TO</p>
      <div>
        <button
          style={{ backgroundColor: selectedShowTo === 'all' ? 'hsla(72, 100%, 47%, 65%)' : '' }}
          onClick={() => handleShowToChange("all")}
        >
          <h2 style={{ color: selectedShowTo === 'all' ? 'black' : '' }} >ALL</h2>
        </button>
        <button
          style={{ backgroundColor: selectedShowTo === 'male' ? 'hsla(72, 100%, 47%, 65%)' : '' }} onClick={() => handleShowToChange("male")}
        >
          <img
            style={{ filter: selectedShowTo === 'male' ? 'invert(1)' : '' }}
            src={male} alt="Male" />
        </button>
        <button
          style={{ backgroundColor: selectedShowTo === 'female' ? 'hsla(72, 100%, 47%, 65%)' : '' }} onClick={() => handleShowToChange("female")}
        >
          <img
            style={{ filter: selectedShowTo === 'female' ? 'invert(1)' : '' }}
            src={female} alt="Female" />
        </button>
      </div>
    </div>
  );
}