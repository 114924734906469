import React, { useState } from "react";
import { rejectFriendRequest } from "../friendButton/firndButFuncs";

export function UnblockCard({ user }) {
    const [visible, setVisible] = useState(true)
    const [loading, setLoading] = useState('idle')

    async function handleClick() {
        setLoading('loading')
        await rejectFriendRequest(user.mainid).then(() => {
            setVisible(false)
        }).catch((e) => {
            setLoading('idle')
        })
    }

    if (visible) {
        return (
            <div style={{ position: 'relative' }} className="minicard">
                <img src={user.picture_url} alt={user.name} className="mini-user-picture" />
                <div className="mini-user-details">
                    <p className="mini-smallText1">{user.name}</p>
                </div>
                <p className="unblockButton" onClick={handleClick}>{loading === 'loading' ? 'Loading' : 'Un-Block'}</p>
            </div>
        );
    }

}


