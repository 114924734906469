import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportGroup } from './ReportGroup';
import repImg from '../../Pics/repImg.png'
import edit from '../../Pics/edit.png'
import StarRatings from 'react-star-ratings';

export function FakeGroupCard({ }) {
    const [rating, setRating] = useState(0)
    const [ratingCount, setRatingCount] = useState(0)
    const [myRating, setMyRating] = useState(null)
    const [coachHidden, setCoachHidden] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [likedByMe, setLickedByMe] = useState(false)
    const [dynamicCount, setDynamicCount] = useState(0)
    const dispatch = useDispatch();

    return (
        <div style={{ height: '160px' }} className='groupCardCont'>
            <div className='fakeBackGround coachCardProfilePic' />
            <div className='coachMainInfoCont'>
                <h3 className='fake'>LOADING ...</h3>
                <div className='SportCont'>
                    <div className="fakeBackGround otherProfileFav" />
                    <h4 className='fake'>Loading ...</h4>
                </div>
                <h5 className='fake'>Loading ...</h5>
            </div>
            <div className='starsCont'>
                <p ><span style={{ color: 'hsl(72, 100%, 47%)', width: '100px' }}>Members:</span> (0)</p>
            </div>


        </div>
    )
}