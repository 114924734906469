import cycling from './Pics/sports/cycling.png'
import football from './Pics/sports/football.png'
import gym from './Pics/sports/gym.png'
import running from './Pics/sports/running.png'
import swimming from './Pics/sports/swimming.png'
import walking from './Pics/sports/walking.png'
import yoga from './Pics/sports/yoga.png'
import tennis from './Pics/sports/tennis.png'
import ultimate from './Pics/sports/ultimate.png'
import padel from './Pics/sports/padel.png'
import spikeball from './Pics/sports/spikeball.png'
import hiking from './Pics/sports/hiking.png'
import basketball from './Pics/sports/basketball.png'
import amfootball from './Pics/sports/amfootball.png'
import skateboard from './Pics/sports/skateboard.png'
import volleyball from './Pics/sports/volleyball.png'
import kayaking from './Pics/sports/kayaking.png'
import boxing from './Pics/sports/boxing.png'
import archerytag from './Pics/sports/archerytag.png'
import calisthenics from './Pics/sports/calisthenics.png'



import stopwatch from './Pics/stopwatch.png'
import logo192 from './Pics/logo192.png'
import rednotif from './Pics/rednotif.png'
import notif from './Pics/notif.png'
import chat from './Pics/chat.png'
import chatRed from './Pics/chatRed.png'
import announce from './Pics/announce.png'
import searchUser from './Pics/searchUser.png'
import searchGroup from './Pics/searchGroup.png'
import duoGreen from './Pics/duoGreen.png'
import whistle from './Pics/whistle.png'
import nearByPic from './Pics/nearBy.png'
import publicPhoto from "./Pics/public.png";
import publicPhotoOff from "./Pics/publicoff.png";
import feedGreen from './Pics/feedGreen.png'
import trophy from './Pics/trophy.png'
import friendsPic from './Pics/connects.png'
import friends2 from './Pics/friends2.png'
import logWorkouts from './Pics/logWorkouts.png'
import back from './Pics/back.png'
import thinback from './Pics/thinback.png'
import infoImg from './Pics/info.png'
import refreshGreen from './Pics/refreshGreen.png'
import reviewImg from './Pics/reviewImg.png'
import experience from './Pics/experience.png'
import feed from './Pics/feed.png'
import traineeGreen from './Pics/traineeGreen.png'
import traineeGrey from './Pics/traineeGrey.png'
import edit from './Pics/edit.png'
import editGreen from './Pics/editGreen.png'
import call from './Pics/call.png'
import search from './Pics/search.png'
import NoAds from './Pics/NoAds.png'
import meetUpGreen from './Pics/meetup2.png'
import coachprofile from './Pics/coachprofile.png'
import ranks from './Pics/ranks.png'
import Logos from './Pics/Logos.png'
import recordPic from './Pics/record.png'
import homeLogo from './homeLogo.png'
import eye from './Pics/eye.png'
import plus from './Pics/plus.png'
import event from './Pics/event.png'
import commentPic from './Pics/comment.png'
import commentPicWhite from './Pics/commentWhite.png'
import shareWhite from './Pics/sharewhite.png'


export const picsToSave = {
    stopwatch,
    eye,
    logo192,
    rednotif,
    notif,
    chat,
    chatRed,
    back,
    thinback,
    running,
    gym,
    cycling,
    walking,
    padel,
    tennis,
    football,
    basketball,
    volleyball,
    yoga,
    swimming,
    ultimate,
    spikeball,
    kayaking,
    boxing,
    skateboard,
    amfootball,
    hiking,
    archerytag,
    calisthenics,
    announce,
    searchUser,
    searchGroup,
    duoGreen,
    whistle,
    nearByPic,
    publicPhoto,
    publicPhotoOff,
    feedGreen,
    trophy,
    friendsPic,
    friends2,
    logWorkouts,
    infoImg,
    refreshGreen,
    reviewImg,
    experience,
    feed,
    traineeGreen,
    traineeGrey,
    edit,
    call,
    search,
    NoAds,
    meetUpGreen,
    coachprofile,
    ranks,
    Logos,
    recordPic,
    homeLogo,
    editGreen,
    plus,
    event,
    commentPic,
    shareWhite,
    commentPicWhite
};