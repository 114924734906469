import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectData } from '../home/homeSlice';
import { useParams, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { Post } from './Post';
import { FakePost } from './FakePost';
import './soloPost.css'


export function SoloPost({ }) {
    const myinfo = useSelector(selectData)
    const [loading, setLoading] = useState(null)
    const [postData, setPostData] = useState(null)
    const [canSeePost, setCanSeePost] = useState(false)
    const [isPostPublic, setIsPostPublic] = useState(false)
    const [request, setRequest] = useState(false)
    const navigate = useNavigate();
    let { postid } = useParams();

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const swipeBackHandlers = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });


    async function fetchSinglePost(postid) {
        setLoading(true)
        try {
            const response = await fetch(`/api/post/loadsinglepost/${postid}`, {
                method: 'GET'
            });

            if (response.ok) {
                const post = await response.json();
                setPostData(post)
                setLoading('done')
            } else {
                const errorResponse = await response.json();
                setLoading(null)
                throw new Error(errorResponse.error);
            }
        } catch (error) {
            console.error('Error fetching single post:', error);
            setLoading(null)
            throw error;
        }
    }



    useEffect(() => {
        try {
            let id = postid.replace(":", "");
            fetchSinglePost(id)
        } catch (e) {

        }
    }, [postid]);


    useEffect(() => {
        if (postData) {
            try {
                if (postData.public === true) {
                    setCanSeePost(true);
                } else if (myinfo.friends[postData.creatorid]?.status === 'friends' || postData.creatorid === myinfo.mainid) {
                    setCanSeePost(true);
                } else {
                    setCanSeePost(false);
                }
            } catch (e) {
                setCanSeePost(false);
            }
            try {
                setIsPostPublic(postData.public)
            } catch (e) {
            }
        }
    }, [postData, myinfo]);

    useEffect(() => {
        if (postData) {
            try {
                // Check if the user's friends list contains the creator of the post and their status is 'friends'
                if (postData.taggedid === myinfo.mainid && postData.showtagged === false) {
                    setRequest(true);
                } else {
                    setRequest(false);
                }
            } catch (e) {
                setRequest(false);
            }
        }
    }, [postData, myinfo]);

    if (canSeePost === false) {
        return (
            ""
        )
    }

    return (
        <div  {...swipeBackHandlers} style={{ zIndex: '10' }} className="friendsList">
            <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                {/*request ?
                    <div style={{ lineHeight: '1.3' }} className='tagRequestTitile' >
                        <h2>{postData.creatorname}</h2>
                        <p>Wants to tag you in this post !</p>
                    </div>
                    :
                ""*/}
                {loading === 'done' ? <Post thumb={postData.thumb} postid={postData.postid} image={postData.postphoto} creatorid={postData.creatorid} creatorname={postData.creatorname} creatorpicture={postData.picture_url} createTime={postData.time} caption={postData.caption} sport={postData.sport} taggedid={postData.taggedid} taggedname={postData.taggedname} likes={postData.likes} myMainid={myinfo.mainid} showtagged={postData.showtagged} setRequest={setRequest} hiddenby={postData.hiddenby} commentscount={postData.commentscount} likescount={postData.likescount} /> :
                    <FakePost key={'2'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={myinfo.mainid} showtagged={false} setRequest={""} />
                }
                {request
                    ?
                    <div style={{ marginTop: '10px' }} className='tagRequestTitile'>
                        <p style={{ fontWeight: '500' }}  >Do you accept being tagged in this post?</p>
                        {isPostPublic ? <p style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} >{postData.creatorname} tagged you in this <span style={{ fontWeight: '500' }}>PUBLIC</span> post, </p> : <p style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} >{postData.creatorname}'s friends will be able to see your profile throught this post.</p>}
                        <p style={{ textAlign: 'left', fontSize: '14px', marginTop: '8px' }} >If you dont't want to accept, just ignore this page. Your name wont appeare in this post unless you accept</p>
                    </div>
                    :
                    ""
                }
            </div>
        </div>
    );
}


