import React, { useState, useEffect } from 'react';
import { FakeChatCard } from '../chats/FakeChatCard'
import { SwipeInUser } from '../user/SwipeInUser'
import { MiniCard } from './MiniCard';
import liked from '../../Pics/liked.png'
import { useSwipeable } from "react-swipeable";

export function MiniList({ postid, setShowUserslist }) {
    const [loading, setLoading] = useState(null);
    const [usersList, setUsersList] = useState([]);

    useEffect(() => {
        window.smallListOpend = true
    }, [])

    const handleSwipeRight = () => {
        setShowUserslist(null)
        try {
            setTimeout(() => {
                window.smallListOpend = false
            }, 500);
        } catch (e) {

        }

    };


    const swipeBackHandlersBack = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    async function fetchPostLikesUsers(postid) {
        setLoading('loading');

        try {
            const response = await fetch(`/api/post/likesusers`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ postid: postid }),
            });

            if (response.ok) {
                const data = await response.json();
                setUsersList(data);
                setLoading('done')
            } else {
                setLoading(null);
                setShowUserslist(null)
                console.error('Failed to load users');
            }
        } catch (error) {
            setLoading(null);
            setShowUserslist(null)
            console.error('Failed to load users', error);
        }
    }


    useEffect(() => {
        try {
            if (postid) {
                fetchPostLikesUsers(postid); // This will run when the component mounts
            }
        } catch (e) {

        }
    }, [postid]); //

    const back = () => {
        setLoading(null);
        setShowUserslist(null)
    }

    if (loading === 'done' && usersList.length === 0) {
        return (
            <div >
                {loading === 'loading' || loading === 'done' ?
                    <SwipeInUser back={back} /> : ''}
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }} className="mutualPage">
                    <div className="mutuallist">
                        <h2>No Likes yet!</h2>
                    </div>
                </div>
            </div>
        )
    }


    return (
        <div {...swipeBackHandlersBack}>
            {loading === 'loading' || loading === 'done' ?
                <SwipeInUser back={back} /> : ''}
            {loading === 'loading' ? (
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }} className="mutualPage">
                    <div className="mutuallist">
                        <div style={{ marginBottom: '10px', alignItems: 'center', gap: '10px', display: 'flex', height: '30px', width: 'fit-content' }} >
                            <img style={{ height: '100%' }} src={liked} />
                            <p style={{ fontSize: '18px', fontWeight: '500', color: 'white' }} >Liked by:</p>
                        </div>
                        <FakeChatCard />
                    </div>
                </div>
            ) : null}
            {loading === 'done' && usersList.length > 0 ? (
                <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }} className="mutualPage">
                    <div className="mutuallist">
                        <div style={{ marginBottom: '10px', alignItems: 'center', gap: '10px', display: 'flex', height: '30px', width: 'fit-content' }} >
                            <img style={{ height: '100%' }} src={liked} />
                            <p style={{ fontSize: '18px', fontWeight: '500', color: 'white' }} >Liked by:</p>
                        </div>
                        {usersList.map((user) => (
                            <MiniCard setShowUserslist={setShowUserslist} key={user.mainid} user={user} />
                        ))}
                    </div>
                </div>
            ) :
                ""
            }
        </div>
    );
}
