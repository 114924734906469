import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SmallLoadingBar } from "../root/SmallLoadingBar";
import { disableRefresh, selectData } from "../home/homeSlice";
import { SingleCommentEvent } from "./SingleCommentEvent";
import repImg from '../../Pics/repImg.png'

import { setMainId } from "../list/tempProfileSlice";
import { AddCommentEvent } from "./AddCommentEvent";

export function EventComments({ eventid, creatorid, creating, setCreating, dynamicCommentsCount, setDynamicCommentsCount }) {
    const myInfo = useSelector(selectData)
    const [status, setStatus] = useState('loading')
    const [coach, setCoach] = useState(null)
    const [comments, setComments] = useState(null)
    const [students, setStudents] = useState(null)
    const [addingComment, setAddingComment] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()


    const fetchComments = async () => {
        if (coach === null) {
            setStatus('loading')
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/events/laodeventscomments`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ eventid: eventid }),
            });
            if (response.ok) {
                const data = await response.json();
                setComments(data)
                setDynamicCommentsCount(data.length)
                setStatus('done')
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchComments()
    }, [])


    if (status === 'loading') {
        return (
            <div className="postCommentsCont">
                <div style={{ padding: '0px 0px' }} className='reviewsCont'>
                    {Array.from({ length: dynamicCommentsCount }, (_, index) => (
                        <div style={{ borderBottom: '1px solid black' }} key={index} className='singleReviewCont'>
                            <p style={{ width: "100%", lineHeight: "1.2", marginBottom: '3px' }}>
                                <span
                                    style={{ color: 'hsla(72, 100%, 47%, 70%)' }}>
                                    Loading . . .:
                                </span>
                                <br />
                                <span style={{ marginTop: '5px', display: 'inline-block' }}>
                                    - Loading . . .
                                </span>
                            </p>
                            <img className='repImgInSingleReview' src={repImg} />
                        </div>
                    ))}
                    {dynamicCommentsCount === 0 ? < h5 style={{ color: 'white', gridRow: '1 / 2' }}>No Comments Yet</h5> : ""}
                </div>
            </div >
        )
    }

    if (status === 'done') {
        return (
            <div style={{ position: 'relative' }} >
                <img style={{ position: 'absolute', height: "40px", top: '-53px', right: '15px', padding: '10px' }} onClick={() => { setAddingComment(true) }} src={localStorage.getItem('plus')} />
                {
                    creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                        <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
                    </div> : ""
                }
                {status === 'done' && comments && comments.length > 0 ?
                    <div style={{ padding: '0px 10px', maxHeight: 'fit-content' }} className='reviewsCont'>
                        {addingComment ?
                            <AddCommentEvent setCreating={setCreating} myname={myInfo.name} comments={comments} setComments={setComments} setAddingComment={setAddingComment} eventid={eventid} creatorid={creatorid} setDynamicCommentsCount={setDynamicCommentsCount} />
                            :
                            ""
                        }
                        {comments.slice().sort((a, b) => b.indexid - a.indexid).map((rev) => (
                            <SingleCommentEvent key={rev.indexid} rev={rev} myMainid={myInfo.mainid} eventid={eventid} creatorid={creatorid} setComments={setComments} setDynamicCommentsCount={setDynamicCommentsCount} setCreating={setCreating} />
                        ))}
                    </div>
                    :
                    <div style={{ padding: '0px 10px' }} className='reviewsCont'>
                        {addingComment ?
                            <AddCommentEvent setCreating={setCreating} myname={myInfo.name} comments={comments} setComments={setComments} setAddingComment={setAddingComment} eventid={eventid} creatorid={creatorid} setDynamicCommentsCount={setDynamicCommentsCount} />
                            :
                            ""
                        }
                        {status === 'done' ? <h5 style={{ color: 'white', gridRow: '1 / 2' }}>No Comments Yet</h5> : ""}
                    </div >
                }
            </div>

        )
    }
}
