import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { loadChatReqs } from "./chatReqsSlice";
import close from "../../Pics/close.png";
import { useNavigate } from "react-router-dom";
import { deleteRequest } from "./chatReqsFuncs";
import { generateRequestLink, textToDisplay } from "./smallrequestsFuncs";
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import { loadReqs } from "../reqs/reqsSlice";


export function SmallRequest({ myId, request, setNotification }) {
    const [show, setShow] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handelDelete = (notID, status) => {
        setShow(false)
        deleteRequest(notID, status).then((response) => {
            if (status.includes('chat')) {
                dispatch(loadChatReqs(myId));
            } else {
                dispatch(loadReqs(myId));
            }
        });
        setNotification(false);
    };

    const handelGoToUser = (request) => {
        const link = generateRequestLink(request, myId)
        navigate(link)
        if (request.status === 'groupchat' || request.status === 'chat') {
            setShow(false)
            deleteRequest(request.senderid, request.status).then(() => {
                dispatch(loadChatReqs(myId));
            }).then(() => {
                setNotification(false);
            }).catch((e) => {
                console.log(e)
            })
        }

    };


    if (show === true) {
        return (
            <div
                onClick={request.status === 'meetingrequest' || request.status === 'meetingaccept' || request.status === 'meetingconfirmed' ? () => { dispatch(setMeetingID(`meet:${request.meetingid}`)) } : undefined}
                className="smallChatReq activeBlack"
                style={{ width: '100%' }}
            >
                <div
                    onClick={() => { handelGoToUser(request) }}
                    className="smallLink"
                >
                    <img className="smallProfPic" src={request.picture_url} />
                </div>
                <div
                    onClick={() => { handelGoToUser(request) }}
                    className="smallText"
                >
                    <p >{request.name}<br /> {textToDisplay(request.status)}</p>
                </div>
                <img
                    style={{ width: '100%', padding: '7px', filter: 'invert(1)' }}
                    className="closeX"
                    onClick={() => handelDelete(request.senderid, request.status)}
                    src={close}
                />
            </div>
        )
    }

}
