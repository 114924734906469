import React, { useEffect, useState } from "react";
import './chatCard.css';

export function ChatCard({ chat }) {
  const { other_user_name, other_user_picture, last_message } = chat;
  const [contentToDisplay, setContentToDisplay] = useState('');

  useEffect(() => {
    if (last_message.includes('.wav')) {
      setContentToDisplay("Audio Message");
    } else if (last_message.includes('.jpeg') || last_message.includes('.jpg') || last_message.includes('.png')) {
      setContentToDisplay("Photo Message");
    } else if (last_message.includes('.mp4')) {
      setContentToDisplay("Video Message");
    } else {
      setContentToDisplay(last_message);
    }
  }, [last_message]);

  return (
    <div className="chat-card">
      <img src={other_user_picture} alt={other_user_name} className="user-picture" />
      <div className="user-details">
        <p style={{ fontWeight: 'bold' }} className="smallText1">{other_user_name}</p>
        <p style={{ textAlign: 'left' }} className="smallText2">{contentToDisplay}</p>
      </div>
    </div>
  );
}
