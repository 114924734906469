import React from 'react';

export const RankingHeader = ({ rank, name }) => {
    let rankLabel;

    switch (rank) {
        case 1:
            rankLabel = '1st';
            break;
        case 2:
            rankLabel = '2nd';
            break;
        case 3:
            rankLabel = '3rd';
            break;
        default:
            if (rank >= 4 && rank <= 15) {
                rankLabel = `${rank}th`;
            } else {
                rankLabel = `${rank}`;
            }
            break;
    }

    return (
        <div className="ranking-header">
            <p>{rankLabel}</p>
        </div>
    );
};
