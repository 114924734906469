import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadChatReqs = createAsyncThunk(
  "chatReqs/loadChatReqs",
  async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/profile/newchat`
    );
    const json = await data.json();
    return json;
  }
);

export const chatReqsSlice = createSlice({
  name: "chatReqs",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {
    reset: (state) => {
      state.data = [];
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadChatReqs.pending, (state) => {
        if (state.data.length < 1) {
          state.status = "loading";
        }
      })
      .addCase(loadChatReqs.fulfilled, (state, action) => {
        state.log = "logged";
        state.status = "done";
        state.data = action.payload;
      })
      .addCase(loadChatReqs.rejected, (state, action) => {
        state.log = "notlogged";
        state.status = "failed";
        state.data = null;
      });
  },
});

export const selectData = (state) => state.chatReqs.data;
export const selectStatus = (state) => state.chatReqs.status;
export const selectLog = (state) => state.chatReqs.log;
export const { reset } = chatReqsSlice.actions;

export default chatReqsSlice.reducer;
