import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FakePost } from "../Posts/FakePost";
import { ChangePP } from '../profile/changePP'
import { fetchMyPosts } from './myPageFuncs'
import { Post } from "../Posts/Post";
import { disableRefresh, enableRefresh } from "../home/homeSlice";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { selectData } from "../home/homeSlice";
import changePPIMGG from "../../Pics/changePP.png";
import "./myPage.css";


export function MyPage() {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [initialHeight, setInitialHeight] = useState(0);
  const [loading, setLoading] = useState(null)
  const [posts, setPosts] = useState(null)
  const [filteredPosts, setFilteredPosts] = useState(null)
  const [bigPic, setBigPic] = useState(false)
  const [PPMod, setPPMod] = useState(false);
  const data = useSelector(selectData);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSwipeRight = () => {
    navigate(-1)
  };

  const handleSwipeLeft = () => {
    navigate(+1)
  };

  const swipeBackHandlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
  });


  const handleSwipeDown = () => {
    setLoading(true)
    fetchMyPosts(setLoading, setPosts);
  };

  const swipeBackHandlersRefresh = useSwipeable({
    onSwipedDown: handleSwipeDown,
    preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
  });


  useEffect(() => {
    setLoading(true)
    fetchMyPosts(setLoading, setPosts);
  }, []);

  const openEditPP = () => {
    if (PPMod === false) {
      dispatch(disableRefresh())
    }
    if (PPMod === true) {
      dispatch(enableRefresh())
    }
    setPPMod((PPMod) => !PPMod);

  };

  useEffect(() => {
    if (!posts) return;
    try {
      const updatedFilteredPosts = posts.filter((post) => {
        if (selectedFilter === "All") {
          return true; // Show all posts
        } else if (selectedFilter === "My Posts") {
          return post.creatorid === data.mainid;
        } else if (selectedFilter === "Tagged") {
          return post.taggedid === data.mainid;
        }
        return false;
      });
      setFilteredPosts(updatedFilteredPosts);
    } catch (e) {

    }
  }, [selectedFilter, posts]);

  useEffect(() => {
    if (data) {
      setTimeout(() => {
        const infoContElement = document.getElementById('profileCont');
        if (infoContElement) {
          setInitialHeight(infoContElement.clientHeight);
        }
      }, 33000);
    }
  }, [data]);




  return (
    <div style={{ marginTop: '10px' }}>
      <div>
        {<div style={{ display: 'flex', width: 'fit-content', gap: '10px', justifyContent: 'space-around', alignItems: 'center', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px', backgroundColor: "transparent" }} className="hintCont">
          <div style={{ margin: '0px auto', width: "50px", position: 'relative' }} className="ppwithplusPost activeBlack">
            <img onMouseUp={() => navigate('/home/postcreator')} className="miniPPInFeedShortCut" src={data.picture_url} />
            <img style={{ right: '0px' }} onMouseUp={() => navigate('/home/postcreator')} className="plusInFeedShortCut" src={localStorage.getItem('plus')} />
          </div>
          <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">MY - JOURNEY</label>
        </div>}
        {/*<div className="ProfileHeader2">
          <div {...swipeBackHandlersRefresh} style={{ height: '50px' }} className="MiniCreateButtonsCont">
            {bigPic ?
              <div onClick={() => setBigPic(false)} className="otherBigPic">
                <img src={data.picture_url} />
              </div>
              :
              ''
            }
            <img onClick={() => setBigPic(true)} className="miniPP" src={data.picture_url} />
            <img onClick={openEditPP} className="openChangePP" src={changePPIMGG} />
            {PPMod ? <ChangePP PPMod={PPMod} setPPMod={setPPMod} /> : ""}
            <p onMouseUp={() => navigate('/home/postcreator')} style={{ color: 'black', margin: 'auto 0px' }} className="goToCreatPost">CREATE POST</p>
            <p onMouseUp={() => navigate('/home/myworkouts')} style={{ color: 'black', margin: 'auto 0px', padding: '0px' }} className="goToCreatPost">MY WORKOUTS</p>
            <div className="postsFilterCont">
              <p
                style={{ backgroundColor: selectedFilter === "All" ? "hsla(72, 100%, 47%, 65%)" : "", color: selectedFilter === "All" ? "black" : "black" }}
                onClick={() => setSelectedFilter("All")}
              >
                All
              </p>
              <p
                style={{ backgroundColor: selectedFilter === "My Posts" ? "hsla(72, 100%, 47%, 65%)" : "", color: selectedFilter === "My Posts" ? "black" : "black" }} onClick={() => setSelectedFilter("My Posts")}
              >
                My Posts
              </p>
              <p
                style={{ backgroundColor: selectedFilter === "Tagged" ? "hsla(72, 100%, 47%, 65%)" : "", color: selectedFilter === "Tagged" ? "black" : "black" }} onClick={() => setSelectedFilter("Tagged")}
              >
                Tagged
              </p>
            </div>

          </div>
        </div>*/}
        {
          loading === 'done' && posts.length === 0 ?
            <div style={{ marginTop: '0px', marginBottom: '25px', height: '100%' }} className="scrollList">
              <div style={{ height: '100%', width: '100%' }}>
                <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}> No created/tagged in posts </h3>
                <div style={{ margin: '0px auto', width: "50px", position: 'relative' }} className="ppwithplusPost activeBlack">
                  <img onMouseUp={() => navigate('/home/postcreator')} className="miniPPInFeedShortCut" src={data.picture_url} />
                  <img style={{ right: '0px' }} onMouseUp={() => navigate('/home/postcreator')} className="plusInFeedShortCut" src={localStorage.getItem('plus')} />
                </div>
              </div>
            </div> :
            ""
        }
        {loading === 'done' && filteredPosts ? (
          filteredPosts.map((post) => (
            <Post thumb={post.thumb} key={post.postid} postid={post.postid} image={post.postphoto} creatorid={post.creatorid} creatorname={post.creatorname} creatorpicture={post.creator_picture_url} createTime={post.time} caption={post.caption} sport={post.sport} taggedid={post.taggedid} taggedname={post.taggedname} likes={post.likes} myMainid={data.mainid} showtagged={post.showtagged} hiddenby={post.hiddenby} setRequest={""} fetchMyPosts={fetchMyPosts} setLoading={setLoading} setPosts={setPosts} commentscount={post.commentscount} likescount={post.likescount} />
          ))
        ) : (
          <div>
            <FakePost key={'2'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
            <FakePost key={'3'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
          </div>
        )}
      </div>
    </div>
  )
}
