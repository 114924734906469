import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUser } from './userFuncs'

export const loadUser= createAsyncThunk(
  'user/loadUser',
  async (mainid) => {     
    const data = await fetchUser(mainid)   
    const json = await data
    return json;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {   
    info: [],    
    status: 'idle',    
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {  
    clearInfo: (state, action) => {
      state.info = [];
      state.status = 'idle';
    },  
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder      
      .addCase(loadUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.status = 'done';
        state.info = action.payload;        
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.status = 'failed';
        console.log('failed')
      })
  },
});

export const selectInfo = (state) => state.user.info;
export const selectStatus = (state) => state.user.status;
export default userSlice.reducer;
export const { clearInfo } = userSlice.actions;


