import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { handleEditGender } from "../profile/proFuncs";
import { loadHome, editData } from "../home/homeSlice";
import { ImageLinkChanger } from '../profile/ImageLinkChanger';
import { Fav1 } from '../profile/Fav1';
import { Fav2 } from '../profile/Fav2';


export function Gender({ data, selectedGender, setSelectedGender }) {
  const [genderHelper, setGenderHelper] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.location.pathname !== '/home/profile') {
      navigate('/home/profile')
    }
  }, [])


  const handleGenderChange = (gender) => {
    setSelectedGender(gender)
    setGenderHelper(gender)
  };

  useEffect(() => {
    if (genderHelper) {
      setSelectedGender(genderHelper)
    }
  }, [selectedGender])

  const confirmGenderChange = (gender) => {
    handleEditGender(gender, dispatch, loadHome, editData, navigate);
  }

  return (
    <div style={{ height: 'calc(100vh - 104px)' }} className="containerLogin">
      <div style={{ marginBottom: '10px', borderRadius: '15px', padding: '20px 15px 10px 20px' }} className='boxLogin'>
        <h3 style={{ marginLeft: '0px' }} >Required:</h3>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Gender:</h4>
        <p style={{ marginLeft: '0px' }} className="disclaimer">Gender is required for App functionality</p>
        <div className="gender-options">
          <div style={{ borderRadius: '15px' }} className={`gender-option ${selectedGender === "male" ? "active" : ""}`} onClick={() => handleGenderChange("male")}>
            Male
          </div>
          <div style={{ borderRadius: '15px' }} className={`gender-option ${selectedGender === "female" ? "active" : ""}`} onClick={() => handleGenderChange("female")}>
            Female
          </div>
        </div>
        <h3 style={{ marginLeft: '0px' }} >Optional:</h3>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Favorite Sport:</h4>
        <p className="disclaimer">Set your favorite sports to get notified when a user nearby is interested in them, or a nearby Group Created</p>
        <div style={{ display: 'flex', gap: '20px' }}>
          <Fav1 fav={data.fav1} />
          <Fav2 fav={data.fav2} />
        </div>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Social Profile:</h4>
        <p className="disclaimer">Help other users know it's really you by linking your social profiles. </p>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ display: 'flex', height: '50px', width: '100px', gap: '20px' }}>
            <ImageLinkChanger data={data} tempBack={'#f5f5f59c'} tempColor={'white'} />
          </div>
        </div>
      </div>
      <p style={{ backgroundColor: selectedGender === 'male' || selectedGender === 'female' ? 'hsla(72, 100%, 47%, 65%)' : "" }} onClick={() => confirmGenderChange(selectedGender)} className='confirmWelcomePage'>
        CONFIRM
      </p>
    </div >
  );
}