import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadTodayFeatured, selectTodayFeatured, selectTodayFeaturedStatus } from './workoutsSlice';
import { FeaturedWorkoutCard } from './FeaturedWorkoutCard';
import { ConfirmedFeaturedWorkoutCard } from './ConfirmedFeaturedWorkoutCard'
import { BigFeaturedWorkout } from './BigFeaturedWorkout';
import Slider from 'react-slick';
import './featuredWorkout.css'

export const FeaturedWorkout = ({ ConfirmedFeatured, ConfirmedFeaturedStatus }) => {
    const [bigPic, setBigPic] = useState(null)
    const [showWorkoutInfo, setShowWorkoutInfo] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState("Add");
    const [trackedSoloWorkouts, setTrackedSoloWorkouts] = useState([]);
    const TodayFeatured = useSelector(selectTodayFeatured);
    const TodayFeaturedStatus = useSelector(selectTodayFeaturedStatus)
    const dispatch = useDispatch()

    useEffect(() => {
        if (TodayFeatured === null) {
            dispatch(loadTodayFeatured())
        }
    }, [])

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        arrows: false,
        dots: true,
    }

    useEffect(() => {
        const localStorageData = JSON.parse(localStorage.getItem('trackedSoloWorkouts'));

        if (localStorageData) {
            setTrackedSoloWorkouts(localStorageData);
        }
    }, [selectedFilter]);

    return (
        <div>
            <div style={{ height: '40px', padding: '0px 30px', gap: '15px', borderRadius: '0px' }} className="feedFilter">
                <label className="activeBlack" onClick={() => setSelectedFilter('Add')} style={{ color: selectedFilter === 'Add' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Today's</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Tracked')} style={{ color: selectedFilter === 'Tracked' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Tracked</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Confirmed')} style={{ color: selectedFilter === 'Confirmed' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Completed</label>
            </div>
            {bigPic !== null ?
                <div onClick={() => setBigPic(null)} className='fullScreenBlack'>
                    <img style={{ filter: "invert(.82)", borderRadius: '15px' }} src={bigPic} />
                </div>
                :
                ''
            }
            {showWorkoutInfo ?
                <div style={{ zIndex: '700' }} className="tempMeetingPage">
                    <div style={{ zIndex: '900' }} className="meetingPageCont">
                        <div onClick={() => setShowWorkoutInfo(null)} style={{ zIndex: '55', width: '100%' }} className='fuLLwidthandHeight' />
                        <BigFeaturedWorkout workout={showWorkoutInfo} setBigPic={setBigPic} setSelectedFilter={setSelectedFilter} />
                        <div onClick={() => setShowWorkoutInfo(null)} style={{ zIndex: '55', width: '100%' }} className='fuLLwidthandHeight' />
                    </div>
                </div>
                : ""
            }

            {selectedFilter === 'Add' && TodayFeaturedStatus === 'done' && TodayFeatured ?
                <Slider {...settings}>
                    {TodayFeatured.map((workout) => (
                        <BigFeaturedWorkout key={workout.id} workout={workout} setBigPic={setBigPic} setSelectedFilter={setSelectedFilter} />
                    ))}
                </Slider>
                :
                ""
            }            
            {selectedFilter === 'Tracked' ? (
                <div style={{ padding: '0px 15px' }}>
                    {trackedSoloWorkouts.map((workout) => (
                        <FeaturedWorkoutCard key={workout.id} workout={workout} setTrackedSoloWorkouts={setTrackedSoloWorkouts} setShowWorkoutInfo={setShowWorkoutInfo} selectedFilter={selectedFilter} />
                    ))}
                </div>

            ) : null}
            {selectedFilter === 'Confirmed' && ConfirmedFeaturedStatus === 'done' && ConfirmedFeatured ?
                <div style={{ padding: '0px 15px' }}>
                    {ConfirmedFeatured.map((workout, index) => (
                        <ConfirmedFeaturedWorkoutCard key={index} workout={workout} setTrackedSoloWorkouts={setTrackedSoloWorkouts} setShowWorkoutInfo={setShowWorkoutInfo} selectedFilter={selectedFilter} />
                    ))}
                </div>
                :
                ''
            }

        </div>
    );
};
