import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportGroup } from './ReportGroup';
import repImg from '../../Pics/repImg.png'

import StarRatings from 'react-star-ratings';
import TimeView from '../list/TimeView';
import { disbandMyGroup, ApplyToGroup, WithDrawFromGroup, InvitetoGroup, handleAcceptGroupInvite } from './groupsFuncs';
import { loadMyGroups } from './groupsSlice';
import { useNavigate } from 'react-router-dom';
import { MembersList } from './MembersList';
import { MiniFriends } from '../minifriends/MiniFriends';
import { AudioRecorder } from '../user/AudioRecorder';
import { setMainId } from '../list/tempProfileSlice';
import { editData } from '../home/homeSlice';
import { MiniFriendsMultiSelect } from '../minifriends/MiniFriendsMultiSelect';

export function GroupCard({ group, myMainid, setCreating }) {
    const [groupHidden, setGroupHidden] = useState(false)
    const [membersCount, setMembersCount] = useState(0)
    const [requestsCount, setRequestsCount] = useState(0)
    const [showReport, setShowReport] = useState(false)
    const [groupStatus, setGroupStatus] = useState('none')
    const [membersFilter, setMembersFitler] = useState(null)
    const [showfirendsList, setShowFriendslist] = useState(false)
    const [taggedFriends, setTaggedFriends] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        if (group && myMainid) {
            try {
                if (group.hiddenby.includes(myMainid)) {
                    setGroupHidden(true)
                }
            } catch (e) {
            }
        }
    }, []);

    useEffect(() => {
        if (group && myMainid) {
            if (group.groupid === myMainid) {
                setGroupStatus('owner')
                return
            }
            try {
                const member = group.members.find((member) => member.mainid === myMainid);

                if (member) {
                    if (member.status === 'request') {
                        setGroupStatus('request');
                    } else if (member.status === 'accepted') {
                        setGroupStatus('accepted');
                    } else if (member.status === 'invited') {
                        setGroupStatus('invited');
                    }
                } else {
                    setGroupStatus('none');
                }
            } catch (e) {
                setGroupStatus('none');
            }
        }
    }, [group, myMainid]);

    useEffect(() => {
        try {
            if ((group.iblock && group.iblock.includes(myMainid)) || (group.blocki && group.blocki.includes(myMainid))) {
                setGroupHidden(true)
            }
        } catch (e) {

        }
    }, [group]);

    useEffect(() => {
        try {
            if (group.members === null) {
                setMembersCount(0);
                setRequestsCount(0);
                return
            }
            if (group && group.members) {
                let membersCountHelper = 0;
                let requestsCountHelper = 0;
                for (const member of group.members) {

                    if (member.status === 'request' || member.status === 'invited') {
                        requestsCountHelper++;
                    } else if (member.status === 'accepted') {
                        membersCountHelper++;
                    }
                }

                setMembersCount(membersCountHelper);
                setRequestsCount(requestsCountHelper);
            }
        } catch (e) {
            // Handle the error if needed
            console.error("Error in useEffect:", e);
        }
    }, [group]);

    useEffect(() => {
        try {
            //console.log(taggedFriends)
            if (taggedFriends.length > 0) {
                InvitetoGroup(taggedFriends, setTaggedFriends, setCreating, dispatch, loadMyGroups)
            }
        } catch (e) {
            InvitetoGroup(taggedFriends, setTaggedFriends, setCreating, dispatch, loadMyGroups)
        }
    }, [taggedFriends])


    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    const handleDisband = async () => {
        await disbandMyGroup(dispatch, loadMyGroups, editData)
    }
    const handleCancel = async () => {
        WithDrawFromGroup(group.groupid, setGroupStatus, setCreating, navigate)
    }
    const handleImgClick = () => {
        if (window.location.pathname === `/home/groups/group/:${group.groupid}`) {
            dispatch(setMainId(group.groupid))
        } else {
            navigate(`/home/groups/group/:${group.groupid}`)
        }
    }


    if (groupHidden === false) {
        return (
            <div className='groupCardCont'>
                {showfirendsList && <MiniFriendsMultiSelect setTaggedFriends={setTaggedFriends} setShowFriendslist={setShowFriendslist} members={group.members || []} />}
                {group.groupid !== myMainid ? <img style={{ marginRight: '-5px', marginTop: '115px' }} onClick={() => setShowReport(true)} className='repImgInPost' src={repImg} /> : ""}
                {showReport ? <ReportGroup coachname={group.groupname} groupid={group.groupid} setShowReport={setShowReport} setGroupHidden={setGroupHidden} myMainid={myMainid} /> : ""}
                <img style={{marginTop:'0px'}} onClick={() => { handleImgClick() }} className='coachCardProfilePic' src={group.picture_url} />
                <div className='coachMainInfoCont'>
                    <h4 style={{ marginBottom: '5px' }} onClick={() => { navigate(`/home/groups/group/:${group.groupid}`) }}>{group.groupname}</h4>
                    <div onClick={() => { navigate(`/home/groups/group/:${group.groupid}`) }} style={{ marginBottom: '5px' }} className='SportCont'>
                        {group.interest ? <img style={{ height: '30px', width: '30px' }} className="otherProfileFav" src={photoPicker(group.interest)} /> : ""}
                        <div className='timeViewContInGroup' >
                            <TimeView time={group.time} />
                        </div>
                    </div>
                    <h5 onClick={() => { navigate(`/home/groups/group/:${group.groupid}`) }} style={{ marginBottom: '5px' }}><span style={{ fontWeight: '600', fontSize: '14px' }}>Location: </span>{group.preflocation ? group.preflocation : "Any Where"}</h5>
                    <h5>{group.comment ? group.comment : ""}</h5>
                </div>
                {membersFilter && group.members !== null ?
                    <MembersList groupName={group.groupname} members={group.members} status={membersFilter} groupid={group.groupid} myMainid={myMainid} setMembersFitler={setMembersFitler} />
                    :
                    ""
                }
                <div style={{ marginLeft: '0px' }} className='starsCont'>
                    <p onClick={() => setMembersFitler('accepted')}><span style={{ color: 'hsl(72, 100%, 47%)', width: '100px' }}>Members:</span> ({membersCount})</p>
                    {group.groupid === myMainid ? <p onClick={() => { setMembersFitler('request') }}><span style={{ color: 'hsl(72, 100%, 47%)', width: '100px' }}>Requests:</span> ({requestsCount})</p> : ""}
                    {groupStatus === 'invited' ? <p onClick={() => { handleAcceptGroupInvite(group.groupid, setGroupStatus, setCreating) }} style={{ height: '30px', width: '80px' }} className='activeBlack singleButton'>Accept</p> : ""}
                    {groupStatus === 'none' ? <p onClick={() => { ApplyToGroup(group.groupid, setGroupStatus, setCreating) }} style={{ height: '30px', width: '80px' }} className='activeBlack singleButton'>Join</p> : ""}
                    {groupStatus === 'owner' ? <img onClick={() => setShowFriendslist(true)} style={{ width: '30px', marginRight: '5px' }} src={localStorage.getItem('plus')} /> : ""}
                    {groupStatus === 'owner' ? <p onClick={handleDisband} style={{ height: '30px', padding: '10px', width: 'fit-content' }} className='activeBlack singleButton2'>Disband</p> : ""}
                    {groupStatus === 'request' ? <p onClick={handleCancel} style={{ height: '30px', padding: '10px', width: 'fit-content' }} className='activeBlack singleButton2'>Cancel Reqest</p> : ""}
                    {groupStatus === 'accepted' ? <p onClick={handleCancel} style={{ height: '30px', padding: '10px', width: 'fit-content' }} className='activeBlack singleButton2'>Leave Group</p> : ""}
                </div>
            </div>
        )
    } else {
        return (
            ""
        )
    }
}