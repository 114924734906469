import React, { useEffect, useState } from 'react';
import { ReportComment } from './ReportComment';
import repImg from '../../Pics/repImg.png'
import deletePic from '../../Pics/deletePic.png'
import { useDispatch } from 'react-redux';
import { setMainId } from '../list/tempProfileSlice';
import TextHandler from '../CommonItems/TextHandler';

export function SingleCommentEvent({ rev, myMainid, eventid, creatorid, setComments, setDynamicCommentsCount, setCreating }) {
    const [reviewHidden, setReviewHidden] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const dispatch = useDispatch()

    const handleDeleteComment = async () => {
        const commentId = rev.indexid
        if (rev.commenterid === myMainid || myMainid === creatorid) {
            setCreating(true)
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/events/deleteeventcomment`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ commentid: commentId, eventid: eventid }),
                });
                if (response.ok) {
                    const rev = await response.json()
                    console.log(rev)
                    setComments((prevReviews) => prevReviews.filter((review) => review.indexid !== commentId));
                    setDynamicCommentsCount((dynamicCommentsCount) => dynamicCommentsCount - 1)
                    setCreating(false)
                } else {
                    setCreating(false)
                }
            } catch (error) {
                setCreating(false)
                console.error(error);
            }
        } else {
            return
        }
    };

    if (reviewHidden === false) {
        return (
            <div style={{ borderBottom: '1px solid black' }} className='singleReviewCont'>
                <p style={{ width: "100%", lineHeight: "1.2", marginBottom: '3px' }}>
                    <span
                        onClick={() => dispatch(setMainId(rev.commenterid))}
                        style={{ color: 'hsla(72, 100%, 47%, 70%)', fontWeight: 'bold' }}>
                        {rev.commentername}:
                    </span>
                    <br />
                    <span style={{ marginTop: '5px', display: 'inline-block' }}>
                        - <TextHandler text={rev.comment} />
                    </span>
                </p>
                <div className='repImgInSingleReview' >
                    {rev.commenterid === myMainid ? <img style={{ filter: 'invert(1)' }} onClick={handleDeleteComment} src={deletePic} /> : ""}
                    {rev.commenterid === myMainid ? "" : <img onClick={() => setShowReport(true)} src={repImg} />}
                </div>
                {showReport ? <ReportComment reviewerName={rev.commentername} reviewerId={rev.commenterid} setShowReport={setShowReport} setReviewHidden={setReviewHidden} myMainid={myMainid} revID={rev.indexid} /> : ""}
            </div>
        )
    }
}