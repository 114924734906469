import React, { useState, useEffect } from "react";
import { setMainId } from "../list/tempProfileSlice";
import { useDispatch } from "react-redux";

export function EventUsersList({ users, eventName, setShowComingList }) {
    const dispatch = useDispatch()

    useEffect(() => {
        if (users === null) {
            setShowComingList(null)
        }
    }, [users])

    const openStudentProfile = (mainid) => {
        dispatch(setMainId(mainid))
    }

    return (
        <div className="dropdownContentContainer">
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", padding: '10px 0px', width: '90%', height: '80%' }} className="dropdownContent">
                <h3 style={{ color: 'white', marginBottom: '10px', marginTop: '10px' }}>{eventName}</h3>
                <div style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}>
                    <div style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="">
                        {users ? users.map(user => (
                            <div key={user.mainid} onClick={() => openStudentProfile(user.mainid)} className='singleStudentCont'>
                                <img className='singleStudentProfilePic' src={user.picture_url} />
                                <p>{user.name}</p>
                            </div>
                        ))
                            :
                            ""
                        }
                    </div>
                </div>
                <p onClick={() => { setShowComingList(null) }} style={{ marginTop: '10px' }} className="activeBlack singleButton2">Close</p>
            </div>
        </div>
    );
}
