import React from 'react';
import { useDispatch } from "react-redux";
import { clearInfo } from '../root/repChoiceSlice';
import { handleReport } from "../user/userFuncs";
import { useNavigate } from 'react-router-dom';


export function ReportCoach({ coachname, coachid, setShowReport, setCoachHidden, myMainid }) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleButtonPress = (content, coachname, coachid) => {
        try {
            const confirmed = window.confirm(
                `Report ${coachname} ${content === 'abusive user' ? 'as' : 'for '} ${content}?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
            );
            if (confirmed) {
                confirmReport(content, coachname, coachid)
            } else {
            }
        } catch (e) {

        }

    };

    const confirmReport = (content, coachname, coachid) => {
        try {
            handleReport(content, coachid, coachname, dispatch, clearInfo);
        } catch (e) {
        }
    };

    const blockUser = async (coachid) => {
        try {
            const receiverId = coachid
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/block-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ receiverId }),
                }
            );
            const data = await response.json();
            if (data === 'Blocked') {
                window.alert(`${coachname} is now blocked`)
                await handleHidePost()
                dispatch(clearInfo())
                navigate(-1)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHidePost = async () => {
        setCoachHidden(true);
        setShowReport(false);
        fetch(`/api/coaches/hidecoach`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ coachid: coachid }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        navigate(-1)
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    if (myMainid === coachid) {
        return (
            <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
                <div onClick={() => ""} className="rep-choice-container">
                    <h2 style={{ marginBottom: '20px' }}>Can't Report Your Self!</h2>
                    <button
                        className={`rep-choice-button `}
                        onClick={() => setShowReport(false)}
                    >
                        Close
                    </button>
                </div>
            </div >
        )
    }

    return (
        <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
            <div onClick={() => ""} className="rep-choice-container">
                <h2>REPORT !</h2>
                <h3 style={{ color: 'red' }}>Coach {coachname}'s<span style={{ color: 'black' }}></span> </h3>
                <h4 style={{ marginBottom: '5px' }} >Inappropriate Coach content?</h4>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Coach Profile Picture', coachname, coachid)}
                >
                    Coach Profile Picture
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Coach Bio', coachname, coachid)}
                >
                    Coach Bio
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Coach Experience', coachname, coachid)}
                >
                    Coach Experience
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>
                <button
                    className={`rep-choice-button`}
                    onClick={handleHidePost}
                >
                    Just hide this coach
                </button>
                <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
                <button
                    style={{ color: 'red' }}
                    className={`rep-choice-button `}
                    onClick={() => blockUser(coachid)}
                >
                    Block Coach
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>
                <button
                    className={`rep-choice-button `}
                    onClick={() => setShowReport(false)}
                >
                    cancel
                </button>
            </div>
        </div >
    );

};

