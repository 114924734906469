import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disableRefresh, enableRefresh, selectData } from '../home/homeSlice';
import { ProgressBar } from './ProgressBar'
import { CountdownTimer } from './CountdownTimer';
import refresh from '../../Pics/refresh.png'
import countSound from './countDown.mp3';
import ticking from './ticking.mp3'
import stopBeep from './stopBeep.mp3';
import './customWorkouts.css'
import { useNavigate } from 'react-router-dom';

let countSoundAudio
const checkLocalCountSound = localStorage.getItem('countSoundAudio')
if (checkLocalCountSound) {
    countSoundAudio = new Audio(checkLocalCountSound)
} else {
    countSoundAudio = new Audio(countSound)
}
let tickingAudio
const checkTickingSound = localStorage.getItem('tickingAudio')
if (checkTickingSound) {
    tickingAudio = new Audio(checkTickingSound)
} else {
    tickingAudio = new Audio(ticking)
}
let stopBeepAudio
const checkStopSound = localStorage.getItem('stopBeepSoundAudio')
if (checkStopSound) {
    stopBeepAudio = new Audio(checkStopSound)
} else {
    stopBeepAudio = new Audio(stopBeep)
}

export function CustomWorkoutSetup({ count, setCount, workout, setWorkout, reps, setReps, fixedTime, setFixedTime, restTime, setRestTime, resetCustomWorkout, handleCompleteWorkout, setSelectedFilter }) {
    const myData = useSelector(selectData)
    const [countHelper, setCountHelper] = useState(0)
    const [isResting, setIsResting] = useState(null)
    const [showStartCountDown, setShowStartCountDown] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            if (myData && workout) {
                const updatedWorkout = { ...workout, gifurl: myData.picture_url }
                setWorkout(updatedWorkout)
            }
        } catch (e) {

        }

    }, [])

    useEffect(() => {
        if (fixedTime < 0) {
            setFixedTime(0)
        }
        if (restTime < 0) {
            setRestTime(0)
        }
    }, [fixedTime, restTime])

    useEffect(() => {
        console.log('counter Helper:' + countHelper)
        try {
            if (countHelper > 0 && countHelper < reps) {
                setIsResting(true)
            }
        } catch (e) {
        }
    }, [countHelper])

    const vibrate = (time) => {
        try {
            window.ReactNativeWebView.postMessage(`Vib:${time}`);
        } catch (e) {

        }
    }


    const handleIncreaseCount = () => {
        setCount((count) => count + 1);
    };



    const playAudio = () => {
        //if (/Android/.test(navigator.userAgent)) {
        //    countSoundAudio.loop = true
        //    countSoundAudio.play();
        //    stopBeepAudio.volume = 0
        //    stopBeepAudio.loop = true
        //    stopBeepAudio.play();
        //    tickingAudio.volume = 0
        //    tickingAudio.loop = true
        //    tickingAudio.play();
        //}
    };

    const stopAudio = () => {
        countSoundAudio.pause();
        countSoundAudio.currentTime = 0;
        stopBeepAudio.pause();
        stopBeepAudio.currentTime = 0;
        tickingAudio.pause();
        tickingAudio.currentTime = 0;
    };


    const handleDecreaseCount = () => {
        setCount((count) => {
            if (count === 0) {
                return count;
            } else {
                return count - 1;
            }
        });
    }


    const handleStartTime = () => {
        if (fixedTime && restTime && reps) {
            dispatch(disableRefresh())
            setShowStartCountDown(true)
            playAudio();
        } else {
            try {
                window.ReactNativeWebView.postMessage("Alert:Make sure you set Reps, Workout & Rest times !");
            } catch (e) {

            }
        }
    }

    useEffect(() => {
        return () => {
            stopAudio();
            dispatch(enableRefresh())
        }
    }, []);


    return (
        <div className='postCont' style={{ marginTop: '10px', width: '100%', height: '150%' }}>
            <div className='featuredTip'>
                <div>
                    <h5 style={{ fontSize: '14px', marginTop: '10px', fontStyle: 'italic' }}>SETS DONE: <span style={{ fontSize: '18px', color: 'hsl(72, 100%, 47%)' }}>{count}</span></h5>
                    <h4 style={{ textAlign: "center", color: 'white', fontWeight: '800', fontStyle: 'italic' }} onMouseUp={() => navigate('/home/myworkouts?Load:custom')} className='activeBlack'>GO TO LOGGED</h4>
                    <img onClick={resetCustomWorkout} className='activeBlack' src={refresh} />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto auto', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">WORKOUT NAME</p>
                <div style={{ display: 'flex', width: 'fit-content', marginRight: '20px', paddingRight: '0px' }} >
                    <input
                        style={{ width: '160px', backgroundColor: "hsl(72, 100%, 47%)" }}
                        placeholder='My Workout'
                        type='text'
                        maxLength={25}
                        value={workout.name}
                        onChange={(e) => {
                            const updatedWorkout = { ...workout, name: e.target.value };
                            setWorkout(updatedWorkout);
                        }}
                    />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto auto', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">WORKOUT HINT</p>
                <div style={{ display: 'flex', width: 'fit-content', marginRight: '20px', paddingRight: '0px' }} >
                    <input
                        style={{ width: '160px', backgroundColor: "hsl(72, 100%, 47%)" }}
                        placeholder='Workout hint'
                        type='text'
                        maxLength={25}
                        value={workout.hint}
                        onChange={(e) => {
                            const updatedWorkout = { ...workout, hint: e.target.value };
                            setWorkout(updatedWorkout);
                        }}
                    />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto 120px', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">WORKOUT TIME</p>
                <div >
                    <input
                        className='WorkoutNumberInput'
                        pattern="[0-9]*"
                        placeholder='0'
                        inputMode="numeric"
                        type="number"
                        value={fixedTime}
                        onChange={(e) => {
                            let value = parseInt(e.target.value, 10); setFixedTime(value);
                        }}
                    />
                    <p>Sec</p>
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto 120px', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">REST TIME</p>
                <div >
                    <input
                        className='WorkoutNumberInput'
                        pattern="[0-9]*"
                        inputMode="numeric"
                        type="number"
                        placeholder='0'
                        value={restTime}
                        onChange={(e) => {
                            let value = parseInt(e.target.value, 10); setRestTime(value);
                        }}
                    />
                    <p>Sec</p>
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto 120px', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">SETS</p>
                <div >
                    <input
                        className='WorkoutNumberInput'
                        pattern="[0-9]*"
                        inputMode="numeric"
                        type="number"
                        placeholder='0'
                        value={reps}
                        onChange={(e) => {
                            let value = parseInt(e.target.value, 10); setReps(value);
                        }}
                    />
                    <p>Sets</p>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <p onClick={() => { handleStartTime() }} style={{ marginTop: 'px', fontSize: '22px', fontWeight: '900', fontStyle: 'italic' }} className='activeBlack singleButton'>START</p>
            </div>
            {showStartCountDown ?
                <div style={{ zIndex: '700', position: 'fixed', right: '0px' }} className="tempMeetingPage">
                    <div style={{ height: '100%', zIndex: '900', backgroundColor: 'rgba(17, 17, 17, 0.99)' }} className="meetingPageCont">
                        <CountdownTimer fixedTime={fixedTime} reps={reps} countHelper={countHelper} setCountHelper={setCountHelper} handleIncreaseCount={handleIncreaseCount} setShowStartCountDown={setShowStartCountDown} workout={workout} vibrate={vibrate} countSoundAudio={countSoundAudio} stopAudio={stopAudio} stopBeepAudio={stopBeepAudio} tickingAudio={tickingAudio} handleCompleteWorkout={handleCompleteWorkout} />
                        <h5 style={{ position: 'absolute', width: '100%', fontSize: '20px', top: '420px' }}>SETS DONE: <span style={{ color: 'hsl(72, 100%, 47%)' }}>{count}</span></h5>
                    </div>
                </div>
                :
                ""
            }
            {isResting ?
                <div style={{ zIndex: '700', position: 'fixed', height: '100%', right: '0px' }} className="tempMeetingPage">
                    <div style={{ paddingTop: '280px', paddingBottom: '200px', height: '100%', zIndex: '900', backgroundColor: 'rgba(17, 17, 17, 0.99)' }} className="meetingPageCont">
                        < ProgressBar duration={restTime} onComplete={handleStartTime} setIsResting={setIsResting} />
                        <h5 style={{ position: 'absolute', width: '100%', fontSize: '20px', top: '250px' }}>SETS DONE: <span style={{ color: 'hsl(72, 100%, 47%)' }}>{count}</span></h5>
                        <p onClick={() => { setIsResting(false); setCountHelper(0); stopAudio(); }} style={{ height: '20px', top: '0px' }} className='activeBlack singleButton2'>CANCEL</p>
                    </div>
                </div>
                :
                <div style={{ height: '20px' }}>
                </div>
            }
            <div className='goToLogged'>
                {/*<p style={{ backgroundColor: 'hsla(0, 0%, 10%, 0.9)', fontWeight: '800', fontStyle: 'italic' }} onMouseUp={() => navigate('/home/myworkouts?Load:custom')} className='activeBlack'>LOG OTHER WORKOUT</p>*/}
                {count === 0 ? <p onClick={handleCompleteWorkout} style={{ fontWeight: '800', color: 'grey', fontStyle: 'italic' }} >LOG THIS WORKOUT</p> : <p style={{ backgroundColor: 'hsla(0, 0%, 10%, 0.9)', fontWeight: '800', fontStyle: 'italic' }} onClick={handleCompleteWorkout} className='activeBlack'>LOG THIS WORKOUT</p>}
            </div>
        </div >
    )
}