import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import announce from '../../Pics/announce.png'
import searchUser from '../../Pics/searchUser.png'
import infoImg from '../../Pics/info.png'
import { editData, selectData } from '../home/homeSlice';
import { RootContext } from '../root/RootContextProvider';

export function Duo({ }) {
    const {
        location,
        setLocation,
        canShare,
        setCanShare,
        showShare,
        setShowShare,
        handleShowShare,
        subColor,
        setSubColor,
        didSeeInt,
        setDidSeeInt,
        radius,
        setRadius
    } = useContext(RootContext);
    const [showDesc, setShowDesc] = useState(null)
    const myData = useSelector(selectData)
    const [selectedFilter, setSelectedFilter] = useState('announce')
    const [creating, setCreating] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (myData && (myData.location === null || myData.location === '{}')) {
            dispatch(editData({ location: 'tryingagain' }))
            try {
                window.ReactNativeWebView.postMessage("callLocationFromExpo");
            } catch (e) {
            }
        }
    }, [])

    if (myData && (myData.location === null || myData.location === '{}')) {
        return (
            <div style={{ margin: '20px auto' }} className="boxLogin">
                <p className="disclaimer">
                    To continue using our app please enable location servecies to find people around you.
                    <br />
                    If it is enabled please restart the App.
                </p>
            </div>
        )
    }

    return (
        <div >
            {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
            </div> : ""}
            <div>
                <div style={{  backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
                    {showDesc ?
                        <div onClick={() => setShowDesc(null)} className="descContPage">
                            <div style={{ position: 'fixed' }} className="descCont">
                                <p style={{ color: 'white', textAlign: "left" }}>{showDesc}</p>
                            </div>
                        </div>
                        : ""
                    }
                    <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                        <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                            <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">FIND FIT-BUDDY</label>
                        </div>
                        <div className="bigDuoButtonCont">
                            <div className='bigDuoButton activeBlack'>
                                <img onMouseUp={() => navigate('/home/workout/announce')} className='bigDuoButtonIMG' src={localStorage.getItem('announce') || announce} />
                                <h5 onMouseUp={() => navigate('/home/workout/announce')}>ANNOUNCE</h5>
                                <img onClick={() => setShowDesc('Let others know you are ready for a workout, your preferred time, location, and workout details ')} className="bigDuoButtonInfo" src={localStorage.getItem('infoImg')} />

                            </div>
                            <div className='bigDuoButton activeBlack'>
                                <img onMouseUp={() => navigate('/home/workout/findduo')} className='bigDuoButtonIMG' src={localStorage.getItem('searchUser') || searchUser} />
                                <h5 onMouseUp={() => navigate('/home/workout/findduo')}>DISCOVER</h5>
                                <img onClick={() => setShowDesc('Find users seeking exercise partners and motivate those without a workout plan.')} className="bigDuoButtonInfo" src={localStorage.getItem('infoImg')} />
                            </div>
                        </div>

                    </div>
                </div>
            </div >
        </div>

    )
}
