import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./smallprofile.css";
import { Clock } from "./Clock";
import { getTimeOptions } from "../intButtons/intFuncs";
import { useDispatch } from "react-redux";
import { editData } from "../home/homeSlice";
import { loadCommon } from "../intButtons/intButtonsSlice";
import { updateInterest } from "../intButtons/intFuncs";
import { PublicProfEdit } from "./PublicProfEdit";


export function SmallProfiel({ data, currentInterest, setCurrentInterest }) {
  const [time, setTime] = useState('')
  const [options, setOptions] = useState("");
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (options === "") {
      setOptions(getTimeOptions(4));
    }
  }, []);

  useEffect(() => {
    try {
      if (options) {
        const initialOption = options.options[3];
        const delay = initialOption.delay;
        setTime({ selectedTime: initialOption.dateTimeString, delay: delay });
      }
    } catch (e) {

    }
  }, [options]);

  const photoPicker = (int) => {
    try {
      const picked = int.toLowerCase()
      return localStorage.getItem(picked)
    } catch (e) {

    }
  }

  const handleWithDraw = async () => {
    try {
      const response = await fetch('/api/profile/removeinterest');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      try {
        window.ReactNativeWebView.postMessage(`Alert:WithDrawn, Others won't find you in ${data.interest} list. `);
      } catch (e) {
      }

      dispatch(editData({ interest: null }));

    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleGoal = () => {
    navigate('/home/workout/announce')
  };

  const handleRegister = () => {
    try {
      if (currentInterest) {
        //dispatch(setLoading())
        updateInterest(currentInterest, time, '', '', 40, dispatch, editData, loadCommon)
      } else {
        handleGoal()
      }
    } catch (e) {
      handleGoal()
    }
  };

  return (
    <div className="smallprofileCont">
      <img className="smallprofilePic" loading="lazy" src={data.picture_url} alt="smallProfile picture" />
      <div className="smallprofInfo">
        <h3 className="smallname">{data.name.toUpperCase()}</h3>
        <div className="smallProfDiscover">
          <PublicProfEdit public_profile={data.public_profile} />
        </div>
        {data.interest === currentInterest && currentInterest !== null ?
          <div style={{ width: '90px', border: '0px', marginLeft: '0px', backgroundColor: 'hsla(72, 100%, 47%, 0.579)' }} onClick={handleGoal} className="activeBlack2 removeFromList" >
            <p>Applied</p>
            <img loading="lazy" src={currentInterest ? photoPicker(currentInterest) : ''} />
          </div>
          :
          <div style={{ width: '90px', border: '0px', marginLeft: '0px', backgroundColor: 'hsla(72, 100%, 47%, 0.579)' }} onClick={handleRegister} className="activeBlack2 removeFromList" >
            <p>Register</p>
            <img style={{ filter: 'grayscale(1)' }} loading="lazy" src={currentInterest ? photoPicker(currentInterest) : ''} />
          </div>
        }
        {data.interest ?
          ""
          :
          <div style={{ marginLeft: " 108px" }} onClick={handleGoal} className="activeBlack2 smallconfirmInts" >
            Detailed Apply
          </div>
        }
        {data.interest ?

          <div onClick={handleWithDraw} className="activeBlack2 removeFromList" >
            <p style={{ fontSize: '10px' }}>WithDraw</p>
            <img loading="lazy" src={data.interest ? photoPicker(data.interest) : ''} />
          </div>
          : ""
        }
      </div>
      <div className="clockHelperInSmallProf">
        <Clock />
      </div>
    </div>
  );
}
