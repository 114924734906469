import cycling from '../../Pics/sports/cycling.png'
import football from '../../Pics/sports/football.png'
import gym from '../../Pics/sports/gym.png'
import running from '../../Pics/sports/running.png'
import swimming from '../../Pics/sports/swimming.png'
import walking from '../../Pics/sports/walking.png'
import yoga from '../../Pics/sports/yoga.png'
import tennis from '../../Pics/sports/tennis.png'
import ultimate from '../../Pics/sports/ultimate.png'
import padel from '../../Pics/sports/padel.png'
import spikeball from '../../Pics/sports/spikeball.png'
import hiking from '../../Pics/sports/hiking.png'
import basketball from '../../Pics/sports/basketball.png'
import amfootball from '../../Pics/sports/amfootball.png'
import skateboard from '../../Pics/sports/skateboard.png'
import volleyball from '../../Pics/sports/volleyball.png'
import kayaking from '../../Pics/sports/kayaking.png'
import boxing from '../../Pics/sports/boxing.png'
import archerytag from '../../Pics/sports/archerytag.png'
import calisthenics from '../../Pics/sports/calisthenics.png'

export const pics = {
    running,
    gym,
    cycling,
    walking,
    padel,
    tennis,
    football,
    basketball,
    volleyball,
    yoga,
    swimming,
    ultimate,
    spikeball,
    kayaking,
    boxing,
    skateboard,
    amfootball,
    hiking,
    archerytag,
    calisthenics
};


export const interestData = [
    { name: 'Running', img: pics.running },
    { name: 'GYM', img: pics.gym },
    { name: 'Cycling', img: pics.cycling },
    { name: 'Calisthenics', img: pics.calisthenics },
    { name: 'Walking', img: pics.walking },
    { name: 'ArcheryTag', img: pics.archerytag },
    { name: 'Padel', img: pics.padel },
    { name: 'Tennis', img: pics.tennis },
    { name: 'Football', img: pics.football },
    { name: 'Basketball', img: pics.basketball },
    { name: 'Volleyball', img: pics.volleyball },
    { name: 'Yoga', img: pics.yoga },
    { name: 'Swimming', img: pics.swimming },
    { name: 'Ultimate', img: pics.ultimate },
    { name: 'Spikeball', img: pics.spikeball },
    { name: 'Kayaking', img: pics.kayaking },
    { name: 'Boxing', img: pics.boxing },
    { name: 'Skateboard', img: pics.skateboard },
    { name: 'AmFootball', img: pics.amfootball },
    { name: 'Hiking', img: pics.hiking },
    // Add more interests as needed
];