import React from 'react';
import Slider from 'react-slick';
import { EventCard } from './EventCard'; // Adjust the path as per your project structure
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from 'react-router-dom';

const events = [ /* Array of event data */];

export const ScrollEvents = ({ events, setEvents }) => {
    const navigate = useNavigate()

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        arrows: true,
        dots: true,
    };

    return (
        <Slider {...settings}>
            {events.sort((a, b) => new Date(a.eventtime) - new Date(b.eventtime))
                .map((event) => (
                    <EventCard key={event.eventid} eventid={event.eventid} image={event.image} eventname={event.eventname} location={event.location} eventtime={event.eventtime} sport={event.sport} caption={event.caption} locationdisplay={event.locationdisplay} creatorid={event.creatorid} hiddenby={event.hiddenby} setEvents={setEvents} goingcount={event.goingcount} commentscount={event.commentscount} />
                ))
            }

            <div style={{ display: 'flex', position: 'relative' }} >
                <div style={{ position: 'absolute', width: `${events.length > 0 ? 100 / (events.length + 1) : 100}%` }} className='noPrevWorkout'>
                    <div onMouseUp={() => navigate('/home/events')} style={{ display: 'flex', marginTop: '30px', marginBottom: '10px', width: 'fit-content' }}>
                        No {events.length > 0 && "more"} Events Near-by, Create Event?
                    </div>
                    <div style={{ display: 'flex', gap: '10px', width: 'fit-content' }}>
                        <img className='activeBlack' onMouseUp={() => navigate('/home/events')} style={{ width: '60px' }} src={localStorage.getItem('event')} />
                    </div>
                </div>
                <div style={{ opacity: '0', borderTopRightRadius: '20px', borderTopLeftRadius: '20px', width: 'calc(100%)', aspectRatio: '1.77' }} className='fakeBackGround' />
                <h3 style={{ opacity: '0', }}  >Loading ...</h3>
                <h5 style={{ opacity: '0', marginBottom: '8px', fontSize: '13px', color: 'white', fontWeight: '500' }}>Loading ...</h5>
                <h5 style={{ marginBottom: '23px', opacity: '0', fontSize: '13px', color: 'white', fontWeight: '500' }}> Loading ...</h5 >
            </div >
        </Slider>
    );
};

