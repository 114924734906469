import friends1 from './badges/friends1.png'
import friends2 from './badges/friends2.png'
import friends3 from './badges/friends3.png'
import friends4 from './badges/friends4.png'
import friends5 from './badges/friends5.png'
import workouts1 from './badges/workouts1.png'
import workouts2 from './badges/workouts2.png'
import workouts3 from './badges/workouts3.png'
import workouts4 from './badges/workouts4.png'
import workouts5 from './badges/workouts5.png'
import sets1 from './badges/sets1.png'
import sets2 from './badges/sets2.png'
import sets3 from './badges/sets3.png'
import sets4 from './badges/sets4.png'
import sets5 from './badges/sets5.png'
import Logos3 from './badges/friends3.png'

export const friendsAchievements = [
    { count: 1, goal: 'Have 1 Friend', name: 'Your first friend! 🎉', image: friends1 },
    { count: 5, goal: 'Have 5 Friends', name: 'Reached 20 friends milestone! 🥳', image: friends2 },
    { count: 20, goal: 'Have 20 Friends', name: '50 friends and counting! 🌟', image: friends3 },
    { count: 50, goal: 'Have 50 Friends', name: 'Wow, you have 100 friends! 🎊', image: friends4 },
    { count: 100, goal: 'Have 100 Friends', name: 'Amazing, you\'ve made 200 friends! 🎈', image: friends5 }
];

export const confirmedAchievements = [
    { count: 1, goal: 'Confirm your first Duo Workout', name: 'Your first friend! 🎉', image: workouts1 },
    { count: 5, goal: 'Confirm 5 Duo Workouts', name: 'Reached 20 friends milestone! 🥳', image: workouts2 },
    { count: 20, goal: 'Confirm 20 Duo Workouts', name: '50 friends and counting! 🌟', image: workouts3 },
    { count: 50, goal: 'Confirm 50 Duo Workouts', name: 'Wow, you have 100 friends! 🎊', image: workouts4 },
    { count: 100, goal: 'Confirm 100 Duo Workouts', name: 'Amazing, you\'ve made 200 friends! 🎈', image: workouts5 }
];

export const confirmedGroupAchievements = [
    { count: 1, goal: 'Confirm your first Group Workout', name: 'Your first friend! 🎉', image: workouts1 },
    { count: 5, goal: 'Confirm 5 Group Workouts', name: 'Reached 20 friends milestone! 🥳', image: workouts2 },
    { count: 20, goal: 'Confirm 20 Group Workouts', name: '50 friends and counting! 🌟', image: workouts3 },
    { count: 50, goal: 'Confirm 50 Group Workouts', name: 'Wow, you have 100 friends! 🎊', image: workouts4 },
    { count: 100, goal: 'Confirm 100 Group Workouts', name: 'Amazing, you\'ve made 200 friends! 🎈', image: workouts5 }
];

export const featuredSetsAchievements = [
    { count: 1, goal: 'Do your first featured workout Set', name: 'Your first friend! 🎉', image: sets1 },
    { count: 50, goal: 'Do 50 featured workout Sets', name: 'Reached 20 friends milestone! 🥳', image: sets2 },
    { count: 250, goal: 'Do 250 featured workout Sets', name: '50 friends and counting! 🌟', image: sets3 },
    { count: 500, goal: 'Do 500 featured workout Sets', name: 'Wow, you have 100 friends! 🎊', image: sets4 },
    { count: 1000, goal: 'Do 1000 featured workout Sets', name: 'Amazing, you\'ve made 200 friends! 🎈', image: sets5 }
];

export const fetchEvents = (events, setEvents, setLoadingEvents) => {
    if (events === null) {
        setLoadingEvents(true);
    }
    fetch('/api/events')
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            setEvents(data); // Assuming events will be stored in state
            setLoadingEvents('done');
        })
        .catch(error => {
            console.error('Error fetching events:', error);
            setLoadingEvents(false);
        });
};

export const topBadgePicker = (Achievements, count, setTopBadgeAchieved) => {
    try {
        for (let i = Achievements.length - 1; i >= 0; i--) {
            const achievement = Achievements[i];
            if (count >= achievement.count) {
                setTopBadgeAchieved(achievement)
                break;
            }
        }
    } catch (e) {

    }
}
