import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import facebook from "../../Pics/facebook.png"
import instagram from "../../Pics/instagram.png"
import { loadHome, enableRefresh, disableRefresh, editData } from "../home/homeSlice";



export function ImageLinkChanger({ data, tempBack, tempColor }) {
    const [editMode, setEditMode] = useState([false, false]);
    const [newLinks, setNewLinks] = useState(['', '']);
    const dispatch = useDispatch();


    const openEdit = (index) => {
        dispatch(disableRefresh())
        const newEditMode = [...editMode];
        newEditMode[index] = true;
        setEditMode(newEditMode);
    };

    const closeEdit = (index) => {
        const newEditMode = [...editMode];
        newEditMode[index] = false;
        setEditMode(newEditMode);
        //dispatch(loadHome())
        dispatch(enableRefresh())
    };

    const confirmLink = (index, apiEndpoint) => {
        if (newLinks[index]) {
            fetch(apiEndpoint, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ link: newLinks[index] }),
            })
                .then((response) => {
                    if (response.ok) {
                        closeEdit(index);
                        if (index === 0) {
                            dispatch(editData({ facebooklink: true }))
                        } else if (index === 1) {
                            dispatch(editData({ instagramlink: true }))
                        }
                        try {
                            window.ReactNativeWebView.postMessage("Alert:Link Successfully Updated !");
                        } catch (e) {

                        }
                    } else {
                        closeEdit(index);
                        try {
                            window.ReactNativeWebView.postMessage("Alert:Invalid Link !");
                        } catch (e) {

                        }
                    }
                })
                .catch((error) => {
                    closeEdit(index);
                    try {
                        window.ReactNativeWebView.postMessage("Alert:Invalid Link !");
                    } catch (e) {

                    }
                });
        }
    };

    const removeLink = (index, removeEndpoint) => {
        fetch(removeEndpoint, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ link: null }), // Set the link to null for removal
        })
            .then((response) => {
                if (response.ok) {
                    closeEdit(index);
                    if (removeEndpoint.includes('facebook')) {
                        dispatch(editData({ facebooklink: null }))
                    } else if (removeEndpoint.includes('instagram')) {
                        dispatch(editData({ instagramlink: null }))
                    }
                    try {
                        window.ReactNativeWebView.postMessage("Alert:Link Removed");
                    } catch (e) {

                    }
                } else {
                    closeEdit(index);
                    try {
                        window.ReactNativeWebView.postMessage("Alert:Somthing Went wrong");
                    } catch (e) {

                    }
                }
            })
            .catch((error) => {
                closeEdit(index);
                try {
                    window.ReactNativeWebView.postMessage("invalidremoval");
                } catch (e) {

                }
            });
    };

    return (
        <div style={{ backgroundColor: `${tempBack}` }} className="imageLinkChanger">
            <div className="imageContainer">
                <img style={{ filter: data.facebooklink === null ? 'grayscale(1)' : '' }} className='solciallinkimg'
                    src={facebook}
                    alt="facebook photo"
                    onClick={() => openEdit(0)}
                />
                {editMode[0] && (
                    <div className="editInfoCont">
                        <h3 >Help Others know that this is really you, Link your Facebook</h3>
                        <p style={{ color:`${tempColor}`, fontWeight: '500' }} >Go to your Profile > Click on the ... beside Edit Profile > At the bottom of the page you will find a Copy link button</p>
                        <input
                            className="linkInput"
                            maxLength="120"
                            placeholder="Insert your Facebook Link"
                            type="text"
                            value={newLinks[0]}
                            onChange={(event) => {
                                const newLinksArray = [...newLinks];
                                newLinksArray[0] = event.target.value;
                                setNewLinks(newLinksArray);
                            }}
                        />
                        <div >
                            <button className="confirmButton" onClick={() => confirmLink(0, '/api/profile/updatefacebook')}>
                                Confirm
                            </button>
                            <button className="cancelButton" onClick={() => closeEdit(0)}>
                                Cancel
                            </button>
                            {
                                data.facebooklink ?
                                    <button className="cancelButton" onClick={() => removeLink(0, '/api/profile/removefacebook')}>
                                        Remove
                                    </button>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                )}
            </div>

            <div className="imageContainer">
                <img style={{ filter: data.instagramlink === null ? 'grayscale(1)' : '' }} className='solciallinkimg'
                    src={instagram}
                    alt="instagram photo"
                    onClick={() => openEdit(1)}
                />
                {editMode[1] && (
                    <div className="editInfoCont">
                        <h3 >Help Others know that this is really you, Link your Instagram</h3>
                        <p style={{ color:`${tempColor}`, fontWeight: '500' }}  >Go to your Profile > Click on Share profile > Click on copy link</p>
                        <input
                            className="linkInput"
                            maxLength="120"
                            placeholder="Insert your Instagram Link"
                            type="text"
                            value={newLinks[1]}
                            onChange={(event) => {
                                const newLinksArray = [...newLinks];
                                newLinksArray[1] = event.target.value;
                                setNewLinks(newLinksArray);
                            }}
                        />
                        <div >
                            <button className="confirmButton" onClick={() => confirmLink(1, '/api/profile/updateinstagram')}>
                                Confirm
                            </button>
                            <button className="cancelButton" onClick={() => closeEdit(1)}>
                                Cancel
                            </button>
                            {
                                data.instagramlink ?
                                    <button className="cancelButton" onClick={() => removeLink(1, '/api/profile/removeinstagram')}>
                                        Remove
                                    </button>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}