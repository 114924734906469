import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import like from '../../Pics/like.png'
import liked from '../../Pics/liked.png'
import { setMainId } from "../list/tempProfileSlice";
import { ReportPost } from './ReportPost';
import repImg from '../../Pics/repImg.png'
import { useSwipeable } from "react-swipeable";
import { MiniList } from '../minilist/MiniList';
import './post.css'
import { PostComments } from './PostComments';
import { VideoPlayer } from './VideoPlayer';
import { handleShowBottomNav, handleShowTopNav, selectAppVersion } from '../home/homeSlice';
import { PostMedia } from './PostMedia';
import { appShare } from '../customShare/customShareFuncs';


export function Post({ thumb, postid, image, creatorid, creatorname, creatorpicture, createTime, caption, sport, taggedid, taggedname, likes, myMainid, showtagged, setRequest, fetchMyPosts, setLoading, setPosts, hiddenby, commentscount, likescount }) {
    const [showComments, setShowComments] = useState(window.location.pathname === `/home/post/:${postid}` ? true : false);
    const [askTopUpdate, setAskTopUpdate] = useState(false);
    const [showUsersList, setShowUserslist] = useState(null)
    const [bigPic, setBigPic] = useState(false)
    const [postDeleted, setPostDeleted] = useState(false)
    const [isShowTagged, setIsShowTagged] = useState(showtagged);
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [likedByMe, setLickedByMe] = useState(false)
    const [dynamicCount, setDynamicCount] = useState(likescount)
    const [dynamicCommentsCount, setDynamicCommentsCount] = useState(commentscount || 0)
    const appVersion = useSelector(selectAppVersion)
    const dispatch = useDispatch();
    const postRef = useRef(null)

    const formatTimestamp = (timestampStr) => {
        const options = {
            hour12: true,
            hour: 'numeric',
            minute: '2-digit',
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        };

        const date = new Date(timestampStr);
        return date.toLocaleString('en-US', options);
    };

    const handleSwipeRight = () => {
        setBigPic(false)
    };

    const swipeBackHandlersBack = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    useEffect(() => {
        try {
            if (likes.includes(myMainid)) {
                setLickedByMe(true)
            }
        } catch (e) {

        }
    }, [])

    useEffect(() => {
        try {
            if (hiddenby.includes(myMainid)) {
                setPostDeleted(true)
            }
        } catch (e) {

        }
    }, [])

    const openTempProfile = (mainid) => {
        dispatch(setMainId(mainid))
    };

    async function handleLike(postid, creatorid) {
        setLickedByMe(like => !like)
        fetch(`/api/post/handlelike`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postid: postid, creatorid: creatorid }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        setLickedByMe(data.liked);
                        if (data.liked === true) {
                            setDynamicCount(count => count + 1)
                        } else {
                            setDynamicCount(count => count - 1)
                        }
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    async function handleAcceptTag(postid, taggedid, creatorid) {
        setUpdating(true)
        fetch(`/api/post/accepttag`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postid: postid, taggedid: taggedid, creatorid: creatorid }),
        })
            .then((response) => {
                if (response.ok) {
                    setIsShowTagged(true);
                    setUpdating(false)
                    try {
                        setRequest(false)
                    } catch (e) {

                    }
                } else {
                    setUpdating(false)
                    console.error("Failed to save interest");
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error saving interest:", error);
            });
    }

    async function handleRemoveTag(postid, taggedid, creatorid) {
        setUpdating(true)
        fetch(`/api/post/removetag`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postid: postid, taggedid: taggedid, creatorid: creatorid }),
        })
            .then((response) => {
                if (response.ok) {
                    setIsShowTagged(false);
                    setUpdating(false)
                } else {
                    setUpdating(false)
                    console.error("Failed to remove tag");
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error removing tag:", error);
            });
    }

    async function handlePostDelete(postid, creatorid, image) {
        setUpdating(true)
        fetch(`/api/post/deletepost`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postid: postid, creatorid: creatorid, postphoto: image }),
        })
            .then((response) => {
                if (response.ok) {
                    setPostDeleted(true);
                    setUpdating(false)
                    try {
                        fetchMyPosts(setLoading, setPosts)
                    } catch {

                    }
                } else {
                    setUpdating(false)
                    console.error("Failed to remove tag");
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error removing tag:", error);
            });
    }


    if (postDeleted === true) {
        return (
            ""
        )
    }

    const handleOpenVideo = (link) => {
        try {
            window.ReactNativeWebView.postMessage(`PlayVideo:${link}`);
        } catch (e) {

        }

    }

    return (
        <div ref={postRef} style={{ userSelect: 'none' }} className='postCont' >
            {
                showUsersList ?
                    <MiniList postid={postid} setShowUserslist={setShowUserslist} /> :
                    ""
            }
            {bigPic ?
                <div style={{ padding: '0px' }} {...swipeBackHandlersBack}
                    onClick={() => {
                        setBigPic(false);
                        if (window.location.pathname !== '/home/feed') {
                            dispatch(handleShowBottomNav(true)); dispatch(handleShowTopNav(true))
                        }
                    }}
                    className="fullScreenBlack"
                >
                    <img style={{ width: 'auto', borderRadius: '0px', maxWidth: '100%' }} src={image} />
                </div>
                :
                ''
            }
            {updating ? <div className='CreatingPost'>
                <p className='fake'> Loading..</p>
            </div> : ""}
            <img onClick={() => setShowReport(true)} className='repImgInPost' src={repImg} />
            {showReport ? <ReportPost creatorid={creatorid} creatorname={creatorname} postid={postid} setShowReport={setShowReport} setPostDeleted={setPostDeleted} myMainid={myMainid} /> : ""}
            <div className='postCreatorInfo'>
                <img src={creatorpicture} onClick={() => openTempProfile(creatorid)} className='creatorProfile' />
                <div style={{ marginLeft: '0px' }}>
                    <p onClick={() => openTempProfile(creatorid)} style={{ textAlign: 'left', lineHeight: '1.3' }}>{creatorname}</p>
                    <p style={{ fontSize: '11px', textAlign: 'left' }}>{formatTimestamp(createTime)}</p>
                </div>
            </div>
            <PostMedia image={image} thumb={thumb} setBigPic={setBigPic} />
            <div className='postSportandTage'>
                <div className='doubleTag'>
                    <p onClick={() => openTempProfile(creatorid)}>{creatorname} </p>
                    {isShowTagged && taggedid ? <span>with</span> : ""}
                    {isShowTagged ? <p onClick={() => openTempProfile(taggedid)}> {taggedname}</p> : ""}

                </div>
            </div>
            {sport !== 'null' || sport !== null ?
                <div className='postSportandTage'>

                    <div className='doubleTag'>
                        {sport === 'null' || sport === null ? "" : <span>played</span>}
                        {sport == 'null' ? "" : <p> {sport}</p>}
                    </div>

                </div>
                :
                ""}
            {caption ? <p className='postCaption'>{caption}</p> : ''}
            <div className='smallBarInPostCont' >
                <div className='likeCont'>
                    <img onClick={() => handleLike(postid, creatorid)} src={likedByMe ? liked : like} />
                    <p onClick={() => setShowUserslist(likes)}>{dynamicCount ? dynamicCount : 0}</p>
                </div>
                <div onClick={() => setShowComments((showComments) => !showComments)} className='likeCont'>
                    <img src={localStorage.getItem('commentPicWhite')} />
                    <p >{dynamicCommentsCount ? dynamicCommentsCount : 0}</p>
                </div>
                {appVersion === '3.1.0' && <div className='likeCont'>
                    <img onClick={() => { appShare('Fit-Buddy', 'https://fitbuddy.fit/download', postRef) }} src={localStorage.getItem('shareWhite')} />
                </div>}
                {window.location.pathname !== '/home/feed' && taggedid === myMainid && isShowTagged === false ? <p style={{ backgroundColor: 'hsla(72, 100%, 47%, 65%)' }} onClick={() => handleAcceptTag(postid, taggedid, creatorid)} className='smallPosbuttons'>Accept Tag</p> : ""}
                {window.location.pathname !== '/home/feed' && (creatorid === myMainid || taggedid === myMainid) && window.location.pathname !== '/home/postcreator' && isShowTagged === true ? <p onClick={() => handleRemoveTag(postid, taggedid, creatorid)} className='smallPosbuttons'>Remove Tag</p> : ""}
                {window.location.pathname !== '/home/feed' && creatorid === myMainid && window.location.pathname !== '/home/postcreator' ? <p onClick={() => handlePostDelete(postid, creatorid, image)} className='smallPosbuttons'>Delete Post</p> : ""}
            </div>
            {showComments ?
                <PostComments postid={postid} creatorid={creatorid} showComments={showComments} setShowComments={setShowComments} setCreating={setUpdating} dynamicCommentsCount={dynamicCommentsCount} setDynamicCommentsCount={setDynamicCommentsCount} />
                : ""
            }
        </div>
    )
}