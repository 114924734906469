import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./admin.css";
import { useLocation, useNavigate } from "react-router-dom";
import { selectData, selectStatus, loadHome, selectLog, } from "../home/homeSlice";
import { AdminBigCard } from "./adminBigCard";
import { loadUser, loadUsers, loadusersByReportCount, selectUsers, selectUsersStatus, loadBannedUsers } from "../admin/adminSlice";
import { SendToAll } from "./SendToAll";

export function Admin() {
  const [admin, setAdmin] = useState(false);
  const [searchMainId, setSearchMainId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchByReportCount, setSearchByReportCount] = useState("");
  const users = useSelector(selectUsers);
  const usersStatus = useSelector(selectUsersStatus);
  const navigate = useNavigate();
  const data = useSelector(selectData);
  const status = useSelector(selectStatus);
  const log = useSelector(selectLog);
  const dispatch = useDispatch();
  const page = useLocation();
  const pagePath = page.pathname;
  const back = () => { };

  useEffect(() => {
    dispatch(loadHome());
  }, []);

  useEffect(() => {
    try {
      if (data.mainid === "1") {
        setAdmin(true);
      } else {
        setAdmin(false);
      }
    } catch (e) { }
  }, [data]);

  const handleSearch = () => {
    if (admin) {
      // Dispatch loadUsers action when search button is clicked
      dispatch(loadUsers({ id: searchMainId, name: searchName }));
    }
  };

  const handleSearchByName = () => {
    if (admin && searchName) {
      dispatch(loadUsers({ name: searchName }));
    }
  };

  const handleSearchById = () => {
    if (admin && searchMainId) {
      dispatch(loadUser({ id: searchMainId }));
    }
  };

  const handleSearchByReportCount = () => {
    if (admin && searchByReportCount) {
      dispatch(loadusersByReportCount({ count: searchByReportCount }));
    }
  };

  const handleSearchByBannedUsers = () => {
    if (admin) {
      dispatch(loadBannedUsers());
    }
  };


  if (admin) {
    return (
      <div className="allAdmin">
        <p className="searchButton" onClick={handleSearchByBannedUsers}>Load Banned Users</p>
        <div className="AdminSeraches">
          {/* Search by ID */}
          <div className="searchContainer">
            <label htmlFor="mainIdInput" className="inputLabel">
              Main ID:
            </label>
            <input
              type="text"
              id="mainIdInput"
              value={searchMainId}
              onChange={(e) => setSearchMainId(e.target.value)}
              className="inputField"
            />
            <button onClick={handleSearchById} className="searchButton">
              Search by ID
            </button>
          </div>
          {/* Search by Name */}
          <div className="searchContainer">
            <label htmlFor="nameInput" className="inputLabel">
              Name:
            </label>
            <input
              type="text"
              id="nameInput"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="inputField"
            />
            <button onClick={handleSearchByName} className="searchButton">
              Search by Name
            </button>
          </div>
          {/* Search by Report Count */}
          <div className="searchContainer">
            <label htmlFor="reportCountInput" className="inputLabel">
              Rep Count:
            </label>
            <input
              type="text"
              id="reportCountInput"
              value={searchByReportCount}
              onChange={(e) => setSearchByReportCount(e.target.value)}
              className="inputField"
            />
            <button onClick={handleSearchByReportCount} className="searchButton">
              Report Count
            </button>
          </div>
        </div>
        < SendToAll />
        {/* Display user data if loaded successfully */}
        {usersStatus === "done" && (
          <div className="usersDataCont">
            {users.map((user) => (
              <AdminBigCard info={user} back={back} key={user.mainid} />
            ))}
          </div>
        )}
      </div>
    );
  } else {
    return null;
  }
}
