import React, { useEffect, useState } from 'react';
import { ReportReview } from './ReportReview';
import repImg from '../../Pics/repImg.png'
import { useDispatch } from 'react-redux';
import { setMainId } from '../list/tempProfileSlice';
import TextHandler from '../CommonItems/TextHandler';

export function SingleReview({ rev, myMainid }) {
    const [reviewHidden, setReviewHidden] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const dispatch = useDispatch()


    if (reviewHidden === false) {
        return (
            <div style={{ borderBottom: '1px solid black', marginBottom: '5px' }} className='singleReviewCont'>
                <p style={{ width: "100%", lineHeight: "1.2", marginBottom: '3px' }}>
                    <span
                        onClick={() => dispatch(setMainId(rev.mainid))}
                        style={{ color: 'hsla(72, 100%, 47%, 70%)', fontWeight: 'bold' }}>
                        {rev.studentname}:
                    </span>
                    <br />
                    <span style={{ marginTop: '5px', display: 'inline-block' }}>
                        - <TextHandler text={rev.review} />
                    </span>
                </p>
                <img style={{}} onClick={() => setShowReport(true)} className='repImgInSingleReview' src={repImg} />
                {showReport ? <ReportReview reviewerName={rev.studentname} reviewerId={rev.mainid} setShowReport={setShowReport} setReviewHidden={setReviewHidden} myMainid={myMainid} revID={rev.indexid} /> : ""}
            </div>
        )
    }
}