import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadHome = createAsyncThunk("home/loadHome", async () => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/profile`);
  const json = await data.json();
  return json;
});

export const homeSlice = createSlice({
  name: "home",
  initialState: {
    data: null,
    status: "idle",
    log: "notlogged",
    showTopNav: true,
    showBottomNav: true,
    appVersion: "",
    adCounter: 0
  },
  reducers: {
    reset: (state) => {
      state.data = null;
      state.status = "idle";
      state.log = "notlogged";
    },
    handleShowTopNav: (state, action) => {
      state.showTopNav = action.payload
    },
    handleShowBottomNav: (state, action) => {
      state.showBottomNav = action.payload
    },
    setAppVersion: (state, action) => {
      state.appVersion = action.payload
    },
    enableRefresh: () => {
      window.activateRefresh = true
    },
    disableRefresh: () => {
      window.activateRefresh = false
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    playAdInt: (state, action) => {
      if (state.adCounter < 4) {
        state.adCounter = state.adCounter + 1
      } else {
        state.adCounter = 0
        try {
          if (!state.data.activesub) {
            window.activateRefresh = 'adVeiw'
            window.ReactNativeWebView.postMessage(`showFullScreenAd`);
          } else {
            //window.ReactNativeWebView.postMessage(`Alert: Wont play ad`);          
          }
        } catch (e) {
        }
      }
    },
    editData: (state, action) => {
      try {
        state.data = { ...state.data, ...action.payload };
      } catch (e) {

      }
    },
    modifyNestedData: (state, action) => {
      try {
        const { propertyPath, value } = action.payload;
        const nestedProperties = propertyPath.split('.');
        let nestedData = state.data;

        for (const property of nestedProperties.slice(0, -1)) {
          if (!nestedData[property] || typeof nestedData[property] !== 'object') {
            nestedData[property] = {};
          }
          nestedData = nestedData[property];
        }

        const lastProperty = nestedProperties[nestedProperties.length - 1];
        nestedData[lastProperty] = value;

      } catch (e) {
        // Handle errors if needed
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadHome.pending, (state) => {
        if (state.log !== "logged" && !state.data) {
          state.status = "loadInFeed";
        }
        if (window.location.pathname === '/home/feed') {
          state.status = "loadInFeed";
        }

        //if (state.log !== "logged" && !state.data) {
        //  state.status = "loading";
        //} else if (window.location.pathname === '/home/profile') {
        //  state.status = "loading";
        //}
      })
      .addCase(loadHome.fulfilled, (state, action) => {
        state.data = action.payload;
        state.log = "logged";
        state.status = "done";
        try {
          //window.ReactNativeWebView.postMessage(`enableAd`);
          state.showBottomNav = true
        } catch (e) {
        }
        try {
          console.log('will rem screen')
          window.ReactNativeWebView.postMessage(`Stack:${-20}`);
        } catch (e) {

        }
      })
      .addCase(loadHome.rejected, (state, action) => {
        state.log = "notlogged";
        console.log(state.log)
        state.status = "failed";
        console.log(state.status)
        state.data = null;
      });
  },
});

export const selectData = (state) => state.home.data;
export const selectStatus = (state) => state.home.status;
export const selectLog = (state) => state.home.log;
export const selectShowTopNav = (state) => state.home.showTopNav;
export const selectShowBottomNav = (state) => state.home.showBottomNav;
export const selectAppVersion = (state) => state.home.appVersion;

export const { reset, handleShowTopNav, handleShowBottomNav, enableRefresh, disableRefresh, editData, setStatus, modifyNestedData, playAdInt, setAppVersion } = homeSlice.actions;



export default homeSlice.reducer;
