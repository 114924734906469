import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportCoach } from './ReportCoach';
import repImg from '../../Pics/repImg.png'
import StarRatings from 'react-star-ratings';
import './coachCard.css'
import { useNavigate } from 'react-router-dom';
import call from '../../Pics/callgreen.png'
import chat from '../../Pics/chat.png'

export function BigCoachCard({ coach, myMainid, setOpenCoachProfileEdit, isMyCoach, ApplyToCoach, WithDrawFromCoach, handleCall, myInfo, setBigPic }) {
    const [ratingObj, setRatingObj] = useState(coach.rating)
    const [rating, setRating] = useState(0)
    const [ratingCount, setRatingCount] = useState(0)
    const [coachHidden, setCoachHidden] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [likedByMe, setLickedByMe] = useState(false)
    const [dynamicCount, setDynamicCount] = useState(0)
    const dispatch = useDispatch();
    const navigate = useNavigate()


    useEffect(() => {
        if (coach && myMainid) {
            try {
                if (coach.hiddenby.includes(myMainid)) {
                    setCoachHidden(true)
                }
            } catch (e) {
            }
        }
    }, []);

    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    useEffect(() => {
        try {
            if (Object.keys(ratingObj).length > 0) {
                let ratingCounts = 0;
                let totalRating = 0;
                for (const rating in ratingObj) {
                    ratingCounts++;
                    const ratingString = ratingObj[rating].rating;
                    const ratingValue = parseInt(ratingString);
                    totalRating += ratingValue;
                }
                const averageRating = (totalRating / ratingCounts);
                setRating(averageRating);
                setRatingCount(ratingCounts)
            } else {
                setRating(0)
                setRatingCount(0)
            }
        } catch (e) {

        }
    }, [coach, ratingObj])



    const setNewRating = async (rating) => {
        if (window.location.pathname === `/home/coaches/coach/:${coach.coachid}`) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/ratecoach`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ coachid: coach.coachid, rating: rating }),
                });
                if (response.ok) {
                    const data = await response.json()
                    setRatingObj(data)
                }
            } catch (error) {

            }
        } else {
            return
        }
    }

    if (coachHidden === false) {
        return (
            <div className='bigCoachCardCont'>
                {coach.coachid === myMainid && window.location.pathname === '/home/coaches/coachaccount' ? < img style={{ marginRight: '10px', marginTop: '175px', padding: '5px', width: "30px", zIndex: '500' }} onClick={() => setOpenCoachProfileEdit(coach)} className='editExperience' src={localStorage.getItem('edit')} /> : ""}
                {coach.coachid !== myMainid ? <img style={{ marginRight: '-5px', marginTop: '185px' }} onClick={() => setShowReport(true)} className='repImgInPost' src={repImg} /> : ""}
                {showReport ? <ReportCoach coachname={coach.name} coachid={coach.coachid} setShowReport={setShowReport} setCoachHidden={setCoachHidden} myMainid={myMainid} /> : ""}
                <img onClick={() => setBigPic(coach.picture_url)} className='coachCardProfilePic' src={coach.picture_url} />
                <div style={{ gridRow: '1 / 3' }} className='coachMainInfoCont'>
                    <h3>{coach.name.toUpperCase()}</h3>
                    <div className='SportCont'>
                        {coach.sport ? <img className="otherProfileFav" src={photoPicker(coach.sport)} /> : ""}
                        <h4>{coach.sport}</h4>
                    </div>
                    <h5>{coach.bio}</h5>

                </div>
                <div className='bigStarsCont'>
                    <StarRatings
                        changeRating={window.location.pathname === `/home/coaches/coach/:${coach.coachid}` && coach.coachid !== myMainid && isMyCoach === true ? setNewRating : null}
                        rating={rating ? rating : 0}
                        starRatedColor="hsla(72, 100%, 47%, 40%)"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="3px"
                        starHoverColor="hsl(72, 100%, 47%)"
                    />
                    <p><span style={{ color: 'hsl(72, 100%, 47%)', width: '100px' }}>{rating > 0 ? rating.toFixed(1) : ""}</span> ({ratingCount})</p>
                </div>
                {
                    isMyCoach === true ?
                        <div className='callChatCont'>
                            {coach.shownumber === true ? <img src={call} onClick={() => handleCall(coach.coachid)} style={{ height: '50px', width: '50px', padding: '10px' }} className="activeBlack" /> : ""}
                            <img src={chat} onMouseUp={() => navigate(`/home/:${coach.coachid}`)} style={{ height: '50px', width: '50px', padding: '7px' }} className="activeBlack" />
                        </div>
                        :
                        <div className='callChatCont'>
                            {isMyCoach === 'request' ? <p onClick={() => WithDrawFromCoach(coach.coachid)} style={{ height: '40px', width: '150px', color: "red" }} className="activeBlack singleButton">Cancel Request</p> : ""}
                            {isMyCoach === false && coach.coachid !== myInfo.mainid ? <p onClick={() => ApplyToCoach(coach.coachid)} style={{ height: '30px' }} className="activeBlack singleButton">Apply</p> : ""}
                            {coach.coachid === myInfo.mainid ? <div className='callChatCont'>
                                {coach.shownumber === true ?
                                    <img src={call} onClick={() => handleCall(coach.coachid)} style={{ height: '50px', width: '50px', padding: '10px' }} className="activeBlack" /> : ""}
                                <img src={chat} onMouseUp={() => navigate(`/home/:${coach.coachid}`)} style={{ height: '50px', width: '50px', padding: '7px' }} className="activeBlack" />
                            </div> : ""
                            }
                        </div>
                }
                {isMyCoach === true ? <p onClick={() => WithDrawFromCoach(coach.coachid)} style={{ height: '25px' }} className="activeBlack singleButton2 withdraFromCoach">WithDraw</p> : ""}
            </div>
        )
    } else {
        return (
            ""
        )
    }
}