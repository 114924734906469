import React, { useState } from 'react';
import edit from '../../Pics/edit.png'
import deleteImg from '../../Pics/delete.png'
import play from '../../Pics/play.png'

import './coachExperienceCard.css'
import { useDispatch } from 'react-redux';
import { loadMyCoachBigData } from './coachesSlice';

export function CoachExperiencsCard({ experience, setBigPic, setOpenExpCreator, myMainid, setCreating }) {
    const dispatch = useDispatch()

    const handleDeleteExp = async () => {
        setCreating(true)
        try {
            const formData = new FormData();
            formData.append('expId', experience.id);
            const response = await fetch('/api/coaches/deleteexp', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                dispatch(loadMyCoachBigData())
                setCreating(false)
            } else {
                dispatch(loadMyCoachBigData())
                console.error('Post creation failed:', response.status);
                setCreating(false)
            }
        } catch (error) {
            dispatch(loadMyCoachBigData())
            setCreating(false)
            console.error('Error creating post:', error);
        }
    }

    const handleOpenVideo = (link) => {
        try {
            window.ReactNativeWebView.postMessage(`PlayVideo:${link}`);
        } catch (e) {

        }

    }

    return (
        <div className='coachExpCardCont'>
            {experience.coachid === myMainid ? < img style={{ zIndex: '100' }} onClick={() => setOpenExpCreator(experience)} className='editExperience' src={edit} /> : ""}
            {experience.coachid === myMainid ? < img style={{ zIndex: '100', marginRight: '0px' }} onClick={handleDeleteExp} className='editExperience' src={deleteImg} /> : ""}
            <div className='coachExpCardHead'>

                {experience.image && experience.image.includes('.mp4') ?
                    <div style={{ display: 'flex', justifyContent: 'center' }} onClick={() => handleOpenVideo(experience.image)}>
                        <img style={{ width: '40px', height: '40px', padding: '10px' }} src={play} className='playButton' />
                        <img className='expImage' src={experience.thumb} />
                    </div>
                    : ""
                }
                {experience.image && !experience.image.includes('.mp4') ? <img onClick={() => setBigPic(experience.image)} className='expImage' src={experience.image} /> : ""}
                <div style={{gap:'0px'}} className='expInfo'>
                    <h4 style={{ marginLeft: '0px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>Title: </span>{experience.title.toUpperCase()}</h4>
                    <h5 style={{ marginLeft: '0px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>Start Date: </span>{experience.startdate}</h5>
                    <h5 style={{ marginLeft: '0px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>End Date: </span>{experience.enddate ? experience.enddate : "Till Present"}</h5>
                </div>
            </div>
            <div  className='expCardDescriptionCont'>
                <h5 style={{ marginLeft: '0px', textAlign: 'left', marginBottom: '0px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>Description: </span></h5>
                <p className='expCardDescription'>{experience.description}</p>
            </div>
        </div >
    )
}