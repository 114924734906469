import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setMainId } from '../list/tempProfileSlice';
import { useNavigate } from 'react-router-dom';

export function LeadBoardCard({ user, rank, type }) {
    const [showCoinDescription, setShowCoinDescription] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    return (
        <div className='leadBoardCardCont'>
            {showCoinDescription ?
                <div onClick={() => { setShowCoinDescription(false) }} className="fullScreenClean">
                    <div style={{ height: '130px' }} className='fullScreenCleanBox'>
                        <h5><span style={{ fontSize: '20px', fontWeight: '500' }}>{user.points}</span><br /> <br />FIT-BUDDY ACTIVITY POINTS</h5>
                        <p onMouseUp={() => navigate('/home/achievements')} style={{ color: 'black', height: '30px', fontSize: '14px', padding: '0px 15px', width: 'fit-content' }} className="activeBlack singleButton">Trak My Point</p>
                    </div>
                </div>
                :
                ''
            }
            <img onClick={() => dispatch(setMainId(user.mainid))} className='userImage' src={user.picture_url} alt={user.name} />
            <div onClick={() => dispatch(setMainId(user.mainid))} className='userInfo'>
                <p className='userName'>{user.name}</p>
                <p className='confirmedText'><span className='countSpan'>{user.count} </span>{type} workout(s)</p>
            </div>
            <div onClick={() => { setShowCoinDescription(true) }} style={{ marginRight: '0px', position: 'absolute', right: '10px', bottom: '10px' }} className="CoinCont">
                <p>{user.points}</p>
                <img className="fitCoin" src={localStorage.getItem('Logos')} />
            </div>
        </div>
    )

}