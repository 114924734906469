import React, { useState, useEffect } from "react";
import { SingleMember } from "../groups/SingleMember";
import { useDispatch } from "react-redux";
import { setMainId } from "../list/tempProfileSlice";

export function ConfirmedGroupMembers({ groupName, members, setConfirmedGroupMembers }) {
    const dispatch = useDispatch()

    return (
        <div className="dropdownContentContainer">
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", padding: '10px 0px', width: '90%', height: '80%' }} className="dropdownContent">
                <h3 style={{ color: 'white', marginBottom: '10px', marginTop: '10px' }}>{groupName}</h3>
                <div style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}>
                    <div style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="">
                        {members ? members.map(member => (
                            <div onClick={() => dispatch(setMainId(member.mainid))} style={{ gridTemplateColumns: '100%' }} key={member.mainid} className='singleStudentCont'>
                                <p >{member.membername}</p>
                            </div>
                        ))
                            :
                            ""
                        }
                    </div>
                </div>
                <p onClick={() => { setConfirmedGroupMembers(null) }} style={{ marginTop: '10px' }} className="activeBlack singleButton2">Close</p>
            </div>
        </div>
    );
}
