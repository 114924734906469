import React, { useState, useEffect } from 'react';

export function FakeEventCard({ }) {
    return (
        <div style={{ marginBottom: '19px' }} className='eventCardCont' >
            <div style={{ borderTopRightRadius: '20px', borderTopLeftRadius: '20px', width: 'calc(100%)', aspectRatio: '1.77' }} className='fakeBackGround' />
            <h3  >Loading ...</h3>
            <h5 style={{ fontSize: '13px', color: 'white', fontWeight: '500', marginBottom: '8px', }}>Loading ...</h5>
            <h5 style={{ fontSize: '13px', color: 'white', fontWeight: '500' }}> Loading ...</h5 >
        </div >
    )
}