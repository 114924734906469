import React, { useEffect, useState } from "react";
import { selectData, editData } from "../home/homeSlice";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fav1 } from '../profile/Fav1';
import { Fav2 } from '../profile/Fav2';
import { SwipeInUser } from '../user/SwipeInUser'

export const PromoEvent = ({ setShowFullScreen }) => {
    const [showFinish, setShowFininsh] = useState(false)
    const data = useSelector(selectData);
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            if (data.fav1 || data.fav2) {
                setShowFininsh(true)
            }
        } catch {

        }
    }, [data])

    const updateShowEvent = (state) => {
        fetch(`/api/profile/updateshowevent`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ showEvent: state }),
        })
            .then((response) => {
                if (response.ok) {
                    dispatch(editData({ showevent: state }));
                    handleLeaveEvent()
                } else {
                }
            })
            .catch((error) => {
                console.error("Error updating age:", error);
            });
    }

    const handleLeaveEvent = () => {
        try {
            setShowFullScreen(false)
        } catch {
            navigate('/home/profile')
        }
    }



    return (
        <div className="fullScreenEvent">
            <SwipeInUser back={() => handleLeaveEvent()} />
            <div style={{ marginBottom: '10px', borderRadius: '15px', padding: '20px 15px 10px 20px' }} className='boxLogin'>
                <h3 style={{ textAlign: 'left' }}>Set your favorite sports to get notified when a nearby user is interested in them</h3>
                <div style={{ height: '40px' }} />
                <div style={{ display: 'flex', gap: '60px' }}>
                    <Fav1 fav={data.fav1} />
                    <Fav2 fav={data.fav2} />
                </div>
                <div style={{ height: '40px' }} />
                <div style={{ gap: '20px', display: 'flex' }}>
                    <div style={{ width: '130px', borderRadius: '15px' }} className={`gender-option `} onClick={() => handleLeaveEvent()}>
                        Set Later
                    </div>
                    <div style={{ width: '130px', borderRadius: '15px' }} className={`gender-option`} onClick={() => updateShowEvent(false)}>
                        Don't Show
                    </div>
                </div>
                {showFinish ?
                    <div style={{ marginTop: '20px', width: '130px', borderRadius: '15px' }} className={`gender-option`} onClick={() => handleLeaveEvent()}>
                        Done
                    </div> : ""
                }
            </div >
        </div>
    )
}