import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FriendButton } from '../friendButton/FriendButton'
import "./otherProfile.css"
import { disableRefresh, selectData } from "../home/homeSlice";
import closeGreen from '../../Pics/closeGreen.png'
import { ReportButton } from './ReportButton'
import { SwipeInUser } from './SwipeInUser'
import { MutualFriends } from "./MutualFreinds";
import facebook from "../../Pics/facebook.png"
import instagram from "../../Pics/instagram.png"
import TimeView from "../list/TimeView";
import { UserPosts } from "./UserPosts";
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import { friendsAchievements, confirmedAchievements, confirmedGroupAchievements, featuredSetsAchievements } from '../achievements/achievementsFuncs'
import { TopBadge } from "../achievements/TopBadge";
import { useNavigate } from "react-router-dom";
import { clearInfo } from "../list/tempProfileSlice";
import { calculateAge } from "../profile/proFuncs";

export function OtherProfile({ info, back, openChat, friend, setFriend }) {
  const [imageSrc, setImageSrc] = useState(info.user[0].picture_url);
  const [bigPic, setBigPic] = useState(false)
  const [selectedBadge, setSelectedBadge] = useState(null)
  const [selectedCount, setSelectedCount] = useState(null)
  const myData = useSelector(selectData)
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const openTempMeeting = (mainid) => {
    dispatch(setMeetingID(`user:${mainid}`))
  };


  const photoPicker = (int) => {
    const picked = int.toLowerCase()
    return localStorage.getItem(picked)
  }

  const openLinkOnMob = (link) => {
    dispatch(disableRefresh());
    try {
      window.ReactNativeWebView.postMessage(`link:${link}`);
    } catch (e) {
    }
  };

  const backupImg = () => {
    setImageSrc(localStorage.getItem("logo192")); // Set backup image source when the main image fails to load
    console.log('image not loaded')
  };

  try {
    return (
      <div style={{ zIndex: '30', marginTop: '50px' }} className="otherProfileCont">
        {window.location.pathname === '/home/myprofile' ? "" :
          <div className="repInProf">
            <ReportButton info={info.user[0]} />
          </div>
        }
        <div className="topBadgesProfileCont">
          <TopBadge setSelectedCount={setSelectedCount} setSelectedBadge={setSelectedBadge} count={Object.values(info.user[0].friends).filter(friend => friend.status === 'friends').length} Achievements={friendsAchievements} />
          <TopBadge setSelectedCount={setSelectedCount} setSelectedBadge={setSelectedBadge} count={info.user[0].confirmedcount} Achievements={confirmedAchievements} />
          <TopBadge setSelectedCount={setSelectedCount} setSelectedBadge={setSelectedBadge} count={info.user[0].confirmedgroupmeetings} Achievements={confirmedGroupAchievements} />
          <TopBadge setSelectedCount={setSelectedCount} setSelectedBadge={setSelectedBadge} count={info.user[0].featuredsets} Achievements={featuredSetsAchievements} />
        </div>
        <img className="otherProfileBackArrow" src={closeGreen} onClick={back} />
        <h3 className="otherProfileName">{info.user[0].name.toUpperCase()}</h3>
        <img onClick={() => setBigPic(true)} className="otherProfilePic" src={imageSrc} onError={backupImg} alt="Profile picture" />
        <div className="otherProfilegendandage">
          <p>{info.user[0].gender.toUpperCase()},</p>
          {calculateAge(info.user[0].age)}
          <p onClick={() => { navigate(`/home/posts/:${info.user[0].mainid}`); dispatch(clearInfo()) }} style={{ color: 'hsl(72, 100%, 47%)' }} >POSTS</p>
          {info.user[0].facebooklink ? <img onClick={() => openLinkOnMob(info.user[0].facebooklink)} style={{ width: '30px', borderRadius: '10px' }} src={facebook} /> : ''}
          {info.user[0].instagramlink ? <img onClick={() => openLinkOnMob(info.user[0].instagramlink)} style={{ width: '30px', borderRadius: '10px' }} src={instagram} /> : ''}
        </div>
        <div style={{ marginTop: '17px', marginBottom: '10px' }} className="otherProfilegendandage">
          <p style={{ marginRight: '10px' }} >BUDDIES: {Object.values(info.user[0].friends).filter(friend => friend.status === 'friends').length}</p>
          {window.location.pathname !== '/home/myprofile' ? <MutualFriends myFriends={info.friends} userFriends={info.user[0].friends} userName={info.user[0].name} /> : <p >( {Object.values(info.user[0].friends).filter(friend => friend.status === 'friends').length} ) Mutual</p>}
        </div>
        <p className="otherProfileBio">{info.user[0].bio}</p>
        <div className="otherFavs">
          {info.user[0].fav1 || info.user[0].fav2 ? <p>Fav(s) </p> : <p>No Favorite Sport</p>}
          {info.user[0].fav1 ? <img loading="lazy" className="otherProfileFav" src={photoPicker(info.user[0].fav1)} /> : ''}
          {info.user[0].fav2 ? <img loading="lazy" className="otherProfileFav" src={photoPicker(info.user[0].fav2)} /> : ''}
        </div>
        {info.user[0].interest ?
          <div className="currentPlanOther">
            <img loading="lazy" className="otherProfileFav" src={photoPicker(info.user[0].interest)} />
            <TimeView time={info.user[0].time} />
            <p >
              {info.user[0].preflocation ? info.user[0].preflocation : 'None'}
            </p>
            <p >
              {info.user[0].comment ? info.user[0].comment : 'None'}
            </p>
          </div>
          :
          <p style={{ marginTop: '5px', fontWeight: '600' }}>No Plan</p>
        }
        {window.location.pathname === '/home/myprofile' ? "" :
          <div className="proFrBt">
            {info.user[0].mainid !== myData.mainid ?
              <FriendButton openChat={openChat} proFriends={info.friends} id={info.user[0].mainid} setFriend={setFriend} openTempMeeting={openTempMeeting} />
              :
              ""
            }
          </div>
        }
        {bigPic ?
          <div onClick={() => setBigPic(false)} className="otherBigPic">
            <img src={info.user[0].picture_url} />
          </div>
          :
          ''
        }
        {selectedBadge && selectedCount ? (
          <div onClick={() => { setSelectedBadge(null); setSelectedCount(null) }} className="fullScreenClean">
            <div className='fullScreenCleanBox'>
              <div className='achievementImgHolder'>
                <img src={selectedBadge.image} alt="Achievement Logo" />
              </div>
              <h5 style={{ marginTop: '10px' }}>GOAL: {selectedBadge.goal}</h5>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  } catch (e) {
    back()
  }
}