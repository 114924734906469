import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadReqs } from "../reqs/reqsSlice";
import { deleteRequest } from '../reqs/reqsFuncs'
import { sendFriendRequest, acceptFriendRequest, rejectFriendRequest, sendFriendNotification, acceptFriendNotification } from "./firndButFuncs";
import { clearInfo } from "../list/tempProfileSlice";
import socket from "../../app/io";
import meetup from '../../Pics/meetup.png'
import "./friendButton.css";
import { loadHome } from "../home/homeSlice";
import { loadUser } from "../user/userSlice";
import { loadFriends } from "../friends/friendsSlice";



export function FriendButton({ setNotification, proFriends, id, openChat, setFriend, openTempMeeting }) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation();
  const pagePath = location.pathname;
  const [state, setState] = useState();

  useEffect(() => {
    socket.on("friendRequestReceived", ({ senderId }) => {
      setTimeout(() => {
        try {
          if (id === senderId) {
            setState('request')
            if (pagePath === `/home/:${senderId}`) {
              dispatch(loadUser(id));
              dispatch(loadFriends())
            }
          }
        } catch (e) {
          console.log('no button available to change')
        }
      }, 500);
    });

    return () => {
      socket.off("friendRequestReceived");
    };
  }, []);

  useEffect(() => {
    socket.on("friendRequestAccepted", ({ senderId }) => {
      setTimeout(() => {
        try {
          if (id === senderId) {
            if (pagePath === `/home/:${senderId}`) {
              dispatch(loadUser(id));
              dispatch(loadFriends())
            }
            setState('friends')
            setFriend(true)
          }
        } catch (e) {
          console.log('no button available to change')
        }
      }, 500);
    });

    return () => {
      socket.off("friendRequestAccepted");
    };
  }, [])



  useEffect(() => {
    socket.on("friendRemoved", ({ senderId }) => {
      console.log('removed')
      setTimeout(() => {
        try {
          if (id === senderId) {
            if (pagePath === `/home/:${senderId}`) {
              dispatch(loadUser(id));
              dispatch(loadFriends())
              console.log(';asdasdqw')
            }
            setState('')
            setFriend(false)
            try {
              handelDelete(id, 'request')
            } catch (e) {
              console.log(e)
            }
          }
        } catch (e) {
          console.log('no button available to change')
        }
      }, 600);
    });

    return () => {
      socket.off("friendRemoved");
    };
  }, [])



  useEffect(() => {

    try {
      if (proFriends[id].status === "sent") {
        setState("pending");
      } else if (proFriends[id].status === "request") {
        setState("request");
      } else if (proFriends[id].status === "friends") {
        setState("friends");
        setFriend(true)
      }
    } catch (e) {
      setState("");
    }
  }, [id]);

  function handleAdd() {
    sendFriendNotification(id)
    sendFriendRequest(id)
      .then((data) => {
        console.log(data);
        setState("pending");
        if (pagePath === `/home/:${id}`) {
          dispatch(loadUser(id));
          dispatch(loadFriends())
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handelDelete = (senderid, status) => {
    deleteRequest(senderid, status).then((response) => {
      dispatch(loadReqs(id));
    });
    try {
      setNotification(false);
    } catch (e) {
      console.log('didnt  update noti state')
    }
  };

  function handleAccept() {
    acceptFriendNotification(id)
    acceptFriendRequest(id)
      .then(() => {
        setState("friends");
      }).then(() => {
        try {
          handelDelete(id, 'request')
        } catch (e) {
          console.log(e)
        }
      })
      .then(() => {
        try {
          if (pagePath.includes(id)) {
            setFriend(true)
            dispatch(loadUser(id));
            dispatch(loadFriends())
          }
        } catch (e) {
          console.log('out of page')
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleReject() {
    rejectFriendRequest(id)
      .then((data) => {
        console.log(data);
        setState("");
      })
      .then(() => {
        if (pagePath.includes(id)) {
          dispatch(loadUser(id));
          dispatch(loadFriends())
        }
        try {
          handelDelete(id, 'request')
          setFriend(false)
        } catch (e) {
          console.log(e)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleMessage() {
    if (window.location.pathname === `/home/:${id}`) {
      try {
        openChat()
      } catch (e) {
        console.log('not in user page')
      }
    } else {
      navigate(`/home/:${id}`)
      dispatch(clearInfo())
    }
  }

  if (state === "pending") return <button className="singleButton" onClick={handleReject}>CANCEL</button>;

  if (state === "") return <button className="singleButton" onClick={handleAdd}>CONNECT</button>;

  if (state === "request") {
    return (
      <div className="doublbutCont" style={{ display: 'flex' }} >
        <button className="singleButton" onClick={handleAccept}>CONNECT</button>
        <button style={{ backgroundColor: '#f5f5f5' }} className="singleButton2" onClick={handleReject}>REJECT</button>
      </div>
    );
  }

  if (state === "friends") {
    return (
      <div className="doublbutCont" style={{ display: 'flex' }} >
        <div className="doubleFriendsButton" >
          <button className="singleButton" onClick={handleMessage}>MESSAGE</button>
          <img onClick={() => openTempMeeting(id)} src={meetup} />
        </div>
        <button className="singleButton2" onClick={handleReject}>REMOVE</button>
      </div>
    );
  }
}
