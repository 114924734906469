import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SportPicker } from '../Posts/SportPicke';
import { TimeInput } from '../intButtons/TimeInput'
import { MiniFriends } from '../minifriends/MiniFriends'
import { setMeetingID, clearInfo } from "./tempMeetingPageSlice";
import { editAchievementsData, selectAchievements } from "../achievements/achievementsSlice";


export const MeetUpCreator = ({ myInfo, showfirendsList, setShowFriendslist, selectedSport, setSelectedSport, taggedFriend, setTaggedFriend, creating, setCreating, fetchMyMeetings }) => {
    const [time, setTime] = useState('')
    const dispatch = useDispatch()
    const Achievements = useSelector(selectAchievements)
    const navigate = useNavigate()


    function formatAMPM(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';

        hours = hours % 12;
        hours = hours ? hours : 12; // Handle midnight (0:00) as 12:00 AM
        minutes = minutes < 10 ? '0' + minutes : minutes;

        return `${hours}:${minutes} ${ampm} ${date.getDate()}/${date.getMonth() + 1}`;
    }

    const currentDate = new Date();
    const formattedDate = formatAMPM(currentDate);


    const handleCreateMeeting = async (selectedSport, time, taggedFriend) => {
        if (!selectedSport || !time || !taggedFriend) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Make sure u selected a buddy and sport`);
                return
            } catch (e) {
                return
            }
        }
        setCreating(true)

        try {
            // Send the FormData to the server using the fetch API
            const response = await fetch('/api/meetings/createmeeting', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    sport: selectedSport,  // Replace with your selected sport
                    delay: time.delay,   // Replace with your selected time
                    taggedId: taggedFriend.mainid,     // Replace with the tagged friend's ID
                    taggedName: taggedFriend.name,
                    createdTime: time.selectedTime // Replace with the tagged friend's name
                })
            });

            if (response.ok) {
                setCreating(false)
                const data = await response.json()
                try {
                    if (data.new === false) {
                        try {
                            window.ReactNativeWebView.postMessage(`Alert:An UnFinished workout already exist between you and ${taggedFriend.name}`);

                        } catch (e) {

                        }
                        try {
                            if (window.location.pathname === '/home/myworkouts') {
                                fetchMyMeetings()
                            } else {
                                dispatch(clearInfo())
                                dispatch(setMeetingID(`meet:${data.meetingid}`))
                            }
                        } catch (e) {
                            dispatch(clearInfo())
                            dispatch(setMeetingID(`meet:${data.meetingid}`))
                        }
                    } else {
                        try {
                            window.ReactNativeWebView.postMessage(`Alert:You requested ${taggedFriend.name} to join you in a workout`);

                        } catch (e) {

                        }
                        try {
                            if (window.location.pathname === '/home/achievements' && Achievements && Achievements.invoracc === false) {
                                dispatch(editAchievementsData({ invoracc: true }))
                            }
                        } catch (e) {
                        }
                        try {
                            if (window.location.pathname === '/home/myworkouts') {
                                fetchMyMeetings()
                            } else {
                                dispatch(clearInfo())
                                dispatch(setMeetingID(`meet:${data.meetingid}`))
                            }
                        } catch (e) {
                            dispatch(clearInfo())
                            dispatch(setMeetingID(`meet:${data.meetingid}`))
                        }

                    }
                } catch (e) {

                }
                //navigate(`/home/feed`)
            } else {
                setCreating(false)
                console.error('Post creation failed:', response.status);
            }
        } catch (error) {
            setCreating(false)
            console.error('Error creating post:', error);
        }
    };

    const openFriendsList = () => {
        setShowFriendslist((state) => !state)
    }



    return (
        <div style={{ width: '100%' }}>
            {creating ? <div className='CreatingPost'>
                <p className='fake'> Sending</p>
            </div> : ""}
            <div style={{ gridTemplateColumns: '100%' }} className="hintCont">
                <label style={{ margin: 'auto' }} htmlFor="comment" >INVITE TO WORKOUT</label>
            </div>
            <TimeInput setTime={setTime} maxTime={24} />
            <div className="hintCont">
                <label htmlFor="comment">BUDDY</label>
                {taggedFriend ? <p onClick={openFriendsList} style={{ fontWeight: '500' }}>{taggedFriend.name}</p> : <p className='commanButtons' style={{ fontWeight: '500', color: 'black' }} onClick={openFriendsList} >Select Your Buddy</p>}
            </div>
            <div className="hintCont">
                <label htmlFor="comment">SPORT</label>
                {selectedSport ? <p onClick={() => setSelectedSport(null)} style={{ fontWeight: '500' }}>{selectedSport}</p> : <SportPicker selectedSport={selectedSport} setSelectedSport={setSelectedSport} />}
            </div>
            <div style={{ borderRadius: '15px' }} className='confirmPostCont'>
                <p onClick={() => handleCreateMeeting(selectedSport, time, taggedFriend)}>Send Invite</p>
            </div>
            {showfirendsList ? <MiniFriends setTaggedFriend={setTaggedFriend} setShowFriendslist={setShowFriendslist} /> : ""}
        </div>
    )
}