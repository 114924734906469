import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export function NewVersion({ text, notes, setAskTopUpdate }) {
    const componentRef = useRef(null);
    const navigate = useNavigate()

    const handleYesUpdate = () => {
        navigate('/updatenow')
    }

    const handleNoUpdate = () => {
        setAskTopUpdate(false)
    }

    useEffect(() => {
        try {
            const handleOutsideClick = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setAskTopUpdate(false)
                }
            };

            const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

            document.addEventListener(clickEvent, handleOutsideClick);

            return () => {
                document.removeEventListener(clickEvent, handleOutsideClick);
            };
        } catch (e) {

        }
    }, []);
    return (
        <div className="descContPage">
            <div style={{ padding: "15px 15px" }} ref={componentRef} className="refPrompCont">

                <p style={{ textAlign: 'left', marginBottom: '5px' }} className="subscriptionDetails">
                    <strong style={{ fontSize: '22px' }}>{text}</strong>
                </p>
                {notes && notes.length > 0 ?
                    notes.map((note, index) => {
                        return (
                            <p style={{ textAlign: 'left', marginBottom: "5px" }} key={index} >{note}</p>
                        )
                    })
                    : ""
                }
                <div style={{ marginTop: '20px' }} className="subscriptionButtons">
                    <button onMouseUp={() => handleYesUpdate()} className="primaryButton">Update Now</button>
                    <button style={{ fontSize: '12px', border: '0px' }} onMouseUp={() => handleNoUpdate()} className="secondaryButton">Maybe Later</button>
                </div>
            </div>
        </div>
    )

}