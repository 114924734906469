import React, { useEffect, useRef, useState } from "react"
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { FavShortCut } from "./FavShortCut";
import { FakePost } from "../Posts/FakePost";
import { Fav1 } from "../profile/Fav1";
import { Fav2 } from "../profile/Fav2";
import { ConfirmInterest } from "./ConfirmInterest";
import { TodayWorkoutsBar } from "../workoutsManager/TodayWorkoutsBar";
import refreshGreen from '../../Pics/refreshGreen.png'

import duoGreen from '../../Pics/duoGreen.png'
import friendsPic from '../../Pics/connects.png'
import whistle from '../../Pics/whistle.png'
import nearByPic from '../../Pics/nearBy.png'
import { AskPrompet } from "../popUps/AskPrompet"
import './newHeader.css'

export const NewShortcut = ({ data, favoriteShortcutHandle, handleRefreshFeed, nearBy, loadingNearBy }) => {
    const navigate = useNavigate()
    const [showPromtoFavDuo, setShowPromtoFavDuo] = useState(null)
    const [showPromtoFavGroup, setShowPromtoFavGroup] = useState(null);

    const handleGoToGroup = (fav) => {
        window.selectedGroupSport = fav
        navigate('/home/groups/findgroups')
    }

    useEffect(() => {
        try {
            if (loadingNearBy === 'done' && nearBy[data.fav1] === 0 && nearBy[data.fav2] === 0) {
                setShowPromtoFavDuo(true)
            } else {
                setShowPromtoFavDuo(false)
            }
        } catch (e) {

        }
        try {
            if (loadingNearBy === 'done' && nearBy[`${data.fav1}Group`] === 0 && nearBy[`${data.fav2}Group`] === 0) {
                setShowPromtoFavGroup(true)
            } else {
                setShowPromtoFavGroup(false)
            }
        } catch (e) {

        }
    }, [nearBy])


    return (
        <div className="newShorCutCont">
            <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
                <div onMouseUp={() => navigate('/home/workout')} className="activeBlack findInshortCutCont">
                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
                    <h5>USERS NEARBY, looking for a workout partner</h5>
                </div>
                <div >

                </div>
                <div className="favShorCutContButton1">
                    {data.fav1 ? <FavShortCut fav={data.fav1} radius={40} favoriteShortcutHandle={favoriteShortcutHandle} /> : <Fav1 fav={data.fav1} backGrounColor={"hsla(0, 0%, 10%, 0.9)"} height={"100%"} />}
                    {data.fav1 ? <p className="shortCutCountNumber" onClick={() => favoriteShortcutHandle(data.fav1, 40)} >{loadingNearBy === 'done' ? nearBy[data.fav1] : ".."}</p> : ""}
                </div>
                <div className="favShorCutContButton2">
                    {data.fav2 ? <FavShortCut fav={data.fav2} radius={40} favoriteShortcutHandle={favoriteShortcutHandle} /> : <Fav2 fav={data.fav2} backGrounColor={"hsla(0, 0%, 10%, 0.9)"} height={"100%"} />}
                    {data.fav2 ? <p className="shortCutCountNumber" onClick={() => favoriteShortcutHandle(data.fav2, 40)} >{loadingNearBy === 'done' ? nearBy[data.fav2] : ".."}</p> : ""}
                </div>
            </div>
            <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
                <div onMouseUp={() => navigate('/home/groups')} className="activeBlack findInshortCutCont">
                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
                    <h5>GROUPS NEARBY, looking for players to join!</h5>
                </div>
                <div >
                </div>
                <div className="favShorCutContButton1">
                    {data.fav1 ? <FavShortCut fav={data.fav1} radius={40} favoriteShortcutHandle={handleGoToGroup} /> : <Fav1 fav={data.fav1} backGrounColor={"hsla(0, 0%, 10%, 0.9)"} height={"100%"} />}
                    {data.fav1 ? <p className="shortCutCountNumber" onClick={() => handleGoToGroup(data.fav1)} >{loadingNearBy === 'done' ? nearBy[`${data.fav1}Group`] : "..."}</p> : ""}
                </div>
                <div className="favShorCutContButton2">
                    {data.fav2 ? <FavShortCut fav={data.fav2} radius={40} favoriteShortcutHandle={handleGoToGroup} /> : <Fav2 fav={data.fav2} backGrounColor={"hsla(0, 0%, 10%, 0.9)"} height={"100%"} />}
                    {data.fav2 ? <p className="shortCutCountNumber" onClick={() => handleGoToGroup(data.fav2)} >{loadingNearBy === 'done' ? nearBy[`${data.fav2}Group`] : "..."}</p> : ""}
                </div>
            </div>

        </div>
    )


    //return (
    //    <div className="newShorCutCont">
    //        {showPromtoFavDuo ?
    //            <div className="FeedShortCutHeaderContButton newShortCutButton activeBlack" style={{ width: '100%' }}>
    //                <div onMouseUp={() => navigate('/home/workout/findduo')} className=" findInshortCutCont">
    //                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
    //                    <h5>NO <span style={{ color: 'hsl(72, 100%, 47%)' }}>USERS</span> active nearby based on favorite sport</h5>
    //                </div>
    //                <div >
    //
    //                </div>
    //                <div className="noFavShortcut  ">
    //                    <h5>DISCOVER others by Favorite Sport and encourage them?</h5>
    //                </div>
    //            </div>
    //            :
    //            <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
    //                <div onMouseUp={() => navigate('/home/workout')} className="activeBlack findInshortCutCont">
    //                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
    //                    <h5>USERS NEARBY, looking for a workout partner</h5>
    //                </div>
    //                <div >
    //
    //                </div>
    //                <div className="favShorCutContButton1">
    //                    {data.fav1 ? <FavShortCut fav={data.fav1} radius={40} favoriteShortcutHandle={favoriteShortcutHandle} /> : <Fav1 fav={data.fav1} />}
    //                    {data.fav1 ? <p className="shortCutCountNumber" onClick={() => favoriteShortcutHandle(data.fav1, 40)} >{loadingNearBy === 'done' ? nearBy[data.fav1] : ".."}</p> : ""}
    //                </div>
    //                <div className="favShorCutContButton2">
    //                    {data.fav2 ? <FavShortCut fav={data.fav2} radius={40} favoriteShortcutHandle={favoriteShortcutHandle} /> : <Fav2 fav={data.fav2} />}
    //                    {data.fav2 ? <p className="shortCutCountNumber" onClick={() => favoriteShortcutHandle(data.fav2, 40)} >{loadingNearBy === 'done' ? nearBy[data.fav2] : ".."}</p> : ""}
    //                </div>
    //            </div>
    //        }
    //        {showPromtoFavGroup ?
    //            <div className="FeedShortCutHeaderContButton newShortCutButton activeBlack" style={{ width: '100%' }}>
    //                <div onMouseUp={() => navigate('/home/groups/creategroup')} className=" findInshortCutCont">
    //                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
    //                    <h5>NO <span style={{ color: 'hsl(72, 100%, 47%)' }}>GROUPS</span> nearby based on your favorite sport</h5>
    //                </div>
    //                <div >
    //
    //                </div>
    //                <div className="noFavShortcut  ">
    //                    <h5>LEAD the way and create a group</h5>
    //                </div>
    //            </div>
    //            :
    //            <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
    //                <div onMouseUp={() => navigate('/home/groups')} className="activeBlack findInshortCutCont">
    //                    <img src={localStorage.getItem('nearByPic') || nearByPic} />
    //                    <h5>GROUPS NEARBY, looking for players to join!</h5>
    //                </div>
    //                <div >
    //                </div>
    //                <div className="favShorCutContButton1">
    //                    {data.fav1 ? <FavShortCut fav={data.fav1} radius={40} favoriteShortcutHandle={handleGoToGroup} /> : <Fav1 fav={data.fav1} />}
    //                    {data.fav1 ? <p className="shortCutCountNumber" onClick={() => handleGoToGroup(data.fav1)} >{loadingNearBy === 'done' ? nearBy[`${data.fav1}Group`] : "..."}</p> : ""}
    //                </div>
    //                <div className="favShorCutContButton2">
    //                    {data.fav2 ? <FavShortCut fav={data.fav2} radius={40} favoriteShortcutHandle={handleGoToGroup} /> : <Fav2 fav={data.fav2} />}
    //                    {data.fav2 ? <p className="shortCutCountNumber" onClick={() => handleGoToGroup(data.fav2)} >{loadingNearBy === 'done' ? nearBy[`${data.fav2}Group`] : "..."}</p> : ""}
    //                </div>
    //            </div>
    //        }
    //    </div>
    //)

}