import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectData, selectStatus, loadFriends, reset, selectResults, filterUsers } from "./friendsSlice";
import { Link, useNavigate } from "react-router-dom";
import { Card } from '../list/Card'
import { LoadingCards } from '../root/LoadingCards'
import "./friends.css";
import { UnblockCard } from "./UnBlockCard";

export function Friends({ id }) {
  const [type, setType] = useState(window.friendsType || 'friends')
  const [search, setSearch] = useState('')
  const results = useSelector(selectResults)
  const data = useSelector(selectData);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  useEffect(() => {
    window.friendsType = type
    setSearch("")
    dispatch(loadFriends(type));
  }, [type]);

  useEffect(() => {
    dispatch(filterUsers(search))
  }, [search])

  if (status === "idle") {
    return <div>Idle</div>;
  } else {
    return (
      <div style={{ padding: '0px 0px 59px 0px' }} className="feedPostsCont">
        <div style={{ userSelect: 'none', display: 'flex', flexDirection: 'column', marginTop: '0px', width: '100%' }}>
          <div style={{ marginTop: '10px', marginBottom: '0px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
            <label className="activeBlack" onClick={() => setType('friends')} style={{ color: type === 'friends' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">FRIENDS</label>
            <label className="activeBlack" onClick={() => setType('request')} style={{ color: type === 'request' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">REQUESTS</label>
            <label className="activeBlack" onClick={() => setType('sent')} style={{ color: type === 'sent' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">SENT</label>
            <label className="activeBlack" onClick={() => setType('iblock')} style={{ width: '80px', color: type === 'iblock' ? "" : '#f5f5f59c', fontSize: '13px' }} htmlFor="comment">X</label>
          </div>
          <div className="seachContFR">
            <input
              style={{ margin: '10px 0px 5px 0px' }}
              className="hint"
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              maxLength={17}
              placeholder="search"
            />
          </div>
        </div>
        {status === 'done' && type !== 'iblock' ?
          <div style={{ marginTop: '10px', height: '100%' }} className="scrollList">
            {results.map((friend) => (
              <div key={friend.mainid} style={{ display: 'flex' }} onMouseUp={() => navigate(`/home/:${friend.mainid}`)}   >
                <Card proFriends={data.friends} user={friend} />
              </div>
            ))}
            <div style={{ height: type !== 'iblock' ? '50px' : "" }} />
          </div>
          :
          <div style={{ display: type !== 'iblock' ? "" : "none", marginTop: '10px', height: '100%' }} className="scrollList">
            <LoadingCards />
          </div>
        }
        {status === 'done' && type === 'iblock' ?
          <div style={{ marginTop: '0px', padding: '0px 10px', marginTop: '10px', height: '100%' }} className="scrollList">
            {results && results.length > 0 ?
              results.map((friend) => (
                <UnblockCard key={friend.mainid} user={friend} />
              ))
              :
              <div style={{ marginTop: '0px', height: '100%' }} className="scrollList">
                <p style={{ color: 'white' }}>No Blocked users</p>
              </div>
            }
            <div style={{ height: '50px' }} />
          </div>
          :
          <div style={{ display: type === 'iblock' ? "" : "none", marginTop: '10px', height: '100%' }} className="scrollList">
            <LoadingCards />
          </div>
        }

      </div>
    );
  }
}
