import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectData, selectStatus } from "../home/homeSlice";
import { fetchEvents } from './eventsFuncs'
import { ScrollEvents } from "./ScrollEvents";
import { useSwipeable } from "react-swipeable";
import { Outlet, useNavigate } from "react-router-dom";

import './events.css'
import { EventCard } from "./EventCard";
import { FakeEventCard } from "./FakeEventCard";
import Radius from "../intButtons/Radius";
import { SportPickerNew } from "../groups/SportPickerNew";
import Slider from "react-slick";

const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    arrows: true,
    dots: true,
};

export function DiscoverEvents({ }) {
    const [loadingEvents, setLoadingEvents] = useState(null)
    const [events, setEvents] = useState(null)
    const [selectedSport, setSelectedSport] = useState(localStorage.getItem('EventSport') || null);
    const [eventRadius, setEventRadius] = useState(400)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (selectedSport === null) {
            const radius = eventRadius
            localStorage.removeItem('EventSport')
            fetchEvents(events, setEvents, setLoadingEvents, selectedSport, radius)
        }
    }, [selectedSport])

    useEffect(() => {
        if (selectedSport) {
            const radius = eventRadius
            const sport = selectedSport
            fetchEvents(events, setEvents, setLoadingEvents, sport, radius)
            localStorage.setItem('EventSport', sport);
        }
    }, [selectedSport])

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeDown = () => {
        //handleRefreshFeed()
    };
    const swipeBackHandlersBack = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    const swipeBackHandlersRefresh = useSwipeable({
        onSwipedDown: handleSwipeDown,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    return (
        <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
            <div style={{ marginTop: '0px', marginBottom: '25px', height: '100%' }} className="scrollList">
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">DISCOVER-EVENTS</label>
                </div>
                <div className='findGroupsHeader'>
                    <Radius radius={eventRadius} setRadius={setEventRadius} />
                    <div style={{ width: '50px', height: '50px' }}>
                        <SportPickerNew selectedSport={selectedSport} setSelectedSport={setSelectedSport} />
                    </div>
                    <img style={{ position: 'relative', left: '0px' }} onClick={() => fetchEvents(events, setEvents, setLoadingEvents, selectedSport, eventRadius)} className="refreshInFeedImg activeBlack" src={localStorage.getItem('refreshGreen')} />

                </div>
                {loadingEvents === 'done' && events ? (
                    <Slider {...settings}>
                        {events.sort((a, b) => new Date(a.eventtime) - new Date(b.eventtime))
                            .map((event) => (
                                <EventCard key={event.eventid} eventid={event.eventid} image={event.image} eventname={event.eventname} location={event.location} eventtime={event.eventtime} sport={event.sport} caption={event.caption} locationdisplay={event.locationdisplay} creatorid={event.creatorid} hiddenby={event.hiddenby} setEvents={setEvents} goingcount={event.goingcount} commentscount={event.commentscount} />
                            ))}
                        {events.length === 0 && (
                            <div onClick={() => { }} className='noPrevWorkout'>
                                <p>
                                    No {selectedSport ? selectedSport : ""} Events <br />Create Event?
                                </p>
                                <div style={{ display: 'flex', gap: '10px', width: 'fit-content' }}>
                                    <img className='activeBlack' onMouseUp={() => navigate('/home/events/createevent')} style={{ width: '80px' }} src={localStorage.getItem('plus')} />
                                </div>

                            </div>
                        )}
                    </Slider>
                ) : (
                    <div>
                        <FakeEventCard />
                    </div>
                )}
                <div style={{ height: '200px' }} />
            </div>
        </div>
    )
}


