import React from 'react';

// Function to extract URLs from text
const extractUrls = (text) => {
    try {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.match(urlRegex);
    } catch (error) {

    }
};

// Component to render text with styled links
const TextHandler = ({ text }) => {
    const openLinkOnMob = (link) => {
        try {
            window.ReactNativeWebView.postMessage(`link:${link}`);
        } catch (e) { }
    };

    // Function to split the text and replace URLs with styled spans
    const parseText = (text) => {
        const parts = text.split(/(https?:\/\/[^\s]+)/g);
        return parts.map((part, index) => {
            // Check if the part is a URL and style it
            if (extractUrls(part)) {
                return (
                    <span onClick={() => { openLinkOnMob(part) }} key={index} style={{ fontWeight: 'bold', textDecoration: 'underline' }}>
                        {part}
                    </span>
                );
            }
            // Render normal text
            return <React.Fragment key={index}>{part}</React.Fragment>;
        });
    };

    return <span style={{ marginLeft: '0px', textAlign: 'left' }}>{parseText(text)}</span>
};

export default TextHandler;
