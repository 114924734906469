const wc = require('which-country')

export async function fetchNearByFavSport(setLoadingNearBy, setNearBy, fav1, fav2) {
  setLoadingNearBy(true);
  let favList1 = [];
  let favList2 = [];
  let favcount1;
  let favcount2;
  let favGroupList1 = [];
  let favGroupList2 = [];
  let favGroupcount1;
  let favGroupcount2;
  let allFavs;
  const sport1 = fav1
  const sport2 = fav2
  const sportGroup1 = `${fav1}Group`
  const sportGroup2 = `${fav2}Group`

  try {
    const response = await fetch('/api/loadnearbyfavs', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fav1: fav1, fav2: fav2 }),
    });

    if (response.ok) {
      const data = await response.json();
      for (const user of data) {
        if (user.interest === fav1) {
          if (user.type === 'user') {
            favList1.push(user);
          } else if (user.type === 'group') {
            favGroupList1.push(user);
          }
        } else if (user.interest === fav2) {
          if (user.type === 'user') {
            favList2.push(user);
          } else if (user.type === 'group') {
            favGroupList2.push(user);
          }
        }
      }
      favcount1 = favList1.length;
      favcount2 = favList2.length;
      favGroupcount1 = favGroupList1.length;
      favGroupcount2 = favGroupList2.length;
      allFavs = { [sport1]: favcount1, [sport2]: favcount2, [sportGroup1]: favGroupcount1, [sportGroup2]: favGroupcount2 };
      setNearBy(allFavs); // Set the retrieved posts in the state
      setLoadingNearBy('done');
    } else {
      favcount1 = 0;
      favcount2 = 0;
      favGroupcount1 = 0
      favGroupcount2 = 0
      allFavs = { [sport1]: favcount1, [sport2]: favcount2, [sportGroup1]: favGroupcount1, [sportGroup2]: favGroupcount2 };
      setNearBy(allFavs); // Set the retrieved posts in the state
      setLoadingNearBy('done');
    }
  } catch (error) {
    favcount1 = 0;
    favcount2 = 0;
    favGroupcount1 = 0
    favGroupcount2 = 0
    allFavs = { [sport1]: favcount1, [sport2]: favcount2, [sportGroup1]: favGroupcount1, [sportGroup2]: favGroupcount2 };
    setNearBy(allFavs); // Set the retrieved posts in the state
    setLoadingNearBy('done');
  }
}

export async function fetchFeedPosts(setLoadingPost, setPosts, data, publicPost, setOffset, scrollRef) {
  //try {
  //  if (scrollRef) {
  //    scrollRef.current.scrollTop = 0;
  //  }
  //
  //} catch (e) {
  //
  //}
  let regon
  setLoadingPost(true)

  if (publicPost === true) {
    try {
      const parsedLocation = await JSON.parse(data.location);
      const lat = await parsedLocation.latitude;
      const lon = await parsedLocation.longitude;
      regon = await wc([lon, lat])
    } catch (e) {
      regon = "null"
    }
  } else {
    regon = "null"
  }

  try {
    const response = await fetch('/api/post/loadfeedposts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ regon: regon, publicPost: publicPost }),
      }
    ); // Adjust the API endpoint as needed
    if (response.ok) {
      const data = await response.json();
      setPosts(data);
      setLoadingPost('done')
      try {
        if (data.length > 0) {
          if (data.length < 20) {
            setOffset(0)
          } else {
            setOffset(data[data.length - 1].postid)
          }
        } else {
          setOffset(0)
        }
      } catch (e) {
        setOffset(0)
      }
    } else {
      console.error('Failed to fetch posts');
      setLoadingPost(null)
    }
  } catch (error) {
    setLoadingPost(null)
    console.error('Error fetching posts:', error);
  }
}

export async function fetchNextFeedPosts(setLoadingNextPosts, setNextPosts, setPosts, offset, setOffset, data, publicPost) {
  let regon
  if (offset === 0) {
    return
  }
  setLoadingNextPosts(true)
  if (publicPost === true) {
    try {
      const parsedLocation = await JSON.parse(data.location);
      const lat = await parsedLocation.latitude;
      const lon = await parsedLocation.longitude;
      regon = await wc([lon, lat])
    } catch (e) {
      regon = "null"
    }
  } else {
    regon = "null"
  }

  try {
    const nextNumber = offset
    const response = await fetch('/api/post/loadnextfeedposts',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ regon: regon, publicPost: publicPost, nextNumber: nextNumber }),
      }
    ); // Adjust the API endpoint as needed
    if (response.ok) {
      const data = await response.json();
      try {
        if (data.length > 0) {
          if (data.length < 20) {
            setOffset(0)
          } else {
            setOffset(data[data.length - 1].postid)
          }
        } else {
          setOffset(0)
        }
      } catch (e) {
        setOffset(0)
      }
      setPosts(prevPosts => [...prevPosts, ...data]); // Set the retrieved posts in the state
      setLoadingNextPosts('done')
    } else {
      console.error('Failed to fetch posts');
      setLoadingNextPosts(null)
    }
  } catch (error) {
    setLoadingNextPosts(null)
    console.error('Error fetching posts:', error);
  }
}


export async function fetchMemberInGroups(setJoindCount, setRequestCount, setLoadingGroupsInCount) {
  setLoadingGroupsInCount('loading')
  try {
    const response = await fetch('/api/groups/groupscount'); // Adjust the API endpoint as needed
    if (response.ok) {
      const data = await response.json()
      if (data.length > 0) {
        for (const stat of data) {
          if (stat.status === 'request') {
            setRequestCount((count) => count + 1)
          } else {
            setJoindCount((count) => count + 1)
          }
        }
      }
      setLoadingGroupsInCount('done')
    } else {
      setLoadingGroupsInCount('failed')
    }
  } catch (error) {
    setLoadingGroupsInCount('failed')
  }
}



export const getInitialTimeForFeed = () => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
  const localISOTime = (new Date(Date.now() - tzoffset + (60 * 60 * 1000))).toISOString().slice(0, 16);
  const hours = new Date(localISOTime).getHours().toString().padStart(2, '0');
  const minutes = new Date(localISOTime).getMinutes().toString().padStart(2, '0');
  const day = new Date(localISOTime).getDate().toString().padStart(2, '0');
  const month = (new Date(localISOTime).getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
  const year = new Date(localISOTime).getFullYear().toString().slice(2);
  const timeStringToSave = `${hours}:${minutes} ${day}/${month}/${year}`;
  const initialDelay = (new Date(localISOTime).getTime()) - Date.now()
  return ({ selectedTime: timeStringToSave, delay: initialDelay });
}

export const shouldShowRefPromp = () => {
  const savedTimestamp = localStorage.getItem('hideRefPrompUntil');
  if (savedTimestamp) {
    const hideUntil = new Date(savedTimestamp);
    //console.log('Current Time:', new Date());
    //console.log('Hide Until:', hideUntil);
    if (new Date() < hideUntil) {
      return false; // Hide the prompt if the time hasn't passed
    }
  }
  //const savedValue = localStorage.getItem('showRefPromp');
  //return (savedValue !== null ? JSON.parse(savedValue) : (window.didSeeRef === true ? false : true));
  return ((window.didSeeRef === true ? false : true));
};