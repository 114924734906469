import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMainId } from "../list/tempProfileSlice";

export function SearchCard({ mianid, name, picture_url, fav1, fav2 }) {
    const dispatch = useDispatch()
    const [imageSrc, setImageSrc] = useState(picture_url);

    const backupImg = () => {
        setImageSrc(localStorage.getItem("logo192")); // Set backup image source when the main image fails to load
        console.log('image not loaded')
    };

    return (
        <div onClick={() => dispatch(setMainId(mianid))} className="minicard">
            <img src={imageSrc} alt={name} className="mini-user-picture" onError={backupImg} />
            <div className="mini-user-details">
                <p className="mini-smallText1">{name}</p>
            </div>
        </div>
    );
}
