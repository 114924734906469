import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchCommonInt } from './intFuncs'

export const loadCommon = createAsyncThunk("intButtons/loadCommon", async ({ interest, radius }) => {
  const data = await fetchCommonInt(interest, radius);
  const json = await data;
  return json;
});

export const intButtonsSlice = createSlice({
  name: "intButtons",
  initialState: {
    users: [],
    results: [],
    finalResults:[],
    friends: {},
    status: "idle",
    interest: null,
  },
  reducers: {
    reset: (state) => {
      state.users = [];
      state.status = "idle";
      state.log = "notlogged";
    },
    setLoading: (state, action) => {
      state.status = 'idle';
    },
    filterUsers: (state, action) => {
      const { timeFilter, commentFilter, locationFilter } = action.payload;
    
      state.finalResults = state.users.filter(user => {
        try {
          const matchTime = timeFilter === '' || user.time.toLowerCase().includes(timeFilter.toLowerCase());
          const matchComment = commentFilter === '' || user.comment.toLowerCase().includes(commentFilter.toLowerCase());
          const matchLocation = locationFilter === '' || user.preflocation.toLowerCase().includes(locationFilter.toLowerCase());
          
          return matchTime && matchComment && matchLocation;
        } catch (e) {
          return false;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadCommon.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loadCommon.fulfilled, (state, action) => {
        state.users = action.payload.users.usersWithinRadius;
        state.results = action.payload.users.usersWithinRadius;
        state.finalResults = action.payload.users.usersWithinRadius;       
        state.friends = action.payload.friends;
        state.status = "done";
        
      })
      .addCase(loadCommon.rejected, (state, action) => {
        state.status = "failed";
        console.log(state.status)
        state.users = null;
      });
  },
});

export const selectUsers = (state) => state.intButtons.users;
export const selectFriends = (state) => state.intButtons.friends;
export const selectInterest = (state) => state.intButtons.interest;
export const selectResults = (state) => state.intButtons.results;  
export const selectFinalResults = (state) => state.intButtons.finalResults; 
export const selectstatus = (state) => state.intButtons.status;
export const { reset, setLoading, filterUsers } = intButtonsSlice.actions;

export default intButtonsSlice.reducer;