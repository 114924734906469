import React from "react";
import './miniFriends.css'

export function MiniFriendCard({ setTaggedFriend, setShowFriendslist, setLoading, friend }) {

    function handleClick() {
        setTaggedFriend(friend)
        setShowFriendslist(false)
        setLoading(null)
    }

    return (
        <div onClick={handleClick} className="minicard">
            <img src={friend.picture_url} alt={friend.name} className="mini-user-picture" />
            <div className="mini-user-details">
                <p className="mini-smallText1">{friend.name}</p>
            </div>
        </div>
    );
}
