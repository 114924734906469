import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNearByCoaches } from './coachesFuncs';

export const loadMyCoach = createAsyncThunk(
  'coaches/loadMyCoach',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/coaches/loadmycoach`);
    const json = await data.json();
    return json;
  }
);

export const loadMyCoachBigData = createAsyncThunk(
  'coaches/loadMyCoachBigData',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/coaches/loadmycoachbigdata`);
    const json = await data.json();
    return json;
  }
);

export const loadNearByCoaches = createAsyncThunk(
  'coaches/loadNearByCoaches',
  async ({ sport, radius }) => {
    const data = await fetchNearByCoaches(sport, radius)
    return data;
  }
);

export const loadMyCoaches = createAsyncThunk(
  'coaches/loadMyCoaches',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/coaches/loadmycoaches`);
    const json = await data.json();
    return json;
  }
);

export const coachesSlice = createSlice({
  name: 'coaches',
  initialState: {
    myCoachProfile: null,
    myCoachProfileStatus: 'idle',
    myReviews: null,
    myStudents: null,
    myExperiences: null,
    myCoachBigDataStatus: 'idle',
    coachesNearBy: null,
    coachesNearByStatus: 'idle',
    myCoaches: null,
    myCoachesStatus: 'idle'
  },
  reducers: {
    editMyCoachProfileData: (state, action) => {
      try {
        state.myCoachProfile = { ...state.myCoachProfile, ...action.payload };
      } catch (e) {

      }
    },
    editStudentsData: (state, action) => {
      try {
        const parentArray = [...state[action.payload.parent]];
        const indexToUpdate = parentArray.findIndex(student => student.studentid === action.payload.info.studentid);

        if (indexToUpdate !== -1) {
          parentArray[indexToUpdate] = { ...action.payload.info };
        }

        state[action.payload.parent] = parentArray;
      } catch (e) {
        // Handle the error if needed
      }
    },
    deleteFromStudents: (state, action) => {
      try {
        const parentArray = state[action.payload.parent].filter(student => student.studentid !== action.payload.info.studentid);
        state[action.payload.parent] = parentArray;
      } catch (e) {
        // Handle the error if needed
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMyCoach.pending, (state) => {
        if (state.myCoachProfile === null) {
          state.myCoachProfileStatus = 'loading';
        }
      })
      .addCase(loadMyCoach.fulfilled, (state, action) => {
        state.myCoachProfile = action.payload;
        state.myCoachProfileStatus = 'done';
      })
      .addCase(loadMyCoach.rejected, (state, action) => {
        state.myCoachProfileStatus = 'failed';
        state.myCoachProfile = null;
      })
      //loadMyCoachBigData
      .addCase(loadMyCoachBigData.pending, (state) => {
        if (state.myExperiences === null) {
          state.myCoachBigDataStatus = 'loading';
        }
      })
      .addCase(loadMyCoachBigData.fulfilled, (state, action) => {
        state.myExperiences = action.payload.experiences;
        state.myStudents = action.payload.students;
        state.myReviews = action.payload.reviews;
        state.myCoachBigDataStatus = 'done';
      })
      .addCase(loadMyCoachBigData.rejected, (state, action) => {
        state.myCoachBigDataStatus = 'failed';
        state.myReviews = null
        state.myStudents = null
        state.myExperiences = null
      })
      //loadNearByCoaches
      .addCase(loadNearByCoaches.pending, (state) => {

        state.coachesNearByStatus = 'loading';

      })
      .addCase(loadNearByCoaches.fulfilled, (state, action) => {
        state.coachesNearBy = action.payload
        state.coachesNearByStatus = 'done';
      })
      .addCase(loadNearByCoaches.rejected, (state, action) => {
        state.coachesNearByStatus = 'failed';
        state.coachesNearBy = null
      })
      //loadMyCoaches
      .addCase(loadMyCoaches.pending, (state) => {
        if (state.myCoaches === null) {
          state.myCoachesStatus = 'loading';
        }
      })
      .addCase(loadMyCoaches.fulfilled, (state, action) => {
        state.myCoaches = action.payload
        state.myCoachesStatus = 'done';
      })
      .addCase(loadMyCoaches.rejected, (state, action) => {
        state.myCoachesStatus = 'failed';
        state.myCoaches = null
      })
  },
});

export const selectMyCoachProfile = (state) => state.coaches.myCoachProfile;
export const selectMyCoachProfileStatus = (state) => state.coaches.myCoachProfileStatus;
export const selectMyReviews = (state) => state.coaches.myReviews;
export const selectMyStudents = (state) => state.coaches.myStudents;
export const selectMyExperiences = (state) => state.coaches.myExperiences;
export const selectMyCoachBigDataStatus = (state) => state.coaches.myCoachBigDataStatus;
export const selectcoachesNearBy = (state) => state.coaches.coachesNearBy;
export const selectcoachesNearByStatus = (state) => state.coaches.coachesNearByStatus;
export const selectMyCoaches = (state) => state.coaches.myCoaches;
export const selectMyCoachesStatus = (state) => state.coaches.myCoachesStatus;


export const { editMyCoachProfileData, editStudentsData, deleteFromStudents } = coachesSlice.actions;
export default coachesSlice.reducer;


