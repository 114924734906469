
import oneuserGreen from '../../Pics/oneuserGreen.png'


export const services = [
    { name: 'FRIENDS', desc: "Search & Manage your friends list. You Can Also invite friends to workout from here.", link: '/home/friends', img: "", localName: "friendsPic" },
    { name: 'RANKINGS', desc: "Ranking of top Fit-Buddies", link: '/home/leadboards', img: "", localName: "ranks" },
    { name: 'EVENTS', desc: "Create Events Or Discover Events & track your events", link: '/home/events', img: "", localName: "event" },
    { name: 'MY WORKOUTS', desc: "Log, manage, and track your custom workouts", link: '/home/myworkouts?Load:custom', img: "", localName: "logWorkouts" },
    { name: 'DUO WORKOUTS', desc: "Track your duo workouts.", link: '/home/myworkouts?Load:duo', img: "", localName: "logWorkouts" },
    { name: 'GROUP WORKOUTS', desc: "Track your group workouts.", link: '/home/myworkouts?Load:group', img: "", localName: "logWorkouts" },
    { name: 'PORTAL', desc: "Create, manage, delete posts, tag friends, and accept tag requests.", link: '/home/mypage', img: "", localName: "feedGreen" },
    { name: 'CHALLENGES', desc: "Stay motivated with daily and weekly challenges,Unlock Achievements badges and track your earned FIT-Activity points", link: "/home/achievements", img: "", localName: "trophy" },
    { name: 'ANNOUNCE DUO', desc: "Announce your Workout Plan", link: '/home/workout/announce', img: "", localName: "announce" },
    { name: 'FIND DUO', desc: "Discover others want to exercise nearby.", link: '/home/workout/findduo', img: '', localName: "searchUser" },
    { name: 'CREATE GROUP', desc: "Create a sports group ", link: '/home/groups/creategroup', img: "", localName: "friends2" },
    { name: 'FIND GROUPS', desc: "Discover nearby groups with shared sport interest. ", link: '/home/groups/findgroups', img: "", localName: "searchGroup" },
    { name: 'COACH ACCOUNT', desc: "Setup & Edit my coach account", link: '/home/coaches/coachaccount', img: '', localName: "coachprofile" },
    { name: 'MY COACHES', desc: "Discover nearby cooaches", link: '/home/coaches/coachesnearby', img: '', localName: "traineeGreen" },
    { name: 'TIMER', desc: "Let Fit-Timer handle your exercise intervals and reps with audio feedback—no need to fiddle with a stopwatch.", link: "/home/stopwatchonly", img: "", localName: "stopwatch" },
    //{ name: 'FEATURED', desc: "Never get bored with our randomly featured workouts to keep your body active and engaged. ", link: "/home/myworkouts?Load:featured", img: featuredPic, localName: "" },    //{ name: 'CHATS', desc: "Latest Chats", link: '/home/chats', img: chatPic,localName: "" },
    { name: 'PROFILE', desc: "Manage your profile by updating your picture, bio, favorite sports, and privacy settings.", link: '/home/profile', img: oneuserGreen, localName: "" },
    { name: 'PREVIEW', desc: "View your profile through others' eyes.", link: '/home/myprofile', img: "", localName: "eye" },
]

export const defaultAppServiceNames = ['FRIENDS', 'MY WORKOUTS', 'PORTAL', 'CHALLENGES']