import React, { useEffect } from "react";
import Logos from '../../Pics/Logos.png'
const AppleLink = 'https://apps.apple.com/us/app/fit-buddy/id6450426723'
const GoogleLink = 'https://play.google.com/store/apps/details?id=com.fitbuddy.fitbuddy'

export const UpdateNow = () => {

    const openDownloadLink = (link) => {
        try {
            window.ReactNativeWebView.postMessage(`link:${link}`);
        } catch (e) {
        }
    }
    useEffect(() => {
        try {
            if (/Android/.test(navigator.userAgent)) {
                openDownloadLink(GoogleLink)
            } else {
                try {
                    window.ReactNativeWebView.postMessage(`link:${AppleLink}`);
                } catch (e) {
                }
            }
        } catch (e) {

        }
    }, [])

    return (
        <div className="containerDownload">
        <div className="boxDownload">
          <img style={{ height: '120px', marginBottom: '10px' }} className="loginLogo" src={Logos} />
          <h1>FIT-BUDDY</h1>
        </div>
      </div>
    )
}