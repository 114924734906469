import React, { useState, useEffect } from 'react';
import Logos from '../../Pics/Logos.png';
import { Achievements } from './Achievements';



export const TopBadge = ({ setSelectedCount, setSelectedBadge, count, Achievements, }) => {
    const [TopBadgeAchieved, setTopBadgeAchieved] = useState(null)

    useEffect(() => {
        try {
            for (let i = Achievements.length - 1; i >= 0; i--) {
                const achievement = Achievements[i];
                if (count >= achievement.count) {
                    setTopBadgeAchieved(achievement)
                    break;
                }
            }
        } catch (e) {
            setTopBadgeAchieved(null)
        }
    }, [count]);
    if (TopBadgeAchieved) {
        return (
            <div className="achievements-container">
                <div className="topBadgeItemCont" >
                    <img
                        style={{ filter: count >= TopBadgeAchieved.count ? 'grayscale(0)' : 'grayscale(1)' }}
                        onClick={() => { setSelectedBadge(TopBadgeAchieved); setSelectedCount(count) }}
                        src={TopBadgeAchieved.image}
                        alt="Achievement Logo"
                    />
                </div>
            </div>
        );
    }
};
