import React, { useState } from 'react';
import { pics } from '../intButtons/sportPics'; // Import the images for different sports

import { interestData } from '../intButtons/sportPics'

export function SportPicker({ selectedSport, setSelectedSport }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen((prevState) => !prevState);
    };

    const handleSelectedSport = (selectedSport) => {
        setSelectedSport(selectedSport)
    };

    const handleSportSelection = (sport) => {
        handleSelectedSport(sport)
    };

    return (
        <div style={{ padding: '0px 15px', width: 'auto', height: '20px', marginBottom: '5px', fontSize: '12px' }} className="setSportCreatePost fav1" onClick={toggleDropdown}>
            {selectedSport !== null ? <p className="interOnClick" style={{ color: 'black', fontWeight: '500' }}>{selectedSport}</p> : <p className="interOnClick" style={{ color: 'black', fontWeight: '500' }}>Set Sport</p>}
            {isOpen && (
                <div className="dropdownContentContainer">
                    <div className="dropdownContent">
                        <h3 style={{ color: 'white' }} className="dropdownItem" >Select Sport</h3>
                        <div className='favsButtons'>
                            {interestData.map((interest) => (
                                <div
                                    key={interest.name}
                                    type="button"
                                    onClick={() => handleSportSelection(interest.name)}
                                    style={
                                        selectedSport === interest.name
                                            ? { backgroundColor: 'hsl(72, 100%, 47%, 50%)', color: '#f5f5f59c' }
                                            : {}
                                    }
                                >
                                    <img src={localStorage.getItem(interest.name.toLocaleLowerCase()) || interest.img} alt={interest.name} />
                                </div>
                            ))}
                        </div>
                        <p style={{ color: 'black' }} onClick={() => handleSportSelection(null)} className="removeFav dropdownItem" > Clear Sport</p>
                    </div>
                </div>
            )}
        </div>
    );
};

