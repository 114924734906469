export const loadLeadBoards = async (setLoading, setUsers, type) => {
    try {
        setLoading('loading'); // Set loading state to true

        const response = await fetch('/api/leadboards/loadusers', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ type }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch users');
        }

        const data = await response.json();

        if (data && data.users) {
            setUsers(data.users); // Update state with fetched users
        } else {
            setUsers([]); // Clear users state if no data is returned
        }

        setLoading('done'); // Set loading state to false after data fetch
    } catch (error) {
        console.error('Error loading leaderboards:', error);
        setLoading('idle'); // Set loading state to false on error
        // Optionally handle error state or display an error message
    }
};
