import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUsers,
  selectFriends,
  selectstatus,
  selectFinalResults,
  filterUsers
} from "../intButtons/intButtonsSlice";
import { playAdInt, selectData } from "../home/homeSlice";
import { Card } from "./Card";
import "./list.css";
import { NoPlayers } from "./NoPlayers";
import { setMainId } from "./tempProfileSlice";
import { LoadingCards } from '../root/LoadingCards'
import { fetchMoreNonActive, fetchNonActive } from "./listFuncs";
import shareImage from '../../Pics/2024small.png'


export function List({ profID, currentInterest, filterOpened, radius, setShowFilterButton, setBigPic }) {
  const [search, setSearch] = useState('')
  const [timeSearch, setTimeSearch] = useState('')
  const [locationSearch, setLocationSearch] = useState('')
  const [nonActiveList, setNonActiveList] = useState(null)
  const [nonActiveStatus, setNonActiveStatus] = useState('idle')
  const [lastActiveHelper, setLastActiveHelper] = useState(null)
  const [moreNonActiveStatus, setMoreNonActiveStatus] = useState('idle')
  const [canLoadMore, setCanLoadMore] = useState(false)
  const finalResults = useSelector(selectFinalResults)
  const users = useSelector(selectUsers);
  const proFriends = useSelector(selectFriends);
  const data = useSelector(selectData);
  const status = useSelector(selectstatus);
  const dispatch = useDispatch();

  const openTempProfile = (mainid) => {
    dispatch(setMainId(mainid))
  };

  useEffect(() => {
    dispatch(filterUsers({ timeFilter: timeSearch, commentFilter: search, locationFilter: locationSearch }));
  }, [timeSearch, search, locationSearch]);

  useEffect(() => {
    setTimeout(() => {
      if (status === 'done') {
        if (status === 'done' && users && users.length > 0) {
          setShowFilterButton(true)
        } else {
          setShowFilterButton(false)
        }
        setCanLoadMore(true)
      } else if (status === 'loading') {
        setNonActiveStatus('idle')
      }
    }, 200);
  }, [status])

  useEffect(() => {
    if (canLoadMore === true) {
      fetchNonActive(setNonActiveStatus, setNonActiveList, setLastActiveHelper, data.location, currentInterest, radius)
      setCanLoadMore(false)
    }
  }, [canLoadMore])

  const loadMoreNonActive = async () => {
    if (lastActiveHelper !== null && lastActiveHelper !== 0) {
      dispatch(playAdInt())
      fetchMoreNonActive(setMoreNonActiveStatus, setNonActiveList, lastActiveHelper, setLastActiveHelper, data.location, currentInterest, radius)
    }
  }


  if (status === 'idle') {
    return (
      <div className="listjs">
        <div style={{ marginTop: '160px', marginBottom: '5px' }} className="scrollList2">
          <p className="Nosportsenthusiasts">Adjust your prefered radius then Pick Sport to find others interested in that sport</p>
        </div>
      </div>
    )
  }

  if (status === 'done' && users && users.length === 0) {
    return (
      <div className="listjs">
        <div style={{ marginTop: '160px', marginBottom: '5px' }} className="scrollList2">
          <div className="shareContainerList">
            <div className="shareDivList">
              <p className="">No nearby Fit-Buddies planning on {currentInterest}.Help us Grow and share Fit-Buddy</p>
              <NoPlayers time={data.time} preflocation={data.preflocation} interest={currentInterest} />
            </div>
            <img onClick={() => setBigPic(shareImage)} style={{ width: '80%', borderRadius: '8px' }} src={shareImage} />
          </div>
          <p className="nonActiveOthers">Others who have favorited {currentInterest}</p>
          {
            nonActiveStatus === 'done' && nonActiveList ?
              nonActiveList.map((user) =>
                profID !== user.mainid ? (
                  <div onClick={() => openTempProfile(user.mainid)} key={user.mainid}>
                    <Card proFriends={proFriends} user={user} />
                  </div>
                ) : null
              )
              :
              <div>
                {nonActiveStatus === 'loading' ?
                  <LoadingCards /> :
                  ""
                }
              </div>
          }
          {nonActiveStatus === 'done' && lastActiveHelper !== null && lastActiveHelper !== 0 ?
            <div>
              {moreNonActiveStatus === 'loading' ?
                <p style={{ backgroundColor: 'hsla(0, 0%, 15%, 0.5)' }} className="loadMoreByFav fake">Loading . . .</p>
                :
                <p className="loadMoreByFav activeBlack" onClick={loadMoreNonActive}>Load More</p>}
            </div>
            :
            ""
          }
        </div>
      </div>
    )
  }

  return (
    <div className="listjs">
      {filterOpened ?
        <div className="doubleSearch" >
          <div className="seachCont">
            <label htmlFor="comment">TIME</label>
            <input
              className="hint"
              type="text"
              id="search"
              value={timeSearch}
              onChange={(event) => setTimeSearch(event.target.value)}
              maxLength={10}
              placeholder="search"
            />
          </div>
          <div className="seachCont">
            <label htmlFor="comment">DETAILS</label>
            <input
              className="hint"
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              maxLength={17}
              placeholder="search"
            />
          </div>
          <div className="seachContLocation">
            <label htmlFor="comment">LOCATION</label>
            <input
              className="hint"
              type="text"
              id="search"
              value={locationSearch}
              onChange={(event) => setLocationSearch(event.target.value)}
              maxLength={10}
              placeholder="search"
            />
          </div>
        </div>
        : ""
      }
      <div style={{ marginTop: filterOpened ? '225px' : '160px', marginBottom: '5px' }} className="scrollList2">
        {
          status === 'done' ?
            finalResults
              .slice() // Make a copy of the array to avoid modifying the original state
              .sort((a, b) => {
                // Assuming user.time is in the format "hh:mm d/m/y"
                const [timeA, dateA] = a.time.split(" ");
                const [timeB, dateB] = b.time.split(" ");

                // Extracting hours and minutes from time string
                const [hoursA, minutesA] = timeA.split(":");
                const [hoursB, minutesB] = timeB.split(":");

                // Extracting day, month, and year from date string
                const [dayA, monthA, yearA] = dateA.split("/");
                const [dayB, monthB, yearB] = dateB.split("/");

                // Creating date objects for comparison
                const dateObjectA = new Date(yearA, monthA - 1, dayA, hoursA, minutesA);
                const dateObjectB = new Date(yearB, monthB - 1, dayB, hoursB, minutesB);

                // Sorting based on time (earliest to latest)
                return dateObjectA - dateObjectB;
              })
              .map((user) =>
                profID !== user.mainid ? (
                  <div onClick={() => openTempProfile(user.mainid)} key={user.mainid}>
                    <Card proFriends={proFriends} user={user} />
                  </div>
                ) : null
              )
            :
            <LoadingCards />
        }
        <p className="nonActiveOthers">Others who have favorited {currentInterest}</p>
        {
          nonActiveStatus === 'done' && nonActiveList ?
            nonActiveList.map((user) =>
              profID !== user.mainid ? (
                <div onClick={() => openTempProfile(user.mainid)} key={user.mainid}>
                  <Card proFriends={proFriends} user={user} />
                </div>
              ) : null
            )
            :
            <div style={{ marginTop: '10px' }}>
              {nonActiveStatus === 'loading' ?
                <LoadingCards /> :
                ""
              }
            </div>
        }
        {nonActiveStatus === 'done' && lastActiveHelper !== null && lastActiveHelper !== 0 ?
          <div>
            {moreNonActiveStatus === 'loading' ?
              <p style={{ backgroundColor: 'hsla(0, 0%, 15%, 0.5)' }} className="loadMoreByFav fake">Loading . . .</p>
              :
              <p className="loadMoreByFav activeBlack" onClick={loadMoreNonActive}>Load More</p>}
          </div>
          :
          ""
        }
      </div>
    </div>
  );

}
