import React, { createContext, useState } from 'react';
import { defEvent } from '../events/eventsFuncs';

// Create the context
export const RootContext = createContext();

const RootContextProvider = ({ children }) => {
    const [location, setLocation] = useState("waiting");
    const [canShare, setCanShare] = useState(false);
    const [showShare, setShowShare] = useState(false);
    const [subColor, setSubColor] = useState('rgba(255, 0, 0, 0.274)');
    const [didSeeInt, setDidSeeInt] = useState(false);
    const [radius, setRadius] = useState(localStorage.getItem('Radius') || 40);
    const [asktoUpdatePic, setAskToUpdatePic] = useState(false)
    const [textToAsk, setTextToAk] = useState("")
    const [askTopUpdate, setAskTopUpdate] = useState(false);
    const [askForceUpdate, setAskForceUpdate] = useState(false);
    const [realCords, setRealCords] = useState("")
    const [selectedGender, setSelectedGender] = useState("");
    const [version, setVersion] = useState(null);
    const [platform, setPlatform] = useState('');
    const [isinSwipeUser, setIsinSwipeUser] = useState(false);
    const [refLink, setRefLink] = useState()
    const [draftEvent, setDraftEvent] = useState(defEvent)

    const handleShowShare = () => {
        setShowShare(prevShowShare => !prevShowShare);
    };

    return (
        <RootContext.Provider value={{ location, setLocation, canShare, setCanShare, showShare, setShowShare, handleShowShare, subColor, setSubColor, didSeeInt, setDidSeeInt, radius, setRadius, asktoUpdatePic, setAskToUpdatePic, textToAsk, setTextToAk, askTopUpdate, setAskTopUpdate, askForceUpdate, setAskForceUpdate, realCords, setRealCords, selectedGender, setSelectedGender, version, setVersion, platform, setPlatform, isinSwipeUser, setIsinSwipeUser, refLink, setRefLink, draftEvent, setDraftEvent }}>
            {children}
        </RootContext.Provider>
    );
};

export default RootContextProvider;
