import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectData } from "../home/homeSlice";
import { setMainId } from "../list/tempProfileSlice";

export function MiniCard({ user }) {
    const data = useSelector(selectData)
    const dispatch = useDispatch()

    const handleOpenProfile = () => {
        if (user.showto === 'all' || user.showto === data.gender) {
            dispatch(setMainId(user.mainid))
        } else {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Can't Open due to privacy`);

            } catch (e) {
                alert("Can't Open due to gender privacy");
            }
        }
    }

    return (
        <div onClick={handleOpenProfile} className="minicard">
            <img src={user.picture_url} alt={user.name} className="mini-user-picture" />
            <div className="mini-user-details">
                <p className="mini-smallText1">{user.name}</p>
            </div>
        </div>
    );


}
