import React from "react";
import './chatCard.css'
import Logos from '../../Pics/Logos.png'

export function FakeChatCard({ }) {

  return (
    <div className="chat-card">
      <img src={Logos} alt='Fit-Buddy Logo' className="user-picture" />
      <div className="user-details">
        <p className="fake smallText1">Loading...</p>
        <p className="fake smallText2">Loading...</p>
      </div>
    </div>
  );
}
