import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editData, selectData } from '../home/homeSlice'
import { useNavigate } from "react-router-dom";
import { interestData } from "../intButtons/sportPics";
import share from '../../Pics/share.png'
import { TimeInputNew } from "../intButtons/TimeInputNew";
import { pics } from "../intButtons/sportPics";
import { SportPicker } from "../Posts/SportPicke";
import { SportPickerNew } from "./SportPickerNew";
import { updateGroupInterest } from "./groupsFuncs";
import { loadMyGroups, selectMyGroup } from "./groupsSlice";
import { AskPrompet } from "../popUps/AskPrompet";

export function CreateGroup({ }) {
  const [askToConfirmGroup, setAskToConfirmGroup] = useState(false)
  const [creating, setCreating] = useState(false)
  const myInfo = useSelector(selectData);
  const [selectedInt, setSelectedInt] = useState(null);
  const [time, setTime] = useState("");
  const [groupName, setGroupName] = useState(myInfo ? `${myInfo.name.split(' ')[0]}'s Group` : "");
  const [comment, setComment] = useState("");
  const [preflocation, setPreflocation] = useState("");
  const myGroup = useSelector(selectMyGroup)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInt = (interest) => {
    setSelectedInt(interest);
  };


  const handleConfirm = () => {
    try {
      if (myGroup && myGroup.interest) {
        setAskToConfirmGroup(true)
      } else {
        updateGroupInterest(myGroup, groupName, selectedInt, time, comment, preflocation, navigate, dispatch, loadMyGroups, setCreating, editData)
      }
    } catch (e) {
      updateGroupInterest(myGroup, groupName, selectedInt, time, comment, preflocation, navigate, dispatch, loadMyGroups, setCreating, editData)
    }
  }

  const handleYes = () => {
    updateGroupInterest(myGroup, groupName, selectedInt, time, comment, preflocation, navigate, dispatch, loadMyGroups, setCreating, editData)
  }

  const handleNo = () => {
    setAskToConfirmGroup(false)
  }

  return (
    <div style={{ marginTop: '10px', }} className="scrollHelper">
      <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%' }} className="hintCont">
        <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">CREATE GROUP</label>
      </div>
      <div style={{ width: '100%', padding: '0px 10px' }}>
        <div style={{ marginBottom: '10px' }} className="tahCont">
          <div className="hintCont">
            <label htmlFor="comment">NAME</label>
            <input
              className="hint"
              type="text"
              id="groupname"
              value={groupName}
              onChange={(event) => setGroupName(event.target.value)}
              maxLength={50}
              placeholder="Group Name"
            />
          </div>
          <TimeInputNew setTime={setTime} maxTime={48} />
          <div className="hintCont">
            <label htmlFor="comment">LOCATION</label>
            <input
              className="hint"
              type="text"
              id="preflocation"
              value={preflocation}
              onChange={(event) => setPreflocation(event.target.value)}
              maxLength={17}
              placeholder="Location"
            />
          </div>
          <div className="hintCont">
            <label htmlFor="comment">DETAILS</label>
            <input
              className="hint"
              type="text"
              id="comment"
              value={comment}
              onChange={(event) => setComment(event.target.value)}
              maxLength={17}
              placeholder="Details"
            />
          </div>
        </div>
        {askToConfirmGroup ?
          <AskPrompet text={"You Already have an Active Created Group,Creating this group will disband Current Group and Create New"} handleYes={handleYes} handleNo={handleNo} />
          : ""
        }
        {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
          <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
        </div> : ""}
        <SportPickerNew selectedSport={selectedInt} setSelectedSport={setSelectedInt} height="110px" width="110px" fontSize="20px" />
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center' }} className="radandconf">
          <div style={{ margin: 'auto', padding: '15px 0px', width: '80%' }} className="confirmInts" type="button" onClick={handleConfirm}>
            {selectedInt ? `CREATE ${selectedInt.toUpperCase()} GROUP` : 'CHOOSE SPORT'}
          </div>
        </div>
        <div style={{ height: '100px' }} />
      </div>
    </div>
  );
}
