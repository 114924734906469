import React, { useState, useEffect } from "react";
import { defaultAppServiceNames, services as initialServices } from "./appServicesFuncs";
import './appServices.css';
import { useNavigate } from "react-router-dom";
import infoImg from '../../Pics/info.png';
import { EditAppServecis } from "./EditAppServecis";


export function AppServices({ showMore }) {
    const savedServiceNames = JSON.parse(localStorage.getItem('chosenServices')) || [];
    const initialServicesSubset = savedServiceNames.map(name => initialServices.find(service => service.name === name));
    const [services, setServices] = useState(initialServicesSubset);
    const [showDesc, setShowDesc] = useState(null);
    const [isEdeting, setIsEdeting] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const initialServicesSubset = savedServiceNames.map(name => initialServices.find(service => service.name === name));
        setServices(initialServicesSubset);

    }, [localStorage.getItem('chosenServices')]);

    return (
        <div className="appServicesCont">
            {/*<h3>APP SERVICES</h3> */}
            {showDesc ?
                <div onClick={() => setShowDesc(null)} className="descContPage">
                    <div style={{ position: 'fixed' }} className="descCont">
                        <p style={{ color: 'white', textAlign: "left" }}>{showDesc}</p>
                    </div>
                </div>
                : null
            }
            {showMore && (
                <img onClick={() => setIsEdeting(true)} className="editImgAppServ" src={localStorage.getItem('editGreen')} alt="Edit" />
            )}
            {isEdeting && (
                <EditAppServecis setServices={setServices} setShowDesc={setShowDesc} setIsEdeting={setIsEdeting} />
            )}
            {services.length > 0 ? (
                services.map((service) => (
                    <div className="activeBlack appServiceButton" key={service.name}>
                        {service.desc && (
                            <img onClick={() => setShowDesc(service.desc ? service.desc : null)} className="appServiceButtonInfoImg" src={infoImg} alt="Info" />
                        )}
                        <div onMouseUp={() => navigate(service.link)} className="appServiceButtonLogoCont">
                            <img  className="appServiceButtonLogo" src={localStorage.getItem(service.localName) || service.img} alt={service.name} />
                            <h5>{service.name}</h5>
                        </div>
                    </div>
                ))
            ) : (
                <p style={{color:'white'}}>No shortcuts selected yet.</p>
            )}
        </div>
    );
}
