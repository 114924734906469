import React from "react";
import Logos from '../../Pics/Logos.png'

export function FakeCarD({ }) {
    return (
        <div className={`fake Card`}>
            <div className={`cardPP`}/>
            <div className={`fake cardName`}>Loading</div>
            <div className={`fake cardgendandage`}> Loading</div>
            <div className={`fake cardTimeCont`}>
                Loading
            </div>
            <div className={`fake cardComment`}>
  
            </div>
            <div className={`fake cardLocation`}>
            
            </div>
            <div className={`fake repInCard`}>

            </div>
        </div>
    )
}

export function LoadingCards({ }) {


    return (
        <div style={{ marginTop: '0px', marginBottom: '25px' }} className="scrollList">
            <FakeCarD />
            <FakeCarD />
            <FakeCarD />
            <FakeCarD />            
        </div>

    );
}

