import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPosts } from '../myPage/myPageFuncs'
import { Post } from "../Posts/Post";
import { useSwipeable } from "react-swipeable";
import { FakePost } from "../Posts/FakePost";
import { handleShowBottomNav, handleShowTopNav, selectData } from "../home/homeSlice";
import { useNavigate, useParams } from "react-router-dom";



export function UserPosts({ passedID }) {
    const [loading, setLoading] = useState(null)
    const [posts, setPosts] = useState(null)
    const data = useSelector(selectData);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let { mainid } = useParams();
    const scrollRef = useRef(0);
    const previousScrollTopRef = useRef(0);

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };
    const handleSwipeLeft = () => {
        navigate(-1)
    };


    const swipeBackHandlers = useSwipeable({
        onSwipedRight: handleSwipeRight,
        onSwipedLeft: handleSwipeLeft
    });
    const swipeBackHandlers2 = useSwipeable({
        onSwipedRight: handleSwipeRight,
        onSwipedLeft: handleSwipeLeft

    });

    useEffect(() => {
        try {
            if (passedID) {
                console.log(passedID)
                fetchUserPosts(passedID, setLoading, setPosts);
            } else {
                let id = mainid.replace(":", "");
                fetchUserPosts(id, setLoading, setPosts);
            }
        } catch (e) {

        }
    }, [mainid, passedID]);


    useEffect(() => {
        if (loading === 'failed') {
            navigate(-1)
        }
    }, [loading])

    useEffect(() => {
        try {
            //const scrollRef = document.getElementById('feedsCont');
            const scrollElement = scrollRef.current;
            if (!scrollElement) return;
            const handleScroll = () => {
                const scrollHeight = scrollElement.scrollHeight;
                const scrollTop = scrollElement.scrollTop;
                const clientHeight = scrollElement.clientHeight;
                console.log(scrollHeight)
                console.log(scrollTop)
                console.log(clientHeight)


                const previousScrollTop = previousScrollTopRef.current;
                const isScrollingDown = scrollTop > previousScrollTop;
                const isScrollingUp = scrollTop < previousScrollTop;

                if (isScrollingUp) {
                    dispatch(handleShowBottomNav(true));
                    dispatch(handleShowTopNav(true));
                } else if (isScrollingDown && (scrollTop > (10))) {
                    dispatch(handleShowBottomNav(false));
                    dispatch(handleShowTopNav(false));

                }

                previousScrollTopRef.current = scrollTop;
            };

            scrollElement.addEventListener('scroll', handleScroll);

            return () => {
                // Cleanup: Remove the event listener when the component unmounts
                scrollElement.removeEventListener('scroll', handleScroll);
            };

        } catch (e) {

        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(handleShowBottomNav(true))
            dispatch(handleShowTopNav(true))
        }
    })

    return (
        <div {...swipeBackHandlers2} style={{ zIndex: '12', top: '0px', height: '100vh' }} id="profileCont" className={passedID ? "" : "feedPostsCont"}>
            <div ref={scrollRef} id="feedsCont" style={{ zIndex: '20', paddingTop: window.location.pathname.includes('/home/posts/:') ? '45px' : "0px", marginTop: '0px', marginBottom: '0px' }} className="scrollList">
                {loading === 'done' && posts.length === 0 ?
                    <div style={{ height: '50px', paddingTop: '10px' }} className="">
                        <h2 className="" style={{ color: 'white', margin: 'auto auto ' }}>No posts available</h2>
                    </div>
                    : ""}
                {loading === 'done' && posts ? (
                    posts.map((post) => (
                        <Post thumb={post.thumb} key={post.postid} postid={post.postid} image={post.postphoto} creatorid={post.creatorid} creatorname={post.creatorname} creatorpicture={post.creator_picture_url} createTime={post.time} caption={post.caption} sport={post.sport} taggedid={post.taggedid} taggedname={post.taggedname} likes={post.likes} myMainid={data.mainid} showtagged={post.showtagged} setRequest={""} hiddenby={post.hiddenby} commentscount={post.commentscount} likescount={post.likescount} />
                    ))
                ) : (
                    <div>
                        <FakePost key={'2'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
                        <FakePost key={'3'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
                    </div>
                )}
                <div style={{ height: '150px' }} />
            </div>
        </div>
    )
}


