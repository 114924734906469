import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleEditPP } from "./proFuncs";
import { loadHome, enableRefresh, disableRefresh } from "../home/homeSlice";

import styles from "./profile.css";

export function ChangePP({ PPMod, setPPMod }) {
  const [newPP, setNewPP] = useState("");
  const componentRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const handleOutsideClick = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          setPPMod(false);
        }
      };

      const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

      document.addEventListener(clickEvent, handleOutsideClick);

      return () => {
        document.removeEventListener(clickEvent, handleOutsideClick);
      };
    } catch (e) {

    }
  }, []);

  const openEditPP = () => {
    if (PPMod === false) {
      dispatch(disableRefresh())
    }
    if (PPMod === true) {
      dispatch(enableRefresh())
    }
    setPPMod((PPMod) => !PPMod);

  };

  const confimPP = () => {
    handleEditPP(newPP, dispatch, loadHome);
  };

  const openUploadMenue = () => {
    try {
      window.ReactNativeWebView.postMessage("Photo:profile");
    } catch (e) {

    }
  };

  return (
    <div ref={componentRef} style={{ paddingBottom: '20px', position: 'fixed' }} className="editInfoCont">
      <h3 >Add Your Porfile Picture</h3>
      <h3>Upload</h3>
      <div>
        <p>Upload a 1:1 photo of yourself</p>
        <button className="cancelButton" onClick={openUploadMenue}>
          Choose
        </button>
      </div>
      <br />
      <h3>Or Add Link</h3>
      <p>Add a link to a photo of yourself with 1:1 ratio</p>
      <input
        className="bioInput"
        placeholder="Pasete image link "
        type="text"
        value={newPP}
        maxLength="400"
        onChange={(event) => setNewPP(event.target.value)}
      />
      <div>
        <button className="confirmButton" onClick={confimPP}>
          Confirm
        </button>
        <button className="cancelButton" onClick={openEditPP}>
          Cancel
        </button>
      </div>
    </div>
  );
}
