import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportGroup } from './ReportGroup';
import repImg from '../../Pics/repImg.png'
import edit from '../../Pics/edit.png'
import StarRatings from 'react-star-ratings';
import { TimeInputClean } from '../intButtons/TimeInputClean';
import close from "../../Pics/close.png";
import { confirmGroupMeeting, setGroupMeetingTime } from './groupsFuncs';
import { useNavigate } from 'react-router-dom';
import { editData } from '../home/homeSlice';


export function GroupMeetingTime({ group, groupStatus, myMainid, setCreating }) {
    const [showEditTime, setShowEditTime] = useState(false)
    const [canConfirm, setCanConfirm] = useState(false)
    const [time, setTime] = useState(group.meetingtime ? (new Date(group.meetingtime * 1000).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Use 12-hour time format
        day: '2-digit',
        month: 'short', // Use abbreviated month name (e.g., "Jan")
    })) : "")
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {
        try {
            const currentDate = Date.now()
            const timeOffset = 60 * 60 * 1000
            const timeToCheckInSec = ((currentDate - timeOffset) / 1000)
            if (group.meetingtime && (group.meetingtime < timeToCheckInSec)) {
                setCanConfirm(true)
            }
        } catch {

        }

    }, [group])

    const handleSetMeetingTime = async () => {
        setGroupMeetingTime(time, setCreating, setShowEditTime)
    }

    const handleConfirmGroupMeeting = async () => {
        confirmGroupMeeting(setCreating, navigate)
        dispatch(editData({ groupinterest: null }))
    }


    if (canConfirm === true) {
        if (groupStatus === 'owner') {
            return (
                <div className='activeBlack GroupMeetingTimeCont'>
                    <p onClick={handleConfirmGroupMeeting} className='activeBlack singleButton' style={{ width: '150px', padding: '5px 5px', fontSize: '10px' }}>Confirm This Group Meeting</p>
                    <p onClick={() => { setShowEditTime(true); setCanConfirm(false) }} className='activeBlack singleButton2' style={{ width: '100px', padding: '5px 5px', fontSize: '10px' }}>Set Other Time</p>
                </div>
            )
        } else if (groupStatus === 'accepted') {
            return (
                <div className='activeBlack GroupMeetingTimeCont'>
                    <p>Waiting Leader To Confirm Workout </p>
                </div>
            )
        }
    } else {
        if (groupStatus === 'owner') {
            return (
                <div className='activeBlack GroupMeetingTimeCont'>
                    {group.meetingtime && showEditTime === false ?
                        <p onClick={() => { setShowEditTime(true) }} style={{ width: '100%', color: 'hsl(72, 100%, 47%)' }}>Meeting at {(new Date(group.meetingtime * 1000).toLocaleString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true, // Use 12-hour time format
                            day: '2-digit',
                            month: 'short',// Use abbreviated month name (e.g., "Jan")
                        }))} </p>
                        :
                        <p onClick={() => { setShowEditTime(true) }}>{showEditTime ? "" : "Agreed Group Meeting Time Not Set Yet"}</p>
                    }
                    {showEditTime ?
                        <div style={{ width: '100%', display: 'flex' }}>
                            <TimeInputClean setTime={setTime} maxTime={48} />
                            <p onClick={handleSetMeetingTime} className='singleButton' style={{ width: '70px', padding: '5px', fontSize: '10px' }}>Confirm</p>
                            <img style={{ filter: 'invert(1)', width: '20px', marginRight: '20px' }} src={close} onClick={() => setShowEditTime(false)} />
                        </div>
                        :
                        ""
                    }
                </div >
            )
        } else if (groupStatus === 'accepted') {
            return (
                <div className='GroupMeetingTimeCont'>
                    {group.meetingtime ?
                        <p style={{ color: 'hsl(72, 100%, 47%)' }}>Meeting at {(new Date(group.meetingtime * 1000).toLocaleString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: true, // Use 12-hour time format
                            day: '2-digit',
                            month: 'short'// Use abbreviated month name (e.g., "Jan")
                        }))} </p>
                        :
                        <p>Group Didn't agree on Meeting Time Yet </p>
                    }
                </div>
            )
        }
    }

}