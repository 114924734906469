import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom'
import { handleEditGender } from "../profile/proFuncs";
import { loadHome, editData, disableRefresh } from "../home/homeSlice";
import { ImageLinkChanger } from '../profile/ImageLinkChanger';
import { Fav1 } from '../profile/Fav1';
import { Fav2 } from '../profile/Fav2';


export function NameFix({ }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(disableRefresh())
  }, [])

  return (
    <div style={{ height: 'calc(100vh - 104px)' }} className="containerLogin">
      <div style={{ borderRadius: '15px', padding: '20px 15px 10px 20px' }} className='boxLogin'>
        <h3 style={{ marginBottom: '10px' }} >Fix Name Issue</h3>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Your name is null null</h4>
        <p style={{ marginLeft: '0px' }} className="disclaimer">This happens when you sign up with Apple and delete Your FitBuddy profile, as Apple provide the name one time on register.</p>
        <h3 style={{ marginBottom: '10px', marginLeft: '0px' }} >To Fix This Issue:</h3>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Step 1:</h4>
        <p className="disclaimer">Go to Settings on your Ios Device -> Click on Your Apple ID -> Sign-In & Security -> Sign in With Apple -> Click on FitBuddy -> Stop using Apple ID</p>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Step 2:</h4>
        <p style={{ marginLeft: '0px' }} className="disclaimer">Delete Your Profile<br /> Click on the Menu button at the top left -> Click Help -> Scroll to Bottom and Click Delete Profile</p>
        <h4 style={{ color: 'black', marginBottom: '5px', marginLeft: '0px' }} >Step 3:</h4>
        <p className="disclaimer">Sign Up With Apple and your name should be fixed.</p>
      </div>
    </div >
  );
}