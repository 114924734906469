import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const loadChats = createAsyncThunk(
  'chats/loadChats',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/profile/chats`);
    const json = await data.json();
    return json;
  }
);

export const chatsSlice = createSlice({
  name: 'chats',
  initialState: {
    chats: null,
    status: 'idle',
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadChats.pending, (state) => {
        if (state.data === null) {
          state.status = 'loading';
        }
      })
      .addCase(loadChats.fulfilled, (state, action) => {
        state.status = 'done';
        state.chats = action.payload;
        console.log(action.payload)
      })
      .addCase(loadChats.rejected, (state, action) => {
        state.status = 'failed';
        state.chats = null;
      })
  },
});

export const selectChats = (state) => state.chats.chats;
export const selectStatus = (state) => state.chats.status;
export default chatsSlice.reducer;


