import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import like from '../../Pics/like.png'
import liked from '../../Pics/liked.png'
import { ReportPost } from './ReportPost';
import repImg from '../../Pics/repImg.png'


import './post.css'

export function FakePost({ postid, image, creatorid, creatorname, createTime, caption, sport, taggedid, taggedname, likes, myMainid, showtagged, setRequest }) {
    const [postDeleted, setPostDeleted] = useState(false)
    const [isShowTagged, setIsShowTagged] = useState(showtagged);
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [likedByMe, setLickedByMe] = useState(false)
    const [dynamicCount, setDynamicCount] = useState(likes.length)
    const dispatch = useDispatch();



    return (
        <div className='postCont'>
            {window.location.pathname === '/home/postcreator' || window.location.pathname === '/home/mypage' ? "" : <img onClick={() => setShowReport(true)} className='repImgInPost' src={repImg} />}
            {showReport ? <ReportPost creatorid={creatorid} creatorname={creatorname} postid={postid} setShowReport={setShowReport} setPostDeleted={setPostDeleted} /> : ""}
            <div className='postCreatorInfo'>
                <div style={{ height: '40px' }} className='fakeBackGround creatorProfile' />
                <div style={{ marginLeft: '0px' }}>
                    <p style={{ textAlign: 'left', lineHeight: '1.3' }} className='fake'>{creatorname}</p>
                    <p style={{ fontSize: '11px', textAlign: 'left' }} className='fake'>{createTime}</p>
                </div>
            </div>
            <div loading='lazy' style={{ width: 'calc(100%)', height: 'calc(100vh - 100px)', marginBottom: '10px', backgroundColor: "hsl(0, 0%, 20%)" }} src={image} />
            <div className='postSportandTage'>
                <div className='doubleTag'>
                    <p className='fake'>{creatorname} </p>
                    {isShowTagged && taggedid ? <span className='fake'>with</span> : ""}
                    {isShowTagged ? <p> {taggedname}</p> : ""}

                </div>
            </div>
            {sport !== 'null' || sport !== null ?
                <div className='postSportandTage'>

                    <div className='doubleTag'>
                        {sport === 'null' || sport === null ? "" : <span className='fake'>played</span>}
                        {sport == 'null' ? "" : <p className='fake'> {sport}</p>}
                    </div>

                </div>
                :
                ""}
            {caption ? <p className='fake postCaption'>{caption}</p> : ''}
            <div style={{ display: 'flex' }}>
                <div className='likeCont'>
                    <img src={likedByMe ? liked : like} />
                    <p>{dynamicCount ? dynamicCount : 0}</p>
                </div>
            </div>
        </div>
    )
}