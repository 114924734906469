import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MySingleStudent } from './MySingleStudent';
import { DiscoverCoaches } from './DiscoverCoaches';
import './coachesNearby.css'
import { MyCoaches } from './MyCoaches';
import { selectData } from '../home/homeSlice';

export const CoachesNearby = ({ }) => {
    const myInfo = useSelector(selectData)
    const [updating, setUpdating] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(localStorage.getItem('CoachesNearbyFilter') || "Discover");
    const dispatch = useDispatch()

    const handleFilter = async (filter) => {
        setSelectedFilter(filter)
        localStorage.setItem('CoachesNearbyFilter', filter);
    }

    return (
        <div style={{ top: '0px', padding: '10px 0px 0px 0px'}} id="profileCont" className="feedPostsCont">
            <div id="feedsCont" style={{ paddingTop: '45px', marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">
                <div style={{ padding: '0px 15px', gap: '20px', borderRadius: '0px' }} className="feedFilter">
                    <label className="activeBlack" onClick={() => handleFilter('MyCoaches')} style={{ color: selectedFilter === 'MyCoaches' ? "" : '#f5f5f59c', fontSize: '17px' }} htmlFor="comment">My Coaches</label>
                    <label className="activeBlack" onClick={() => handleFilter('Discover')} style={{ color: selectedFilter === 'Discover' ? "" : '#f5f5f59c', fontSize: '17px' }} htmlFor="comment">Discover</label>
                </div>
                {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                    <p className='fake'> Confirming...</p>
                </div> : ""}
                {selectedFilter === 'MyCoaches' ? (
                    <MyCoaches myMainid={myInfo.mainid} />
                ) : null}
                {selectedFilter === 'Discover' ?
                    <DiscoverCoaches myMainid={myInfo.mainid} />
                    :
                    ''
                }
            </div>
        </div>
    );
};
