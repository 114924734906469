import countSound from './countDown.mp3';
import ticking from './ticking.mp3'
import stopBeep from './stopBeep.mp3';

export const loadAudios = () => {
    //localStorage.setItem('countSoundAudio', countSound);
    //localStorage.setItem('tickingAudio', ticking);
    //localStorage.setItem('stopBeepSoundAudio', stopBeep);
    try {
        const countSoundAudioURL = localStorage.getItem('countSoundAudio');
        if (countSoundAudioURL) {
            //console.log('Sound saved')
        } else {
            localStorage.setItem('countSoundAudio', countSound);
        }
    } catch (e) {

    }
    try {
        const tickingAudioURL = localStorage.getItem('tickingAudio');
        if (tickingAudioURL) {
            //console.log('Sound saved')
        } else {
            localStorage.setItem('tickingAudio', ticking);
        }
    } catch (e) {
    }
    try {
        const stopBeepAudiUrl = localStorage.getItem('stopBeepSoundAudio');
        if (stopBeepAudiUrl) {
            //console.log('Sound saved')
        } else {
            localStorage.setItem('stopBeepSoundAudio', stopBeep);
        }
    } catch (e) {
    }
}
//const countSoundAudio = new Audio(localStorage.getItem('countSoundAudio'));
//const tickingAudio = new Audio(localStorage.getItem('tickingAudio'));
//const stopBeepAudio = new Audio(localStorage.getItem('stopBeepSoundAudio'));

