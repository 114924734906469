import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setMainId } from "../list/tempProfileSlice";
import { TimeViewInMeeting } from './TimeViewInMeeting'
import './meetUpCard.css'
import socket from "../../app/io";
import deleteImg from '../../Pics/delete.png'
import { clearInfo } from './tempMeetingPageSlice';


export function MeetUpCard({ meetingid, creatorid, creatorname, createtime, sport, taggedid, taggedname, myMainid, notifytime, meetingtime, finishedtime, state, fetchMyMeetings }) {
    const [isState, setIsState] = useState(state)
    const [meetingDeleted, setMeetingDeleted] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        socket.on("meetingstatechange", ({ iomeetingid, state }) => {
            try {
                if (iomeetingid === meetingid && state === 'deleted') {
                    setMeetingDeleted(true)
                    dispatch(clearInfo())
                    try {
                        if (window.location.pathname === '/home/myworkouts') {
                            fetchMyMeetings()
                        }
                    } catch (e) {

                    }
                } else if (iomeetingid === meetingid && state !== 'deleted') {
                    setIsState(state)
                    try {
                        if (window.location.pathname === '/home/myworkouts') {
                            fetchMyMeetings()
                        }
                    } catch (e) {

                    }
                }
            } catch (e) {

            }
        });

        return () => {
            socket.off("meetingstatechange");
        };
    }, []);

    const photoPicker = (int) => {
        try {
            const picked = int.toLowerCase()
            return localStorage.getItem(picked)
        } catch (e) {

        }
    }

    useEffect(() => {
        if (isState !== 'confirmed') {
            if (isState === 'awaiting') {
                const currentTime = Math.floor((Date.now() / 1000))
                if (currentTime > notifytime || notifytime === null) {
                    setIsState('readysoon')
                }
            }
        }
    }, [isState])

    useEffect(() => {
        if (isState !== 'confirmed') {
            if (isState === 'readysoon') {
                const currentTime = Math.floor((Date.now() / 1000))
                if (currentTime > meetingtime) {
                    setIsState('inmeeting')
                }
            }
        }
    }, [isState])

    useEffect(() => {
        if (isState !== 'confirmed') {
            if (isState === 'inmeeting') {
                const currentTime = Math.floor((Date.now() / 1000))
                if (currentTime > finishedtime) {
                    setIsState('canconfirm')
                }
            }
        }
    }, [isState])


    useEffect(() => {
        try {
            if (isState !== 'confirmed') {
                const interval = setInterval(() => {
                    if (isState === 'awaiting') {
                        const currentTime = Math.floor((Date.now() / 1000))
                        if (currentTime > notifytime || notifytime === null) {
                            setIsState('readysoon')
                        }
                    }
                    if (isState === 'readysoon') {
                        const currentTime = Math.floor((Date.now() / 1000))
                        if (currentTime > meetingtime) {
                            setIsState('inmeeting')
                        }
                    }
                    if (isState === 'inmeeting') {
                        const currentTime = Math.floor((Date.now() / 1000))
                        if (currentTime > finishedtime) {
                            setIsState('canconfirm')
                        }
                    }
                }, 60000);

                return () => {
                    clearInterval(interval);
                };
            }
        } catch (e) {

        }

    }, []);


    const openTempProfile = (mainid) => {
        if (window.location.pathname === '/home/myworkouts') {
            dispatch(setMainId(mainid))
        } else {
            return
        }

    };



    async function handleAcceptMeeting(meetingid, taggedid, creatorid, notifytime, meetingtime, finishedtime) {
        setUpdating(true)
        fetch(`/api/meetings/acceptmeeting`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ meetingid: meetingid, taggedid: taggedid, creatorid: creatorid, notifytime: notifytime, meetingtime: meetingtime, finishedtime: finishedtime }),
        })
            .then((response) => {
                if (response.ok) {
                    setIsState('awaiting');
                    setUpdating(false)
                    try {
                        if (window.location.pathname === '/home/myworkouts') {
                            fetchMyMeetings()
                        }
                    } catch (e) {

                    }
                } else {
                    setUpdating(false)
                    try {
                        window.ReactNativeWebView.postMessage(`Alert:You missed this workout, it will be deleted.`);

                    } catch (e) {
                        alert("You missed this workout, it will be deleted.");
                    }
                    handleMeetingDelete(meetingid, creatorid, taggedid)
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error saving interest:", error);
            });
    }

    async function handleConfirmMeeting(meetingid, taggedid, creatorid, sport) {
        setUpdating(true)
        fetch(`/api/meetings/confirmmeeting`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ meetingid: meetingid, taggedid: taggedid, creatorid: creatorid, sport: sport }),
        })
            .then((response) => {
                if (response.ok) {
                    setIsState('confirmed');
                    setUpdating(false)
                    try {
                        if (window.location.pathname === '/home/myworkouts') {
                            fetchMyMeetings()
                        }
                    } catch (e) {

                    }
                } else {
                    setUpdating(false)
                    console.error("Failed to save interest");
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error saving interest:", error);
            });
    }

    async function handleMeetingDelete(meetingid, creatorid, taggedid) {
        setUpdating(true)
        fetch(`/api/meetings/deletemeeting`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ meetingid: meetingid, creatorid: creatorid, taggedid: taggedid }),
        })
            .then((response) => {
                if (response.ok) {
                    setUpdating(false)
                    dispatch(clearInfo())
                    try {
                        window.ReactNativeWebView.postMessage(`Alert:Workout abandoned`);
                    } catch (e) {

                    }
                    try {
                        if (window.location.pathname.includes('/home/myworkouts')) {
                            fetchMyMeetings()
                        }
                    } catch (e) {
                        console.log(e)
                    }
                } else {
                    setUpdating(false)
                    console.error("Failed to remove tag");
                }
            })
            .catch((error) => {
                setUpdating(false)
                console.error("Error removing tag:", error);
            });
    }

    //async function handleRemoveTag(postid, taggedid, creatorid) {
    //    setUpdating(true)
    //    fetch(`/api/post/removetag`, {
    //        method: "PUT",
    //        headers: {
    //            "Content-Type": "application/json",
    //        },
    //        body: JSON.stringify({ postid: postid, taggedid: taggedid, creatorid: creatorid }),
    //    })
    //        .then((response) => {
    //            if (response.ok) {
    //                setIsShowTagged(false);
    //                setUpdating(false)
    //            } else {
    //                setUpdating(false)
    //                console.error("Failed to remove tag");
    //            }
    //        })
    //        .catch((error) => {
    //            setUpdating(false)
    //            console.error("Error removing tag:", error);
    //        });
    //}
    //



    if (meetingDeleted === true) {
        return (
            ""
        )
    }

    return (
        <div style={{ border: isState === 'confirmed' ? '1px solid hsl(72, 100%, 47%)' : '1px solid grey' }} className='meetingCardCont' >
            {updating ? <div className='CreatingPost'>
                <p className='fake'> Loading..</p>
            </div> : ""}
            <div className='meetingCardInfo'>
                <div className='doubleTagInMeeting'>
                    <p style={{ color: 'white' }} onClick={() => openTempProfile(creatorid)}>{creatorname} </p>
                    <p style={{ color: 'white', marginLeft: '0px' }} onClick={() => openTempProfile(taggedid)}> {taggedname}</p>
                </div>
                <div className='timeInMeeting'>
                    <TimeViewInMeeting time={createtime} />
                </div>
                <img className='bigCardFav' style={{ height: '30px', width: '30px' }} src={photoPicker(sport)} />
            </div>
            {isState !== 'confirmed' ?
                <div style={{ display: 'flex', marginTop: '10px', marginRight: '10px', gap: '10px' }}>
                    {taggedid === myMainid && isState === 'pending' ? <p style={{ padding: '3px 10px', marginLeft: '10px', backgroundColor: 'hsla(72, 100%, 47%, 65%)' }} onClick={() => handleAcceptMeeting(meetingid, taggedid, creatorid, notifytime, meetingtime, finishedtime)} className='smallPosbuttons'>Accept Meeting</p> : ""}
                    {creatorid === myMainid && isState === 'pending' ? <p style={{ padding: '3px 10px', marginLeft: '10px' }} className='smallPosbuttons'>Waiting for {taggedname} to accept</p> : ""}
                    {isState === 'awaiting' ?
                        <div style={{ display: 'flex' }} className='smallPosbuttons'>
                            <p style={{ marginRight: '5px' }}>Your Workout is set at </p>
                            <TimeViewInMeeting time={createtime} />
                        </div>
                        :
                        ""
                    }
                    {isState === 'readysoon' ? <p style={{ padding: '3px 10px', marginLeft: '10px' }} className='smallPosbuttons'>Your Workout Start's Soon</p> : ""}
                    {isState === 'inmeeting' ? <p style={{ padding: '3px 10px', marginLeft: '10px' }} className='smallPosbuttons'>In Workout now, Confirm it after you finsih.</p> : ""}
                    {isState === 'canconfirm' ?
                        <div style={{ display: 'flex', gap: '15px' }}>
                            <p style={{ padding: '3px 12px' }} className='smallPosbuttons'>Did you meet?</p>
                            <p style={{ backgroundColor: 'hsla(72, 100%, 47%, 65%)' }} onClick={() => handleConfirmMeeting(meetingid, taggedid, creatorid, sport)} className='smallPosbuttons'>Yes</p>
                            <p onClick={() => { handleMeetingDelete(meetingid, creatorid, taggedid) }} className='smallPosbuttons'>No</p>
                        </div>
                        :
                        ""
                    }
                    <img onClick={() => { handleMeetingDelete(meetingid, creatorid, taggedid) }} style={{ height: '25px', marginRight: '0px' }} src={deleteImg} />
                </div>
                :
                ""
            }
        </div>
    )
}