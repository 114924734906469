import { useEffect } from "react";
import socket from "../../app/io";
import { loadGroupPage } from "./groupsFuncs";


export function ResetGroupHelper({ group, setGroup, setLoadingGroup }) {
    useEffect(() => {
        socket.on("resetGroup", ({ groupPage }) => {
            console.log('should reset')
            if (window.location.pathname === `/home/groups/group/:${groupPage}`) {

                loadGroupPage(groupPage, group, setGroup, setLoadingGroup)
            } else {
                console.log(groupPage)
            }
        });

        return () => {
            socket.off("resetGroup");
        };
    }, []);

    return (
        null
    )

}