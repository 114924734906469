import React, { useState } from "react";
import { defaultAppServiceNames, services as initialServices } from "./appServicesFuncs";
import './appServices.css';
import { useNavigate } from "react-router-dom";
import infoImg from '../../Pics/info.png';

export function EditAppServecis({ setServices, setShowDesc, setIsEdeting }) {
    const savedServiceNames = JSON.parse(localStorage.getItem('chosenServices')) || [];
    const initialServicesSubset = savedServiceNames.map(name => initialServices.find(service => service.name === name));
    const [selectedServices, setSelectedServices] = useState(initialServicesSubset);
    const navigate = useNavigate();

    const handleToggleService = (service) => {
        const isSelected = selectedServices.some(s => s.name === service.name);
        let updatedServices;
        if (isSelected) {
            updatedServices = selectedServices.filter(s => s.name !== service.name);
        } else {
            updatedServices = [...selectedServices, service];
        }
        setSelectedServices(updatedServices);
        localStorage.setItem('chosenServices', JSON.stringify(updatedServices.map(s => s.name)));
    };

    const handleDone = () => {
        setIsEdeting(false);
    };

    const handleServiceClick = (service) => {
        handleToggleService(service);
    };

    return (
        <div style={{ flexDirection: 'column' }} className="fullScreenBlack">
            <div className="appServicesCont">
                <p style={{ width: '100%', color: 'white',marginBottom:'5px' }}>Choose shortcuts in order you desire. </p>
                {initialServices.map((service) => {
                    const isSelected = selectedServices.some(s => s.name === service.name);
                    const order = isSelected ? selectedServices.findIndex(s => s.name === service.name) + 1 : null;

                    return (
                        <div
                            style={{ filter: isSelected ? "" : 'grayscale(1)' }}
                            className="appServiceButton"
                            key={`${service.name}-edit`}
                        >
                            {service.desc && (
                                <img
                                    onClick={() => setShowDesc(service.desc ? service.desc : null)}
                                    className="appServiceButtonInfoImg"
                                    src={infoImg}
                                />
                            )}
                            {isSelected && (
                                <p className="appServiceOrderEdit">{order}</p>
                            )}
                            <div onClick={() => handleServiceClick(service)} className="appServiceButtonLogoCont">
                                <img className="appServiceButtonLogo" src={localStorage.getItem(service.localName) || service.img} />
                                <h5>{service.name}</h5>
                            </div>
                        </div>
                    );
                })}
            </div>
            <p onClick={handleDone} className="singleButton">Done</p>
        </div>
    );
}
