import React, { useEffect, useState } from 'react';

import back from '../../Pics/back.png'
import { CoachCard } from './CoachCard';
import { useDispatch, useSelector } from 'react-redux';
import { editMyCoachProfileData, loadMyCoach, loadMyCoachBigData, selectMyCoachBigDataStatus, selectMyCoachProfile, selectMyCoachProfileStatus, selectMyExperiences, selectMyReviews, selectMyStudents } from './coachesSlice';
import Slider from 'react-slick';
import { CoachExperiencsCard } from './CoachExperiencsCard';
import { CreateOrEditExperience } from './CreateOrEditExperience';
import { EditCoachProfile } from './EditCoachProfile';
import { applyAsCoach, emptyExp } from './coachesFuncs';
import { SingleReview } from './SingleReview';
import { MyStudents } from './MyStudents';
import { selectData } from '../home/homeSlice';
import { useSwipeable } from "react-swipeable";
import { useNavigate } from 'react-router-dom';
import socket from "../../app/io";
import { FakeCoachCard } from './FakeCoachCard';
import { BigCoachCard } from './BigCoachCard';
import { UserPosts } from '../user/UserPosts';

export function CoachAccount({ }) {
    const [type, setType] = useState('exp')
    const [isCoach, setIsCoach] = useState(false)
    const [remainingDays, setRemainingDays] = useState(0);
    const [showExp, setShowExp] = useState(false)
    const [showRevs, setShowRevs] = useState(false)
    const [showStudents, setShowStudents] = useState(true)
    const myInfo = useSelector(selectData)
    const myCoachProfile = useSelector(selectMyCoachProfile)
    const myCoachProfileStatus = useSelector(selectMyCoachProfileStatus)
    const myCoachBigDataStatus = useSelector(selectMyCoachBigDataStatus)
    const [creating, setCreating] = useState(false)
    const experiences = useSelector(selectMyExperiences)
    const students = useSelector(selectMyStudents)
    const reviews = useSelector(selectMyReviews)
    const [bigPic, setBigPic] = useState(null)
    const [openExpCreator, setOpenExpCreator] = useState(null)
    const [openCoachProfileEdit, setOpenCoachProfileEdit] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const settings = {
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        arrows: false,
        dots: true,
    };

    useEffect(() => {
        dispatch(loadMyCoach())
    }, [])

    useEffect(() => {
        if (myCoachProfileStatus === 'done') {
            if (myCoachProfile !== false) {
                setIsCoach(true)
            } else {
                setIsCoach(false)
            }
        }
    }, [myCoachProfile])

    useEffect(() => {
        try {
            if (myCoachProfileStatus === 'done' && isCoach === true && myCoachProfile && myCoachProfile.subend) {
                const calculateRemainingDays = () => {
                    const currentDate = new Date().getTime();
                    const subEndDate = new Date(myCoachProfile.subend).getTime(); // Convert to timestamp
                    const remainingTime = Math.max(0, subEndDate - currentDate);
                    const remainingDays = Math.ceil(remainingTime / (1000 * 60 * 60 * 24));
                    setRemainingDays(remainingDays);
                };

                calculateRemainingDays();
            }
        } catch (e) {
            setRemainingDays(0);
        }
    }, [isCoach, myCoachProfile]);

    useEffect(() => {
        if (isCoach === true) {
            dispatch(loadMyCoachBigData())
        }
    }, [isCoach, dispatch])


    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
    });
    const swipeHandlers0 = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
    });

    useEffect(() => {
        socket.on("resetMyCoach", ({ }) => {
            if (window.location.pathname === `/home/coaches/coachaccount`) {
                dispatch(loadMyCoachBigData())
            }
        });

        return () => {
            socket.off("resetMyCoach");
        };
    }, []);

    const handleShowPhoneNumber = async () => {
        try {
            const response = await fetch(`/api/coaches/changephonevisbility`)
            if (response.ok) {
                const data = await response.json()
                const state = await data.shownumber
                dispatch(editMyCoachProfileData({ shownumber: state }))
                try {
                    if (state === true) {
                        window.ReactNativeWebView.postMessage(`Alert:Only Students Can See And Call You Number`);
                    } else {
                        window.ReactNativeWebView.postMessage(`Alert:No One Can See And Call You Number`);
                    }
                } catch (e) {

                }
            } else {
            }
        } catch (e) {
            dispatch(editMyCoachProfileData({ shownumber: false }))
        }
    }

    if (myCoachProfileStatus === 'loading') {
        return (
            <FakeCoachCard />
        )
    }

    const doNothing = () => {

    }

    //if (myCoachProfile === false) {
    //    return (
    //        <div {...swipeHandlers} className='youAreNotRegistered'>
    //            {creating ? <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
    //                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
    //            </div> : ""}
    //            <h2>Apply as Coach!</h2>
    //            <br />
    //            <h4>1 - Verify your phone number.You can chose to HIDE/SHOW later. <span style={{ color: 'hsl(72, 100%, 47%)' }}>{myInfo.phone_number ? "Complete" : ""}</span></h4>
    //            <br />
    //            <h4>2 - Return back here to Create and Edit Your coach profile.</h4>
    //            <br />
    //            <h4>3 - Once your profile is set, you can subscribe to your preferred plan and students will be able to find u </h4>
    //            <h3 onMouseUp={() => navigate('/home/subs')} style={{ textDecoration: 'underline' }}>See Pricing</h3>
    //            <br />
    //            <br />
    //            {!myInfo.phone_number ? <p onClick={() => { navigate('/home/verifynumber') }} style={{ height: '20px', width: '250px' }} className='activeBlack singleButton'>Verify My Phone Number</p> : ""}
    //            {myInfo.phone_number ? <p style={{ color: 'hsl(72, 100%, 47%)', height: '20px', marginBottom: '30px' }} className=''>Your Phone is Verified</p> : ""}
    //            {myInfo.phone_number ? <p onClick={() => { applyAsCoach(navigate, setCreating, dispatch, loadMyCoach) }} style={{ height: '20px' }} className='activeBlack singleButton'>Get Started</p> : ""}
    //            <br />
    //        </div>
    //
    //    )
    //}

    if (myCoachProfile === false) {
        return (
            <div {...swipeHandlers} className='youAreNotRegistered'>
                {creating ? <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                    <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
                </div> : ""}
                <h2>Apply as Coach!</h2>
                <br />
                <h4>1 - Apply as a Coach and set your profile</h4>
                <br />
                <h4>2 - Once your profile is set, you can subscribe to your preferred plan and students will be able to find u </h4>
                <h3 onMouseUp={() => navigate('/home/subs')} style={{ textDecoration: 'underline' }}>See Pricing</h3>
                <br />
                <br />
                <p onClick={() => { applyAsCoach(navigate, setCreating, dispatch, loadMyCoach) }} style={{ height: '20px' }} className='activeBlack singleButton'>Get Started</p>
                <br />
            </div>

        )
    }

    if (myCoachProfileStatus === 'done' && myCoachProfile !== false) {
        return (
            <div {...swipeHandlers0} style={{ top: '0px', padding: '0px 0px 0px 0px' }} id="profileCont" className="feedPostsCont">
                <div id="feedsCont" style={{ paddingTop: '45px', marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">


                    {bigPic !== null ?
                        <div style={{ zIndex: '950' }} onClick={() => setBigPic(null)} className='fullScreenBlack'>
                            <img style={{ maxWidth: '90%' }} src={bigPic} />
                        </div>
                        :
                        ''
                    }
                    {openExpCreator ?
                        <div style={{ zIndex: '9900' }} className='createEditExpCont' >
                            <CreateOrEditExperience experience={openExpCreator} setOpenExpCreator={setOpenExpCreator} myMainid={myInfo.mainid} />
                        </div>
                        :
                        ""
                    }
                    {openCoachProfileEdit ?
                        <div className='createEditExpCont' >
                            <EditCoachProfile coach={myCoachProfile} setOpenCoachProfileEdit={setOpenCoachProfileEdit} myMainid={myInfo.mainid} />
                        </div>
                        :
                        ""
                    }
                    {creating ? <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                        <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
                    </div> : ""}
                    <div {...swipeHandlers0} >
                        <BigCoachCard coach={myCoachProfile} myMainid={myInfo.mainid} setOpenCoachProfileEdit={setOpenCoachProfileEdit} isMyCoach={false} ApplyToCoach={doNothing} WithDrawFromCoach={doNothing} handleCall={doNothing} myInfo={myInfo} setBigPic={setBigPic} />
                    </div>
                    {
                        myCoachProfile && myCoachProfile.subed === true ?
                            <div style={{ marginTop: '-8px', height: '54px', padding: '10px 10px', display: 'flex' }} className='experienceCont'>
                                <img src={localStorage.getItem('call')} onClick={handleShowPhoneNumber} style={{ backgroundColor: myCoachProfile.shownumber ? "" : 'rgba(255, 0, 0, 0.371)', height: '34px', width: '34px', padding: '3px' }} className="activeBlack singleButton" />
                                <div onMouseUp={() => navigate(`/home/verifynumber`)} style={{ height: '30px', fontSize: '14px', padding: '0px 0px', width: 'fit-content' }} className="activeBlack singleButton">
                                    <img style={{ width: '30px', padding: '5px' }} src={localStorage.getItem('call')} />
                                    <img style={{ width: '30px', filter: 'invert(1)', padding: '5px' }} src={localStorage.getItem('edit')} />
                                </div>
                                {
                                    <p style={{ height: '30px', fontSize: '14px', padding: '0px 15px', width: 'fit-content' }} className="activeBlack singleButton">{remainingDays} days remaining</p>
                                }
                            </div>
                            :
                            <div style={{ marginTop: '-8px', height: '54px', padding: '10px 40px', display: 'flex' }} className='experienceCont'>
                                <img src={localStorage.getItem('call')} onClick={handleShowPhoneNumber} style={{ backgroundColor: myCoachProfile.shownumber ? "" : 'rgba(255, 0, 0, 0.371)', height: '34px', width: '34px', padding: '3px' }} className="activeBlack singleButton" />
                                <div onMouseUp={() => navigate(`/home/verifynumber`)} style={{ height: '30px', fontSize: '14px', padding: '0px 0px', width: 'fit-content' }} className="activeBlack singleButton">
                                    <img style={{ width: '30px', padding: '5px' }} src={localStorage.getItem('call')} />
                                    <img style={{ width: '30px', filter: 'invert(1)', padding: '5px' }} src={localStorage.getItem('edit')} />
                                </div>
                                {myCoachProfile ? <p onMouseUp={() => navigate('/home/subs')} style={{ width: '170px', height: '30px' }} className="activeBlack singleButton2">Subscrib to get discoverd</p> : ""}
                            </div>
                    }
                    <div style={{ backgroundColor: 'hsla(0, 0%, 10%, 0)', marginTop: '0px', marginBottom: '10px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
                        <img className="activeBlack selectiveImgFilter" onClick={() => setType('post')} style={{ backgroundColor: type === 'post' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('feed')} />
                        <img className="activeBlack selectiveImgFilter" onClick={() => setType('exp')} style={{ backgroundColor: type === 'exp' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('experience')} />
                        <img className="activeBlack selectiveImgFilter" onClick={() => setType('rev')} style={{ backgroundColor: type === 'rev' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('reviewImg')} />
                        <img className="activeBlack selectiveImgFilter" onClick={() => setType('student')} style={{ backgroundColor: type === 'student' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('traineeGrey')} />
                    </div>
                    {type === 'exp' ?
                        < div className='experienceCont'>
                            <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowExp((state) => !state) }} className='experienceHead'>
                                <h4 >EXPERIENCE(S):</h4>
                                <img onClick={() => { setOpenExpCreator(emptyExp) }} className='plusInExp' src={localStorage.getItem('plus')} />
                            </div>
                            {myCoachBigDataStatus === 'done' && experiences ?
                                <div>
                                    {experiences.slice().sort((a, b) => new Date(b.startdate) - new Date(a.startdate))
                                        .map((experience) => (
                                            <CoachExperiencsCard key={experience.id} experience={experience} setBigPic={setBigPic} setOpenExpCreator={setOpenExpCreator} myMainid={myInfo.mainid} setCreating={setCreating} />
                                        ))
                                    }
                                </div>
                                :
                                <div className='reviewsCont'>
                                    {myCoachBigDataStatus === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Experiences added yet</h2> : ""}
                                </div >
                            }
                        </div>
                        : ""
                    }
                    {type === 'post' ?
                        < div className='experienceCont'>
                            <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowExp((state) => !state) }} className='experienceHead'>
                                <h4 >POSTS:</h4>
                            </div>
                            {myCoachBigDataStatus === 'done' ?
                                <UserPosts passedID={myInfo.mainid} />
                                :
                                <div style={{ display: showExp ? "" : 'none' }} className='coachExpCardCont'>
                                    {myCoachBigDataStatus === 'done' && showExp ? <h2 style={{ color: 'white', gridRow: '1 / 3' }}>No Experiences Added</h2> : ""}
                                </div >
                            }
                        </div>
                        : ""
                    }
                    {type === 'rev' ?
                        <div className='experienceCont'>
                            <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} className='experienceHead'>
                                <h4 >REVIEWS:</h4>
                            </div>
                            {myCoachBigDataStatus === 'done' && reviews ?
                                <div className='reviewsCont'>
                                    {reviews.slice().sort((a, b) => b.indexid - a.indexid).map((rev) => (
                                        <SingleReview key={rev.indexid} rev={rev} myMainid={myInfo.mainid} />
                                    ))}
                                </div>
                                :
                                <div className='reviewsCont'>
                                    {myCoachBigDataStatus === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Reviews Yet</h2> : ""}
                                </div >
                            }
                        </div>
                        : ""
                    }
                    {
                        type === 'student' ?
                            <div style={{ marginBottom: '100px' }} className='experienceCont'>
                                <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowStudents((state) => !state) }} className='experienceHead'>
                                    <h4 >TRAINEES:</h4>
                                </div>
                                {myCoachBigDataStatus === 'done' && students ?
                                    <MyStudents students={students} myMainid={myInfo.mainid} />
                                    :
                                    <div style={{ height: '100px' }} className='coachExpCardCont'>
                                        {myCoachBigDataStatus === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Students or Requests</h2> : ""}
                                    </div >
                                }
                            </div>
                            : ""
                    }
                </div >
            </div>
        )
    }
}