import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SmallLoadingBar } from "../root/SmallLoadingBar";
import { CoachCard } from "./CoachCard";
import { disableRefresh, selectData } from "../home/homeSlice";
import { CoachExperiencsCard } from "./CoachExperiencsCard";
import { SingleReview } from "./SingleReview";
import Slider from 'react-slick';
import back from '../../Pics/back.png'

import { MySingleStudent } from "./MySingleStudent";
import socket from "../../app/io";
import { setMainId } from "../list/tempProfileSlice";
import { AddReview } from "./AddReview";
import { FakeCoachCard } from './FakeCoachCard'
import { BigCoachCard } from "./BigCoachCard";
import { UserPosts } from "../user/UserPosts";

export function CoachPage({ }) {
  const [type, setType] = useState('exp')
  const myInfo = useSelector(selectData)
  const [showExp, setShowExp] = useState(false)
  const [showRevs, setShowRevs] = useState(false)
  const [creating, setCreating] = useState(false)
  const [showStudents, setShowStudents] = useState(false)
  const [bigPic, setBigPic] = useState(null)
  const [status, setStatus] = useState('idle')
  const [coach, setCoach] = useState(null)
  const [experiences, setExperiences] = useState(null)
  const [reviews, setReviews] = useState(null)
  const [students, setStudents] = useState(null)
  const [isMyCoach, setIsMyCoach] = useState(false)
  const [studentsCount, setStudentsCount] = useState(0)
  const [addingReview, setAddingReview] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  let { coachid } = useParams();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    arrows: false,
    dots: true,
  };

  const setOpenCoachProfileEdit = () => {
    console.log('Not Your Profile')
  }

  const setOpenExpCreator = () => {
    console.log('Not Your Profile')
  }


  const fetchAllCoachData = async (id) => {
    if (coach === null) {
      setStatus('loading')
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/loadallcoachdata`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id }),
      });
      if (response.ok) {
        const data = await response.json();
        setCoach(data.coach)
        setExperiences(data.experiences)
        setReviews(data.reviews)
        setStudents(data.students)
        setStatus('done')
      }
    } catch (error) {
      console.error(error);
    }
  }

  const ApplyToCoach = async (id) => {
    setCreating(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/applytocoach`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ coachid: id }),
      });
      if (response.ok) {
        fetchAllCoachData(id)
        setTimeout(() => {
          setCreating(false)
        }, 500);
      }
    } catch (error) {
      setCreating(false)
      console.error(error);
    }
  }

  const WithDrawFromCoach = async (id) => {
    setCreating(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/withdrawfromcoach`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ coachid: id }),
      });
      if (response.ok) {
        const updatedStudents = students.filter(student => student.studentid !== myInfo.mainid);
        setStudents(updatedStudents)
        setCreating(false)
      }
    } catch (error) {
      setCreating(false)
      console.error(error);
    }
  }

  useEffect(() => {
    let id = coachid.replace(":", "");
    fetchAllCoachData(id)
  }, [coachid]);

  useEffect(() => {
    if (students) {
      try {
        const mainidToCheck = myInfo.mainid
        if (students.some(student => student.studentid === mainidToCheck && student.status === 'request')) {
          setIsMyCoach('request')
        } else if (students.some(student => student.studentid === mainidToCheck && student.status === 'accepted')) {
          setIsMyCoach(true)
        } else {
          setIsMyCoach(false)
        }
      } catch (e) {
        setIsMyCoach(false)
      }
    }
  }, [students]);

  const handleCall = async (id) => {
    setCreating(true)
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/getcoachnumber`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ coachid: id }),
      });
      if (response.ok) {
        const data = await response.json();
        try {
          setCreating(false)
          dispatch(disableRefresh())
          window.ReactNativeWebView.postMessage(`link:tel:${data.phone_number}`);
        } catch (e) {
          setCreating(false)
        }
      } else {
        try {
          setCreating(false)
          window.ReactNativeWebView.postMessage(`Alert:Not a Student`);
        } catch (e) {
          setCreating(false)
        }
      }
    } catch (error) {
      setCreating(false)
      console.error(error);
    }
  }

  useEffect(() => {
    socket.on("resetCoach", ({ coachid }) => {
      if (window.location.pathname === `/home/coaches/coach/:${coachid}`) {
        fetchAllCoachData(coachid)
      }
    });

    return () => {
      socket.off("resetCoach");
    };
  }, []);

  if (status === 'loading') {
    return (
      <FakeCoachCard />
    )
  }

  if (status === 'done') {
    return (
      <div style={{ top: '0px', padding: '0px 0px 0px 0px' }} id="profileCont" className="feedPostsCont">

        <div id="feedsCont" style={{ paddingTop: '45px', marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">

          {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
            <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
          </div> : ""}
          {bigPic !== null ?
            <div style={{ zIndex: '950' }} onClick={() => setBigPic(null)} className='fullScreenBlack'>
              <img style={{ maxWidth: '90%' }} src={bigPic} />
            </div>
            :
            ''
          }
          {coach ? <BigCoachCard coach={coach} myMainid={myInfo.mainid} setOpenCoachProfileEdit={setOpenCoachProfileEdit} isMyCoach={isMyCoach} ApplyToCoach={ApplyToCoach} WithDrawFromCoach={WithDrawFromCoach} handleCall={handleCall} myInfo={myInfo} setBigPic={setBigPic} /> : ""}
          <div style={{ backgroundColor: 'hsla(0, 0%, 10%, 0)', marginTop: '0px', marginBottom: '10px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
            <img className="activeBlack selectiveImgFilter" onClick={() => setType('post')} style={{ backgroundColor: type === 'post' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('feed')} />
            <img className="activeBlack selectiveImgFilter" onClick={() => setType('exp')} style={{ backgroundColor: type === 'exp' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('experience')} />
            <img className="activeBlack selectiveImgFilter" onClick={() => setType('rev')} style={{ backgroundColor: type === 'rev' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('reviewImg')} />
            <img className="activeBlack selectiveImgFilter" onClick={() => setType('student')} style={{ backgroundColor: type === 'student' ? " hsl(72, 100%, 47%)" : 'hsla(0, 0%, 13%, 1)' }} src={localStorage.getItem('traineeGrey')} />
          </div>
          {type === 'post' ?
            < div className='experienceCont'>
              <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowExp((state) => !state) }} className='experienceHead'>
                <h4 >POSTS:</h4>
              </div>
              {status === 'done' ?
                <UserPosts passedID={coach.coachid} />
                :
                <div style={{ display: showExp ? "" : 'none' }} className='coachExpCardCont'>
                  {status === 'done' && showExp ? <h2 style={{ color: 'white', gridRow: '1 / 3' }}>No Posts Available</h2> : ""}
                </div >
              }
            </div>
            : ""
          }
          {type === 'exp' ?
            < div className='experienceCont'>
              <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowExp((state) => !state) }} className='experienceHead'>
                <h4 >EXPERIENCE(S):</h4>
              </div>
              {status === 'done' && experiences ?
                <div>
                  {experiences.slice().sort((a, b) => new Date(b.startdate) - new Date(a.startdate))
                    .map((experience) => (
                      <CoachExperiencsCard key={experience.id} experience={experience} setBigPic={setBigPic} setOpenExpCreator={setOpenExpCreator} myMainid={myInfo.mainid} setCreating={setCreating} />
                    ))
                  }
                </div>
                :
                <div className='reviewsCont'>
                  {status === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Experiences added yet</h2> : ""}
                </div >
              }
            </div>
            : ""
          }
          {type === 'rev' ?
            <div className='experienceCont'>
              <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} className='experienceHead'>
                <h4 >REVIEWS:</h4>
                {isMyCoach === true ? <img style={{ marginRight: '30px' }} onClick={() => { setAddingReview(true) }} className='plusInExp' src={localStorage.getItem('plus')} /> : ""}
              </div>
              {status === 'done' && reviews ?
                <div className='reviewsCont'>
                  {addingReview ?
                    <AddReview setCreating={setCreating} myname={myInfo.name} reviews={reviews} setReviews={setReviews} setAddingReview={setAddingReview} coachid={coach.coachid} />
                    :
                    ""
                  }
                  {reviews.slice().sort((a, b) => b.indexid - a.indexid).map((rev) => (
                    <SingleReview key={rev.indexid} rev={rev} myMainid={myInfo.mainid} />
                  ))}
                </div>
                :
                <div className='reviewsCont'>
                  {addingReview ?
                    <AddReview setCreating={setCreating} myname={myInfo.name} reviews={reviews} setReviews={setReviews} setAddingReview={setAddingReview} coachid={coach.coachid} />
                    :
                    ""
                  }
                  {status === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Reviews Yet</h2> : ""}
                </div >
              }
            </div>
            : ""
          }
          {
            type === 'student' ?
              <div className='experienceCont'>
                <div style={{ borderBottom: "10px solid hsla(0, 0%, 10%, 0.9)" }} onClick={() => { setShowStudents((state) => !state) }} className='experienceHead'>
                  <h4 >TRAINEES:</h4>
                </div>
                {status === 'done' && students ?
                  <div >
                    {students.map((student) => {
                      if (student.status === 'accepted') {
                        return (
                          <MySingleStudent key={student.studentid} student={student} myMainid={myInfo.mainid} setUpdating={setCreating} />
                        )
                      }
                    })}
                  </div>
                  :
                  <div className='reviewsCont'>
                    {status === 'done' ? <h2 style={{ color: 'white', gridRow: '1 / 2' }}>No Students Yet</h2> : ""}
                  </div >
                }
              </div>
              : ""
          }
        </div >
      </div>
    )
  }
}
