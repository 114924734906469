import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SportPicker } from '../Posts/SportPicke';
import { useNavigate } from 'react-router-dom';
import { SmallLoadingBar } from '../root/SmallLoadingBar';
import { FakeGroupCard } from './FakeGroupCard';
import { playAdInt, selectData } from '../home/homeSlice';
import { loadNearByGroups, selectGroupsNearBy, selectGroupsNearByStatus } from './groupsSlice';
import { GroupCard } from './GroupCard';
import Radius from '../intButtons/Radius';
import { SportPickerNew } from './SportPickerNew';


export const DiscoverGroups = ({ }) => {
    const myInfo = useSelector(selectData)
    const [selectedSport, setSelectedSport] = useState(window.selectedGroupSport || null);
    const [creating, setCreating] = useState(false)
    const [groupRadius, setGroupRadius] = useState(localStorage.getItem('Radius') || 40)
    const dispatch = useDispatch()
    const groupsNearBy = useSelector(selectGroupsNearBy)
    const groupsNearByStatus = useSelector(selectGroupsNearByStatus)
    const navigate = useNavigate()

    useEffect(() => {
        try {
            if (window.location.search !== "") {
                const tobeEdited = window.location.search
                if (tobeEdited.startsWith('?Load:')) {
                    const selectedShortCut = tobeEdited.substring(6)
                    setSelectedSport(selectedShortCut)
                    window.selectedGroupSport = selectedSport
                }
            }
        } catch (e) {

        }
    }, [])



    useEffect(() => {
        if (selectedSport) {
            const radius = groupRadius
            const sport = selectedSport
            window.selectedGroupSport = selectedSport
            dispatch(loadNearByGroups({ sport, radius }))
            dispatch(playAdInt())
        }
    }, [selectedSport])

    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    const setOpenCoachProfileEdit = () => {
        console.log('Not Your Profile')
    }

    return (
        <div style={{ paddingTop: '10px' }} className="friendsList">

            <div style={{ marginTop: '0px', marginBottom: '10px', paddingBottom: '100px' }} className="scrollList">
                {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                    <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
                </div> : ""}
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%' }} className="hintCont">
                    <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">FIND GROUPS</label>
                </div>
                <div className='findGroupsHeader'>
                    <Radius radius={groupRadius} setRadius={setGroupRadius} />
                    <div style={{ width: '50px', height: '50px' }}>
                        <SportPickerNew selectedSport={selectedSport} setSelectedSport={setSelectedSport} />
                    </div>
                </div>
                {groupsNearByStatus === 'done' && groupsNearBy.length === 0 && selectedSport ?
                    <div style={{ display: 'flex', height: '100px' }}>
                        <h3 style={{ color: 'white' }}>No Available {selectedSport} Groups NearBy</h3>
                    </div>
                    :
                    ""
                }
                {groupsNearByStatus === 'done' && groupsNearBy ?
                    groupsNearBy.slice() // Make a copy of the array to avoid modifying the original state
                        .sort((a, b) => {
                            // Assuming user.time is in the format "hh:mm d/m/y"
                            const [timeA, dateA] = a.time.split(" ");
                            const [timeB, dateB] = b.time.split(" ");

                            // Extracting hours and minutes from time string
                            const [hoursA, minutesA] = timeA.split(":");
                            const [hoursB, minutesB] = timeB.split(":");

                            // Extracting day, month, and year from date string
                            const [dayA, monthA, yearA] = dateA.split("/");
                            const [dayB, monthB, yearB] = dateB.split("/");

                            // Creating date objects for comparison
                            const dateObjectA = new Date(yearA, monthA - 1, dayA, hoursA, minutesA);
                            const dateObjectB = new Date(yearB, monthB - 1, dayB, hoursB, minutesB);

                            // Sorting based on time (earliest to latest)
                            return dateObjectA - dateObjectB;
                        })
                        .map((group) =>
                            group.groupid !== myInfo.mainid ? (
                                <GroupCard key={group.groupid} group={group} myMainid={myInfo.mainid} setCreating={setCreating} />

                            ) : null
                        )
                    :
                    < div >
                        {groupsNearByStatus === 'loading' ?
                            <div>
                                <FakeGroupCard />
                                <FakeGroupCard />
                                <FakeGroupCard />
                            </div>
                            :
                            ""
                        }
                    </div>
                }
            </div >
        </div>
    );
};
