import React, { useState, useEffect } from 'react';
import Logos from '../../Pics/Logos.png';
import { Achievements } from './Achievements';



export const AchievementBadges = ({ setSelectedCount, setSelectedBadge, count, Achievements, topBadgeAchieved, setTopBadgeAchieved }) => {

    useEffect(() => {
        if (count > 0) {
            try {
                for (let i = Achievements.length - 1; i >= 0; i--) {
                    const achievement = Achievements[i];
                    if (count >= achievement.count) {
                        setTopBadgeAchieved(achievement)
                        break;
                    }
                }
            } catch (e) {
                setTopBadgeAchieved(null)
            }
        }
    }, [count]);

    const handleSection = (achievement, count) => {
        setSelectedBadge(achievement);
        setSelectedCount(count)
        console.log('called')
    }

    if (window.location.pathname === '/home/achievements') {
        return (
            <div className="achievements-container">
                {Achievements.map((achievement) => {
                    return (
                        <div onClick={() => handleSection(achievement, count)} className="achievement" key={achievement.name}>
                            <img
                                style={{ filter: count >= achievement.count ? 'grayscale(0)' : 'grayscale(1)' }}
                                src={achievement.image}
                                alt="FitBuddy Achievement Logo"
                            />
                        </div>
                    );
                })}

            </div>
        );
    }
};
