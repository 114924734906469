import React, { useEffect, useRef } from "react";

export function ConfirmInterest({ selectedShortCut, handleYes, handleNo, setAskToSetInterest }) {
    const componentRef = useRef(null);

    useEffect(() => {
        try {
            const handleOutsideClick = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setAskToSetInterest(false)
                }
            };

            const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

            document.addEventListener(clickEvent, handleOutsideClick);

            return () => {
                document.removeEventListener(clickEvent, handleOutsideClick);
            };
        } catch (e) {

        }
    }, []);
    return (
        <div className="otherBigPic" style={{ backgroundColor: 'none', zIndex: '13', width: '100%', display: 'flex' }}>
            <div ref={componentRef} className="doYouWatToSetCont" >
                <p >No nearby Fit-Buddies interested in  {selectedShortCut}.You are also not set for {selectedShortCut}<br /> <br /> SetUp {selectedShortCut} first ? So others know you are ready for {selectedShortCut}</p>
                <div className="yesAndNo" style={{ display: 'flex', marginTop: '20px' }}>
                    <p onClick={() => handleYes()} style={{ textAlign: 'center' }} className="activeBlack">Yes</p>
                    <p onClick={() => handleNo()} style={{ textAlign: 'center' }} className="activeBlack">No</p>
                </div>
            </div>
        </div>
    )

}