import React from 'react';
import { useDispatch } from "react-redux";
import { clearInfo } from '../root/repChoiceSlice';
import { handleReport } from "../user/userFuncs";
import { useNavigate } from 'react-router-dom';


export function ReportGroup({ coachname, groupid, setShowReport, setGroupHidden, myMainid }) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleButtonPress = (content, coachname, groupid) => {
        try {
            const confirmed = window.confirm(
                `Report ${coachname} ${content === 'abusive user' ? 'as' : 'for '} ${content}?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
            );
            if (confirmed) {
                confirmReport(content, coachname, groupid)
            } else {
            }
        } catch (e) {

        }

    };

    const confirmReport = (content, coachname, groupid) => {
        try {
            handleReport(content, groupid, coachname, dispatch, clearInfo);
        } catch (e) {
        }
    };

    const blockUser = async (groupid) => {
        try {
            const receiverId = groupid
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/block-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ receiverId }),
                }
            );
            const data = await response.json();
            if (data === 'Blocked') {
                window.alert(`${coachname} is now blocked`)
                await handleHideGroup()
                dispatch(clearInfo())
                navigate(-1)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHideGroup = async () => {
        setGroupHidden(true);
        setShowReport(false);
        fetch(`/api/groups/hidegroup`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ groupid: groupid }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        if (window.location.pathname === `/home/groups/group/:${groupid}`) {
                            navigate(-1)
                        } else {
                            setGroupHidden(true)
                        }
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    if (myMainid === groupid) {
        return (
            <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
                <div onClick={() => ""} className="rep-choice-container">
                    <h2 style={{ marginBottom: '20px' }}>Can't Report Your Self!</h2>
                    <button
                        className={`rep-choice-button `}
                        onClick={() => setShowReport(false)}
                    >
                        Close
                    </button>
                </div>
            </div >
        )
    }

    return (
        <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
            <div onClick={() => ""} className="rep-choice-container">
                <h2>REPORT !</h2>
                <h3 style={{ color: 'red' }}>Group : {coachname}<span style={{ color: 'black' }}></span> </h3>
                <h4 style={{ marginBottom: '5px' }} >Inappropriate Coach content?</h4>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Group Name', coachname, groupid)}
                >
                    Group Name
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Group Location', coachname, groupid)}
                >
                    Group Location
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Group Details', coachname, groupid)}
                >
                    Group Details
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>
                <button
                    className={`rep-choice-button`}
                    onClick={handleHideGroup}
                >
                    Just hide this Group
                </button>
                <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
                <button
                    style={{ color: 'red' }}
                    className={`rep-choice-button `}
                    onClick={() => blockUser(groupid)}
                >
                    Block Group Creator
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>
                <button
                    className={`rep-choice-button `}
                    onClick={() => setShowReport(false)}
                >
                    cancel
                </button>
            </div>
        </div >
    );

};

