import React from 'react';

export const TimeViewInMeeting = ({ time }) => {
    const now = new Date();
    let displayText = 'No Plan';

    if (time) {
        const [timeString, dateString] = time.split(' ');
        const [hours, minutes] = timeString.split(':');
        const [day, month, year] = dateString.split('/');

        // Assuming the year is in 2-digit format, convert it to 4-digit
        const fullYear = parseInt(year) + 2000;

        const userTime = new Date(fullYear, month - 1, day, hours, minutes);

        const userTime12Hours = userTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        }).replace(/\s/g, ''); // Remove all spaces

        displayText = `${userTime12Hours} ${dateString}`;
    }

    return <p className="cardTime">{displayText}</p>;
};

