import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectData } from '../home/homeSlice'
import socket from "../../app/io";
import edit from '../../Pics/edit.png'
import deletePic from '../../Pics/deletePic.png'
import deleteRedPic from '../../Pics/delete.png'
import { sendGroupMessage } from "./groupsFuncs";
import { setMainId } from "../list/tempProfileSlice";
import { AskPrompet } from "../popUps/AskPrompet";
import TextHandler from "../CommonItems/TextHandler";

export function GroupChat({ myInfo, group, groupStatus, onChat }) {
  const [recording, setRecording] = useState(false)
  const [record, setRecord] = useState(null)
  const [record64, setRecord64] = useState(null)
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photo64, setPhoto64] = useState('');
  const [uploadingPhoto, setUploadingPhoto] = useState(false)
  const [uploadingRecord, setUploadingRecord] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [editingChat, setEditingChat] = useState(false);
  const [askToConfirmDeleteAll, setAskToConfirmDeleteAll] = useState(false)
  const chatBoxRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const inputBox = useRef(null);
  const dispatch = useDispatch();

  const vibrate = (time) => {
    try {
      window.ReactNativeWebView.postMessage(`Vib:${time}`);
    } catch (e) {
    }
  }

  useEffect(() => {
    if (inputText) {
      adjustTextareaHeight();
    }
  }, [inputText]);

  const adjustTextareaHeight = () => {
    if (inputBox.current) {
      inputBox.current.style.height = 'auto'; // Reset the height
      inputBox.current.style.height = inputBox.current.scrollHeight + 'px'; // Set the height to the scrollHeight
    }
  };

  useEffect(() => {
    socket.on("groupmsg", ({ content, senderId, name, picture_url, groupid, type }) => {
      vibrate(200)
      if (window.location.pathname === `/home/groups/group/:${groupid}`) {
        const message = {
          senderid: senderId,
          content,
          sender_name: name,
          sender_pic: picture_url,
          sender: senderId === myInfo.mainid ? "me" : "other",
          type: type
        };
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
    return () => {
      socket.off("groupmsg");
    };
  }, [socket, messages]);

  useEffect(() => {
    if (group && (groupStatus === "accepted" || groupStatus === "owner")) {
      fetchSavedMessages(group.groupid);
    }
  }, [group, groupStatus]);

  const fetchSavedMessages = (groupid) => {
    fetch("/api/groups/groupmessages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ groupid }),
    })
      .then((response) => response.json())
      .then((data) => {
        const fetchedMessages = data.map((message) => {
          return {
            content: message.content,
            senderid: message.senderid,
            sender_pic: message.sender_pic,
            sender_name: message.senderid === myInfo.mainid ? "" : message.sender_name,
            sender: message.senderid === myInfo.mainid ? "me" : "other",
            type: message.type
          };
        }).reverse();
        setMessages(fetchedMessages);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  };

  useEffect(() => {
    // Scroll the chat box to the bottom on initial load
    scrollToBottom();
  }, [messages]);

  const handleSendMessage = () => {
    inputBox.current.focus();
    if (inputText.trim() === "") return;
    // Emit the "send message" event to the server
    sendGroupMessage(inputText, group.groupid, 'text')
    const sentMessage = {
      content: inputText,
      sender: "me",
      type: 'text'
    };
    setMessages((prevMessages) => [...prevMessages, sentMessage]);
    setInputText("");
    inputBox.current.style.height = 'auto';
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  };

  const scrollToBottom = () => {
    try {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    } catch (e) {
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default "Enter" key behavior
      handleSendMessage(); // Call the function to send the message
    }
  };

  const handleDeleteAllChat = (id) => {
    fetch("/api/groups/deleteGroupMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    })
      .then((response) => response.json())
      .then((data) => {
        fetchSavedMessages(id);
        setEditingChat(false)
        setAskToConfirmDeleteAll(false)
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  }

  const handleYes = () => {
    handleDeleteAllChat(group.groupid)
  }

  const handleNo = () => {
    setAskToConfirmDeleteAll(false)
    setEditingChat(false)
  }

  return (
    <div style={{ marginTop: '0px', height: '100%' }} className="chat-container">
      {askToConfirmDeleteAll ?
        <AskPrompet text={"Delete all sent messages between you and this group from everyone."} handleYes={handleYes} handleNo={handleNo} />
        : ""
      }
      <div className="editChatCont">
        {editingChat ? <img onClick={() => setAskToConfirmDeleteAll(true)} src={deletePic} /> : ""}
        {!editingChat ? <img onClick={() => (setEditingChat(true))} src={edit} /> : ""}
        {editingChat ? <img onClick={() => (setEditingChat(false))} src={deleteRedPic} /> : ""}
      </div>
      <div style={{ bottom: '50px', height: `calc(100% - ${onChat ? '220px' : '320px'})` }} ref={chatBoxRef} className="chat-box">
        <div style={{ height: '600px' }} />
        <div className="boxchatwarn">
          <h3>ALERT !</h3>
          <h5>Before you connect, make sure you follow these rules for your safety & safety of others</h5>
          <p>* Dont meet in dark areas.</p>
          <p>* Dont meet in empty places.</p>
          <p>* Dont give out your phone-number until you feel its safe to.</p>
          <p>* Dont give your exact training location until you feel its safe to.</p>
        </div>
        {messages.map((message, index) => (
          <div
            style={{ padding: message.type === 'image' || message.type === 'video' ? '4px 4px' : "6px 10px" }}
            key={index}
            className={`message ${message.sender === "me" ? "sent" : "received"
              }`}
          >
            <div style={{ whiteSpace: 'pre-wrap' }} className={`message-content ${message.sender === "me" ? "align-right" : "align-left"}`}>
              {message.sender_name ?
                <div onClick={() => dispatch(setMainId(message.senderid))} style={{ paddingRight: '3px', marginLeft: '0px', marginBottom: '3px', textAlign: 'left', display: 'flex', gap: '5px' }}>
                  <img src={message.sender_pic} style={{ width: '20px', borderRadius: '50%' }} />
                  <strong style={{ marginLeft: '0px' }} >{message.sender_name.split(' ')[0] + ":"}</strong>
                </div>
                : ""
              }
              <TextHandler text={message.content} />
            </div>
          </div>
        ))}
      </div>
      <div className="input-container">
        {/*
        <img className="backArrow2" src={backArrow} onClick={back} />
        */}
        <textarea
          style={{ position: record || selectedVideo ? 'absolute' : '', bottom: record ? '-100px' : '' }}
          ref={inputBox}
          value={inputText}
          onChange={(e) => {
            setInputText(e.target.value);
            adjustTextareaHeight(); // Adjust the height as the user types
          }}
          onFocus={() => {
            setTimeout(() => {
              scrollToBottom()
            }, 150);
          }}
          inputMode="text"
          enterKeyHint="Return"
          placeholder="send message"
          rows="1" // Start with a single row
          className="auto-adjusting-textarea"
        />
        {(inputText === "" ?
          ""
          :
          <div className="sendTextMsgButtonCont">
            <img onClick={handleSendMessage} className=" activeBlack chatButtons" style={{ zIndex: '100', transform: 'rotate(180deg)' }} src={localStorage.getItem('thinback')} />
            <div style={{ height: inputBox && inputBox.current.style.height ? inputBox.current.style.height : '50px', maxHeight: '150px', zIndex: '500', minHeight: '100px' }} onClick={handleSendMessage} className="sendTextMsgButtonHelper" />
          </div>
        )}
      </div>
    </div>
  );
}