import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './meetUpCard.css'
import deleteImg from '../../Pics/delete.png'


export function FakeMeetUpCard({ meetingid, creatorid, creatorname, createtime, sport, taggedid, taggedname, myMainid, notifytime, meetingtime, finishedtime, state }) {
    const [isState, setIsState] = useState(state)
    const [meetingDeleted, setMeetingDeleted] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const dispatch = useDispatch();


    if (meetingDeleted === true) {
        return (
            ""
        )
    }

    return (
        <div style={{ border: isState === 'confirmed' ? '1px solid hsl(72, 100%, 47%)' : '1px solid grey' }} className='meetingCardCont' >
            {updating ? <div className='CreatingPost'>
                <p className='fake'> Loading..</p>
            </div> : ""}
            <div className='fake meetingCardInfo'>
                <div className='fake doubleTagInMeeting'>
                    <p >Loading ... </p>
                    <p style={{ marginLeft: '0px' }} > Loading ...</p>
                </div>
                <div className='fake timeInMeeting'>
                    <p>Loading ...</p>
                </div>
                <div className='fakeBackGround bigCardFav' style={{ height: '30px', width: '30px' }} />
            </div>
            <div style={{ display: 'flex', marginTop: '10px', marginRight: '10px', gap: '10px' }}>

                <img style={{ height: '25px', marginRight: '0px' }} src={deleteImg} />
            </div>
        </div>
    )
}