import React from 'react';
import { useDispatch } from "react-redux";
import { clearInfo } from '../root/repChoiceSlice';
import { handleReport } from "../user/userFuncs";
import { loadHome } from '../home/homeSlice';


export function ReportPost({ creatorname, creatorid, postid, setShowReport, setPostDeleted, myMainid }) {

    const dispatch = useDispatch();

    const handleButtonPress = (content, creatorname, creatorid, postid) => {
        try {
            const confirmed = window.confirm(
                `Report ${creatorname} ${content === 'abusive user' ? 'as' : 'for '} ${content}?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
            );
            if (confirmed) {
                confirmReport(content, creatorname, creatorid, postid)
            } else {
            }
        } catch (e) {

        }

    };

    const confirmReport = (content, creatorname, creatorid, postid) => {
        try {
            handleReport(content, creatorid, creatorname, dispatch, clearInfo, postid);
        } catch (e) {
        }
    };

    const blockUser = async (creatorid) => {
        try {
            const receiverId = creatorid
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/block-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ receiverId }),
                }
            );
            const data = await response.json();
            if (data === 'Blocked') {
                window.alert(`${creatorname} is now blocked`)
                dispatch(clearInfo())
                setPostDeleted(true)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHidePost = () => {
        setPostDeleted(true);
        setShowReport(false);
        fetch(`/api/post/hidepost`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ postid: postid }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        console.log('post hidden')
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    if (myMainid === creatorid) {
        return (
            <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
                <div onClick={() => ""} className="rep-choice-container">
                    <h2 style={{ marginBottom: '20px' }}>Can't report own post</h2>
                    <button
                        className={`rep-choice-button `}
                        onClick={() => setShowReport(false)}
                    >
                        Close
                    </button>
                </div>
            </div >
        )
    }

    return (
        <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
            <div onClick={() => ""} className="rep-choice-container">
                <h2>REPORT !</h2>
                <h3 style={{ color: 'red' }}>{creatorname}'s<span style={{ color: 'black' }}> post</span> </h3>
                <h4 style={{ marginBottom: '5px' }} >Inappropriate post content?</h4>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Post Media', creatorname, creatorid, postid)}
                >
                    Post Media
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Post Caption', creatorname, creatorid, postid)}
                >
                    Post Caption
                </button>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Wrong Tag', creatorname, creatorid, postid)}
                >
                    Wrong Tag
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>
                <button
                    className={`rep-choice-button`}
                    onClick={handleHidePost}
                >
                    Just hide this post
                </button>
                <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
                <button
                    style={{ color: 'red' }}
                    className={`rep-choice-button `}
                    onClick={() => blockUser(creatorid)}
                >
                    Block User
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>

                <button
                    className={`rep-choice-button `}
                    onClick={() => setShowReport(false)}
                >
                    cancel
                </button>
            </div>
        </div >
    );

};

