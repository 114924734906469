import React, { useState } from 'react';
import back from '../../Pics/back.png'


export const BigFeaturedWorkout = ({ workout, setBigPic, setSelectedFilter }) => {
    const [showInstruction, setShowInstruction] = useState(false)

    const handleAddToTrack = async () => {

        const newWorkout = { ...workout, sets: 0 }
        // 1. Get the existing trackedSoloWorkouts from local storage (if it exists).
        const trackedSoloWorkouts = JSON.parse(localStorage.getItem('trackedSoloWorkouts')) || [];

        // 2. Check if the new workout ID already exists in the trackedSoloWorkouts.
        const newWorkoutId = newWorkout.id;
        const existingIndex = trackedSoloWorkouts.findIndex(workout => workout.id === newWorkoutId);

        if (existingIndex !== -1) {
            try {
                window.ReactNativeWebView.postMessage("Alert:Workout already tracked.");
            } catch (e) {
                console.log('Workout with the same ID already exists.');

            }
            return;
        }

        // 3. Add the new workout object to the array.
        trackedSoloWorkouts.push(newWorkout);

        // 4. Ensure that the trackedSoloWorkouts array has a maximum length of 10.
        if (trackedSoloWorkouts.length > 10) {
            // Remove the oldest workout.
            trackedSoloWorkouts.shift();
        }

        // 5. Save the updated array back to local storage.
        localStorage.setItem('trackedSoloWorkouts', JSON.stringify(trackedSoloWorkouts));
        try {
            window.ReactNativeWebView.postMessage("Alert:Tracked");
        } catch (e) {
            console.log('Tracked');

        }
    };

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <div className='featuredWorkoutBigCont'>
                <img onClick={() => setBigPic(workout.gifurl)} src={workout.gifurl} />
                <div className='BigWorkOutInfo'>
                    <h3><span style={{ color: 'hsl(72, 100%, 47%)' }}>Name: </span>{workout.name.toUpperCase()}</h3>
                    <h3><span style={{ color: 'hsl(72, 100%, 47%)' }}>Target:</span> {workout.target.toUpperCase()}</h3>
                    <h3><span style={{ color: 'hsl(72, 100%, 47%)' }}>Secondary:</span></h3>
                    {workout.secondarymuscles && workout.secondarymuscles.map((secondary) => (
                        <h3 style={{ marginLeft: '25px' }} key={secondary}>- {secondary}</h3>
                    ))}
                </div>
            </div>
            <div className='WorkoutMidArea'>
                <p onClick={handleAddToTrack} style={{ height: '20px' }} className='activeBlack singleButton'>Track</p>
                <div style={{ display: 'flex' }}>
                    <h3 onClick={() => { setShowInstruction((state) => !state) }} style={{ marginRight: '10px', color: 'white' }}>Instructions</h3>
                    <img onClick={() => { setShowInstruction((state) => !state) }} className={showInstruction ? 'dropDownArrow' : 'closeDropDownArrow'} style={{ height: '20px' }} src={back} />
                </div>
            </div>
            {showInstruction ?
                <div className='WorkoutInstructionsCont' >
                    {workout.instructions && workout.instructions.map((instruction, index) => (
                        <h5 key={index}>- {instruction}</h5>
                    ))}
                </div>
                :
                ""
            }
        </div>
    );
};
