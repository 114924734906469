import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadCommon, setLoading } from "../intButtons/intButtonsSlice";
import { selectData, selectStatus, editData, playAdInt, selectAppVersion, handleShowBottomNav, handleShowTopNav } from "../home/homeSlice";
import { fetchFeedPosts, fetchNearByFavSport, fetchNextFeedPosts, shouldShowRefPromp } from './feedFuncs'
import { getTimeOptions, notifyNearBy, updateInterest } from "../intButtons/intFuncs";
import { Post } from "../Posts/Post";
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { FavShortCut } from "./FavShortCut";
import { FakePost } from "../Posts/FakePost";
import { Fav1 } from "../profile/Fav1";
import { Fav2 } from "../profile/Fav2";
import { ConfirmInterest } from "./ConfirmInterest";
import { TodayWorkoutsBar } from "../workoutsManager/TodayWorkoutsBar";
import refreshGreen from '../../Pics/refreshGreen.png'

import back from '../../Pics/back.png'
import searchPic from '../../Pics/search.png'
import './feed.css'
import { AppServices } from "../home/AppServices";
import { FeedShortCutHeader } from "./FeedShortCutHeader";
import { InterestedIn } from "./InterestedIn";
import { fetchEvents } from "../events/eventsFuncs";
import { ScrollEvents } from "../events/ScrollEvents";
import { FakeEventCard } from "../events/FakeEventCard";
import { NewHeader } from "./NewHeader";
import { NewShortcut } from "./NewShortcut";
import { RefAdsPrompet } from "../popUps/RefAdsPrompet";
import { RootContext } from "../root/RootContextProvider";


export function Feed({ }) {
    const [showMore, setShowMore] = useState(JSON.parse(localStorage.getItem('showAppServices')) || false);
    const [showRefPromp, setShowRefPromp] = useState(shouldShowRefPromp);
    const [loadingNearBy, setLoadingNearBy] = useState(null)
    const [loadingPosts, setLoadingPost] = useState(null)
    const [askToSetInterest, setAskToSetInterest] = useState(false)
    const [selectedShortCut, setSelectedShortCut] = useState(null)
    const [loadingEvents, setLoadingEvents] = useState(null)
    const [events, setEvents] = useState(null)
    const [showEvents, setShowEvents] = useState(true)
    const [nearBy, setNearBy] = useState(null)
    const [posts, setPosts] = useState(null)
    const [offset, setOffset] = useState(null);
    const [nextPosts, setNextPosts] = useState(null)
    const [loadingNextPosts, setLoadingNextPosts] = useState(null)
    const [canScroll, setCanScroll] = useState(false)
    const [publicPost, setPublicPost] = useState(true)
    const data = useSelector(selectData);
    const status = useSelector(selectStatus);
    const appVersion = useSelector(selectAppVersion)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const scrollRef = useRef(0);
    const previousScrollTopRef = useRef(0);
    const lastScrollUpPositionRef = useRef(0);




    const favoriteShortcutHandle = (fav, radius) => {
        try {
            window.selectedDuoSport = fav
            navigate(`/home/workout/findduo?${fav}`)
        } catch (e) {
            setSelectedShortCut(fav)
            if (fav) {
                dispatch(loadCommon({ interest: fav, radius }));
                navigate(`/home/workout/findduo?${fav}`)
            }
        }
    };

    const handleRefreshFeed = () => {
        setShowEvents(true)
        try {
            window.ReactNativeWebView.postMessage(`Vib:100`);
        } catch (e) {
        }
        setLoadingPost(true)
        setPosts(null)
        setLoadingEvents(true)
        fetchNearByFavSport(setLoadingNearBy, setNearBy, data.fav1, data.fav2)
    }

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const handleSwipeDown = () => {
        handleRefreshFeed()
    };

    const swipeDownHandlersRefresh = useSwipeable({
        onSwipedDown: (eventData) => {
            // Check if the swipe distance in the downward direction is greater than 50 pixels
            if (eventData.deltaY > 50) {
                handleSwipeDown();
            }
        },
        //onSwipedLeft: handleSwipeLeft,
        //onSwipedRight: handleSwipeRight,
    });

    useEffect(() => {
        if (status === 'loadInFeed') {
            if (posts === null) {
                setLoadingPost(true)
            }
            setLoadingNearBy(true)
            setLoadingEvents(true)
            fetchNearByFavSport(setLoadingNearBy, setNearBy, data.fav1, data.fav2)
        }
    }, [status]);

    useEffect(() => {
        setTimeout(() => {
            fetchNearByFavSport(setLoadingNearBy, setNearBy, data.fav1, data.fav2)
            setLoadingEvents(true)
        }, 300);
    }, []);

    useEffect(() => {
        if (loadingNearBy === 'done') {
            fetchEvents(events, setEvents, setLoadingEvents)
            dispatch(playAdInt())
        }
    }, [loadingNearBy, publicPost]);

    useEffect(() => {
        if (loadingEvents === 'done' && posts === null) {
            fetchFeedPosts(setLoadingPost, setPosts, data, publicPost, setOffset, scrollRef)
        }
    }, [loadingEvents]);


    const handleYes = () => {
        navigate(`/home/workout/announce?${selectedShortCut}`)
    }

    const handleNo = () => {
        setAskToSetInterest(false)
        setSelectedShortCut(null)
        //if (selectedShortCut) {
        //    const radius = 40
        //    dispatch(loadCommon({ interest: selectedShortCut, radius }));
        //    navigate(`/home/workout/findduo?${selectedShortCut}`)
        //}
    }

    const loadNextPosts = () => {
        dispatch(playAdInt())
        fetchNextFeedPosts(setLoadingNextPosts, setNextPosts, setPosts, offset, setOffset, data, publicPost)
    };

    useEffect(() => {
        try {
            //const scrollRef = document.getElementById('feedsCont');
            const scrollElement = scrollRef.current;
            if (!scrollElement) return;
            const handleScroll = () => {
                const scrollHeight = scrollElement.scrollHeight;
                const scrollTop = scrollElement.scrollTop;
                const clientHeight = scrollElement.clientHeight;
                //console.log(scrollHeight)
                //console.log(scrollTop)
                //console.log(clientHeight)
                if (scrollHeight - scrollTop < (clientHeight + (/Android/.test(navigator.userAgent) ? 1500 : 120))) {
                    if (offset !== 0) {
                        setCanScroll(true)
                    }
                }

                const previousScrollTop = previousScrollTopRef.current;
                const isScrollingDown = scrollTop > previousScrollTop;
                const isScrollingUp = scrollTop < previousScrollTop;

                // Track the last scroll up position
                const lastScrollUpPosition = lastScrollUpPositionRef.current;

                if (isScrollingUp) {

                    if (previousScrollTop - scrollTop > 10) {
                        dispatch(handleShowBottomNav(true));
                        dispatch(handleShowTopNav(true));
                        lastScrollUpPositionRef.current = scrollTop;
                    }
                } else if (isScrollingDown && scrollTop > 60) {
                    if (scrollTop - lastScrollUpPosition > 10) {
                        dispatch(handleShowBottomNav(false));
                        dispatch(handleShowTopNav(false));
                    }
                    lastScrollUpPositionRef.current = scrollTop;
                }

                previousScrollTopRef.current = scrollTop;
            };

            scrollElement.addEventListener('scroll', handleScroll);

            return () => {
                // Cleanup: Remove the event listener when the component unmounts
                scrollElement.removeEventListener('scroll', handleScroll);
            };

        } catch (e) {

        }
    }, [offset]);

    useEffect(() => {
        if (canScroll === true && posts) {
            loadNextPosts()
            setTimeout(() => {
                setCanScroll(false)
            }, 500);
        }
    }, [canScroll])

    const scrollToTop = () => {
        try {
            if (scrollRef.current) {
                scrollRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        } catch (e) {
            console.error('Scroll to top failed:', e);
        }
    };

    function handleMessageEvent(event) {
        const data = event.data;
        if (data === 'backToTop') {
            scrollToTop()
        }

    }

    useEffect(() => {
        // Add event listeners when component mounts
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessageEvent);
        }
        if (typeof document !== 'undefined') {
            document.addEventListener('message', handleMessageEvent);
        }
        // Clean up event listeners when component unmounts
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessageEvent);
            }
            if (typeof document !== 'undefined') {
                document.removeEventListener('message', handleMessageEvent);
            }
        };
    }, []);

    const handleShowAppServices = () => {
        const showMoreHelper = !showMore;
        localStorage.setItem('showAppServices', JSON.stringify(showMoreHelper));
        setShowMore(showMoreHelper);
    };

    useEffect(() => {
        if (loadingEvents === 'done' && events && events.length === 0) {
            setShowEvents(false)
        }
    }, [loadingEvents, events])

    useEffect(() => {
        return () => {
            dispatch(handleShowBottomNav(true))
            dispatch(handleShowTopNav(true))
        }
    })
    return (

        <div style={{ zIndex: '12', top: '0px', height: '100vh' }} className="feedPostsCont">
            {askToSetInterest ?
                <ConfirmInterest selectedShortCut={selectedShortCut} handleYes={handleYes} handleNo={handleNo} setAskToSetInterest={setAskToSetInterest} />
                :
                ""
            }
            {showRefPromp && !data.activesub && (
                <RefAdsPrompet setShowRefPromp={setShowRefPromp} />
            )}
            <div id="feedsCont" ref={scrollRef} style={{ paddingTop: '45px', marginTop: '0px', marginBottom: '0px', height: '100%', minHeight: '1500px' }} className="scrollList">
                <div style={{ marginBottom: '0px' }} {...swipeDownHandlersRefresh}>
                    <div style={{ height: '50px' }} className="topFeedBar">
                        <div style={{ position: 'relative' }} className="ppwithplusPost activeBlack">
                            <img onMouseUp={() => navigate('/home/postcreator')} className="miniPPInFeedShortCut" src={data.picture_url} />
                            <img onMouseUp={() => navigate('/home/postcreator')} className="plusInFeedShortCut" src={localStorage.getItem('plus')} />
                        </div>
                        <img style={{ position: 'absolute', left: '70px' }} onClick={handleRefreshFeed} className="refreshInFeedImg activeBlack" src={localStorage.getItem('refreshGreen')} />
                        <img className="activeBlack" onMouseUp={() => navigate('/home/leadboards')} style={{ marginLeft: '10px', marginRight: '10px', height: '35px', borderRadius: '3px' }} src={localStorage.getItem('ranks')} />

                        <img className="activeBlack" onMouseUp={() => navigate('/home/search')} style={{ marginLeft: '10px', marginRight: '20px', height: '30px', borderRadius: '3px' }} src={localStorage.getItem('search')} />
                    </div>
                    <NewHeader appVersion={appVersion} />
                    <div className="nearbyTitle" />
                    <NewShortcut data={data} favoriteShortcutHandle={favoriteShortcutHandle} handleRefreshFeed={handleRefreshFeed} nearBy={nearBy} loadingNearBy={loadingNearBy} />
                    {
                        <div onClick={handleShowAppServices} className="activeBlack showMoreAppServices">
                            <div style={{ gap: '10px', display: 'flex', width: "fit-content" }}>
                                <h5 style={{ color: !showMore ? "rgba(255, 255, 255, 0.689)" : "hsl(72, 100%, 47%)" }}>{"MY SHORTCUTS"}</h5>
                                <img style={{ height: '26px', filter: !showMore ? "grayscale(1)" : "" }} src={localStorage.getItem('eye')} onClick={() => { setShowMore((state) => !state) }} />
                            </div>
                        </div>
                    }
                    {showMore ?
                        <AppServices showMore={showMore} />
                        : ''
                    }
                    {showMore === '123' ?
                        <InterestedIn myMainid={data.mainid} interest={data.interest} groupinterest={data.groupinterest} />
                        : ''
                    }
                    {/*<div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                        <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-BUDDY</label>
                    </div>*/}
                </div>
                <div onClick={() => setShowEvents(true)} style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px' }} />
                <div style={{ paddingBottom: '2px' }} className={`eventContInFeed ${!showEvents ? 'hide' : ''}`}>
                    {

                        <div onMouseUp={() => { navigate('/home/events'); }} style={{ backgroundColor: 'none', marginBottom: '0px', display: 'flex', borderRadius: '0px', marginTop: '10px', height: '40px', padding: '5px 20px' }} className="hintCont">
                            <div style={{ display: 'flex' }}>
                                <label style={{ margin: 'auto', fontSize: '18px' }} htmlFor="comment">FIT EVENTS</label>
                                <label className="activeBlack" style={{ fontSize: '13px', width: '30px' }} htmlFor="comment"><img style={{ width: '25px' }} src={localStorage.getItem('event')} /></label>
                            </div>
                        </div>

                    }
                    {

                        loadingEvents === 'done' && events ? (
                            <div className="ScrollEventsCont">
                                <ScrollEvents events={events} setEvents={setEvents} />
                            </div>
                        ) : (
                            <div style={{ paddingBottom: '2px' }} className="ScrollEventsCont">
                                <FakeEventCard />
                            </div>
                        )

                    }

                </div>
                <div onClick={() => setShowEvents(!showEvents)} style={{ marginTop: '10px', backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px', zIndex: '-1' }} className="feedFilter">

                </div>
                <div onMouseUp={() => { navigate('/home/mypage'); }} style={{ backgroundColor: 'none', marginBottom: '10px', display: 'flex', borderRadius: '0px', marginTop: '10px', height: '40px', padding: '5px 20px' }} className="hintCont">
                    <div style={{ display: 'flex' }}>
                        <label style={{ margin: 'auto', fontSize: '18px' }} htmlFor="comment">FIT JOURNEY</label>
                        <label className="activeBlack" style={{ fontSize: '13px', width: '30px' }} htmlFor="comment"><img style={{ width: '25px' }} src={localStorage.getItem('feedGreen')} /></label>
                    </div>
                </div>
                {loadingPosts === 'done' && posts.length === 0 ?
                    <div style={{ height: '100%', width: '100%' }}>
                        {publicPost ? <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}> No Journeys shared ! <br /> Lead the way in sharing your sports journey and inspire Fit-Community</h3> : <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}> No Friends Journeys shared ! <br />  Lead the way in sharing your sports journey and inspire your friends</h3>}
                        <div style={{ margin: '10px auto' }}>
                            <img onMouseUp={() => navigate('/home/postcreator')} className="miniPP" src={data.picture_url} />
                            <img style={{ width: '22px', position: 'absolute', marginLeft: "-22px", marginTop: '30px' }} onMouseUp={() => navigate('/home/postcreator')} src={localStorage.getItem('plus')} />
                        </div>
                    </div>
                    :
                    ""
                }
                {loadingPosts === 'done' && posts ? (
                    posts.map((post, index) => (
                        <Post thumb={post.thumb} key={index} postid={post.postid} image={post.postphoto} creatorid={post.creatorid} creatorname={post.creatorname} creatorpicture={post.creator_picture_url} createTime={post.time} caption={post.caption} sport={post.sport} taggedid={post.taggedid} taggedname={post.taggedname} likes={post.likes} myMainid={data.mainid} showtagged={post.showtagged} hiddenby={post.hiddenby} setRequest={""} commentscount={post.commentscount} likescount={post.likescount} />
                    ))
                ) : (
                    <div>
                        {(Array.from({ length: 20 }, (_, index) => index + 1)).map((id) => (
                            <FakePost key={id} postid={String(id)} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
                        ))}
                    </div>
                )}
                {offset === 0 && (
                    <div style={{ height: '100%', width: '100%' }}>
                        <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}> No More Posts</h3>
                        <div style={{ height: '100%', width: '100%' }}>
                            {publicPost ?
                                <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}>share your sports journey and inspire Fit-Community</h3> : <h3 className="Didntpostyet" style={{ color: 'white', margin: 'auto auto ', fontSize: '18px' }}>share your sports journey and inspire Fit-Community</h3>
                            }
                            <div style={{ margin: '10px auto' }}>
                                <img onMouseUp={() => navigate('/home/postcreator')} className="miniPP" src={data.picture_url} />
                                <img style={{ width: '22px', position: 'absolute', marginLeft: "-22px", marginTop: '30px' }} onMouseUp={() => navigate('/home/postcreator')} src={localStorage.getItem('plus')} />
                            </div>
                            <img onClick={scrollToTop} style={{ width: '50px', transform: 'rotate(90deg)' }} src={back} className="backTopTop" />
                        </div>
                    </div>
                )}
                {loadingNextPosts === true && (
                    <div>
                        {(Array.from({ length: 20 }, (_, index) => index + 1)).map((id) => (
                            <FakePost key={id} postid={String(id)} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={data.mainid} showtagged={false} setRequest={""} />
                        ))}
                    </div>
                )}
            </div>
        </div >
    )
}


