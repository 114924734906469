import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

export function ForceToUpdate({ text }) {
    const componentRef = useRef(null);
    const navigate = useNavigate()

    const handleYesUpdate = () => {
        navigate('/updatenow')
    }


    return (
        <div className="otherBigPic" style={{ backgroundColor: 'none', zIndex: '13', width: '100%', display: 'flex' }}>
            <div ref={componentRef} className="doYouWatToSetCont" >
                <p >{text}</p>
                <div className="yesAndNo" style={{ display: 'flex', marginTop: '20px' }}>
                    <p onClick={() => handleYesUpdate()} style={{ textAlign: 'center', width: '130px' }} className="activeBlack">Update Now</p>
                </div>
            </div>
        </div>
    )

}