export const pureExpItems = [
    { name: 'Month', productID: 'subs.pure1m', totalPrice: 1.99, duration: 1, save: '0 %' },
    { name: 'Months', productID: 'subs.pure6m', totalPrice: 8.99, duration: 6, save: '40 %' },
    { name: 'Months', productID: 'subs.pure12m', totalPrice: 11.99, duration: 12, save: '60 %' },
]

export const coachExpItems = [
    { name: 'Month', productID: 'subs.coach1m', totalPrice: 2.99, duration: 1, save: '0 %' },
    { name: 'Months', productID: 'subs.coach6m', totalPrice: 11.99, duration: 6, save: '40 %' },
    { name: 'Months', productID: 'subs.coach12m', totalPrice: 17.99, duration: 12, save: '60 %' },
]

export const consItems = [
    { name: 'Cons1', productID: 'cons.test2', totalPrice: 6.99, duration: 1, save: '0 %' },
    { name: 'Cons3', productID: 'cons.test3', totalPrice: 6.99, duration: 1, save: '0 %' },
     
]