import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import "./list.css";
import { selectMainId, selectInfo, selectStatus, clearInfo, loadUser } from "./tempProfileSlice";
import { useSwipeable } from "react-swipeable";
import { OtherProfile } from "../user/OtherProfile"



export function TempProfile({ }) {
  const [friend, setFriend] = useState(false);
  const [block, setBlock] = useState('')
  const mainid = useSelector(selectMainId);
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const userID = info.user[0].mainid;
      if (info.friends[userID].status === "friends") {
        setFriend(true);
      } else if (info.friends[userID].status === "iblock") {
        dispatch(clearInfo());
      } else if (info.friends[userID].status === "blocki") {
        dispatch(clearInfo());
      } else {
        setFriend(false);
      }
    } catch (e) {
      setFriend(false);
    }
  }, [info, mainid]);

  const openChat = ""

  const back = () => {
    try {
      dispatch(clearInfo());
    } catch (e) {

    }
  };

  useEffect(() => {
    if (mainid) {
      dispatch(loadUser(mainid))
    }
  }, [mainid])

  const handleSwipeRight = () => {
    back()
  };

  const swipeBackHandlersBack = useSwipeable({
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
  });

  if (block === 'iblock' || block === 'blocki') {
    return <h1>content unavailable</h1>
  }

  if (mainid) {
    if (status === "done") {
      return (
        <div {...swipeBackHandlersBack} className="tempProfilePage">
          <OtherProfile back={back} info={info} openChat={openChat} friend={friend} setFriend={setFriend} />
        </div>
      );
    } else {
      return (
        <div className="tempProfilePage">
          <div style={{ zIndex: '30', marginTop: '50px' }} className="otherProfileCont">
            <div className="repInProf">
            </div>
            <img className="otherProfileBackArrow" />
            <h3 className="fake otherProfileName">Loading...</h3>
            <div className="otherProfilePic" />
            <div className="fake otherProfilegendandage">
              <p>Loading...</p>
              <img style={{ filter: 'greyscale(1)', width: '30px', borderRadius: '10px' }} />
              <img style={{ filter: 'greyscale(1)', width: '30px', borderRadius: '10px' }} />
            </div>
            <div style={{ marginTop: '17px', marginBottom: '10px' }} className="fake otherProfilegendandage">
              <p >BUDDIES: ...</p>
            </div>
            <p className="fake otherProfileBio">Loading...</p>
            <div className="fake otherFavs">
              <p>No Favorite Sport</p>
            </div>
            <p className='fake' style={{ marginTop: '5px', fontWeight: '600' }}>Loading...</p>
            <div className="proFrBt">
            </div>
          </div>
        </div>)
    }
  }

};

