import React from 'react';

export const RankTimeHeader = ({ time }) => {
    // Create a Date object from the time prop
    const date = new Date(time);

    // Options for formatting the date to show only month and year
    const options = { month: 'short', year: 'numeric' };

    // Format the date
    const formattedDate = date.toLocaleDateString(undefined, options);

    return (
        <div className="ranking-header">
            <p style={{fontSize:'30px'}}>{formattedDate}</p>
        </div>
    );
};
