import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import "./repChoice.css"
import { selectInfo, clearInfo } from "./repChoiceSlice";
import { clearInfo as clearTempProfile } from '../list/tempProfileSlice';
import { handleReport } from "../user/userFuncs";
import { useNavigate } from 'react-router-dom';


export function RepChoice({ }) {
  const info = useSelector(selectInfo);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleButtonPress = (content, info) => {
    try {
      const confirmed = window.confirm(
        `Report ${info.name} ${content === 'abusive user' ? 'as' : 'for '} ${content}?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
      );
      if (confirmed) {
        confirmReport(content, info)
      } else {
        dispatch(clearInfo())
      }
    } catch (e) {

    }

  };

  const confirmReport = (content, info) => {
    try {
      handleReport(content, info.mainid, info.name, dispatch, clearInfo);
    } catch (e) {
    }
  };

  const blockUser = async (info) => {
    try {
      const receiverId = info.mainid
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/block-user`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ receiverId }),
        }
      );
      const data = await response.json();
      if (data === 'Blocked') {
        window.alert(`${info.name} is now blocked`)
        dispatch(clearInfo())
        if (window.location.pathname === `/home/:${info.mainid}`) {
          navigate(-1)
        } else {
          dispatch(clearTempProfile())       
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (info) {
    return (
      <div className="rep-choice-page">
        <div className="rep-choice-container">
          <h1 style={{ marginTop: '-15px', marginBottom: '0px' }} >Report !</h1>
          <h3 style={{ color: 'red' }}>{info.name} </h3>
          <h4 style={{ marginBottom: '5px' }} >Inappropriate content?</h4>
          <button
            className={`rep-choice-button`}
            onClick={() => handleButtonPress('Inappropriate profile picture', info)}
          >
            Profile Picture
          </button>
          <button
            className={`rep-choice-button`}
            onClick={() => handleButtonPress('Inappropriate bio', info)}
          >
            Bio
          </button>
          <button
            className={`rep-choice-button `}
            onClick={() => handleButtonPress('Inappropriate Detail', info)}
          >
            Detail
          </button>
          <button
            className={`rep-choice-button `}
            onClick={() => handleButtonPress('Inappropriate Location', info)}
          >
            Location
          </button>
          <button
            className={`rep-choice-button `}
            onClick={() => handleButtonPress('Inappropriate Chat', info)}
          >
            Chat
          </button>
          <button
            className={`rep-choice-button `}
            onClick={() => handleButtonPress('Inappropriate Facebook Link', info)}
          >
            Facebook Link
          </button>
          <button
            className={`rep-choice-button `}
            onClick={() => handleButtonPress('Inappropriate Instagram Link', info)}
          >
            Instagram Link
          </button>
          <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>

          <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
          <button
            style={{ color: 'red' }}
            className={`rep-choice-button `}
            onClick={() => blockUser(info)}
          >
            Block User
          </button>
          <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>

          <button
            className={`rep-choice-button `}
            onClick={() => dispatch(clearInfo())}
          >
            cancel
          </button>
        </div>
      </div>
    );
  } else {
    return (
      ''
    )
  }

};

