import React from "react";

const TypingIndicator = ({ isTyping, isRecording }) => {
    return (
        <div className="typing-indicator">
            {isTyping && !isRecording && <div className="typing-dots">Typing<span className="dot"></span><span className="dot"></span><span className="dot"></span></div>}
            {isRecording && <div className="typing-dots">Recording Audio<span className="dot"></span><span className="dot"></span><span className="dot"></span></div>}
        </div>
    );
};

export default TypingIndicator;
