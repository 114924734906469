import React, { useState, useEffect } from "react";
import { getTimeOptions, calculateDelay } from "./intFuncs";

export const TimeInput = ({ setTime, maxTime }) => {
  const [selectedTime, setSelectedTime] = useState("");
  const [options, setOptions] = useState("");

  const handleTimeChange = (event) => {
    const selectedTimeString = event.target.value;
    const delay = calculateDelay(selectedTimeString);

    setSelectedTime(selectedTimeString);
    setTime({ selectedTime: selectedTimeString, delay: delay });
  };

  useEffect(() => {
    if (options === "") {
      setOptions(getTimeOptions(maxTime));
    }
  }, []);

  useEffect(() => {
    if (options) {
      const initialOption = options.options[0];
      setSelectedTime(initialOption.dateTimeString);
      const delay = initialOption.delay;
      setTime({ selectedTime: initialOption.dateTimeString, delay: delay });
    }
  }, [options]);

  if (options !== "") {
    return (
      <div className="timeCont">
        <label htmlFor="time">TIME</label>
        <select id="time" value={selectedTime} onChange={handleTimeChange}>
          {options.optionsJSX}
        </select>
      </div>
    );
  }
};