
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { handleCopyClick } from "../refprog/refProgFuncs";
import { RootContext } from "./RootContextProvider";
import AppleDownload from '../../Pics/AppleDownload.png';
import GoogleDownload from '../../Pics/GoogleDownload.png';
import feed from '../../Pics/download/feed.png'
import announce from '../../Pics/download/announce.png'
import discover from '../../Pics/download/discover.png'
import createpost from '../../Pics/download/createpost.png'
import coach from '../../Pics/download/coach.png'
import eventImg from '../../Pics/download/event.png'
import stopwatch from '../../Pics/download/stopwatch.png'
import log from '../../Pics/download/log.png'
import instagram from '../../Pics/instagram.png'


import homeLogo from '../../homeLogo.png'
import './downloadNow.css';
import Slider from 'react-slick';

const AppleLink = 'https://apps.apple.com/us/app/fit-buddy/id6450426723';
const GoogleLink = 'https://play.google.com/store/apps/details?id=com.fitbuddy.fitbuddy';
const instaGramLink = "instagram://user?username=fitbuddy_workoutbuddies"
const features = [
  {
    imgSrc: feed,
    altText: "Find Partners",
    description: "Find workout partners, groups, coaches & much more!"
  },
  {
    imgSrc: announce,
    altText: "Share Posts",
    description: "Announce your workout plans and connect with others who share your interests."
  },
  {
    imgSrc: discover,
    altText: "Friends System",
    description: "Find near sports enthusiasts who want to workout and connect with others who share your interests."
  },
  {
    imgSrc: createpost,
    altText: "Create post",
    description: "Create and share posts about your progress, and inspire others to share their experiences too."
  },
  {
    imgSrc: coach,
    altText: "Notifications",
    description: "Become a coach or find coaches nearby."
  },
  {
    imgSrc: eventImg,
    altText: "Stopwatch with Loop",
    description: "Create & Discover Events and Classes"
  },
  {
    imgSrc: stopwatch,
    altText: "Save Workouts",
    description: "Stay focused with our stopwatch that manages exercise, rest, and loops seamlessly."
  },
  {
    imgSrc: log,
    altText: "Save Workouts",
    description: "Track your workouts and confirm exercises done in groups or with partners."
  },
];

const settings = {
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  arrows: true,
  dots: true,
};

export const DownloadNow = () => {
  const { refLink, setRefLink } = useContext(RootContext);
  const [other, setOther] = useState(false)
  const navigate = useNavigate();

  useEffect(() => {
    try {
      if (window.location && window.location.href) {
        const currentUrl = window.location.href
        if (currentUrl.includes(`?ref=`)) {
          setRefLink(currentUrl)
          //try {
          //  handleCopyClick(currentUrl, 'Refrance Link')
          //} catch (e) {
          //
          //}
        } else {
          try {
            //if (/Android/.test(navigator.userAgent)) {
            //  window.location.replace(GoogleLink)
            //} else if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
            //  window.location.replace(AppleLink)
            //} else {
            //  setOther(true)
            //}
          } catch (e) {
            setOther(true)
          }
        }
      }
    } catch (error) {

    }
  }, [])

  return (
    <div className="download-now">
      <div className='intro'>
        <img src={homeLogo} alt="Fit-buddy" className="logoDownload" />
        <div style={{ width: '100%' }}>
          <h1 className="titleDownload">FIT-BUDDY</h1>
          <h1 className="subtitleDownload">No More Solo Workouts!</h1>
        </div>
      </div>
      {/* <div className="download-bar">
        <div className="download-bar-item">
          <img src={localStorage.getItem('duoGreen')} alt="App Store" className="icon" />
          <span className="user-count">{7}k+ Donwloads</span>
        </div>
        <a href={instaGramLink} className="download-bar-item">
          <img src={instagram} alt="Instagram" className="icon" />
          <span>4K+ Followers</span>
        </a>
      </div>*/}
      <Slider {...settings}>
        {features.map((feature, index) => (
          <div key={index} className='featureItemCont'>
            <div className="feature-item">
              <div className='feature-imgCont'>
                <img src={feature.imgSrc} alt="Fit-buddy" className="feature-img" />
              </div>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </Slider>
      <div className="download-buttons">
        <a className='activeBlack' href={AppleLink}>
          <img src={AppleDownload} alt="Download on the App Store" />
        </a>
        <a className='activeBlack' href={GoogleLink}>
          <img src={GoogleDownload} alt="Get it on Google Play" />
        </a>
      </div>
      {
        refLink && (<div style={{ color: 'white', margin: '20px 0px' }}>
          <p>Refrence Link:</p>
          <p>{refLink}</p>

          <p onClick={() => handleCopyClick(refLink, 'Refrance Link')} style={{ marginTop: '10px', width: '170px', height: '30px' }} className="singleButton activeBlack">Copy Reference Link</p>
        </div>)
      }

      <div className="PolicyEulaCont">
        <p><span onMouseUp={() => navigate('/policy')}>Policy</span> <span onMouseUp={() => navigate('/eula')}>EULA</span></p>
      </div>
    </div >
  );
};
