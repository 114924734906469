import React, { useState, useEffect } from 'react';

export function Clock() {
  const [dateTime, setDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    // Update the date and time every minute
    const interval = setInterval(() => {
      setDateTime(getCurrentDateTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  function getCurrentDateTime() {
    const now = new Date();
    const date = now.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit', // Use '2-digit' instead of 'numeric'
    });
    const options = {
      hour: '2-digit',
      minute: '2-digit'
    };
    if (window.location.pathname === '/home/profile') {
      options.second = '2-digit';
    }
    const time = now.toLocaleTimeString('en-US', options);
    return `${time} ${date}`;
    //return `${time}`;
  }

  return <div className='clock'>{dateTime}</div>;
}