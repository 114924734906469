import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./profile.css";
import { Bio } from "./Bio";
import { Age } from "./Age";
import { Fav1 } from './Fav1'
import { Fav2 } from './Fav2'
import { Clock } from './Clock'
import { ShowTo } from "./ShowTo";
import { disableRefresh, editData, enableRefresh, loadHome, selectData } from "../home/homeSlice";
import { loadCommon } from "../intButtons/intButtonsSlice";
import { ImageLinkChanger } from './ImageLinkChanger'
import TimeView from '../list/TimeView'
import connects from "../../Pics/connects2.png";
import { goToPlayersList } from './proFuncs'
import { Location } from './Location'
import { Swipe } from "../home/Swipe";
import { ChangePP } from "./changePP";
import changePPIMGG from "../../Pics/changePP.png";
import { SwipeInUser } from "../user/SwipeInUser";
import { PublicProfEdit } from "./PublicProfEdit";
import { RootContext } from "../root/RootContextProvider";
import { MyPage } from "../myPage/MyPage";


export function Profile({ }) {
  const { location } = useContext(RootContext);
  const data = useSelector(selectData)
  const [initialHeight, setInitialHeight] = useState(0);
  const [bigPic, setBigPic] = useState(false)
  const [PPMod, setPPMod] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(loadHome())
  }, [])

  function handleMessageEvent(event) {
    const data = event.data;
    try {
      if (data.length > 0 && data === 'OpenChangePP') {
        setBigPic(true)
        setTimeout(() => {
          setPPMod(true)
        }, 200);
      }
    } catch (e) {

    }
  }

  useEffect(() => {
    // Add event listeners when component mounts
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleMessageEvent);
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('message', handleMessageEvent);
    }
    // Clean up event listeners when component unmounts
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('message', handleMessageEvent);
      }
      if (typeof document !== 'undefined') {
        document.removeEventListener('message', handleMessageEvent);
      }
    };
  }, []);


  useEffect(() => {
    if (data) {
      setTimeout(() => {
        const infoContElement = document.getElementById('profileCont');
        if (infoContElement) {
          setInitialHeight(infoContElement.clientHeight);
        }
      }, 830);
    }
  }, [data]);

  const openEditPP = () => {
    if (PPMod === false) {
      dispatch(disableRefresh())
    }
    if (PPMod === true) {
      dispatch(enableRefresh())
    }
    setPPMod((PPMod) => !PPMod);

  };

  return (
    <div style={{ padding: '0px 0px 59px 0px', minHeight: initialHeight !== 0 ? initialHeight : '' }} id="profileCont" className="myPageCont friendsList">
      <div style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">
        {location === null ? (
          <Location />
        ) : (
          <div id="profileCont" className="profileCont">
            {<div onMouseUp={() => navigate('/home/workout')} className="workOutButotn activeBlack">
              FIND <br /> FITBUDDY
            </div>}
            {bigPic ?
              <div style={{ flexDirection: 'column' }} className="otherBigPic">
                <SwipeInUser back={() => setBigPic(false)} />
                <img onClick={() => setBigPic(false)} style={{ marginBottom: '10px' }} src={data.picture_url} />
                <img style={{ marginTop: '10px', width: '50px' }} onClick={openEditPP} className="openChangePP" src={changePPIMGG} />
                {PPMod ? <ChangePP PPMod={PPMod} setPPMod={setPPMod} /> : ""}
              </div>
              :
              ''
            }
            {
              data.interest ?
                <div className="confirmedwithtime">
                  <h3 style={{ textAlign: 'center' }} onClick={() => goToPlayersList(data, dispatch, loadCommon, navigate)} className="welcome"> CONFIRMED {data.interest.toUpperCase()}</h3>
                  <h3 className="profileTimeview">
                    <TimeView time={data.time} />
                  </h3>
                </div>
                :
                <h3 className="welcome"> WELCOME BACK, <br /> {data.name.split(' ')[0].toUpperCase()}!</h3>
            }
            <div className="infoCont" >
              <div className="ProfilePic">
                <img onClick={() => setBigPic(true)} style={{ width: '100%' }} loading="lazy" src={data.picture_url} />
              </div>
              <Clock />
              <div className="profileText">
                <p className="fullName">{data.name}</p>
                <div style={{ display: 'flex', marginLeft: '0px', marginBottom: '5px' }}>
                  <p className="gender">{data.gender.toUpperCase()},</p>
                  <Age data={data} />
                </div>
                <Bio data={data} />
              </div>
              {
                /*
                <div className="picandnot" >
                  <div onMouseUp={() => navigate('/home/feed')} className="changePPCont">
                    <img loading="lazy" className="feedButtonPhoto" src={feed} />
                  </div>
                  <div onMouseUp={() => navigate('/home/mypage')} className="changePPCont">
                    <img loading="lazy" className="oneUserPhoto" src={oneuser} />
                  </div>
                </div>
                */
              }
              <div className="publicStatusCont" >
                <PublicProfEdit public_profile={data.public_profile} />
              </div>
            </div>
            <div className="favsCont">
              <div className="favs">
                <Fav1 fav={data.fav1} />
                <Fav2 fav={data.fav2} />
              </div>
            </div>
            <div className="visbToCont">
              <ShowTo data={data} />
            </div>
            <div onMouseUp={() => navigate('/home/friends')} className="connectsbtn" >
              <div>
                <img src={connects} />
              </div>
              <p style={{ lineHeight: '1.1' }}>BUDDIES<br /> ( {Object.values(data.friends).filter(friend => friend.status === 'friends').length} )</p>
            </div>
            <ImageLinkChanger data={data} />
            <Swipe />

          </div>
        )
        }
        <MyPage />
      </div>
    </div >
  );
}
