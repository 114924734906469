export const textToDisplay = (status) => {
    switch (status) {
        case 'chat':
            return 'Sent Message';
        case 'groupchat':
            return 'Sent Group Message';
        case 'request':
            return 'Wants to connect';
        case 'accept':
            return 'Is now connected';
        case 'tagrequest':
            return 'Wants to tag you';
        case 'tagaccept':
            return 'Accepted your tag';
        case 'like':
            return 'Liked your Post';
        case 'commentonpost':
            return 'Commented on Post';
        case 'meetingrequest':
            return 'Wants to Workout';
        case 'meetingaccept':
            return 'Accepted workout';
        case 'meetingconfirmed':
            return 'Confirmed Workout';
        case 'applytocoach':
            return 'Wants Coaching';
        case 'acceptstudent':
            return 'Accepted Application';
        case 'applytogroup':
            return 'Want to Join Group';
        case 'invitetogroup':
            return 'Invited You to Group';
        case 'acceptgroupmember':
            return 'Accepted You in Group';
        case 'acceptinvitetogroup':
            return 'Accepted Group Invite';
        case 'groupmeetingtime':
            return 'Group Meeting Set';
        case 'groupmeetingconfirmed':
            return 'Confirmed Group Meeting';
        case 'eventcomment':
            return 'Commented on Event ';
        case 'eventjoin':
            return 'Joined Event ';
        default:
            return ''; // or any default value or action
    }
}

export const generateRequestLinkOld = (request, id) => {
    const link = request.status === 'groupchat' ?
        `/home/groups/group/:${request.groupid}` :
        request.status === 'tagaccept'
            ? `/home/post/:${request.postid}`  // If tagaccept, link to a tagaccept page
            : request.status === 'tagrequest'
                ? `/home/post/:${request.postid}`  //commentonpost If tagrequest, link to a tagrequest page
                : request.status === 'like'
                    ? `/home/post/:${request.postid}`        // If like, link to a like page
                    : request.status === 'commentonpost'
                        ? `/home/post/:${request.postid}`        // If like, link to a like page
                        : request.status === 'meetingrequest'
                            ? `/home/:${request.senderid}`  // If meetingrequest, link to a meetingrequest page
                            : request.status === 'meetingaccept'
                                ? `/home/:${request.senderid}`  // If meetingrequest, link to a meetingrequest page
                                : request.status === 'meetingconfirmed'
                                    ? `/home/:${request.senderid}`  // If meetingrequest, link to a meetingrequest page
                                    : request.status === 'applytocoach'
                                        ? `/home/coaches/coachaccount`  // If meetingrequest, link to a meetingrequest page
                                        : request.status === 'acceptstudent'
                                            ? `/home/coaches/coach/:${request.senderid}`  // If meetingrequest, link to a meetingrequest page
                                            : request.status === 'applytogroup'
                                                ? `/home/groups/group/:${id}`  // If meetingrequest, link to a meetingrequest page
                                                : request.status === 'invitetogroup' || request.status === 'acceptgroupmember'
                                                    ? `/home/groups/group/:${request.senderid}`  // If meetingrequest, link to a meetingrequest page
                                                    : request.status === 'acceptinvitetogroup'
                                                        ? `/home/groups/group/:${id}`  // If meetingrequest, link to a meetingrequest page
                                                        : request.status === 'groupmeetingtime'
                                                            ? `/home/groups/group/:${request.senderid}`  //'groupmeetingtime', 'groupmeetingconfirmed' If meetingrequest, link to a meetingrequest page
                                                            : request.status === 'groupmeetingconfirmed'
                                                                ? `/home/myworkouts?Load:group`  //'groupmeetingtime', 'groupmeetingconfirmed' If meetingrequest, link to a meetingrequest page
                                                                : request.status === 'groupmeetingconfirmed'
                                                                    ? `/home/myworkouts?Load:group`  //'groupmeetingtime', 'groupmeetingconfirmed' If meetingrequest, link to a meetingrequest page
                                                                    : `/home/:${request.senderid}`


    return link
}

export const generateRequestLink = (request, id) => {
    switch (request.status) {
        case 'groupchat':
            return `/home/groups/group/:${request.groupid}`;
        case 'tagaccept':
        case 'tagrequest':
        case 'like':
        case 'commentonpost':
            return `/home/post/:${request.postid}`;
        case 'meetingrequest':
        case 'meetingaccept':
        case 'meetingconfirmed':
            return `/home/:${request.senderid}`;
        case 'applytocoach':
            return `/home/coaches/coachaccount`;
        case 'acceptstudent':
            return `/home/coaches/coach/:${request.senderid}`;
        case 'applytogroup':
        case 'acceptinvitetogroup':
            return `/home/groups/group/:${id}`;
        case 'invitetogroup':
        case 'acceptgroupmember':
        case 'groupmeetingtime':
            return `/home/groups/group/:${request.senderid}`;
        case 'groupmeetingconfirmed':
            return `/home/myworkouts?Load:group`;
        case 'eventjoin':
        case 'eventcomment':
            return `/home/event/:${request.eventid}`;
        default:
            return `/home/:${request.senderid}`; // or any default value or link
    }
}
