import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectChats, selectStatus, loadChats } from "./chatsSlice";
import { useNavigate } from "react-router-dom";
import { ChatCard } from './ChatCard'
import { FakeChatCard } from './FakeChatCard'

export function Chats() {
  const [search, setSearch] = useState('');
  const chats = useSelector(selectChats);
  const status = useSelector(selectStatus);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadChats());
  }, [dispatch]);

  const filteredChats = chats
    ? chats.filter(chat => {
        const otherUserName = chat.other_user_name.toLowerCase();
        return otherUserName.includes(search.toLowerCase());
      })
    : [];

  const renderedChats = filteredChats
    .slice()
    .sort((a, b) => b.chat_id - a.chat_id)
    .map((chat) => (
      <div onMouseUp={() => navigate(`/home/:${chat.other_user_id}`)} key={chat.chat_id}>
        <ChatCard chat={chat} />
      </div>
    ));

  return (
    <div className="friendsList">
       <div style={{marginBottom:'10px'}} className="seachContFR">
          <input
            className="hint"
            type="text"
            id="search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            maxLength={17}
            placeholder="search"
          />
        </div>
      <div style={{ padding: '10px', marginTop: '0px', marginBottom: '25px' }} className="scrollList">
        {status === 'done' ? renderedChats : Array(10).fill().map((_, index) => <FakeChatCard key={index} />)}
      </div>
    </div>
  );
}
