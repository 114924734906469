import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import search from '../../Pics/search.png'
import { editData, handleShowBottomNav, selectData } from '../home/homeSlice';
import { RootContext } from '../root/RootContextProvider';
import { EventCreatorOrEditor } from './EventCreatorOrEditor';
import { EventPreview } from './EventPreview';
import { EventCard } from './EventCard';


export function CreateEvent({ }) {
    const { draftEvent, setDraftEvent } = useContext(RootContext);
    const [type, setType] = useState('Editor')
    const [creating, setCreating] = useState(false)
    const myInfo = useSelector(selectData)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [photo64, setPhoto64] = useState('');
    let { eventid } = useParams();

    useEffect(() => {
        dispatch(handleShowBottomNav(false))
    }, [])

    function handleMessageEvent(event) {
        const data = event.data;
        try {
            if (data.length > 0 && data.startsWith('SelectedPhoto:')) {
                const data64 = data.substring(14);
                setSelectedPhoto(`data:image/jpeg;base64,${data64}`)
                setPhoto64(data64)
                setDraftEvent(prevState => ({
                    ...prevState,
                    image: `data:image/jpeg;base64,${data64}`,
                    image64: data64
                }));

            }
        } catch (e) {

        }
        try {
            if (data.length > 0 && data.startsWith('SelectedVideo:')) {
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Please select a photo`);
                } catch (e) {

                }
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        // Add event listeners when component mounts
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessageEvent);
        }
        if (typeof document !== 'undefined') {
            document.addEventListener('message', handleMessageEvent);
        }
        // Clean up event listeners when component unmounts
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessageEvent);
            }
            if (typeof document !== 'undefined') {
                document.removeEventListener('message', handleMessageEvent);
            }
            dispatch(handleShowBottomNav(true))
        };
    }, []);




    return (

        <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
            {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
            </div> : ""}
            <div style={{ zIndex: '20', marginTop: '0px' }} className="scrollList">
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">{!eventid ? 'CREATE' : "EDIT"} EVENT</label>
                </div>                
                <EventCreatorOrEditor event={draftEvent} setEvent={setDraftEvent} />
            </div>
        </div>
    )
}
