export const handleEditGender = (gender, dispatch, loadHome, editData, navigate) => {
  if (gender === 'male' || gender === 'female') {
    dispatch(editData({ gender: gender }))
    fetch("/api/profile/updategender", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ gender }),
    })
      .then((response) => {
        if (response.ok) {
          dispatch(editData({ gender: gender }))
          dispatch(loadHome());
          navigate('/home/profile')
        } else {
          dispatch(loadHome());
          alert("Failed to update gender");
        }
      })
      .catch((error) => {
        dispatch(loadHome());
        console.error("Error updating gender:", error);
        alert("Failed to update gender");
      });
  }
};

export const updateVersion = (version) => { 
  if (version) {
    fetch(`/api/profile/updateversion`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ version: version }),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Version Ok')
          window.updatedVersionOnServer = true
        } else {
          console.log('Version Not Updated')
        }
      })
      .catch((error) => {
        console.error("Error updating age:", error);
      });
  }
};

export const handleEditBio = (newBio, dispatch, editData, setBioMod) => {
  if (newBio) {
    fetch(`/api/profile/updatebio`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ bio: newBio }),
    })
      .then((response) => {
        if (response.ok) {
          setBioMod(false)
          dispatch(editData({ bio: newBio }));
        } else {
          alert("Failed to update bio");
        }
      })
      .catch((error) => {
        console.error("Error updating bio:", error);
        alert("Failed to update bio1");
      });
  }
};

export const handleEditPP = (newPP, dispatch, loadHome) => {
  if (newPP) {
    fetch(`/api/profile/updatePP`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ PP: newPP }),
    })
      .then((response) => {
        if (response.ok) {
          dispatch(loadHome());
        } else {
          try {
            window.ReactNativeWebView.postMessage("Alert:Make sure its a valid photo link !");
          } catch (e) {
            alert("Failed to update PP");
          }
        }
      })
      .catch((error) => {
        try {
          window.ReactNativeWebView.postMessage("Alert:Make sure its a valid photo link !");
        } catch (e) {
          alert("Failed to update PP");
        }
      });
  }
};

export const handleEditAge = (newAge, dispatch, editData, setAgeMod) => {
  if (newAge) {
    fetch(`/api/profile/updateage`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ age: newAge }),
    })
      .then((response) => {
        if (response.ok) {
          setAgeMod(false)
          dispatch(editData({ age: JSON.stringify(newAge) }));
        } else {
          alert("Failed to update age");
        }
      })
      .catch((error) => {
        console.error("Error updating age:", error);
        alert("Failed to update age");
      });
  }
};

export const handleEditShowTo = (selectedShowTo, dispatch, editData) => {
  if (selectedShowTo !== null) {
    fetch(`/api/profile/updateshowto`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ showTo: selectedShowTo }),
    })
      .then((response) => {
        if (response.ok) {
          try {
            dispatch(editData({ showto: selectedShowTo }));
          } catch (e) {

          }
        } else {
          alert("Failed to update showTo");
        }
      })
      .catch((error) => {
        console.error("Error updating showTo:", error);
        alert("Failed to update showTo");
      });
  }
};


export async function updateLocation(setLocation, setRealCords) {
  if (navigator.geolocation) {
    try {
      const position = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      const cords = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      //setLocation('locationgranted');
      await saveLocation(cords, setLocation, setRealCords);
    } catch (error) {
      setLocation(null);
    }
  } else {
    setLocation(null);
  }
}

export async function saveLocation(cords, setLocation, setRealCords) {
  setLocation('GetReady');
  try {
    const response = await fetch(`/api/profile/updatelocation`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cords }),
    });
    if (response.ok) {
      setRealCords(JSON.stringify(cords))
      setLocation('locationSaved');
    } else {
      setLocation(null);
      setRealCords(null)
      console.error("Failed to save location");
    }
  } catch (error) {
    setLocation(null);
    setRealCords(null)
    console.error("Error saving location:", error);
  }
}

export async function saveMobSubscription(subscription, setSubColor) {
  try {
    const response = await fetch(`/api/subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ subscription }),
    })
    if (response.ok) {
      setSubColor('hsla(72, 100%, 47%, 65%)');
    } else {
      setSubColor('rgba(255, 0, 0, 0.274)');
      console.error("Failed to save subscription");
    }

  } catch (error) {
    setSubColor('rgba(255, 0, 0, 0.274)');
    console.error("Error saving subscription:", error);
  }
}

export const goToPlayersList = (data, dispatch, loadCommon, navigate) => {
  try {
    if (data) {
      if (data.interest) {
        const radius = data.radius
        dispatch(loadCommon({ interest: data.interest, radius }));
        navigate('/home/workout/findduo')
      }
    }
  } catch (e) {

  }
};

export const calculateAge = (age) => {
  try {
    if (age.includes('year')) {
      const ageObj = JSON.parse(age);
      const currentDate = new Date();
      const birthDate = new Date(ageObj.year, ageObj.month - 1, ageObj.day);
      let ageYears = currentDate.getFullYear() - birthDate.getFullYear();

      // Adjust age based on the month and day
      if (
        currentDate.getMonth() < birthDate.getMonth() ||
        (currentDate.getMonth() === birthDate.getMonth() &&
          currentDate.getDate() < birthDate.getDate())
      ) {
        ageYears--;
      }

      return <p>{ageYears}</p>;
    } else {
      return (
        <p>{age}</p>
      ); // Return null instead of an empty <p> element
    }
  } catch (e) {
    return (
      <p>{age}</p>
    );
  }
};


export  async function getLocalStorageUsage() {
  let totalBytes = 0;
  for (let key in localStorage) {
    if (localStorage.hasOwnProperty(key)) {
      totalBytes += key.length + localStorage.getItem(key).length;
    }
  }
  // Convert bytes to kilobytes and megabytes for readability
  const usedKB = (totalBytes / 1024).toFixed(2);
  const usedMB = (totalBytes / 1024 / 1024).toFixed(2);

  // Estimate total local storage capacity (most browsers provide around 5MB per domain)
  const totalCapacityKB = 5120; // 5MB in kilobytes
  const totalCapacityMB = (totalCapacityKB / 1024).toFixed(2);

  // Calculate remaining space
  const remainingKB = totalCapacityKB - usedKB;
  const remainingMB = (remainingKB / 1024).toFixed(2);

  console.log(`Used Storage: ${usedKB} KB (${usedMB} MB)`);
  console.log(`Total Capacity: ${totalCapacityKB} KB (${totalCapacityMB} MB)`);
  console.log(`Remaining Storage: ${remainingKB} KB (${remainingMB} MB)`);
  try {
    window.ReactNativeWebView.postMessage(`Alert:${usedMB} / ${totalCapacityMB} `);
  } catch (e) {

  }
}


