import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { handleShowBottomNav, selectData } from "../home/homeSlice";
import { loadGroupPage } from './groupsFuncs'
import { GroupCard } from "./GroupCard";
import socket from "../../app/io";
import { FakeGroupCard } from "./FakeGroupCard";
import { SmallGroupCard } from "./SmallGroupCard";
import { GroupChat } from "./GroupChat";
import { GroupMeetingTime } from "./GroupMeetingTime";
import { ResetGroupHelper } from "./ResetGroupHelper";

export function GroupPage({ }) {
  const [groupStatus, setGroupStatus] = useState('none')
  const myInfo = useSelector(selectData)
  const [creating, setCreating] = useState(false)
  const [group, setGroup] = useState(null)
  const [loadingGroup, setLoadingGroup] = useState(false)
  const [onChat, setOnChat] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  let { groupid } = useParams();

  useEffect(() => {
    let id = groupid.replace(":", "");
    loadGroupPage(id, group, setGroup, setLoadingGroup)
  }, [dispatch, groupid]);

  useEffect(() => {
    if (group && myInfo) {
      const myMainid = myInfo.mainid
      if (group.groupid === myMainid) {
        dispatch(handleShowBottomNav(false))
        try {
          window.ReactNativeWebView.postMessage(`disableAd`);
        } catch (e) {
        }
        setGroupStatus('owner')
        return
      }
      try {
        const member = group.members.find((member) => member.mainid === myMainid);
        if (member) {
          if (member.status === 'request') {
            setGroupStatus('request');
            setOnChat(false)
          } else if (member.status === 'accepted') {
            setGroupStatus('accepted');
            dispatch(handleShowBottomNav(false))
            try {
              window.ReactNativeWebView.postMessage(`disableAd`);
            } catch (e) {
            }
            setOnChat(true)
          } else if (member.status === 'invited') {
            setGroupStatus('invited');
            setOnChat(false)
          }
        } else {
          setGroupStatus('none');
          setOnChat(false)
        }
      } catch (e) {
        setGroupStatus('none');
        setOnChat(false)
      }
    }
  }, [group, myInfo]);

  useEffect(() => {
    if (loadingGroup === 'done' && group && myInfo) {

      try {
        if ((group.iblock && group.iblock.includes(myInfo.mainid)) || (group.blocki && group.blocki.includes(myInfo.mainid))) {
          setGroupStatus('block')
        }
      } catch (e) {

      }
    }
  }, [group, groupStatus]);


  useEffect(() => {
    return () => {
      dispatch(handleShowBottomNav(true))
      try {
        //window.ReactNativeWebView.postMessage(`enableAd`);
      } catch (e) {
      }
    };
  }, [])

  if (loadingGroup === 'done' && groupStatus === 'block') {
    return (
      <div style={{ marginTop: '0px', width: '100%' }}>
        <h2 style={{ margin: '20px auto', color: 'white' }}>Group Unavailable</h2>
      </div>
    )
  }

  if (loadingGroup === 'done' && group.interest === null) {
    return (
      <div style={{ marginTop: '0px', width: '100%' }}>
        <h2 style={{ margin: '20px auto', color: 'white' }}>Group Disbanded</h2>
        {window.location.pathname === `/home/groups/group/:${myInfo.mainid}` ? <p onMouseUp={() => navigate('/home/groups/creategroup')} style={{ width: '150px' }} className="singleButton">CREATE GROUP</p> : ""}
      </div>
    )
  }

  if (loadingGroup === 'done') {
    return (
      <div style={{ paddingTop: '10px' }} className="friendsList">
        <ResetGroupHelper group={group} setGroup={setGroup} setLoadingGroup={setLoadingGroup} />
        <div style={{ marginTop: '0px', marginBottom: '10px', paddingBottom: '100px' }} className="scrollList">

          {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
            <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
          </div> : ""}
          {onChat ?
            <SmallGroupCard group={group} myMainid={myInfo.mainid} setCreating={setCreating} setOnChat={setOnChat} />
            :
            <GroupCard group={group} myMainid={myInfo.mainid} setCreating={setCreating} />
          }
          {groupStatus === 'accepted' || groupStatus === 'owner' ?
            <GroupMeetingTime group={group} groupStatus={groupStatus} myMainid={myInfo.mainid} setCreating={setCreating} />
            : ""
          }
          {groupStatus === 'accepted' || groupStatus === 'owner' ?
            <div onClick={() => setOnChat(true)} className="onChatCont" style={{ height: `calc(100% - ${onChat ? '170px' : '260px'})` }}>
              <GroupChat myInfo={myInfo} group={group} groupStatus={groupStatus} onChat={onChat} />
            </div>
            :
            ""
          }
        </div>
      </div>
    )
  } else {
    return (
      <div style={{ marginTop: '0px', width: '100%' }}>
        <FakeGroupCard />
      </div>
    )
  }

}
