import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { loadMyGroups, selectMyGroup, selectMyGroupsStatus, selectMyJoinedGroups } from './groupsSlice';
import { useDispatch, useSelector } from 'react-redux';

import search from '../../Pics/search.png'
import './groups.css'
import { GroupCard } from './GroupCard';
import { editData, selectData } from '../home/homeSlice';
import socket from "../../app/io";
import { FakeGroupCard } from './FakeGroupCard';
import { GroupMeetingTime } from './GroupMeetingTime';

export function Groups({ }) {
    const [selectedFilter, setSelectedFilter] = useState('none')
    const [creating, setCreating] = useState(false)
    const myInfo = useSelector(selectData)
    const myGroupsStatus = useSelector(selectMyGroupsStatus)
    const myGroup = useSelector(selectMyGroup)
    const myJoinedGroups = useSelector(selectMyJoinedGroups)
    const [activeGroup, setActiveGroup] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if (window.location.pathname === '/home/groups') {
            dispatch(loadMyGroups())
        }
    }, [window.location.pathname])

    useEffect(() => {
        try {
            if (myGroupsStatus === 'done' && myGroup && myGroup.interest) {
                setActiveGroup(true)
            } else {
                setActiveGroup(false)
            }
        } catch (e) {
            setActiveGroup(false)
        }
    }, [myGroupsStatus, myGroup])

    useEffect(() => {
        socket.on("resetMyGroups", ({ }) => {
            if (window.location.pathname === `/home/groups`) {
                dispatch(loadMyGroups())
            }
        });

        return () => {
            socket.off("resetMyGroups");
        };
    }, [socket, dispatch]);

    useEffect(() => {
        if (myInfo && (myInfo.location === null || myInfo.location === '{}')) {
            dispatch(editData({ location: 'tryingagain' }))
            try {
                window.ReactNativeWebView.postMessage("callLocationFromExpo");
            } catch (e) {
            }
        }
    }, [])

    if (myInfo && (myInfo.location === null || myInfo.location === '{}')) {
        return (
            <div style={{ margin: '20px auto' }} className="boxLogin">
                <p className="disclaimer">
                    To continue using our app please enable location servecies to find people around you.
                    <br />
                    If it is enabled please restart the App.
                </p>
            </div>
        )
    }

    return (
        <div>
            <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
                {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                    <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
                </div> : ""}
                <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                    <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                        <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-GROUPS</label>
                    </div>
                    <div style={{ marginTop: '10px', padding: '0px 10px', gap: '10px', borderRadius: '0px', height: "50px" }} className="feedFilter">
                        <div onMouseUp={() => navigate('/home/groups/creategroup')} className='activeBlack buttonWithImage'>
                            <h5>CREATE GROUP</h5>
                            <img src={localStorage.getItem('plus')} />
                        </div>
                        <div onMouseUp={() => navigate('/home/groups/findgroups')} className='activeBlack buttonWithImage'>
                            <h5>FIND GROUPS</h5>
                            <img src={search} />
                        </div>
                    </div>
                    {myGroupsStatus === 'done' ?
                        <div>
                            {activeGroup ?
                                <div style={{ marginBottom: '10px' }} className="">
                                    <h4 className='postCont' style={{ marginBottom: '10px', marginLeft: '0px', paddingLeft: '10px', color: 'hsl(72, 100%, 47%)' }} >CREATED GROUP :</h4>
                                    <GroupCard group={myGroup} myMainid={myInfo.mainid} setCreating={setCreating} />
                                    <GroupMeetingTime group={myGroup} groupStatus='owner' myMainid={myInfo.mainid} setCreating={setCreating} />
                                </div>
                                :
                                <div className='postCont'>
                                    <h3 style={{ color: 'white' }}>No Active Group Created</h3>
                                </div>
                            }
                            {myJoinedGroups && myJoinedGroups.length > 0 ?
                                <div className="">
                                    <h4 className='postCont' style={{ marginLeft: '0px', paddingLeft: '10px' }} >JOINED GROUPS :</h4>
                                    {myJoinedGroups.map((group) => {
                                        return (
                                            <div key={group.groupid}>
                                                <GroupCard group={group} myMainid={myInfo.mainid} setCreating={setCreating} />
                                            </div>
                                        )
                                    })}
                                </div>
                                : ""
                            }
                        </div>
                        :
                        <div className="">
                            <div style={{ marginBottom: '10px' }} className="">
                                <h4 className='postCont' style={{ marginBottom: '10px', marginLeft: '0px', paddingLeft: '10px', color: 'hsl(72, 100%, 47%)' }} >CREATED GROUP :</h4>
                                <FakeGroupCard />
                                <div className='activeBlack GroupMeetingTimeCont'>
                                    <p className='fake'>Loading ...</p>
                                </div>
                            </div>
                            <div className="">
                                <h4 className='postCont' style={{ marginLeft: '0px', paddingLeft: '10px' }} >JOINED GROUPS :</h4>
                                <FakeGroupCard />
                                <FakeGroupCard />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}
