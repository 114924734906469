import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadUsers = createAsyncThunk("admin/loadUsers", async ({ name }) => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/admin/loadusers?name=${name}`);
  const json = await data.json();
  return json;
});

export const loadUser = createAsyncThunk("admin/loadUser", async ({ id }) => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/admin/loaduser?mainid=${id}`);
  const json = await data.json();
  return json;
});

export const loadusersByReportCount = createAsyncThunk("admin/loadusersByReportCount", async ({ count }) => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/admin/loadusersByReportCount?count=${count}`);
  const json = await data.json();
  return json;
});

export const loadBannedUsers = createAsyncThunk("admin/loadbannedusers", async () => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/admin/loadbannedusers`);
  const json = await data.json();
  return json;
});

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    users: [],
    usersStatus: "idle",
  },
  reducers: {
    reset: (state) => {
      state.users = [];
      state.usersStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadUsers.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(loadUsers.fulfilled, (state, action) => {
        state.usersStatus = "done";
        state.users = action.payload;
        console.log(state.users)
      })
      .addCase(loadUsers.rejected, (state, action) => {
        state.usersStatus = "failed";
        console.log(state.usersStatus);
        state.users = [];
      })
      .addCase(loadUser.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.usersStatus = "done";
        state.users = [action.payload];
        console.log(state.users)
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.usersStatus = "failed";
        console.log(state.usersStatus);
        state.users = [];
      })
      .addCase(loadusersByReportCount.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(loadusersByReportCount.fulfilled, (state, action) => {
        state.usersStatus = "done";
        state.users = action.payload;
        console.log(state.users)
      })
      .addCase(loadusersByReportCount.rejected, (state, action) => {
        state.usersStatus = "failed";
        console.log(state.usersStatus);
        state.users = [];
      })
      .addCase(loadBannedUsers.pending, (state) => {
        state.usersStatus = "loading";
      })
      .addCase(loadBannedUsers.fulfilled, (state, action) => {
        state.usersStatus = "done";
        state.users = action.payload;
        console.log(state.users)
      })
      .addCase(loadBannedUsers.rejected, (state, action) => {
        state.usersStatus = "failed";
        console.log(state.usersStatus);
        state.users = [];
      });
  },
});

export const selectUsers = (state) => state.admin.users;
export const selectUsersStatus = (state) => state.admin.usersStatus;

export const { reset } = adminSlice.actions;

export default adminSlice.reducer;
