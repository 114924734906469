import React, { useState, useEffect } from 'react';
import { MeetManager } from './MeetManager';
import { FeaturedWorkout } from './FeaturedWorkout'
import { useDispatch, useSelector } from 'react-redux';
import { loadConfirmedFeatured, loadConfirmedCustom, selectConfirmedFeatured, selectConfirmedFeaturedStatus, selectConfirmedCustom } from './workoutsSlice';
import { CustomWorkouts } from './CustomWorkouts';
import { loadAudios } from './wokoutsAudio';
import { FakeMeetUpCard } from './FakeMeetUpCard';
import { ConfirmedGroupWorkoutCard } from './ConfirmedGroupWorkoutCard';
import { ConfirmedGroupMembers } from './ConfirmedGroupMembers';
import { useNavigate } from 'react-router-dom';

loadAudios()

export function MyWorkouts({ }) {
    const [creating, setCreating] = useState(false)
    const [confirmedGroupMembers, setConfirmedGroupMembers] = useState(null)
    const [showfirendsList, setShowFriendslist] = useState(false)
    const [meetingCount, setMeetingCount] = useState(0)
    const [meetings, setMeetings] = useState(null)
    const [loading, setLoading] = useState(null)
    const [myWorkoutFilter, setMyWorkoutFilter] = useState(localStorage.getItem('MyWorkoutFilter') || 'custom')
    const ConfirmedCustom = useSelector(selectConfirmedCustom)
    const ConfirmedFeatured = useSelector(selectConfirmedFeatured)
    const ConfirmedFeaturedStatus = useSelector(selectConfirmedFeaturedStatus)
    const [ConfirmedFCount, setConfirmedFCount] = useState(0)
    const [groupMeeting, setGroupMeeting] = useState(null)
    const [groupMeetingStatus, setGroupMeetingStatus] = useState(null)
    const [groupMeetingCount, setGroupMeetingCount] = useState(0)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        try {
            if (window.location.search !== "") {
                const tobeEdited = window.location.search
                if (tobeEdited.startsWith('?Load:')) {
                    const selectedShortCut = tobeEdited.substring(6)
                    setMyWorkoutFilter(selectedShortCut)

                }

            }
        } catch (e) {

        }
    }, [])

    async function fetchMyMeetings() {
        try {
            const response = await fetch('/api/meetings/loadmymeeting'); // Adjust the API endpoint as needed
            if (response.ok) {
                const data = await response.json();
                setMeetings(data);
                setLoading('done')
            } else {
                console.error('Failed to fetch posts');
                setLoading(null)
            }
        } catch (error) {
            setLoading(null)
            console.error('Error fetching posts:', error);
        }
    }

    async function fetchMyGroupMeetings() {
        try {
            const response = await fetch('/api/meetings/loadmygroupmeeting'); // Adjust the API endpoint as needed
            if (response.ok) {
                const data = await response.json();
                setGroupMeeting(data);
                setGroupMeetingStatus('done')
            } else {
                console.error('Failed to fetch posts');
                setGroupMeetingStatus(null)
            }
        } catch (error) {
            setGroupMeetingStatus(null)
            console.error('Error fetching posts:', error);
        }
    }

    useEffect(() => {
        if (!meetings) {
            setLoading(true)
        }
        fetchMyMeetings();
    }, []);

    useEffect(() => {
        if (meetings && ConfirmedFeatured === null) {
            dispatch(loadConfirmedFeatured())
        }
    }, [meetings]);

    useEffect(() => {
        if (ConfirmedFeatured && ConfirmedCustom === null) {
            dispatch(loadConfirmedCustom())
        }
    }, [ConfirmedFeatured]);

    useEffect(() => {
        if (ConfirmedCustom && groupMeeting === null) {
            fetchMyGroupMeetings()
        }
    }, [ConfirmedCustom]);


    useEffect(() => {
        try {
            if (meetings) {
                const confirmedMeetingsCount = meetings.reduce((count, meeting) => {
                    if (meeting.state === 'confirmed') {
                        return count + 1;
                    }
                    return count;
                }, 0);

                setMeetingCount(confirmedMeetingsCount);
            }
        } catch (e) {

        }

    }, [meetings]);

    useEffect(() => {
        try {
            if (ConfirmedFeatured) {
                setConfirmedFCount(ConfirmedFeatured.length)
            }
        } catch (e) {

        }
    }, [ConfirmedFeatured])

    useEffect(() => {
        try {
            if (groupMeeting) {
                setGroupMeetingCount(groupMeeting.length)
            }
        } catch (e) {
            setGroupMeetingCount(0)
        }
    }, [groupMeeting])


    return (
        <div style={{ zIndex: showfirendsList === true ? '10' : '9', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
            <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">MY-WORKOUTS</label>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                        <label style={{ fontSize: '12px' }}>Duo: {meetingCount}</label>
                        <label style={{ fontSize: '12px' }}>Group: {groupMeetingCount}</label>
                    </div>
                </div>
                {
                    <div style={{ padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
                        <label className="activeBlack" onClick={() => { setMyWorkoutFilter('custom'); localStorage.setItem('MyWorkoutFilter', 'custom'); }} style={{ color: myWorkoutFilter === 'custom' ? "" : '#f5f5f59c' }} htmlFor="comment">CUSTOM</label>
                        <label className="activeBlack" onClick={() => { setMyWorkoutFilter('duo'); localStorage.setItem('MyWorkoutFilter', 'duo'); }} style={{ color: myWorkoutFilter === 'duo' ? "" : '#f5f5f59c', fontSize: '14px' }} htmlFor="comment">DUO</label>
                        <label className="activeBlack" onClick={() => { setMyWorkoutFilter('group'); localStorage.setItem('MyWorkoutFilter', 'group'); }} style={{ color: myWorkoutFilter === 'group' ? "" : '#f5f5f59c' }} htmlFor="comment">GROUPS</label>
                        {/*<label className="activeBlack" onClick={() => { setMyWorkoutFilter('featured'); localStorage.setItem('MyWorkoutFilter', 'featured');}} style={{ color: myWorkoutFilter === 'featured' ? "" : '#f5f5f59c' }} htmlFor="comment">FEAT.</label>*/}
                    </div>
                }
                {myWorkoutFilter === 'custom' ?
                    <CustomWorkouts ConfirmedFeatured={ConfirmedFeatured} ConfirmedFeaturedStatus={ConfirmedFeaturedStatus} />
                    :
                    ""
                }
                {myWorkoutFilter === 'featured' ?
                    <FeaturedWorkout ConfirmedFeatured={ConfirmedFeatured} ConfirmedFeaturedStatus={ConfirmedFeaturedStatus} />
                    :
                    ""
                }
                {myWorkoutFilter === 'duo' ?
                    <MeetManager showfirendsList={showfirendsList} setShowFriendslist={setShowFriendslist} meetings={meetings} loading={loading} fetchMyMeetings={fetchMyMeetings} />
                    :
                    ""
                }
                {myWorkoutFilter === 'group' ?
                    <div>
                        {creating ? <div style={{ zIndex: '5000', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                            <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
                        </div> : ""}
                        {confirmedGroupMembers ? <ConfirmedGroupMembers groupName={confirmedGroupMembers[0].groupname} members={confirmedGroupMembers} setConfirmedGroupMembers={setConfirmedGroupMembers} /> : ""}
                        {groupMeetingStatus === 'done' ?
                            < div style={{ marginTop: '10px', width: 'calc(100% - 20px)' }} >
                                {
                                    groupMeeting && groupMeeting.length > 0 ?
                                        groupMeeting.map((workout) =>
                                            <ConfirmedGroupWorkoutCard key={workout.grouptime} workout={workout} setCreating={setCreating} setConfirmedGroupMembers={setConfirmedGroupMembers} />
                                        )
                                        :
                                        <div onMouseUp={() => navigate('/home/groups')} className='noPrevWorkout'>
                                            <p>
                                                No Confirmed Group Workouts Saved,<br /> Create or join a Group?
                                            </p>
                                            <img src={localStorage.getItem('friends2')} />
                                        </div>
                                }
                            </div>
                            :
                            < div style={{ marginTop: '10px', width: 'calc(100% - 20px)' }} >
                                <FakeMeetUpCard />
                                <FakeMeetUpCard />
                                <FakeMeetUpCard />
                            </div>
                        }
                    </div>
                    :
                    ""
                }
            </div>
        </div >
    );
}
