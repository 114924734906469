import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../../Pics/Logos.png'
import { selectLog, loadHome } from "../home/homeSlice";
import { useSelector, useDispatch } from "react-redux";

export function Founders({ }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate()
  const log = useSelector(selectLog);
  const dispatch = useDispatch()

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/adminlogin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (response.ok) {
        // Redirect to /home
        window.location.href = '/home/profile';
      } else {
        console.log('Authentication failed');
      }
    } catch (error) {
      console.log('Error occurred:', error);
    }
  };


  return (
    <div className="containerLogin">
      <div className="boxLogin">
        <img className="loginLogo" src={logo} />
        <form onSubmit={handleSubmit}>
          <div>
            <label>Username:</label>
            <input type="text" value={username} onChange={handleUsernameChange} required />
          </div>
          <div>
            <label>Password:</label>
            <input type="password" value={password} onChange={handlePasswordChange} required />
          </div>
          <button style={{marginTop: '10px'}} type="submit">Log in</button>
        </form>
      </div>
    </div>
  );

}

/*
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/construction">CONSTRUCTION</NavLink> 
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/architecture">ARCHITECTURE</NavLink>    
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/furniture">FURNITURE</NavLink>
*/
