import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { disableRefresh } from '../home/homeSlice';

import { loadMyCoachBigData } from './coachesSlice';

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export function CreateOrEditExperience({ experience, setOpenExpCreator, myMainid }) {
    const [creating, setCreating] = useState(false)
    const [indexId] = useState(experience.id || 'new');
    const [mediaType, setMediaType] = useState(experience.thumb ? 'video' : (experience.image ? 'image' : 'none'))
    const [selectedPhoto, setSelectedPhoto] = useState(experience.image || localStorage.getItem('plus'));
    const [photo64, setPhoto64] = useState('');
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [thumb, setThumb] = useState(experience.thumb || '')
    const [title, setTitle] = useState(experience.title || '');
    const [startDate, setStartDate] = useState(experience.startdate || getCurrentDate());
    const [endDate, setEndDate] = useState(!experience.id ? getCurrentDate() : experience.enddate || "");
    const [description, setDescription] = useState(experience.description || '');
    const dispatch = useDispatch()

    function handleMessageEvent(event) {
        const data = event.data;
        try {
            if (data.length > 0 && data.startsWith('SelectedPhoto:')) {
                const data64 = data.substring(14);
                setSelectedPhoto(`data:image/jpeg;base64,${data64}`)
                setSelectedVideo(null)
                setPhoto64(data64)
                setMediaType('image')
            }
        } catch (e) {

        }
        try {
            if (data.length > 0 && data.startsWith('SelectedVideo:')) {
                const vidueUrl = data.substring(14);
                setSelectedVideo(vidueUrl)
                setThumb(`https://fitbuddystorage.blob.core.windows.net/images/${myMainid}/TempThumb.jpeg?date=${Date.now()}`)
                setSelectedPhoto(null)
                setPhoto64(null)
                setMediaType('video')
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        // Add event listeners when component mounts
        if (typeof window !== 'undefined') {
            window.addEventListener('message', handleMessageEvent);
        }
        if (typeof document !== 'undefined') {
            document.addEventListener('message', handleMessageEvent);
        }
        // Clean up event listeners when component unmounts
        return () => {
            if (typeof window !== 'undefined') {
                window.removeEventListener('message', handleMessageEvent);
            }
            if (typeof document !== 'undefined') {
                document.removeEventListener('message', handleMessageEvent);
            }
        };
    }, []);

    const openImagePicker = () => {
        try {
            window.ReactNativeWebView.postMessage("PickPhoto");
            if (/Android/.test(navigator.userAgent)) {
                dispatch(disableRefresh());
            }
        } catch (e) {

        }
    };

    const handleSubmit = async () => {
        setCreating(true)
        if (!title || !description || !startDate) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Experience must have Title, Start Date and Description `);
            } catch (e) {

            }
            setCreating(false)
            return;
        }
        if (startDate && startDate > getCurrentDate()) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Make sure your experience starting date is before today`);
            } catch (e) {

            }
            setCreating(false)
            return;
        }
        if (endDate && endDate > getCurrentDate()) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Make sure your experience end date is before today`);
            } catch (e) {

            }
            setCreating(false)
            return;
        }
        const formData = new FormData();
        try {
            if (mediaType === 'image') {
                if (selectedPhoto.includes('https')) {
                    formData.append('image', selectedPhoto);
                } else {
                    const byteCharacters = window.atob(photo64);
                    const byteArrays = [];
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteArrays.push(byteCharacters.charCodeAt(i));
                    }
                    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'image/jpeg' });
                    formData.append('image', blob, 'exp.jpg');
                }
            }
            if (mediaType === 'video') {
                formData.append('videourl', selectedVideo);
            }
        } catch (e) {

        }

        formData.append('mediaType', mediaType);
        formData.append('expId', indexId);
        formData.append('title', title);
        formData.append('startDate', startDate);
        formData.append('endDate', endDate);
        formData.append('description', description);

        try {
            // Send the FormData to the server using the fetch API
            const response = await fetch('/api/coaches/submitexperience', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                dispatch(loadMyCoachBigData())
                setCreating(false)
                setOpenExpCreator(null)
            } else {
                setCreating(false)
                console.error('Post creation failed:', response.status);
            }
        } catch (error) {
            setCreating(false)
            console.error('Error creating post:', error);
        }
    };

    const clearMedia = () => {
        setSelectedVideo(null)
        setThumb(null)
        setSelectedPhoto(null)
        setPhoto64(null)
        setMediaType('none')

    }

    return (
        <div className='CreateEditExpPage'>
            {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Applying . . .</p>
            </div> : ""}
            <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">{experience.id !== 'new' ? 'EDIT EXPERIENCE' : 'ADD EXPERIENCE'}</label>
            </div>
            <div style={{ gridTemplateRows: '80px auto auto 40px', width: '100%', padding: '10px 10px', backgroundColor: "hsla(0, 0%, 25%, 0.6)" }} className='coachExpCardCont'>
                <div className='coachExpCardHead'>
                    <img onClick={() => openImagePicker()} className='expImage' src={mediaType === 'video' ? thumb : selectedPhoto ? selectedPhoto : localStorage.getItem('plus')} />
                    <div className='expInfo'>
                        <h4 style={{ marginLeft: '0px' }}>
                            <span style={{ color: 'hsl(72, 100%, 47%)' }}>Title: </span>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                max={50}
                            // Additional styling or attributes as needed
                            />
                        </h4>
                        <h5 style={{ marginLeft: '0px' }}>
                            <span style={{ color: 'hsl(72, 100%, 47%)' }}>Start Date: </span>
                            <input
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            // Additional styling or attributes as needed
                            />
                        </h5>
                        <h5 style={{ display: "flex", marginLeft: '0px' }}>
                            <span style={{ color: 'hsl(72, 100%, 47%)' }}>End Date: </span>
                            <input
                                style={{ marginLeft: '5px', marginRight: '5px', minWidth: '70px', minHeight: '10px' }}
                                type="date" // You might want to use a date picker component here
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                placeholder={getCurrentDate()}
                            // Additional styling or attributes as needed
                            />
                            {endDate ? "" : <p onClick={() => setEndDate("")} >Still Working</p>}
                        </h5>
                    </div>
                </div>
                <div className='expCardDescriptionCont'>
                    <h5 style={{ marginLeft: '0px', textAlign: 'left', marginBottom: '5px' }}>
                        <span style={{ color: 'hsl(72, 100%, 47%)' }}>Description: </span>
                    </h5>
                    <textarea
                        style={{ marginLeft: '0px', textAlign: 'left', height: '250px' }}
                        className='expCardDescription'
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength={500}
                    // Additional styling or attributes as needed
                    />
                </div>
                <div className='clearMediaOrEndDate'  >
                    <p onClick={() => clearMedia()} style={{ width: '105px', height: '20px' }} className='activeBlack singleButton2'>Clear Media</p>
                    <p onClick={() => setEndDate("")} style={{ width: '105px', height: '20px' }} className='activeBlack singleButton2'>Clear End Date</p>
                    <p onClick={() => { setOpenExpCreator(null) }} style={{ width: '105px', height: '20px' }} className='activeBlack singleButton2'>Discard</p>
                </div>
                <div className='submitOrCancelExp'  >
                    <p onClick={() => { handleSubmit() }} style={{ height: '20px' }} className='activeBlack singleButton'>Submit</p>
                </div>
            </div>

            <div style={{ height: '300px' }} />
        </div>
    );
}
