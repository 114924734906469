import html2canvas from 'html2canvas';


//export const appShare = async (title, message, image) => {
//     try {
//         const messageObject = {
//             title,
//             message
//         };
//         if (image) {
//             messageObject.imageData64 = image
//         }
//         const messageString = `CustomShare:${encodeURIComponent(JSON.stringify(messageObject))}`;
//         window.ReactNativeWebView.postMessage(messageString);
//     } catch (error) {
//         alert(error)
//         console.error('Error capturing and sharing screenshot:', error);
//     }
//};


export const appShare = async (title, message, postRef, videoUrl) => {
    try {
        const messageObject = {
            title,
            message
        };

        if (postRef) {
            await html2canvas(postRef.current, {
                useCORS: true,
                allowTaint: true,
            }).then(canvas => {
                const data64ToSend = canvas.toDataURL('image/png', 1);
                messageObject.imageData64 = data64ToSend
            }).catch(error => {
                console.error('Failed to capture page as image:', error);
            });
        }

        if (videoUrl) {
            messageObject.videoUrl = videoUrl
        }

        const messageString = `CustomShare:${encodeURIComponent(JSON.stringify(messageObject))}`;

        // Post the message to the React Native WebView
        window.ReactNativeWebView.postMessage(messageString);
    } catch (error) {
        console.error('Error capturing and sharing screenshot:', error);
    }
};
