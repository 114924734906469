import React, { useEffect, useState } from 'react';
import { ReportReview } from './ReportReview';
import greentick from '../../Pics/greentick.png'
import deleteImg from '../../Pics/delete.png'
import { useDispatch } from 'react-redux';
import { setMainId } from '../list/tempProfileSlice';
import { deleteFromStudents, editStudentsData, loadMyCoachBigData } from './coachesSlice';

export function MySingleStudent({ student, myMainid, setUpdating }) {
    const dispatch = useDispatch()

    const openStudentProfile = () => {
        dispatch(setMainId(student.studentid))
    }

    const handleAcceptStudent = async () => {
        setUpdating(true)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/coaches/acceptstudent`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ studentid: student.studentid }),
                }
            );
            if (response.ok) {
                const parent = "myStudents";
                const info = { ...student, status: 'accepted' };
                dispatch(editStudentsData({ parent, info }));
                setUpdating(false)
            } else {
                setUpdating(false)
            }
        } catch (error) {
            console.error(error);
            setUpdating(false)

        }
    }

    const handleRejectStudent = async () => {
        setUpdating(true)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/coaches/removestudent`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ studentid: student.studentid }),
                }
            );
            if (response.ok) {
                const parent = "myStudents";
                // To delete the student, you can pass the info with the same ID but without other properties
                dispatch(deleteFromStudents({ parent, info: { studentid: student.studentid } }));
                setUpdating(false)

            } else {
                setUpdating(false)
            }
        } catch (error) {
            console.error(error);
            setUpdating(false)

        }
    }


    return (
        <div className='singleStudentCont'>
            <img onClick={openStudentProfile} className='singleStudentProfilePic' src={student.picture_url} />
            <p onClick={openStudentProfile} >{student.name}</p>
            {student.status === 'request' && student.coachid === myMainid ? <img onClick={handleAcceptStudent} style={{ width: '25px' }} src={greentick} /> : <img style={{ width: '25px' }} />}
            {student.coachid === myMainid ? <img onClick={handleRejectStudent} style={{ width: '25px' }} src={deleteImg} /> : ""}
        </div>
    )

}