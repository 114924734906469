import React from 'react';
import { useDispatch } from "react-redux";
import { clearInfo } from '../root/repChoiceSlice';
import { handleReport } from "../user/userFuncs";
import { useNavigate } from 'react-router-dom';


export function ReportEvent({ eventname, eventid, creatorid, setShowReport, setEventDeleted, myMainid }) {
    const navigate = useNavigate()
    const dispatch = useDispatch();

    const handleButtonPress = (content, eventname, eventid) => {
        try {
            const confirmed = window.confirm(
                `Report ${eventname} ${content === 'abusive user' ? 'as' : 'for '} ${content}?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
            );
            if (confirmed) {
                confirmReport(content, eventname, eventid)
            } else {
            }
        } catch (e) {

        }

    };

    const confirmReport = (content, eventname, eventid) => {
        try {
            handleReport(content, creatorid, eventname, dispatch, clearInfo, eventid);
        } catch (e) {
        }
    };

    const blockUser = async (creatorid) => {
        try {
            const receiverId = creatorid
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/block-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ receiverId }),
                }
            );
            const data = await response.json();
            if (data === 'Blocked') {
                window.alert(`User is now blocked`)
                await handleHideGroup()
                dispatch(clearInfo())
                navigate(-1)
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHideGroup = async () => {
        setEventDeleted(true);
        setShowReport(false);
        fetch(`/api/events/hideevent`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ eventid: eventid }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        setEventDeleted(true)
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    if (myMainid === eventid) {
        return (
            <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
                <div onClick={() => ""} className="rep-choice-container">
                    <h2 style={{ marginBottom: '20px' }}>Can't Report Your Self!</h2>
                    <button
                        className={`rep-choice-button `}
                        onClick={() => setShowReport(false)}
                    >
                        Close
                    </button>
                </div>
            </div >
        )
    }

    return (
        <div style={{ position: 'relative' }} onClick={() => ""} className="rep-choice-container">
            <h2>REPORT !</h2>
            <h3 style={{ color: 'red' }}>Event : {eventname}<span style={{ color: 'black' }}></span> </h3>
            <h4 style={{ marginBottom: '5px' }} >Inappropriate Coach content?</h4>
            <button
                className={`rep-choice-button`}
                onClick={() => handleButtonPress('Inappropriate Event Photo', eventname, eventid)}
            >
                Event Photo
            </button>
            <button
                className={`rep-choice-button`}
                onClick={() => handleButtonPress('Inappropriate Event Name', eventname, eventid)}
            >
                Event Name
            </button>
            <button
                className={`rep-choice-button`}
                onClick={() => handleButtonPress('Inappropriate Event Location', eventname, eventid)}
            >
                Event Location
            </button>
            <button
                className={`rep-choice-button`}
                onClick={() => handleButtonPress('Inappropriate Event Ticket', eventname, eventid)}
            >
                Event Ticket
            </button>
            <button
                className={`rep-choice-button`}
                onClick={() => handleButtonPress('Inappropriate Event Details', eventname, eventid)}
            >
                Event Details
            </button>
            <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>
            <button
                className={`rep-choice-button`}
                onClick={handleHideGroup}
            >
                Just hide this Event
            </button>
            <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
            <button
                style={{ color: 'red' }}
                className={`rep-choice-button `}
                onClick={() => blockUser(creatorid)}
            >
                Block Event Creator
            </button>
            <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>
            <button
                className={`rep-choice-button `}
                onClick={() => setShowReport(false)}
            >
                cancel
            </button>
        </div>
    );

};

