import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MySingleStudent } from './MySingleStudent';


export const MyStudents = ({ students, myMainid }) => {
    const [updating, setUpdating] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState("Requests");
    const dispatch = useDispatch()

    return (
        <div>
            <div style={{ height: '40px', padding: '0px 50px', gap: '25px', borderRadius: '0px' }} className="feedFilter">
                <label className="activeBlack" onClick={() => setSelectedFilter('Requests')} style={{ color: selectedFilter === 'Requests' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Requests</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Confirmed')} style={{ color: selectedFilter === 'Confirmed' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Accepted</label>
            </div>
            {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                <p className='fake'> Confirming...</p>
            </div> : ""}
            {selectedFilter === 'Requests' ? (
                <div >
                    {students.map((student) => {
                        if (student.status === 'request') {
                            return (
                                <MySingleStudent key={student.studentid} student={student} myMainid={myMainid} setUpdating={setUpdating} />
                            )
                        }
                    })}
                </div>
            ) : null}
            {selectedFilter === 'Confirmed' ?
                <div>
                    {students.map((student) => {
                        if (student.status === 'accepted') {
                            return (
                                <MySingleStudent key={student.studentid} student={student} myMainid={myMainid} setUpdating={setUpdating} />
                            )
                        }
                    })}
                </div>
                :
                ''
            }

        </div>
    );
};
