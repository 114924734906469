import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { handleEditBio } from "./proFuncs";
import { editData } from "../home/homeSlice";

export function Bio({ data }) {
  const [newBio, setNewBio] = useState("");
  const [bioMod, setBioMod] = useState(false);
  const dispatch = useDispatch();

  const openEditBio = () => {
    setBioMod((bioMod) => !bioMod);
  };

  const closeEditBio = () => {
    setBioMod(false)
    //dispatch(loadHome())
  };

  const confirmBio = () => {
    handleEditBio(newBio, dispatch, editData, setBioMod);
  };

  return (
    <div className="bioCont">
      {bioMod === false ? (
        <div style={{ display: 'flex', width: '100%', paddingRight: '10px' }}  >
          <p style={{fontSize:'14px', textAlign: 'left' }} className="bio">{data.bio ? data.bio : 'Edit Bio'}</p>
          <img style={{ marginLeft: '10px' }} src={localStorage.getItem('edit')} className="editButton" onClick={openEditBio} />
        </div>
      ) : (
        <div className="editInfoCont">
          <h3>Write about yourself.</h3>
          <p>Provide a brief personal bio</p>
          <textarea
            id="bioInput"
            placeholder="Share your bio about yourself"
            className="bioInput"
            type="text"
            value={newBio}
            maxLength="80"
            onChange={(event) => setNewBio(event.target.value)}
          />
          <div>
            <button className="confirmButton" onClick={confirmBio}>
              Confirm
            </button>
            <button className="cancelButton" onClick={closeEditBio}>
              Cancel
            </button>
            <h3>{80 - newBio.length}</h3>
          </div>
        </div>

      )}
    </div>
  );
}
