import { createSlice } from '@reduxjs/toolkit';



export const repChoiceSlice = createSlice({
  name: 'repChoice',
  initialState: {   
    info: '',    
    status: 'idle',    
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {  
    clearInfo: (state, action) => {
      state.info = '';
      state.status = 'idle';
    },
    setInfo: (state, action) => {
      state.info = action.payload;      
    },    
  }  
});

export const selectInfo = (state) => state.repChoice.info;
export const selectStatus = (state) => state.repChoice.status;
export default repChoiceSlice.reducer;
export const { clearInfo, setInfo } = repChoiceSlice.actions;


