import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectData, selectLog } from "../home/homeSlice";
import { Swipe } from '../home/Swipe'
import { useNavigate } from "react-router-dom";
import NoAds from '../../Pics/NoAds.png'
import "./subs.css";
import greentick from '../../Pics/greentick.png'
import deletePic from '../../Pics/delete.png'
import { coachExpItems, consItems, pureExpItems } from "./subscriptionFuncs";


export function Subscriptions({ }) {
  const [creating, setCreating] = useState(false)
  const [currentSubName, setCurrentSubName] = useState(null)
  const [remainingTime, setRemainingTime] = useState(null)

  const navigate = useNavigate()
  const log = useSelector(selectLog);
  const myinfo = useSelector(selectData)

  useEffect(() => {
    try {
      if (myinfo.activesub) {
        switch (myinfo.activesub) {
          case 'subs.pure1m':
            setCurrentSubName('PURE Experience 1 Month');
            break;
          case 'subs.pure6m':
            setCurrentSubName('PURE Experience 6 Months');
            break;
          case 'subs.pure12m':
            setCurrentSubName('PURE Experience 12 Months');
            break;
          case 'subs.coach1m':
            setCurrentSubName('COACH Experience 1 Month');
            break;
          case 'subs.coach6m':
            setCurrentSubName('COACH Experience 6 Months');
            break;
          case 'subs.coach12m':
            setCurrentSubName('COACH Experience 12 Months');
            break;
          case 'override':
            setCurrentSubName('GIFT');
            break;
          // Add more cases as needed
          default:

            setCurrentSubName(null);
        }
      }
    } catch (e) {
      setCurrentSubName(null);
    }

    try {
      if (myinfo.subend) {
        // Parse the subend date
        let subendDate = new Date(myinfo.subend);
        // Get the current date
        let now = new Date();
        // Calculate the difference in milliseconds
        let timeDifference = subendDate - now;
        // Convert the difference to a more readable format
        let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        setRemainingTime(` ${days} days, ${hours} hours`)
      } else {
        setRemainingTime(null)
      }
    } catch (e) {
      setRemainingTime(null)
    }
  }, [myinfo])

  const buySub = (productID) => {
    if (myinfo.activesub === productID) {
      window.ReactNativeWebView.postMessage(`Alert:You Already own this product`);
      return
    }
    setCreating(true)
    setTimeout(() => {
      setCreating(false)
    }, 6000);
    window.ReactNativeWebView.postMessage(`BuySub:${productID}`);
  }

  const buyCons = (productID) => {
    window.ReactNativeWebView.postMessage(`BuyCons:${productID}`);
  }

  const restoreSub = () => {
    window.ReactNativeWebView.postMessage(`RestoreSub`);

  }

  return (

    <div className="scrollHelperContainer" >
      <Swipe />
      <div className="scrollHelper" >
        {<div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
          <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-SUBSCRIPTIONS</label>
        </div>}
        {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
          <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'>  Loading . . .</p>
        </div> : ""}
        {currentSubName && remainingTime ?
          <div style={{ marginBottom: '10px' }} className="currentSubTime">
            <p>Current Sub: {currentSubName}</p>
            <p>Remaining Time:{remainingTime}</p>
          </div>
          :
          ""
        }
        <h2 style={{ marginTop: '10px' }} className="SubTitle">PURE Experience:</h2>
        <div style={{ marginBottom: '10px', width: '60%', gridTemplateColumns: '1fr 1fr' }} className="imagesWithTextUnderCont">
          <div className="">
            <img style={{ width: '40px' }} className="appServiceButtonLogo" src={localStorage.getItem('NoAds')} />
            <h5 style={{ color: 'white' }}>Remove Ads</h5>
          </div>
          <div onMouseUp={() => navigate('/home/myworkouts?Load:custom')} className="">
            <img style={{ width: '40px' }} className="appServiceButtonLogo" src={localStorage.getItem('logWorkouts')} />
            <h5 style={{ color: 'white' }}>Save Workouts</h5>
          </div>
        </div>
        <div className="subGroupCont">
          {pureExpItems ? pureExpItems.map((item) => {
            return (
              <div style={{ backgroundColor: myinfo.activesub === item.productID ? "hsla(72, 100%, 47%, 0.7)" : "" }} key={item.productID} onClick={() => buySub(item.productID)} className="subscription-card activeBlack">
                <div className="card-header">
                  <h3 className="product-name">{item.duration}<br />{item.name}</h3>
                  <p className="price-per-month">{(item.totalPrice / item.duration).toFixed(2)} $ /<br /> month</p>
                </div>
                <div className="card-body">
                  <p className="total-price">Total Price:<br /> {item.totalPrice} $</p>
                  <p className="save-amount">Save: {((1 - ((item.totalPrice / item.duration) / 1.99)) * 100).toFixed(0) < 0 ? 0 : ((1 - ((item.totalPrice / item.duration) / 1.99)) * 100).toFixed(0)} %</p>
                </div>
              </div>
            )
          })
            : ""}
        </div>
        <h2 style={{ marginTop: '20px', width: '100%' }} className="SubTitle">COACH Subscription:</h2>
        <div className="imagesWithTextUnderCont" style={{ marginBottom: '10px', display: 'flex' }}>
          <div >
            <img style={{ width: '40px' }} className="" src={localStorage.getItem('NoAds')} />
            <h5 style={{ color: 'white' }}>Remove Ads</h5>
          </div>
          <div onMouseUp={() => navigate('/home/myworkouts?Load:custom')} className="">
            <img style={{ width: '40px' }} className="" src={localStorage.getItem('logWorkouts')} />
            <h5 style={{ color: 'white' }}>Save Workouts</h5>
          </div>
          <div onMouseUp={() => navigate('/home/coaches/coachaccount')} className="">
            <img style={{ width: '40px' }} className="" src={localStorage.getItem('whistle')} />
            <h5 style={{ color: 'white' }}>Coach Account</h5>
          </div>
        </div>
        <div className="subGroupCont">
          {coachExpItems ? coachExpItems.map((item) => {
            return (
              <div style={{ backgroundColor: myinfo.activesub === item.productID ? "hsla(72, 100%, 47%, 0.7)" : "" }} key={item.productID} onClick={() => buySub(item.productID)} className="subscription-card activeBlack">
                <div className="card-header">
                  <h3 className="product-name">{item.duration}<br />{item.name}</h3>
                  <p className="price-per-month">{(item.totalPrice / item.duration).toFixed(2)} $ /<br /> month</p>
                </div>
                <div className="card-body">
                  <p className="total-price">Total Price:<br /> {item.totalPrice} $</p>
                  <p className="save-amount">Save: {((1 - ((item.totalPrice / item.duration) / 2.99)) * 100).toFixed(0) < 0 ? 0 : ((1 - ((item.totalPrice / item.duration) / 2.99)) * 100).toFixed(0)} %</p>
                </div>
              </div>
            )
          })
            : ""}
        </div>
        <div className="subGroupCont">
          {/*
          consItems ? consItems.map((item) => {
            return (
              <div style={{ backgroundColor: myinfo.activesub === item.productID ? "hsla(72, 100%, 47%, 0.7)" : "" }} key={item.productID} onClick={() => buySub(item.productID)} className="subscription-card activeBlack">
                <div className="card-header">
                  <h3 className="product-name">{item.duration}<br />{item.name}</h3>
                  <p className="price-per-month">{(item.totalPrice / item.duration).toFixed(2)} $ /<br /> month</p>
                </div>
                <div className="card-body">
                  <p className="total-price">Total Price:<br /> {item.totalPrice} $</p>
                  <p className="save-amount">Save: {((1 - ((item.totalPrice / item.duration) / 6.99)) * 100).toFixed(0) < 0 ? 0 : ((1 - ((item.totalPrice / item.duration) / 6.99)) * 100).toFixed(0)} %</p>
                </div>
              </div>
            )
          })
            : ""
          */}
        </div>

        <div className="action-buttons">
          <div className="resotePurchCont activeBlack" onClick={restoreSub}>
            <p>Restore Purchase</p>
            <img style={{ width: '20px' }} src={localStorage.getItem('refreshGreen')} />
          </div>
          <div style={{ height: '30px', width: '90px' }} className="resotePurchCont activeBlack" onMouseUp={() => navigate('/policy')}>
            <p>POLICY</p>
          </div>
          <div style={{ height: '30px', width: '90px' }} className="resotePurchCont activeBlack" onMouseUp={() => navigate('/eula')}>
            <p>EULA</p>
          </div>
        </div>

        <div style={{ marginTop: '20px' }} className="refPrompCont">
          <h3>
            <strong className="highlight">INVITE</strong> Your Friends and Unlock  <br /> Pure Subscription Pack <br />
            <strong className="highlight">FOR FREE!</strong>
            <div className="yesAndNo">
              <button style={{ marginTop: '20px' }} onMouseUp={() => navigate('/home/refprog')} className="primaryButton">LEARN MORE</button>
            </div>
          </h3>
        </div>
        <div style={{ height: '200px' }} className="subGroupCont" />
      </div >
    </div>
  );
}
