import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSwipeable } from "react-swipeable";
import { useNavigate } from 'react-router-dom';
import { editData, selectData } from '../home/homeSlice';
import { handleGenRefCode, handleLinkShare, handleCopyClick } from './refProgFuncs';
import { web } from '../root/Root';



export const RefProg = ({ }) => {
    const [sharing, setSharing] = useState(false)
    const myData = useSelector(selectData)
    const [refCount, setRefCount] = useState(myData && myData.refcount ? myData.refcount : 0)
    const [generating, setGenerating] = useState(false)
    const [refCode, setRefCode] = useState(myData && myData.md5 ? `${web}/download?ref=${myData.md5}` : null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const swipeBackHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    useEffect(() => {
        if (sharing === true) {
            setTimeout(() => {
                setSharing(false)
            }, 1500);
        }
    }, [sharing])

    return (

        <div style={{ zIndex: '12', padding: '0px 0px 59px 0px' }} id="profileCont" className="feedPostsCont">
            <div {...swipeBackHandlers} id="feedsCont" style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">
                {sharing ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                    <p className='fake'> Generating Share Link...</p>
                </div> : ""}
                {<div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-REFERENCE</label>
                </div>}
                <div className='youAreNotRegistered'>
                    <h3>Enjoy a  <strong style={{ color: 'hsl(72, 100%, 47%)' }}>FREE</strong> Pure Subscription pack!</h3>
                    <br />
                    <h4>
                        - <strong style={{ color: 'hsl(72, 100%, 47%)' }}>3 MONTHS FREE</strong> subscription for <strong style={{ color: 'hsl(72, 100%, 47%)' }}>EVERY 10</strong> FRIENDS who sign up using your Reference link
                    </h4>
                    <br />
                    {refCode ?
                        <div>
                            <h4 style={{ textAlign: 'center', marginBottom: '10px' }}>Downloads so far:  <strong style={{ color: 'hsl(72, 100%, 47%)' }}>{refCount}</strong></h4>
                            <h4><strong style={{ color: 'hsl(72, 100%, 47%)' }}> -Reference Link:</strong><br /> {refCode}</h4>
                            <br />
                            <p onClick={() => { handleLinkShare(refCode, setSharing) }} style={{ height: '20px' }} className='activeBlack singleButton'>Share</p>
                            <br />
                            <h5> -Copy link and share manually?</h5>
                            <p onClick={() => handleCopyClick(refCode, 'Refrance Link')} style={{ marginTop: '10px', height: '30px' }} className="singleButton activeBlack">Copy Link</p>
                            <br />
                        </div>
                        :
                        <div>
                            <h5> -Start by generating your Reference Link</h5>
                            <br />
                            <p onClick={() => { handleGenRefCode(setRefCode, setGenerating, dispatch, editData, web) }} style={{ height: '20px', marginBottom: '10px' }} className='activeBlack singleButton'>{generating ? 'Generating..' : 'Generate'}</p>
                        </div>
                    }
                    <h6 style={{ marginLeft: '10px', textAlign: 'left' }}>- This program may end at any time, but you WON'T lose your earned subscriptions.</h6>
                </div>
            </div>
        </div>
    );
};
