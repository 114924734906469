import React, { useEffect, useState } from 'react';

export const ProgressBar = ({ duration, onComplete, setIsResting }) => {
  const [progress, setProgress] = useState(0);
  const [counter, setCounter] = useState(0)

  const oneStep = 100 / duration

  useEffect(() => {
    const updateInterval = 1000; // 1 second

    // Start the progress bar


    // Update the progress bar at regular intervals
    const intervalId = setInterval(() => {
      if (duration >= counter) {
        setProgress((prevProgress) => prevProgress + oneStep);
        setCounter((prevCounter) => prevCounter + 1);
      } else {
        clearInterval(intervalId);
      }
    }, updateInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [duration, onComplete, setIsResting, progress, oneStep]);

  useEffect(() => {
    if (duration <= counter) {
      setTimeout(() => {
        onComplete();
        setIsResting(false);
      }, 200);
    }
  }, [counter])

  return (
    <div className="progress-container">
      <div
        className="progress-bar"
        style={{
          width: `${progress}%`,
          height: '20px',
          background: 'hsl(72, 100%, 47%)', // Adjust the color as needed
        }}
      ></div>
      <p>Take Your Breath Champ!</p>
    </div>
  );
};
