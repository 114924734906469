// SingleChatMessage.js
import React from 'react';
import TextHandler from '../CommonItems/TextHandler'; // Adjust the import path as needed

const SingleChatMessage = ({ message, setBigPic, aspectRatio, setAspectRatio }) => {
    return (
        <div
            style={{ padding: message.type === 'image' || message.type === 'video' ? '4px 4px' : "6px 10px" }}
            className={`message ${message.sender === "me" ? "sent" : "received"}`}
        >
            {message.type === 'text' && (
                <div style={{ whiteSpace: 'pre-wrap' }} className={`message-content ${message.sender === "me" ? "align-right" : "align-left"}`}>
                    <TextHandler text={message.content} />
                </div>
            )}
            {message.type === 'audio' && (
                <audio
                    className={`message-content ${message.sender === "me" ? "align-right audioChatSent" : "align-left audioChatReceived"} audioChat`}
                    src={message.content}
                    controls
                    controlsList="nodownload noplaybackrate"
                />
            )}
            {message.type === 'image' && (
                <div className={`${message.sender === "me" ? "align-right" : "align-left"} imageChat`}>
                    <img onClick={() => setBigPic(message.content)} src={message.content} alt="chat-img" />
                </div>
            )}
            {message.type === 'video' && (
                <div style={{ display: 'flex' }} className={`${message.sender === "me" ? "align-right" : "align-left"} imageChat`}>
                    <video
                        onLoadedData={() => setAspectRatio("")}
                        style={{ aspectRatio: aspectRatio, backgroundColor: "black" }}
                        loop
                        src={message.content}
                        width="100%"
                        controls
                    />
                </div>
            )}
        </div>
    );
};

export default SingleChatMessage;
