import React, { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom";
import { fetchMemberInGroups } from "./feedFuncs";


export const InterestedIn = ({ myMainid, interest, groupinterest }) => {
    const navigate = useNavigate()
    const [loadingGroupsInCount, setLoadingGroupsInCount] = useState(null);
    const [joinedCount, setJoindCount] = useState(0)
    const [requestCount, setRequestCount] = useState(0)

    useEffect(() => {
        fetchMemberInGroups(setJoindCount, setRequestCount, setLoadingGroupsInCount)
    }, [])

    return (
        <div className="interestedInCont">
            <div className="activeBlack interestedInButtonCont">
                {interest ?
                    <h6 onMouseUp={() => navigate(`/home/workout/findduo?Load:${interest}`)}>
                        Active in {interest}
                    </h6>
                    :
                    <h5 onMouseUp={() => navigate('/home/workout/announce')}>
                        Not Active Yet
                    </h5>
                }
            </div>
            <div className="activeBlack interestedInButtonCont">
                {groupinterest ?
                    <h6 onMouseUp={() => navigate(`/home/groups/group/:${myMainid}`)}>
                        My {groupinterest} Group
                    </h6>
                    :
                    <h5 onMouseUp={() => navigate('/home/groups/creategroup')}>
                        Create Group
                    </h5>
                }
            </div>
            {loadingGroupsInCount === 'done' ?
                <div className="activeBlack interestedInButtonCont">
                    {joinedCount > 0 || requestCount > 0 ?
                        <h6 onMouseUp={() => navigate(`/home/groups`)}>
                            Gp. Joined {joinedCount}
                            <br />
                            Gp. Requst {requestCount}
                        </h6>
                        :
                        <h5 onMouseUp={() => navigate('/home/groups/findgroups')}>
                            Join Groups
                        </h5>
                    }
                </div>
                :
                <div className="interestedInButtonCont">
                    <h5 >
                        Loading ...
                    </h5>
                </div>
            }
        </div>
    )
}
