export const deleteRequest = async (sender, status) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/delnotification?sender=${sender}&status=${status}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary authentication headers
        // such as Authorization token
      },
    });

    if (response.ok) {
      console.log('Friend request deleted');
    } else {
      console.error('Failed to delete friend request:', response.status);
    }
  } catch (error) {
    console.error('Error deleting friend request:', error);
  }
};

