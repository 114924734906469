import React from 'react';
import { useDispatch } from "react-redux";
import { clearInfo } from '../root/repChoiceSlice';
import { handleReport } from "../user/userFuncs";
import { loadHome } from '../home/homeSlice';


export function ReportComment({ reviewerName, reviewerId, setShowReport, setReviewHidden, myMainid, revID }) {

    const dispatch = useDispatch();

    const handleButtonPress = (content, reviewerName, reviewerId) => {
        try {
            const confirmed = window.confirm(
                `Report ${reviewerName} inappropriate review?\n We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.`
            );
            if (confirmed) {
                confirmReport(content, reviewerName, reviewerId)
            } else {
            }
        } catch (e) {

        }

    };

    const confirmReport = (content, reviewerName, reviewerId) => {
        try {
            handleReport(content, reviewerId, reviewerName, dispatch, clearInfo);
        } catch (e) {
        }
    };

    const blockUser = async (reviewerId) => {
        try {
            const receiverId = reviewerId
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/block-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ receiverId }),
                }
            );
            const data = await response.json();
            if (data === 'Blocked') {
                window.alert(`${reviewerName} is now blocked`)
                dispatch(clearInfo())
                setReviewHidden(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleHidePost = () => {
        setReviewHidden(true);
        setShowReport(false);
        fetch(`/api/coaches/hidereview`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ revID: revID }),
        })
            .then((response) => {
                if (response.ok) {
                    response.json().then(data => {
                        console.log('post hidden')
                    }).catch(error => {
                        console.error("Error parsing JSON data:", error);
                    });
                } else {
                    console.error("failed to handle like");
                }
            })
            .catch((error) => {
                console.error("Error handling like", error);
            });
    }

    if (myMainid === reviewerId) {
        return (
            <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
                <div onClick={() => ""} className="rep-choice-container">
                    <h2 style={{ marginBottom: '20px' }}>Can't Report Your Self!</h2>
                    <button
                        className={`rep-choice-button `}
                        onClick={() => setShowReport(false)}
                    >
                        Close
                    </button>
                </div>
            </div >
        )
    }

    return (
        <div onClick={() => setShowReport(false)} className="reportPostChoises rep-choice-page">
            <div onClick={() => ""} className="rep-choice-container">
                <h2>REPORT !</h2>
                <h3 style={{ color: 'red' }}>{reviewerName}'s<span style={{ color: 'black' }}> Comment</span> </h3>
                <h4 style={{ marginBottom: '5px' }} >Inappropriate Comment?</h4>
                <button
                    className={`rep-choice-button`}
                    onClick={() => handleButtonPress('Inappropriate Comment', reviewerName, reviewerId)}
                >
                    Inappropriate Comment
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>We will review this report within 24 hrs and if deemed Inappropriate the content will be removed within that timeframe. We will also take actions against its author.</p>
                <button
                    className={`rep-choice-button`}
                    onClick={handleHidePost}
                >
                    Just hide this Comment
                </button>
                <h4 style={{ marginBottom: '5px' }}>Abusive User?</h4>
                <button
                    style={{ color: 'red' }}
                    className={`rep-choice-button `}
                    onClick={() => blockUser(reviewerId)}
                >
                    Block User
                </button>
                <p style={{ color: 'black' }} className='disclaimer'>blocking a user will also hide all your & user's content from them and you</p>
                <button
                    className={`rep-choice-button `}
                    onClick={() => setShowReport(false)}
                >
                    cancel
                </button>
            </div>
        </div >
    );

};

