export const handleGenRefCode = async (setRefCode, setGenerating, dispatch, editData, web) => {
    setGenerating(true);
    try {
        const response = await fetch(`/api/profile/refprog-enroll`);
        if (response.ok) {
            const data = await response.json();
            const code = data.refCode;
            dispatch(editData({ md5: code }));
            setRefCode(`${web}/download?ref=${code}`);
        } else {
            console.error("Failed to generate reference code:", response.statusText);
        }
    } catch (error) {
        console.error("Error generating reference code:", error);
    } finally {
        setGenerating(false);
    }
};


export const handleCopyClick = (textToCopy, type) => {
    try {
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Copied ${type}: ${textToCopy}`);
                } catch (e) {
                    alert(`Copied ${type}: ${textToCopy}`)
                }
            })
            .catch(err => {
                console.error('Unable to copy text to clipboard', err);
            });
    } catch (e) {

    }
};


export const handleLinkShare = async (link, setSharing) => {
    setSharing(true)
    if (/Android/.test(navigator.userAgent)) {
        try {
            window.ReactNativeWebView.postMessage(`ShareLink:${link}`);
        } catch (e) {
        }
    } else {
        try {
            const data = {
                title: 'Fit-Buddy',
                text: 'Join me on Fit-Buddy',
                url: link,
            };

            try {
                if (navigator.share && navigator.canShare(data)) {
                    await navigator.share(data);
                } else {
                    // Do something else like copying the data to the clipboard
                }
            } catch (e) {
                // Handle share error
            }
        } catch (e) {
            // Handle error
        }
    }

}