import React, { useEffect, useRef, useState } from "react";
import { FakePost } from "../Posts/FakePost";

import back from '../../Pics/back.png'
import { AppServices } from "./AppServices";
import { useNavigate } from "react-router-dom";
import refreshGreen from '../../Pics/refreshGreen.png'
import searchUser from '../../Pics/searchUser.png'
import searchGroup from '../../Pics/searchGroup.png'
import nearByPic from '../../Pics/nearBy.png'
import { NewHeader } from "../feed/NewHeader";
import { FavShortCut } from "../feed/FavShortCut";


export function LoadingFeed({ }) {
    const [showMore, setShowMore] = useState(JSON.parse(localStorage.getItem('showAppServices')) || false);
    const navigate = useNavigate()
    const hiddenDivRef = useRef(null);
    const [dynamicWidth, setDynamicWidth] = useState(0);

    useEffect(() => {
        const updateDynamicWidth = () => {
            const hiddenDiv = hiddenDivRef.current;
            if (hiddenDiv) {
                const rect = hiddenDiv.getBoundingClientRect();
                setDynamicWidth(rect.width);
            }
        };
        // Update dynamicWidth initially and on resize
        updateDynamicWidth();
        window.addEventListener('resize', updateDynamicWidth);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateDynamicWidth);
        };
    }, []);

    const handleGoToGroup = (fav) => {
        window.selectedGroupSport = fav
        navigate('/home/groups/findgroups')
    }
    const handleShowAppServices = () => {
        const showMoreHelper = !showMore;
        localStorage.setItem('showAppServices', JSON.stringify(showMoreHelper));
        setShowMore(showMoreHelper);
    };

    return (

        <div style={{ zIndex: '12', padding: '0px 0px 20px 0px' }} id="profileCont" className="feedPostsCont">
            <div style={{ marginTop: '0px', marginBottom: '25px', height: '100%' }} className="scrollList">
                <div className="topFeedBar">
                    <div className="ppwithplusPost">
                        <img onMouseUp={() => navigate('/home/postcreator')} className="miniPPInFeedShortCut fakeBackGround" />
                        <img onMouseUp={() => navigate('/home/postcreator')} className="plusInFeedShortCut" src={localStorage.getItem('plus')} />
                    </div>
                    <img className="refreshInFeedImg" src={localStorage.getItem('refreshGreen')} />
                </div>
                <NewHeader />
                <div style={{ marginTop: '10px' }} className="newShorCutCont">
                    <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
                        <div onMouseUp={() => navigate('/home/workout')} className="activeBlack findInshortCutCont">
                            <img src={localStorage.getItem('nearByPic')} />
                            <h5>USERS NEARBY, looking  for a workout partner</h5>
                        </div>
                        <div >

                        </div>
                        <div className="favShorCutContButton1">
                            <div className="favShortCutCont">
                                <div style={{ height: '30px' }} />
                            </div>
                            <p className="shortCutCountNumber" >..</p>
                        </div>
                        <div className="favShorCutContButton2">
                            <div style={{ height: '30px' }} />
                            <p className="shortCutCountNumber" >..</p>
                        </div>
                    </div>
                    <div className="FeedShortCutHeaderContButton newShortCutButton" style={{ width: '100%' }}>
                        <div onMouseUp={() => navigate('/home/groups')} className="activeBlack findInshortCutCont">
                            <img src={localStorage.getItem('nearByPic')} />
                            <h5>GROUPS NEARBY, looking for players to join!</h5>
                        </div>
                        <div >
                        </div>
                        <div className="favShorCutContButton1">
                            <div style={{ height: '30px' }} />
                            <p className="shortCutCountNumber" >..</p>
                        </div>
                        <div className="favShorCutContButton2">
                            <div style={{ height: '30px' }} />
                            <p className="shortCutCountNumber" >..</p>
                        </div>
                    </div>
                </div>
                <div onClick={handleShowAppServices} className="activeBlack showMoreAppServices">
                    <div style={{ gap: '10px', display: 'flex', width: "fit-content" }}>
                        <h5 style={{ color: !showMore ? "rgba(255, 255, 255, 0.689)" : "hsl(72, 100%, 47%)" }}>{"MY SHORTCUTS"}</h5>
                        <img style={{ height: '26px', filter: !showMore ? "grayscale(1)" : "" }} src={localStorage.getItem('eye')} onClick={() => { setShowMore((state) => !state) }} />
                    </div>
                </div>
                {showMore ?
                    <AppServices />
                    : ''
                }
                <div style={{ backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px' }} className="feedFilter">
                </div>
                <div style={{ backgroundColor: 'hsla(72, 100%, 47%, 40%)', height: '1px', padding: '0px', borderRadius: '0px' }} className="feedFilter">
                </div>
                <div>
                    <FakePost key={'1'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={'1'} showtagged={false} setRequest={""} />
                    <FakePost key={'2'} postid={'1'} image={""} creatorid={1} creatorname={'Loading...'} creatorpicture={""} createTime={'Loading...'} caption={"Loading"} sport={"Loading sport"} taggedid={"1"} taggedname={"Loading..."} likes={[0]} myMainid={'1'} showtagged={false} setRequest={""} />
                </div>
            </div>
        </div >
    )
}


