import Reactb from 'react';
import { Swipe } from '../home/Swipe'



export function EULA() {


    return (
        <div className="scrollHelperContainer" >
            <div style={{ marginTop: '0px' }} className="scrollHelper" >
                <div className='privPol'>
                    <h2>Fit-Buddy END-USER LICENSE AGREEMENT (EULA)</h2>
                    <br />
                    <p>This End-User License Agreement is a legal agreement between you (referred to as "Licensee" or "You") and Fit-Buddy (referred to as "Licensor" or "We") regarding the use of the software application Fit-Buddy (referred to as the "App") provided by Licensor.</p>
                    <br />
                    <h3>The App has a no tolerance for objectionable content and abusive users. This policy includes:</h3>
                    <br />
                    <ul>
                        <li>Objectionable Content: The App prohibits the inclusion or dissemination of material that is offensive, discriminatory, or harmful. Objectionable content includes, but is not limited to, content that is offensive, discriminatory, or mean-spirited towards individuals or groups based on factors such as religion, race, sexual orientation, gender, national/ethnic origin, or other attributes.</li>
                        <li>Abusive Behavior: The App prohibits abusive behavior by users. Abusive behavior includes, but is not limited to, harassment, intimidation, or any actions that violate the rights of others.</li>
                    </ul>
                    <br />
                    <br />
                    <h3>Assumption of Risk and Limitation of Liability</h3>
                    <br />
                    <ul>
                        <li>By using Fit-Buddy, you agree that Fit-Buddy shall not be held liable for any injuries, harm, or damages, including but not limited to physical injuries, emotional distress, or financial losses, that may result from the use or inability to use the application.</li>
                        <li>You acknowledge that using Fit-Buddy is at your own risk. It is your responsibility to use the application responsibly and in accordance with all applicable laws and regulations.</li>
                    </ul>
                    <br />
                    <h3>Fit-Buddy Subscriptions:</h3>
                    <br />
                    <h4> - Pure Experience Subscription Plans:</h4>
                    <ul style={{ marginLeft: '30px', marginTop: '10px' }}>
                        <li>Monthly: $ 4.99</li>
                        <li>Semi-Annual: $17.99</li>
                        <li>Annual: $23.99</li>
                    </ul>
                    <br />
                    <h4> - Coach Experience Subscription Plans:</h4>
                    <ul style={{ marginLeft: '30px', marginTop: '10px' }}>
                        <li>Monthly: $ 6.99</li>
                        <li>Semi-Annual: $ 23.99</li>
                        <li>Annual: $ 35.99</li>
                    </ul>
                    <br />
                    <h4> - Payments and Renewal for all Subscription plans:</h4>
                    <ul style={{ marginLeft: '30px', marginTop: '10px' }}>
                        <li style={{ marginBottom: '4px' }}>Payment will be charged to iTunes Account at confirmation of purchase. Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period.</li>
                        <li style={{ marginBottom: '4px' }}>Account will be charged for renewal within 24-hours prior to the end of the current period.</li>
                        <li style={{ marginBottom: '4px' }}>You can manage or turn off auto-renew in your Account settings at any time after purchase</li>
                        <li style={{ marginBottom: '4px' }}>No cancellation of the current subscription is allowed during the active period.</li>
                        <li style={{ marginBottom: '4px' }}>You can change your subscription plan at any time, and the change will take effect within a few minutes. However, any remaining time on your current subscription will be forfeited and cannot be refunded.</li>
                    </ul>
                    <br />
                    <h3>Licensor expects all users of Fit-Buddy to adhere to this zero-tolerance policy and reserves the right to take appropriate action, including but not limited to suspending or terminating access to the App, in response to violations.</h3>
                    <div style={{ height: '100px' }} />
                </div>
            </div>
            <Swipe />
        </div>
    );
}



