import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import meetup from '../../Pics/meetup.png'
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import TimeView from "./TimeView";
import { ReportButton } from '../user/ReportButton'
import "./card.css";
import { useNavigate } from "react-router-dom";

export function Card({ user, proFriends }) {
  const dispatch = useDispatch()
  const [imageSrc, setImageSrc] = useState(user.picture_url);

  const openTempMeeting = (mainid) => {
    dispatch(setMeetingID(`user:${mainid}`))
  };

  const photoPicker = (int) => {
    const picked = int.toLowerCase()
    return localStorage.getItem(picked)
  }

  const backupImg = () => {
    setImageSrc(localStorage.getItem("logo192")); // Set backup image source when the main image fails to load
  };

  try {
    if (proFriends[user.mainid].status === 'iblock' || proFriends[user.mainid].status === 'blocki') {
      return ('')
    } else {
      return (
        <div className="Card">
          {user.interest ? <img className="cardInt" src={photoPicker(user.interest)} /> : ''}
          <img className="cardPP" src={imageSrc} onError={backupImg} />
          <p className="cardName">{user.name.toUpperCase()}</p>
          <p className="cardgendandage" > {user.gender.toUpperCase()}<span style={{ marginLeft: '8px' }}>{user.distance ? user.distance.toFixed(2) + ` Km` : ''}</span> </p>
          <div className="cardTimeCont">
            <TimeView time={user.time} />
          </div>
          <p className="cardComment">
            {user.comment ? user.comment : ''}
          </p>
          <p className="cardLocation">
            {user.preflocation ? user.preflocation : (user.interest ? "Any Where" : '')}
          </p>
          <div className="repInCard">
            <ReportButton key={user.mainid} info={user} />
          </div>
          {/*user.distance ?
            <div style={{ marginTop: "5px" }} className="repInCard">
              <h4>{(user.distance).toFixed(2)} KM</h4>
            </div>
            : ""
          */}
          {
            proFriends[user.mainid].status === 'friends' && window.location.pathname !== '/home/workout/findduo' ?
              <div className="openMeetingButtonInCard" onClick={() => openTempMeeting(user.mainid)}>
                <img style={{ height: '100%' }} src={meetup} />
              </div> :
              ""
          }
        </div>
      );
    }
  } catch (e) {
    return (
      <div className="Card">
        {user.interest ? <img className="cardInt" src={photoPicker(user.interest)} /> : ''}
        <img className="cardPP" src={imageSrc} onError={backupImg} />
        <p className="cardName">{user.name.toUpperCase()}</p>
        <p className="cardgendandage" > {user.gender.toUpperCase()}<span style={{ marginLeft: '8px' }}>{user.distance ? user.distance.toFixed(2) + ` Km` : ''}</span> </p>
        <div className="cardTimeCont">
          <TimeView time={user.time} />
        </div>
        <p className="cardComment">
          {user.comment ? user.comment : ''}
        </p>
        <p className="cardLocation">
          {user.preflocation ? user.preflocation : (user.interest ? "Any Where" : '')}
        </p>
        <div className="repInCard">
          <ReportButton key={user.mainid} info={user} />
        </div>
        {/*user.distance ?
            <div style={{ marginTop: "5px" }} className="repInCard">
              <h4>{(user.distance).toFixed(2)} KM</h4>
            </div>
            : ""
        */}
      </div>
    );
  }
}

/*
<div className="Card">
        <Link className="cardLink" to={`/${user.mainid}`}>
          <img className="cardPP" src={user.picture_url} />
          <h4>{user.name} - {user.age}</h4>
          <img className="cardInt" src={user.interest ? photoPicker(user.interest) : ''} />
          <TimeView time={user.time} />
          <h5>
            {user.comment ? user.comment : ''}
          </h5>
        </Link>
        <div className="interactionCont">
          <FriendButton key={user.mainid} proFriends={proFriends} id={user.mainid} />
        </div>
        <div className="repInCard">
          <ReportButton key={user.mainid} info={user} />
        </div>
      </div>

*/
