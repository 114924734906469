import React, { useState, useEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { useSelector, useDispatch } from "react-redux";
import list from "../../Pics/list.png";
import { useNavigate, useLocation } from "react-router-dom";
import not from "../../Pics/notif_.png";
import share from '../../Pics/share.png'
import facebook from '../../Pics/facebook.png'
import apple from '../../Pics/Apple.png'
import google from '../../Pics/Google.png'
import { selectData, selectLog, reset, selectAppVersion } from "../home/homeSlice";
import { loadCommon } from "../intButtons/intButtonsSlice";
import { clearInfo } from "../user/userSlice";
import "./nav.css";

function SignedInWith({ token }) {
    try {
        if (token === '1') {
            return (
                <div className="singleLink">
                    <div style={{ display: 'flex', width: '100%' }}>
                        <p>Signed in w.</p>
                        <img style={{ filter: 'invert(1)' }} src={apple} alt="Apple Icon" className="appleIcon" />
                    </div>
                </div>
            )
        }
        if (token === '2') {
            return (
                <div className="singleLink">
                    <div style={{ display: 'flex', width: '100%' }}>
                        <p>Signed in w.</p>
                        <img src={facebook} alt="Facebook Icon" className="facebookIcon" />
                    </div>
                </div>
            )
        }
        if (token === '3') {
            return (
                <div className="singleLink">
                    <div style={{ display: 'flex', width: '100%' }}>
                        <p>Signed in w.</p>
                        <img src={google} alt="Google Icon" className="googleIcon" />
                    </div>
                </div>
            )
        }
        if (token) {
            return (
                <div className="singleLink">
                    <div style={{ display: 'flex', width: '100%' }}>
                        <p>Signed In</p>
                    </div>
                </div>
            )
        }
    } catch (e) {

    }
}


export function InfoTab({ subColor }) {
    const [sharing, setSharing] = useState(false)
    const componentRef = useRef(null);
    const data = useSelector(selectData);
    const log = useSelector(selectLog);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const page = useLocation();
    const pagePath = page.pathname
    const appVersion = useSelector(selectAppVersion)



    let activeStyle1 = {
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: 'hsla(0, 0%, 10%, 0.9)'
    };

    useEffect(() => {
        try {
            const handleOutsideClick = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setShow(false);
                }
            };

            const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

            document.addEventListener(clickEvent, handleOutsideClick);

            return () => {
                document.removeEventListener(clickEvent, handleOutsideClick);
            };
        } catch (e) {

        }
    }, []);



    const handleImenue = () => {
        setShow((prevState) => !prevState);
    };

    const navClean = () => {
        if (log !== "logged") {
            navigate("/login");
        } else {
            dispatch(clearInfo());
            navigate('/home/profile');
        }
        handleImenue()
    };

    const lastList = () => {
        if (data) {
            if (data.interest) {
                const radius = data.radius
                dispatch(loadCommon({ interest: data.interest, radius }));
                navigate('/home/workout/findduo')
                handleImenue()
            }
        }

    };

    const handleLogout = async () => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/logout`)
        if (response.ok) {
            window.location.href = '/login'
            dispatch(reset());
            try {
                window.ReactNativeWebView.postMessage("appLogout");
            } catch (e) {
                console.log('on browser')
            }
        } else {
            try {
                window.ReactNativeWebView.postMessage("appLogout");
            } catch (e) {
                console.log('on browser')
            }
            window.location.href = '/login'
            dispatch(reset());
        }
    }

    const handleLinkShare = async () => {
        setSharing(true)
        if (/Android/.test(navigator.userAgent)) {
            try {
                window.ReactNativeWebView.postMessage(`ShareLink:https://fitbuddy.fit`);
            } catch (e) {
            }
        } else {
            try {
                const data = {
                    title: 'Fit-Buddy',
                    text: 'Join me on Fit-Buddy',
                    url: `https://fitbuddy.fit`,
                };

                try {
                    if (navigator.share && navigator.canShare(data)) {
                        await navigator.share(data);
                    } else {
                        // Do something else like copying the data to the clipboard
                    }
                } catch (e) {
                    // Handle share error
                }
            } catch (e) {
                // Handle error
            }
        }

    }

    const handleSwipeLeft = () => {
        setShow(false)
    };

    const swipeForwardHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    const handleSubNotification = () => {
        try {
            window.ReactNativeWebView.postMessage("SubscribeNotification");
        } catch (e) {

        }
    }

    useEffect(() => {
        if (sharing === true) {
            setTimeout(() => {
                setSharing(false)
            }, 1500);
        }
    }, [sharing])

    return (
        <div ref={componentRef} className="DropDown" >
            {sharing ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                <p className='fake'> Generating Share Link...</p>
            </div> : ""}
            <div className="infoNotibutton">
                <img onClick={handleImenue} src={list} />
            </div>
            {log === 'logged' && show ?
                <div {...swipeForwardHandlers} className="HomeDropDownCont">
                    <p onClick={() => { navigate('/home/feed'); handleImenue() }} style={pagePath === '/home/feed' ? activeStyle1 : undefined} className='singleLink' >FEED</p>
                    {/*<p onClick={() => { navigate('/home/mypage'); handleImenue() }} style={pagePath === '/home/mypage' ? activeStyle1 : undefined} className='singleLink' >PORTAL</p>*/}
                    {<p onClick={() => { navigate('/home/events'); handleImenue() }} style={pagePath === '/home/events' ? activeStyle1 : undefined} className='singleLink' >EVENTS</p>}
                    {<p onClick={() => { navigate('/home/achievements'); handleImenue() }} style={pagePath === '/home/achievements' ? activeStyle1 : undefined} className='singleLink' >CHALLENGES</p>}
                    {/*<p onClick={navClean} style={pagePath === '/home/profile' ? activeStyle1 : undefined} className='singleLink' >EDIT PROFILE</p>
                    <p onClick={() => { navigate('/home/workout'); handleImenue() }} style={pagePath === '/home/workout' ? activeStyle1 : undefined} className='singleLink' >FIND FITBUDDY</p>
                    <p onClick={() => { navigate('/home/groups'); handleImenue() }} style={pagePath === '/home/groups' ? activeStyle1 : undefined} className='singleLink' >GROUPS</p>*/}
                    <p onClick={() => { navigate('/home/coaches'); handleImenue() }} style={pagePath === '/home/coaches' ? activeStyle1 : undefined} className='singleLink' >COACHES</p>
                    <p onClick={() => { navigate('/home/myworkouts'); handleImenue() }} style={pagePath === '/home/myworkouts' ? activeStyle1 : undefined} className='singleLink' >MY-WORKOUTS</p>
                    <p onClick={() => { navigate('/home/friends'); handleImenue() }} style={pagePath === '/home/friends' ? activeStyle1 : undefined} className='singleLink' >FRIENDS</p>
                    <p onClick={() => { navigate('/home/chats'); handleImenue() }} style={pagePath === '/home/chats' ? activeStyle1 : undefined} className='singleLink' >CHATS</p>
                    <p onClick={() => { navigate('/home/myprofile'); handleImenue() }} style={pagePath === '/home/myprofile' ? activeStyle1 : undefined} className='singleLink' >PREVIEW PROFILE</p>
                    <div style={{ marginBottom: '6px' }} className="fakeline"></div>
                    <SignedInWith token={data.access_token} />
                    <p onClick={() => { navigate('/home/subs'); handleImenue() }} style={pagePath === '/home/subs' ? activeStyle1 : undefined} className='singleLink' >Subscriptions</p>
                    <p onClick={() => { navigate('/help'); handleImenue() }} style={pagePath === '/help' ? activeStyle1 : undefined} className='singleLink' >HELP !</p>
                    <a onClick={handleLogout} className="singleLink">
                        Logout
                    </a>
                    <div style={{ marginTop: '6px' }} className="fakeline"></div>
                    <div className="dropDpowSmallButtons" >
                        <div onClick={handleSubNotification} style={{ backgroundColor: subColor }} className="enableN">
                            <img loading="lazy" src={not} />
                        </div>
                        <img className="shareImageInDrop" onClick={handleLinkShare} loading="lazy" src={share} />
                    </div>
                </div> :
                ""
            }
        </div>
    );

}
