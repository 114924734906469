import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
const asCoachLink = ""
const asStudentLink = ""

export function Coaches({ }) {
    const [selectedFilter, setSelectedFilter] = useState('none')
    const [showDesc, setShowDesc] = useState(null)
    const navigate = useNavigate()


    const playCoachTutVideo = (link) => {
        try {
            window.ReactNativeWebView.postMessage(`PlayVideo:${link}`);
        } catch (e) {

        }
    }

    return (
        <div>
            <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
                {showDesc ?
                    <div onClick={() => setShowDesc(null)} className="descContPage">
                        <div style={{ position: 'fixed' }} className="descCont">
                            <p style={{ color: 'white', textAlign: "left" }}>{showDesc}</p>
                        </div>
                    </div>
                    : ""
                }
                <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                    <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                        <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-COACHES</label>
                    </div>
                    <div className="bigDuoButtonCont">
                        <div className='bigDuoButton activeBlack'>
                            <img onMouseUp={() => navigate('/home/coaches/coachaccount')} className='bigDuoButtonIMG' src={localStorage.getItem('coachprofile')} />
                            <h5 onMouseUp={() => navigate('/home/coaches/coachaccount')}>COACH PROFILE</h5>
                            <img onClick={() => setShowDesc('Register as Coach and Manage your Coach Profile.')} className="bigDuoButtonInfo" src={localStorage.getItem('infoImg')} />
                        </div>
                        <div className='bigDuoButton activeBlack'>
                            <img onMouseUp={() => navigate('/home/coaches/coachesnearby')} className='bigDuoButtonIMG' src={localStorage.getItem('traineeGreen')} />
                            <h5 onMouseUp={() => navigate('/home/coaches/coachesnearby')}>TRAINEES PROFILE</h5>
                            <img onClick={() => setShowDesc('Discover new coaches and Connect with them')} className="bigDuoButtonInfo" src={localStorage.getItem('infoImg')} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
