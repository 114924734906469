import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAchievements, selectStatus, loadAchievements } from './achievementsSlice'
import { selectData } from "../home/homeSlice";
import { AchievementBadges } from './AchievementBadges'
import Logos from '../../Pics/Logos.png'
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { friendsAchievements, confirmedAchievements, confirmedGroupAchievements, featuredSetsAchievements } from './achievementsFuncs'
import back from '../../Pics/back.png'
import './achievements.css'
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import { TodayWorkoutsBar } from "../workoutsManager/TodayWorkoutsBar";
import { selectTodayFeatured } from "../workoutsManager/workoutsSlice";
import { BigFeaturedWorkout } from "../workoutsManager/BigFeaturedWorkout";
import { DailyCard } from "./DailyCard";

export function Achievements({ }) {
    const [showWorkoutInfo, setShowWorkoutInfo] = useState(null)
    const [bigPic, setBigPic] = useState(null)
    const [selectedFilter, setSelectedFilter] = useState("Add");
    const dailyFields = ['logintoday', 'setsport', 'invoracc', 'confirmworkout'];
    const weeklyFields = ['weeklyone', 'weeklytwo', 'weeklythree'];
    const [showCoinDescription, setShowCoinDescription] = useState(false)
    const [selectedBadge, setSelectedBadge] = useState(null)
    const [selectedCount, setSelectedCount] = useState(null)
    const [showBadges, setShowBadges] = useState(true)
    const [showDaily, setShowDaily] = useState(false)
    const [showWeekly, setShowWeekly] = useState(false)
    const [topFriendAch, setTopFriendAch] = useState(null)
    const [topWorkoutAch, setTopWorkoutAch] = useState(null)
    const [topGroupWorkoutAch, setTopGroupWorkoutAch] = useState(null)
    const [topFeaturedSetsAch, setTopFeaturedSetsAch] = useState(null)
    const [dailyCount, setDailyCount] = useState(0)
    const [dailyCountFeatured, setDailyCountFeatured] = useState(0)
    const [weeklyCount, setWeeklyCount] = useState(0)
    const achievements = useSelector(selectAchievements)
    const status = useSelector(selectStatus)
    const TodayFeatured = useSelector(selectTodayFeatured)
    const [featured1, setFeatured1] = useState(null)
    const [featured2, setFeatured2] = useState(null)
    const [featured3, setFeatured3] = useState(null)
    const data = useSelector(selectData)
    const dispatch = useDispatch()
    const navigate = useNavigate()


    useEffect(() => {
        dispatch(loadAchievements())
    }, [dispatch])


    useEffect(() => {
        try {
            if (status === 'done' && achievements && TodayFeatured) {
                if (achievements.confirmedfeatured[0]) {
                    const helperID = achievements.confirmedfeatured[0]
                    const sets = achievements.confirmedfeaturedtodaysets[0]
                    const workout = TodayFeatured.find(item => item.id === helperID);
                    const finalWorkout = { ...workout, sets }
                    setFeatured1(finalWorkout)
                    setDailyCountFeatured(1);
                }
                if (achievements.confirmedfeatured[1]) {
                    const helperID = achievements.confirmedfeatured[1]
                    const sets = achievements.confirmedfeaturedtodaysets[1]
                    const workout = TodayFeatured.find(item => item.id === helperID);
                    const finalWorkout = { ...workout, sets }
                    setFeatured2(finalWorkout)
                    setDailyCountFeatured(2);
                }
                if (achievements.confirmedfeatured[2]) {
                    const helperID = achievements.confirmedfeatured[2]
                    const sets = achievements.confirmedfeaturedtodaysets[2]
                    const workout = TodayFeatured.find(item => item.id === helperID);
                    const finalWorkout = { ...workout, sets }
                    setFeatured3(finalWorkout)
                    setDailyCountFeatured(3);

                }
            }
        } catch (e) {

        }

    }, [achievements, TodayFeatured, data, status])

    useEffect(() => {
        try {
            if (!featured1.id) {
                setFeatured1(null)
                setFeatured2(null)
                setFeatured3(null)
                setDailyCountFeatured(0)
            }
        } catch (e) {

        }
    }, [achievements, TodayFeatured, data, status, featured1])

    useEffect(() => {
        try {
            if (status === 'done' && achievements) {
                const trueDailyCount = dailyFields.reduce((count, field) => {
                    if (achievements[field] === true) {
                        return count + 1;
                    }
                    return count;
                }, 0);

                setDailyCount(trueDailyCount)
                const trueWeeklyCount = weeklyFields.reduce((count, field) => {
                    if (achievements[field] === true) {
                        return count + 1;
                    }
                    return count;
                }, 0);
                setWeeklyCount(trueWeeklyCount)
            }
        } catch (e) {

        }

    }, [achievements])

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const handleSwipeDown = () => {
        //handleRefreshFeed()
    };
    const swipeBackHandlersBack = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    const swipeBackHandlersRefresh = useSwipeable({
        onSwipedDown: handleSwipeDown,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    return (

        <div {...swipeBackHandlersBack} style={{ zIndex: '12', padding: '0px 0px 20px 0px' }} id="profileCont" className="feedPostsCont">
            <div style={{ marginTop: '0px', marginBottom: '25px', height: '100%' }} className="scrollList">
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ marginLeft: '25px', fontSize: '24px' }} htmlFor="comment">CHALLENGES</label>
                    <div onClick={() => { setShowCoinDescription(true) }} style={{ marginRight: '0px' }} className="CoinCont">
                        <p>{status === 'done' ? achievements.points : "..."}</p>
                        <img className="fitCoin" src={Logos} />
                    </div>
                </div>
                {status === 'done' && achievements ? (
                    <div>
                        {selectedBadge && selectedCount !== null ? (
                            <div onClick={() => { setSelectedBadge(null); setSelectedCount(null) }} className="fullScreenClean">
                                <div className='fullScreenCleanBox'>
                                    <div className='achievementImgHolder'>
                                        <img src={selectedBadge.image} alt="Achievement Logo" />
                                    </div>
                                    <h5>GOAL: {selectedBadge.goal}</h5>
                                    {selectedCount >= selectedBadge.count ? <h5 style={{ padding: '10px 0px', color: selectedCount >= selectedBadge.count ? "hsl(72, 100%, 47%)" : "", fontWeight: '500', letterSpacing: '1px' }}>ACHIEVED</h5> : <h5 style={{ padding: '10px 0px' }}>PROGRESS: {selectedCount} /  {selectedBadge.count}</h5>}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        {status === 'done' && achievements && showCoinDescription ?
                            <div onClick={() => { setShowCoinDescription(false) }} className="fullScreenClean">
                                <div style={{ height: '100px' }} className='fullScreenCleanBox'>
                                    <h5><span style={{ fontSize: '20px', fontWeight: '500' }}>{achievements.points}</span><br /> <br />FIT-BUDDY ACTIVITY POINTS</h5>
                                </div>
                            </div>
                            :
                            ''
                        }
                        <div className="dailyCont">
                            <div className="myBadgesTitle" >
                                <h4 onClick={() => { setShowBadges((state) => !state) }}>MY BADGES </h4>
                                <div className="topAchivmentsCont">
                                    {topFriendAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topFriendAch); setSelectedCount(Object.values(data.friends).filter(friend => friend.status === 'friends').length) }}
                                            src={topFriendAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topWorkoutAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topWorkoutAch); setSelectedCount(achievements.confirmed_meetings ? achievements.confirmed_meetings.length : 0) }}
                                            src={topWorkoutAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topGroupWorkoutAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topGroupWorkoutAch); setSelectedCount(achievements.confirmed_groupmeetings[0].count) }}
                                            src={topGroupWorkoutAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topFeaturedSetsAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topFeaturedSetsAch); setSelectedCount(data.featuredsets) }}
                                            src={topFeaturedSetsAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    <img style={{ position: 'absolute', marginRight: '0px' }} src={back} onClick={() => { setShowBadges((state) => !state) }} className={showBadges ? 'dropDownArrow' : 'closeDropDownArrow'} />
                                </div>
                            </div>
                            {showBadges ?
                                <div className="dailyObjective">
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Friends</p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={Object.values(data.friends).filter(friend => friend.status === 'friends').length} Achievements={friendsAchievements} topBadgeAchieved={topFriendAch} setTopBadgeAchieved={setTopFriendAch} />
                                    </div>
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Duo </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={achievements.confirmed_meetings ? achievements.confirmed_meetings.length : 0} Achievements={confirmedAchievements} topBadgeAchieved={topWorkoutAch} setTopBadgeAchieved={setTopWorkoutAch} />
                                    </div>
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Groups </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={achievements.confirmed_groupmeetings[0].count} Achievements={confirmedGroupAchievements} topBadgeAchieved={topGroupWorkoutAch} setTopBadgeAchieved={setTopGroupWorkoutAch} />
                                    </div>
                                    {/*<div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Sets </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={data.featuredsets} Achievements={featuredSetsAchievements} topBadgeAchieved={topFeaturedSetsAch} setTopBadgeAchieved={setTopFeaturedSetsAch} />
                                    </div>*/}
                                </div>
                                :
                                ""
                            }
                        </div>
                        {/*<TodayWorkoutsBar />*/}
                        {bigPic !== null ?
                            <div style={{ zIndex: '950' }} onClick={() => setBigPic(null)} className='fullScreenBlack'>
                                <img style={{ filter: "invert(.82)" }} src={bigPic} />
                            </div>
                            :
                            ''
                        }
                        {showWorkoutInfo ?
                            <div style={{ zIndex: '700' }} className="tempMeetingPage">
                                <div style={{ zIndex: '900' }} className="meetingPageCont">
                                    <div onClick={() => setShowWorkoutInfo(null)} style={{ zIndex: '55', width: '100%' }} className='fuLLwidthandHeight' />
                                    <BigFeaturedWorkout workout={showWorkoutInfo} setBigPic={setBigPic} setSelectedFilter={setSelectedFilter} />
                                    <div onClick={() => setShowWorkoutInfo(null)} style={{ zIndex: '55', width: '100%' }} className='fuLLwidthandHeight' />
                                </div>
                            </div>
                            : ""
                        }
                        <div className="dailyCont">
                            <div style={{ gridTemplateColumns: 'auto auto' }} className="myBadgesTitle" >
                                <h4 onClick={() => { setShowDaily((state) => !state) }}>DAILY CHALLENGES <span>{dailyCount + dailyCountFeatured} / 4</span></h4>
                                <div style={{ height: '25px' }} />
                                <img style={{ position: 'absolute', marginRight: '0px' }} src={back} onClick={() => { setShowDaily((state) => !state) }} className={showDaily ? 'dropDownArrow' : 'closeDropDownArrow'} />
                            </div>
                            {showDaily ?
                                <div className="dailyObjective">
                                    <div style={{ backgroundColor: achievements.logintoday ? "hsla(72, 100%, 47%, 40%)" : "" }} className="dailyCard">
                                        <p style={{ color: achievements.logintoday ? "black" : "" }} className="objectiveName">Login Today</p>
                                        <div className="CoinCont">
                                            <p>10</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                    <div onMouseUp={() => navigate('/home/workout')} style={{ backgroundColor: achievements.setsport ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.setsport ? "black" : "" }} className="objectiveName">Plan a Workout</p>
                                        <div className="CoinCont">
                                            <p>20</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                    <div onClick={() => dispatch(setMeetingID('meet:00000'))} style={{ backgroundColor: achievements.invoracc ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.invoracc ? "black" : "" }} className="objectiveName">Invite / Accept a Workout</p>
                                        <div className="CoinCont">
                                            <p>20</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                    {/*<div>
                                        {[
                                            { workout: featured1, coinText: 1 },
                                            { workout: featured2, coinText: 2 },
                                            { workout: featured3, coinText: 3 },
                                        ].map((item, index) => (
                                            <DailyCard key={index} {...item} setShowWorkoutInfo={setShowWorkoutInfo} />
                                        ))}
                                    </div>*/}
                                    <div onClick={() => dispatch(setMeetingID('meet:00000'))} style={{ backgroundColor: achievements.confirmworkout ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.confirmworkout ? "black" : "" }} className="objectiveName">Finish a Duo Workout</p>
                                        <div className="CoinCont">
                                            <p>100</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                        <div className="dailyCont">
                            <div style={{ gridTemplateColumns: 'auto auto' }} className="myBadgesTitle" >
                                <h4 onClick={() => { setShowWeekly((state) => !state) }}>WEEKLY CHALLENGES <span>{weeklyCount} / 3</span></h4>
                                <div style={{ height: '25px' }} />
                                <img style={{ position: 'absolute', marginRight: '0px' }} src={back} onClick={() => { setShowWeekly((state) => !state) }} className={showWeekly ? 'dropDownArrow' : 'closeDropDownArrow'} />
                            </div>
                            {showWeekly ?
                                <div className="dailyObjective">
                                    <div onMouseUp={() => navigate('/home/groups')} style={{ backgroundColor: achievements.weeklyone ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.weeklyone ? "black" : "" }} className="objectiveName">Finish a Group Workout</p>
                                        <div className="CoinCont">
                                            <p>300</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                    <div onClick={() => dispatch(setMeetingID('meet:00000'))} style={{ backgroundColor: achievements.weeklytwo ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.weeklytwo ? "black" : "" }} className="objectiveName">Finish a Duo Running Workout</p>
                                        <div className="CoinCont">
                                            <p>200</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                    <div onClick={() => dispatch(setMeetingID('meet:00000'))} style={{ backgroundColor: achievements.weeklythree ? "hsla(72, 100%, 47%, 40%)" : "" }} className="activeBlack dailyCard">
                                        <p style={{ color: achievements.weeklythree ? "black" : "" }} className="objectiveName">Finish a Duo GYM Workout</p>
                                        <div className="CoinCont">
                                            <p>200</p>
                                            <img className="fitCoin" src={Logos} />
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                ) : (
                    <div>
                        {selectedBadge && selectedCount ? (
                            <div onClick={() => { setSelectedBadge(null); setSelectedCount(selectedCount) }} className="fullScreenClean">
                                <div className='fullScreenCleanBox'>
                                    <div className='achievementImgHolder'>
                                        <img style={{ width: '100px' }} src={selectedBadge.image} alt="Achievement Logo" />
                                    </div>
                                    <h5>GOAL: {selectedBadge.goal}</h5>
                                    {selectedCount >= selectedBadge.count ? <button style={{ marginTop: '20px', height: '30px' }} className="singleButton" >ACHIEVED</button> : <h5 style={{ marginTop: '20px', height: '30px' }}>PROGRESS: {selectedCount} /  {selectedBadge.count}</h5>}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                        <div className="dailyCont">
                            <div className="myBadgesTitle" >
                                <h4 onClick={() => { setShowBadges((state) => !state) }}>MY BADGES </h4>
                                <div className="topAchivmentsCont">
                                    {topFriendAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topFriendAch); setSelectedCount(Object.values(data.friends).filter(friend => friend.status === 'friends').length) }}
                                            src={topFriendAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topWorkoutAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topWorkoutAch); setSelectedCount(data.confirmedcount) }}
                                            src={topWorkoutAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topGroupWorkoutAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topGroupWorkoutAch); setSelectedCount(achievements.confirmed_groupmeetings[0].count) }}
                                            src={topGroupWorkoutAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    {topFeaturedSetsAch ? <div className="achievement" >
                                        <img
                                            onClick={() => { setSelectedBadge(topFeaturedSetsAch); setSelectedCount(data.featuredsets) }}
                                            src={topFeaturedSetsAch.image}
                                            alt="Achievement Logo"
                                        />
                                    </div> : ""
                                    }
                                    <img style={{ position: 'absolute', marginRight: '0px' }} src={back} onClick={() => { setShowBadges((state) => !state) }} className={showBadges ? 'dropDownArrow' : 'closeDropDownArrow'} />
                                </div>
                            </div>
                            {showBadges ?
                                <div className="dailyObjective">
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Friends</p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={Object.values(data.friends).filter(friend => friend.status === 'friends').length} Achievements={friendsAchievements} topBadgeAchieved={topFriendAch} setTopBadgeAchieved={setTopFriendAch} />
                                    </div>
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Duo </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={data.confirmedcount} Achievements={confirmedAchievements} topBadgeAchieved={topWorkoutAch} setTopBadgeAchieved={setTopWorkoutAch} />
                                    </div>
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Groups </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={data.confirmedgroupmeetings} Achievements={confirmedGroupAchievements} topBadgeAchieved={topGroupWorkoutAch} setTopBadgeAchieved={setTopGroupWorkoutAch} />
                                    </div>
                                    <div style={{ height: '40px' }} className="badgeCard">
                                        <p className="objectiveName">Sets </p>
                                        <AchievementBadges setSelectedBadge={setSelectedBadge} setSelectedCount={setSelectedCount} count={data.featuredsets} Achievements={featuredSetsAchievements} topBadgeAchieved={topFeaturedSetsAch} setTopBadgeAchieved={setTopFeaturedSetsAch} />
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                        {/*<TodayWorkoutsBar />*/}
                        <div className="dailyCont">
                            <div style={{ gridTemplateColumns: 'auto auto' }} className="myBadgesTitle" >
                                <h4 onClick={() => { setShowDaily((state) => !state) }}>DAILY CHALLENGES <span>{dailyCount} / 4</span></h4>
                                <img src={back} onClick={() => { setShowDaily((state) => !state) }} className={showDaily ? 'dropDownArrow' : 'closeDropDownArrow'} />
                            </div>

                        </div>
                        <div className="dailyCont">
                            <div style={{ gridTemplateColumns: 'auto auto' }} className="myBadgesTitle" >
                                <h4 onClick={() => { setShowWeekly((state) => !state) }}>WEEKLY CHALLENGES <span>{weeklyCount} / 3</span></h4>
                                <img src={back} onClick={() => { setShowWeekly((state) => !state) }} className={showWeekly ? 'dropDownArrow' : 'closeDropDownArrow'} />
                            </div>
                        </div>
                    </div>
                )}
                <div style={{ height: "380px" }} />
            </div>
        </div >
    )
}


