import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import socket from "../../app/io";
import { selectData, selectStatus, loadChatReqs } from "./chatReqsSlice";
import close from "../../Pics/close.png";
import { Link, useNavigate } from "react-router-dom";
import { deleteRequest } from "./chatReqsFuncs";
import "./chatReqs.css";
import { SmallRequest } from "./SmallRequest";

export function ChatReqs({ id }) {
  const componentRef = useRef(null);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(false);
  const data = useSelector(selectData);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const vibrate = (time) => {
    try {
      window.ReactNativeWebView.postMessage(`Vib:${time}`);
    } catch (e) {

    }
  }

  useEffect(() => {
    try {
      const handleOutsideClick = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          setShow(false);
        }
      };

      const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

      document.addEventListener(clickEvent, handleOutsideClick);

      return () => {
        document.removeEventListener(clickEvent, handleOutsideClick);
      };
    } catch (e) {

    }
  }, []);

  function playNotificationSound() {
    const sound = new Audio('./notif.mp3');
    sound.play();
  }

  useEffect(() => {
    socket.on("msg2", ({ content, senderId }) => {
      try {
        if (window.location.pathname === `/home/:${senderId}`) {
          return
        } else {
          vibrate(200)
          dispatch(loadChatReqs(id)).then(() => {
            setNotification(true);
            //playNotificationSound()
            try {
              //window.ReactNativeWebView.postMessage("ping");
            } catch (e) {
              //console.log('on Browser')
            }
          });
        }
      } catch (e) {
      }
    });
    return () => {
      socket.off("msg2");
    };
  }, [dispatch]);


  useEffect(() => {
    socket.on("groupmsg2", ({ groupid }) => {
      try {
        if (window.location.pathname === `/home/groups/group/:${groupid}`) {
          return
        } else {
          vibrate(200)
          dispatch(loadChatReqs(id)).then(() => {
            setNotification(true);
            //playNotificationSound()
            try {
              //window.ReactNativeWebView.postMessage("ping");
            } catch (e) {
              //console.log('on Browser')
            }
          });
        }
      } catch (e) {
      }
    });
    return () => {
      socket.off("groupmsg2");
    };
  }, [dispatch]);

  useEffect(() => {
    try {
      if (data.requests.length > 0 && show === false) {
        setNotification(true);
      }
    } catch (e) {
      console.log("no requests");
    }
  }, [data]);

  const handleNoti = (data) => {
    if (show === true) {
      setShow(false)
      return
    }
    if (notification === true) {
      //dispatch(loadChatReqs(id));
      setNotification(false);
      setShow(true)
    } else {
      try {
        if (data.requests.length < 1) {
          navigate('/home/chats')
          setShow(false)
        }
        if (data.requests.length > 0) {
          setShow(true)
        }
      } catch (e) {
        //dispatch(loadChatReqs(id));
        setNotification(false);
      }
    }

  };


  if (status === "idle") {
    return (
      <div className="chatNotibutton">
        <img src={localStorage.getItem('chat')} />
      </div>
    );
  } else if (status === "loading") {
    return (
      <div className="chatNotibutton">
        <img src={localStorage.getItem('chat')} />
      </div>
    );
  } else if (status === "done") {
    return (
      <div ref={componentRef} className="notList">
        <div className="chatNotibutton">
          <img onClick={() => { handleNoti(data) }} src={notification ? localStorage.getItem('chatRed') : localStorage.getItem('chat')} />
        </div>
        {show ? (
          <div className="reqs">
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '3px', height: 'fit-content', overflowY: 'scroll' }}>
              {data.requests && data.requests.length > 0 ? (
                data.requests.map((request) => (
                  <SmallRequest myId={id} key={request.id} request={request} setNotification={setNotification} />
                ))
              ) : (
                <div style={{ display: 'flex', height: '46px' }} className="smallCard">
                  <p style={{ color: 'white' }}>No new notifications</p>
                </div>
              )}
              <p onClick={() => { navigate('/home/chats'); setShow(false) }} className="goToChat">Go to Chats</p>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
