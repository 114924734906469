import React, { useEffect, useState, useRef } from 'react';
let currentDate

const getCurrentDate = () => {
  const now = new Date();
  const date = now.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: '2-digit',
  });
  const time = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
  return `${date}`;
  //return `${time}`;
}

const TimeView = ({ time }) => {
  const [displayText, setDisplayText] = useState('No Plan');
  const timeDifferenceRef = useRef(null);

  const updateTime = () => {
    if (time) {
      try {
        const now = new Date();
        const [timeString, dateString] = time.split(' ');
        const [hours, minutes] = timeString.split(':');
        const [day, month, year] = dateString.split('/');

        const fullYear = parseInt(year) + 2000;
        const userTime = new Date(fullYear, month - 1, day, hours, minutes);

        const userTime12Hours = userTime.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        }).replace(/\s/g, ''); // Remove all spaces

        const timeDifference = Math.floor((userTime - now) / (1000 * 60)); // Calculate time difference in minutes
        timeDifferenceRef.current = timeDifference;

        if (timeDifference < 60 && timeDifference >= 0) {
          setDisplayText(`Ready in ${timeDifference} mins`);
        } else if (userTime > now) {
          const currentDate = getCurrentDate();
          if (currentDate === `${day}/${month}`) {
            setDisplayText(`${userTime12Hours} Today`);
          } else {
            setDisplayText(`${userTime12Hours} ${dateString}`);
          }
        } else {
          setDisplayText('Ready now');
        }

      } catch (e) {
        setDisplayText('Ready now')
      }
    } else {
      setDisplayText('No Plan')
    }
  };

  useEffect(() => {
    // Update the date and time immediately and then every minute
    updateTime();
    const interval = setInterval(updateTime, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <p className="cardTime">{displayText}</p>;
};

export default TimeView;




