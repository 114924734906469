export async function fetchMyPosts(setLoading, setPosts) {
  try {
    const response = await fetch('/api/post/loadmyposts'); // Adjust the API endpoint as needed
    if (response.ok) {
      const data = await response.json();
      setPosts(data); 
      setLoading('done')
    } else {
      console.error('Failed to fetch posts');
      setLoading(null)
    }
  } catch (error) {
    setLoading(null)
    console.error('Error fetching posts:', error);
  }
}

export async function fetchUserPosts(mainid, setLoading, setPosts) {
  setLoading(true)
  try {
    const response = await fetch(`/api/post/loaduserposts/${mainid}`); // Adjust the API endpoint as needed
    if (response.ok) {
      const data = await response.json();
      console.log(data)
      setPosts(data); 
      setLoading('done')
    } else {
      console.error('Failed to fetch posts');
      setLoading('failed')
    }
  } catch (error) {
    setLoading('failed')
    console.error('Error fetching posts:', error);
  }
}