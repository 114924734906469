import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import like from '../../Pics/like.png'
import liked from '../../Pics/liked.png'
import TimeView from '../list/TimeView';
import { setMainId } from "../list/tempProfileSlice";
import repImg from '../../Pics/repImg.png'
import { useNavigate } from 'react-router-dom';
import { selectData } from '../home/homeSlice';
import { ReportEvent } from './ReportEvent';

export function EventCard({ eventid, image, eventname, location, eventtime, sport, caption, locationdisplay, creatorid, hiddenby, setEvents, goingcount, commentscount }) {
    const [eventDeleted, setEventDeleted] = useState(false)
    const [eventState, setEventState] = useState('pending')
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const myInfo = useSelector(selectData)

    const photoPicker = (int) => {
        try {
            const picked = int.toLowerCase()
            return localStorage.getItem(picked)
        } catch (e) {

        }
    }

    const openTempProfile = (mainid) => {
        dispatch(setMainId(mainid))
    };

    useEffect(() => {
        try {
            if (hiddenby.includes(myInfo.mainid)) {
                setEvents((prevEvents) =>
                    prevEvents.filter(event => event.eventid !== eventid)
                );
            }
        } catch (e) {

        }
        try {
            if (new Date(eventtime).getTime() < Date.now()) {
                setEventState('completed')
            }
        } catch (error) {

        }
    }, [])


    useEffect(() => {
        if (eventDeleted) {
            setEvents((prevEvents) =>
                prevEvents.filter(event => event.eventid !== eventid)
            );
        }
    }, [eventDeleted]);


    return (
        <div className='eventCardCont' >
            {updating ? <div className='CreatingPost'>
                <p className='fake'> Loading..</p>
            </div> : ""}
            <div onMouseUp={() => navigate(`/home/event/:${eventid}`)} className="eventPageMainImgContainer">
                <img className="eventPageMainImg" src={image} alt="Event" />
            </div>
            {creatorid === myInfo.mainid && eventState !== 'completed' && (<img onMouseUp={() => navigate(`/home/events/createevent/:${eventid}`)} className='editEventInCard' src={localStorage.getItem('edit')} />)}
            {creatorid !== myInfo.mainid && (<img onClick={() => setShowReport(true)} className='editEventInCard' style={{ filter: 'invert(1)' }} src={repImg} />)}
            <h3 onMouseUp={() => navigate(`/home/event/:${eventid}`)} >{eventname}</h3>
            <div onMouseUp={() => navigate(`/home/event/:${eventid}`)} className='eventTagCard'>
                <div className="eventTagChild">
                    <img style={{ filter: 'grayscale(1) invert(1)' }} src={photoPicker(sport)} />
                    <h4><span style={{ fontSize: '10px', marginRight: '5px' }}></span></h4>
                </div>
                <div className="eventTagChild">
                    <img style={{ filter: 'grayscale(1)' }} src={localStorage.getItem('friendsPic')} />
                    <h4><span style={{ marginRight: '5px' }}>{goingcount}</span></h4>
                </div>
                <div className="eventTagChild">
                    <img style={{ filter: 'invert(1)' }} src={localStorage.getItem('commentPic')} />
                    <h4><span style={{ marginRight: '5px' }}>{commentscount}</span></h4>
                </div>
            </div>
            <h5 onMouseUp={() => navigate(`/home/event/:${eventid}`)} style={{ marginBottom: '8px', fontSize: '13px', color: 'white', fontWeight: '500', textAlign: 'left' }}>{eventtime ? (new Date(eventtime)).toLocaleString('en-US', { day: 'numeric', month: 'short', year: '2-digit', hour: 'numeric', minute: 'numeric' }) : ""}</h5>
            <h5 onMouseUp={() => navigate(`/home/event/:${eventid}`)} style={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                width: '90%', fontSize: '13px', color: 'white', fontWeight: '500', textAlign: 'left'
            }}>{locationdisplay}</h5>
            {showReport ?
                <div style={{ marginTop: 'calc((100vw * -0.56) - 70px)', zIndex: '500' }} onClick={() => setShowReport(false)} >
                    <ReportEvent eventname={eventname} eventid={eventid} creatorid={creatorid} setShowReport={setShowReport} setEventDeleted={setEventDeleted} myMainid={myInfo.mainid} />
                </div>
                : ""}
        </div>
    )
}
