import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectInfo, selectStatus, loadUser } from "./userSlice";
import "./user.css";
import { useParams } from "react-router-dom";
import { OtherProfile } from "./OtherProfile";
import { Chat } from "./Chat";
import { Card } from '../list/Card'
import { FakeCarD } from '../root/LoadingCards'
import { handleShowBottomNav } from "../home/homeSlice";
import { OnChatCard } from "./OnChatCard";
import { FakeOnChatCard } from "./FakeOnChatCard";


export function User({ }) {
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const [block, setBlock] = useState('')
  const [friend, setFriend] = useState(false);
  const [onChat, setOnChat] = useState(false)
  const [isTyping, setIsTyping] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [checkedIfFriend, setCheckedIfFriend] = useState(false)
  const dispatch = useDispatch();
  let { mainid } = useParams();


  useEffect(() => {
    if (onChat === true) {
      dispatch(handleShowBottomNav(false))
      try {
        window.ReactNativeWebView.postMessage(`disableAd`);

      } catch (e) {
      }
    } else if (onChat === false) {
      dispatch(handleShowBottomNav(true))
      try {
        //window.ReactNativeWebView.postMessage(`enableAd`);
      } catch (e) {
      }
    }
  }, [onChat, mainid])

  useEffect(() => {
    return () => {
      dispatch(handleShowBottomNav(true))
      try {
        //window.ReactNativeWebView.postMessage(`enableAd`);
      } catch (e) {
      }
    };
  }, [])

  useEffect(() => {
    try {
      const userID = info.user[0].mainid;
      if (info.friends[userID].status === "friends") {
        setFriend(true);
        setOnChat(true);
      } else if (info.friends[userID].status === "iblock") {
        setBlock("iblock")
      } else if (info.friends[userID].status === "blocki") {
        setBlock("blocki")
      } else {
        setFriend(false);
        setOnChat(false)
      }
      setCheckedIfFriend(true)
    } catch (e) {
      setFriend(false);
      setCheckedIfFriend(true)
    }
  }, [info]);


  useEffect(() => {
    let id = mainid.replace(":", "");
    dispatch(loadUser(id));
  }, [dispatch, mainid]);

  const back = () => {
    setOnChat(true)
  };

  const openChat = () => {
    setOnChat(true)
  }

  const closeChat = () => {
    setOnChat(false)
  }


  if (status === "done" && checkedIfFriend === true) {
    if (block === 'iblock' || block === 'blocki') {
      return <h1>content unavailable</h1>
    } else {
      if (onChat === false) {
        return (
          <div className="tempProfilePage">
            <OtherProfile back={back} info={info} openChat={openChat} friend={friend} setFriend={setFriend} />
          </div>
        );
      } else if (onChat === true) {
        return (
          <div className="onChatCont" style={{ marginTop: '10px' }}>
            <div className="cardHelper">
              <OnChatCard user={info.user[0]} isTyping={isTyping} isRecording={isRecording} />
              <p style={{ width: 'calc(100% - 60px)', height: '50px', left: '10px' }} onClick={closeChat} className="cardHelper2"></p>
            </div>
            {friend ?
              <Chat
                back={back}
                friend={friend}
                status={status}
                friendid={mainid.replace(":", "")}
                setIsTyping={setIsTyping}
                setIsRecording={setIsRecording}
              />
              :
              ''
            }
          </div>
        )
      }
    }
  } else {
    return (
      <div className="onChatCont" style={{ marginTop: '10px' }}>
        <div className="cardHelper">
          <FakeOnChatCard />
        </div>
      </div>
    );
  }
}
