import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SmallLoadingBar } from "../root/SmallLoadingBar";
import { disableRefresh, selectData } from "../home/homeSlice";
import { SingleCommentPost } from "./SingleCommentPost";
import repImg from '../../Pics/repImg.png'

import { setMainId } from "../list/tempProfileSlice";
import { AddCommentPost } from "./AddCommentPost";

export function PostComments({ postid, creatorid, showComments, setShowComments, setCreating, dynamicCommentsCount, setDynamicCommentsCount }) {
    const myInfo = useSelector(selectData)
    const [status, setStatus] = useState('loading')
    const [coach, setCoach] = useState(null)
    const [reviews, setReviews] = useState(null)
    const [students, setStudents] = useState(null)
    const [addingReview, setAddingReview] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate()


    const fetchPostComments = async () => {
        if (coach === null) {
            setStatus('loading')
        }
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/post/loadpostcomments`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ postid: postid, creatorid: creatorid }),
            });
            if (response.ok) {
                const data = await response.json();
                setReviews(data)
                setDynamicCommentsCount(data.length)
                setStatus('done')
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchPostComments()
    }, [])


    if (status === 'loading') {
        return (
            <div className="postCommentsCont">
                <div style={{ marginTop: '5px', height: "25px" }} className='experienceHead'>
                    <h4 style={{ marginLeft: '5px', fontSize: "14px" }} onClick={() => { setShowComments((state) => !state) }}>Comments:</h4>
                    <img style={{ right: '0px', width: "20px", padding: '0px' }} onClick={() => { setShowComments(true); setAddingReview(true) }} className='plusInExp' src={localStorage.getItem('plus')} />
                </div>
                <div style={{ padding: '0px 10px' }} className='reviewsCont'>
                    {Array.from({ length: dynamicCommentsCount }, (_, index) => (
                        <div style={{ borderBottom: '1px solid black' }} key={index} className='singleReviewCont'>
                            <p style={{ width: "100%", lineHeight: "1.2", marginBottom: '3px' }}>
                                <span
                                    style={{ color: 'hsla(72, 100%, 47%, 70%)' }}>
                                    Loading . . .:
                                </span>
                                <br />
                                <span style={{ marginTop: '5px', display: 'inline-block' }}>
                                    - Loading . . .
                                </span>
                            </p>
                            <img className='repImgInSingleReview' src={repImg} />
                        </div>
                    ))}
                    {dynamicCommentsCount === 0 ? < h5 style={{ color: 'white', gridRow: '1 / 2' }}>No Comments Yet</h5> : ""}
                </div>
            </div >
        )
    }

    if (status === 'done') {
        return (
            <div className="postCommentsCont">
                <div style={{ marginTop: '5px', height: "25px" }} className='experienceHead'>
                    <h4 style={{ marginLeft: '5px', fontSize: "14px" }} onClick={() => { setShowComments((state) => !state) }}>Comments:</h4>
                    <img style={{ right: '0px', width: "20px", padding: '0px' }} onClick={() => { setShowComments(true); setAddingReview(true) }} className='plusInExp' src={localStorage.getItem('plus')} />
                </div>
                {status === 'done' && showComments && reviews && reviews.length > 0 ?
                    <div style={{ padding: '0px 10px' }} className='reviewsCont'>
                        {addingReview ?
                            <AddCommentPost setCreating={setCreating} myname={myInfo.name} reviews={reviews} setReviews={setReviews} setAddingReview={setAddingReview} postid={postid} creatorid={creatorid} setDynamicCommentsCount={setDynamicCommentsCount} />
                            :
                            ""
                        }
                        {reviews.slice().sort((a, b) => b.indexid - a.indexid).map((rev) => (
                            <SingleCommentPost key={rev.indexid} rev={rev} myMainid={myInfo.mainid} postid={postid} creatorid={creatorid} setReviews={setReviews} setDynamicCommentsCount={setDynamicCommentsCount} setCreating={setCreating} />
                        ))}
                    </div>
                    :
                    <div style={{ padding: '0px 10px', display: showComments ? "" : 'none' }} className='reviewsCont'>
                        {addingReview ?
                            <AddCommentPost setCreating={setCreating} myname={myInfo.name} reviews={reviews} setReviews={setReviews} setAddingReview={setAddingReview} postid={postid} creatorid={creatorid} setDynamicCommentsCount={setDynamicCommentsCount} />
                            :
                            ""
                        }
                        {status === 'done' && showComments ? <h5 style={{ color: 'white', gridRow: '1 / 2' }}>No Comments Yet</h5> : ""}
                    </div >
                }
            </div>

        )
    }
}
