import React, { useEffect, useState } from "react";
import repImg from '../../Pics/repImg.png';


export function FakeOnChatCard({ }) {

    return (
        <div className="onChatCard">
            <div style={{ backgroundColor: 'grey' }} className="user-picture" />
            <div className="info">
                <div className="name-status">
                    <img className="loading-icon" src="https://i.gifer.com/ZKZg.gif" alt="Loading..." />
                    <p className="user-name">Loading . . .</p>
                </div>
                <div style={{ minHeight: '20px', width: '100%' }}>
                </div>
            </div>
            <img className="report-icon" src={repImg} alt="Report" />
        </div>
    );
}
