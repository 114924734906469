import React, { useEffect, useState } from 'react';
import greentick from '../../Pics/greentick.png'
import deleteImg from '../../Pics/delete.png'
import { useDispatch } from 'react-redux';
import { setMainId } from '../list/tempProfileSlice';
import { SmallLoadingBar } from '../root/SmallLoadingBar';
import { loadMyGroups } from './groupsSlice';

export function SingleMember({ member, myMainid, groupid, setUpdating }) {
    const [showCard, setShowCard] = useState(true)

    const dispatch = useDispatch()

    const openStudentProfile = () => {
        dispatch(setMainId(member.mainid))
    }

    const handleAcceptGroupMember = async () => {
        //setUpdating(true)
        setShowCard(false)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/groups/acceptgroupmember`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ memberid: member.mainid }),
                }
            );
            if (response.ok) {
                if (window.location.pathname === '/home/groups') {
                    dispatch(loadMyGroups())
                    setTimeout(() => {
                        //setUpdating(false)
                    }, 600);
                } else {
                    //setUpdating(false)
                }
            } else {
                //setUpdating(false)
            }
        } catch (error) {
            console.error(error);
            setUpdating(false)

        }
    }

    const handleRejectGroupMember = async () => {
        //setUpdating(true)
        setShowCard(false)
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/groups/removegroupmember`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ memberid: member.mainid }),
                }
            );
            if (response.ok) {
                if (window.location.pathname === '/home/groups') {
                    dispatch(loadMyGroups())
                    setTimeout(() => {
                        //setUpdating(false)
                    }, 600);
                } else {
                    //setUpdating(false)
                }
            } else {
                //setUpdating(false)
            }
        } catch (error) {
            console.error(error);
            setUpdating(false)

        }
    }

    if (showCard === false) {
        return null
    }

    return (
        <div className='singleStudentCont'>
            <img onClick={openStudentProfile} className='singleStudentProfilePic' src={member.picture_url} />
            <p onClick={openStudentProfile} >{member.name}</p>
            {member.status === 'request' && groupid === myMainid ? <img onClick={handleAcceptGroupMember} style={{ width: '25px' }} src={greentick} /> : <img style={{ width: '25px' }} />}
            {groupid === myMainid ? <img onClick={handleRejectGroupMember} style={{ width: '25px' }} src={deleteImg} /> : ""}
        </div>
    )

}