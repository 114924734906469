import React, { useState, useEffect } from 'react';


export function ConfirmedCustomWorkoutCard({ workout, }) {


    return (
        <div style={{ height: '70px', border: '1px solid hsla(72, 100%, 47%, 50%)' }} className='FeaturedCardCont' >
            <h5 style={{ marginTop: '5px', marginLeft: '10px' }} >Name: {workout.name}</h5>
            <h5 style={{ marginLeft: '10px', fontWeight: '500', fontSize: '12px', marginTop: '25px' }} >Hint: {workout.hint}</h5>
            <h5 style={{ marginLeft: '10px', fontWeight: '500', fontSize: '12px', marginTop: '42px' }} >{workout.date}</h5>
            <h5 style={{ marginTop: '20px', right: '40px' }} > Sets: <span style={{ fontSize: "16px", color: 'hsl(72, 100%, 47%)' }}>{workout.sets}</span></h5 >
        </div >
    )
}