import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { handleShowBottomNav, handleShowTopNav, playAdInt, selectData } from '../home/homeSlice';
import { loadLeadBoards } from './leadBoardsFuncs';
import './leadboards.css'
import { LeadBoardCard } from './LeadBoardCard';
import { Post } from '../Posts/Post';
import { LeadBoardPostCard } from './LeadBoardPostCard';
import { RankingHeader } from './RankHeader';
import { RankTimeHeader } from './RankTimeHeader';
import { useSwipeable } from 'react-swipeable';

export function LeadBoards({ }) {
    const [selectedFilter, setSelectedFilter] = useState('post')
    const [displayFilter, setDisplayFilter] = useState('post')
    const [loading, setLoading] = useState('idle')
    const [users, setUsers] = useState(null)
    const myInfo = useSelector(selectData)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const scrollRef = useRef(0);
    const previousScrollTopRef = useRef(0);

    useEffect(() => {
        setLoading('loading')
        dispatch(playAdInt())
        loadLeadBoards(setLoading, setUsers, selectedFilter)
    }, [selectedFilter])

    useEffect(() => {
        try {
            //const scrollRef = document.getElementById('feedsCont');
            const scrollElement = scrollRef.current;
            if (!scrollElement) return;
            const handleScroll = () => {
                const scrollHeight = scrollElement.scrollHeight;
                const scrollTop = scrollElement.scrollTop;
                const clientHeight = scrollElement.clientHeight;
                console.log(scrollHeight)
                console.log(scrollTop)
                console.log(clientHeight)


                const previousScrollTop = previousScrollTopRef.current;
                const isScrollingDown = scrollTop > previousScrollTop;
                const isScrollingUp = scrollTop < previousScrollTop;

                if (isScrollingUp) {
                    dispatch(handleShowBottomNav(true));
                    dispatch(handleShowTopNav(true));
                } else if (isScrollingDown && (scrollTop > (60))) {
                    dispatch(handleShowBottomNav(false));
                    dispatch(handleShowTopNav(false));

                }

                previousScrollTopRef.current = scrollTop;
            };

            scrollElement.addEventListener('scroll', handleScroll);

            return () => {
                // Cleanup: Remove the event listener when the component unmounts
                scrollElement.removeEventListener('scroll', handleScroll);
            };

        } catch (e) {

        }
    }, []);

    useEffect(() => {
        switch (selectedFilter) {
            case 'duo':
                setDisplayFilter('Confimed Duo Workouts');
                break;
            case 'post':
                setDisplayFilter('This Month Top Liked Posts');
                break;
            case 'group':
                setDisplayFilter('Confimed Group Workouts');
                break;
            case 'alltime':
                setDisplayFilter('Monthly Winners');
                break;
            default:
                // Handle any default case if necessary
                setDisplayFilter('default');
                break;
        }
    }, [selectedFilter]);

    useEffect(() => {
        return () => {
            dispatch(handleShowBottomNav(true))
            dispatch(handleShowTopNav(true))
        }
    })

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };
    const handleSwipeLeft = () => {
        if (window.smallListOpend !== true) {
            navigate(+1)
        }
    };

    const swipeBackHandlers = useSwipeable({
        onSwipedRight: handleSwipeRight,
        onSwipedLeft: handleSwipeLeft,
        delta: 30  // Set swipe threshold to 30 pixels
    });

    return (
        <div {...swipeBackHandlers} style={{ zIndex: '12', top: '0px', height: '100vh' }} className="feedPostsCont">
            <div ref={scrollRef} style={{ zIndex: '20', paddingTop: '45px', marginTop: '0px', marginBottom: '0px' }} className="scrollList">
                <div style={{ position: 'relative', display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ margin: '-5px auto', fontSize: '24px' }} htmlFor="comment">RANKINGS</label>
                    <label style={{ bottom: '3px', left: '-10px', position: 'absolute', width: '100%', fontSize: '10px' }} htmlFor="comment">{displayFilter}</label>
                </div>
                <div style={{ marginTop: '10px', marginBottom: '0px', padding: '0px 10px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
                    <div onClick={() => setSelectedFilter('post')} style={{ filter: selectedFilter === 'post' ? "" : 'grayscale(1)', fontSize: '13px' }} className='activeBlack buttonWithImageVert'>
                        <img src={localStorage.getItem('feedGreen')} />
                    </div>
                    <div onClick={() => setSelectedFilter('duo')} style={{ filter: selectedFilter === 'duo' ? "" : 'grayscale(1)', fontSize: '13px' }} className='activeBlack buttonWithImageVert'>
                        <img src={localStorage.getItem('duoGreen')} />

                    </div>
                    <div onClick={() => setSelectedFilter('group')} style={{ filter: selectedFilter === 'group' ? "" : 'grayscale(1)', fontSize: '13px' }} className='activeBlack buttonWithImageVert'>
                        <img src={localStorage.getItem('friends2')} />

                    </div>
                    <div onClick={() => setSelectedFilter('alltime')} style={{ filter: selectedFilter === 'alltime' ? "" : 'grayscale(1)', fontSize: '13px' }} className='activeBlack buttonWithImageVert'>

                        <img src={localStorage.getItem('trophy')} />

                    </div>
                </div>
                <div style={{ padding: selectedFilter === 'duo' || selectedFilter === 'group' ? '10px 10px' : '10px 0px' }}>
                    {loading === 'done' && (selectedFilter === 'duo' || selectedFilter === 'group') && users && (
                        users.map((user, index) => {
                            return < LeadBoardCard key={user.mainid} user={user} rank={index} type={selectedFilter} />
                        })
                    )}
                    {loading === 'done' && selectedFilter === 'post' && users && (
                        users.map((post, index) => {
                            if (post.likes !== undefined) {
                                return (
                                    <div key={index}>
                                        <RankingHeader rank={index + 1} name={post.creatorname} />
                                        <Post thumb={post.thumb} key={index} postid={post.postid} image={post.postphoto} creatorid={post.creatorid} creatorname={post.creatorname} creatorpicture={post.creator_picture_url} createTime={post.time} caption={post.caption} sport={post.sport} taggedid={post.taggedid} taggedname={post.taggedname} likes={post.likes} myMainid={myInfo.mainid} showtagged={post.showtagged} hiddenby={post.hiddenby} setRequest={""} commentscount={post.commentscount} likescount={post.likescount} />
                                    </div>
                                )
                            }
                        })
                    )}
                    {loading === 'done' && selectedFilter === 'alltime' && users && (
                        users.map((post, index) => {
                            if (post.likes !== undefined) {
                                return (
                                    <div key={index}>
                                        <RankTimeHeader time={post.time} />
                                        <Post thumb={post.thumb} key={index} postid={post.postid} image={post.postphoto} creatorid={post.creatorid} creatorname={post.creatorname} creatorpicture={post.creator_picture_url} createTime={post.time} caption={post.caption} sport={post.sport} taggedid={post.taggedid} taggedname={post.taggedname} likes={post.likes} myMainid={myInfo.mainid} showtagged={post.showtagged} hiddenby={post.hiddenby} setRequest={""} commentscount={post.commentscount} likescount={post.likescount} />
                                    </div>
                                )
                            }
                        })
                    )}
                    {loading === 'loading' && (
                        <div className='leadBoardCardCont'>
                            <p style={{ color: 'white', fontSize: '20px' }}>Loading . . .</p>
                        </div>
                    )}

                    {loading === 'done' && users.length === 0 ?
                        <div>
                            {selectedFilter === 'group' && (
                                <div onMouseUp={() => navigate('/home/groups')} className='noPrevWorkout'>
                                    <p>
                                        Be The First to complete a GROUP workout,<br />
                                        Create or Join Group and Complete it?
                                    </p>
                                    <img src={localStorage.getItem('friends2')} />
                                </div>
                            )}
                            {selectedFilter === 'duo' && (
                                <div onMouseUp={() => navigate('/home/myworkouts?Load:duo')} className='noPrevWorkout'>
                                    <p>
                                        Be The First to complete a DUO workout,<br />
                                        Invite a friend to a workout?
                                    </p>
                                    <img src={localStorage.getItem('meetUpGreen')} />
                                </div>
                            )}
                            {selectedFilter === 'post' && (
                                <div onMouseUp={() => navigate('/home/createpost')} className='noPrevWorkout'>
                                    <p>
                                        No liked posts this week yet<br />
                                        Create a public post
                                    </p>
                                    <img src={localStorage.getItem('feedGreen')} />
                                </div>
                            )}

                        </div>
                        :
                        ""
                    }
                </div>
                <div style={{ height: '150px' }} />
            </div>
        </div>
    )
}
