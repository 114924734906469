import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { selectData } from '../home/homeSlice';
import { selectMainId, selectInfo, selectStatus, clearInfo, loadMeeting } from "./tempMeetingPageSlice";
import { FakeMeetUpCard } from './FakeMeetUpCard'
import { MeetUpCreator } from './MeetUpCreator';
import './tempMeetingPage.css'
import { SwipeInUser } from '../user/SwipeInUser'
import { MeetUpCard } from './MeetUpCard';




export function TempMeetingPage({ }) {
  const componentRef = useRef(null);
  const myinfo = useSelector(selectData)
  const [showCreate, setShowCreate] = useState(false);
  const [block, setBlock] = useState('')
  const mainid = useSelector(selectMainId);
  const info = useSelector(selectInfo);
  const status = useSelector(selectStatus);
  const [showInvite, setShowInvite] = useState(null);
  const [showfirendsList, setShowFriendslist] = useState(false)
  const [selectedSport, setSelectedSport] = useState(null);
  const [taggedFriend, setTaggedFriend] = useState(null)
  const [creating, setCreating] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      const handleOutsideClick = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          dispatch(clearInfo())
        }
      };

      const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

      document.addEventListener(clickEvent, handleOutsideClick);

      return () => {
        document.removeEventListener(clickEvent, handleOutsideClick);
      };
    } catch (e) {

    }
  }, []);


  useEffect(() => {

  }, [info]);

  const openChat = ""

  const back = () => {
    try {
      dispatch(clearInfo());
    } catch (e) {

    }
  };

  useEffect(() => {
    if (mainid) {
      dispatch(loadMeeting(mainid))
    }
  }, [mainid])



  useEffect(() => {
    setTaggedFriend(null)
    try {
      if (info.user) {
        setTaggedFriend(info.user)
        //try {
        //  window.ReactNativeWebView.postMessage(`Alert:No pending workout between you and ${info.user.name}.`);
        //} catch (e) {
        //}
      }
    } catch (e) {

    }
    try {
      if (info.error) {
        //try {
        //  window.ReactNativeWebView.postMessage(`Alert:This workout is not available any more. Set a new one`);
        //} catch (e) {
        //}
      }
    } catch (e) {

    }
    try {
      if (myinfo.interest) {
        setSelectedSport(myinfo.interest)
      } else {
        setSelectedSport(null)
      }
    } catch (e) {

    }
  }, [info])

  if (mainid) {
    if (status === "done" && (info.user || info.error)) {
      return (
        <div style={{ zIndex: '700' }} className="tempMeetingPage">
          <div style={{ zIndex: '900' }} className="meetingPageCont">
            <SwipeInUser back={back} />
            <div onClick={back} className='fuLLwidthandHeight' />
            <div style={{ width: 'calc(100% - 40px' }} >
              <MeetUpCreator
                myInfo={myinfo}
                showfirendsList={showfirendsList}
                setShowFriendslist={setShowFriendslist}
                selectedSport={selectedSport}
                setSelectedSport={setSelectedSport}
                taggedFriend={taggedFriend}
                setTaggedFriend={setTaggedFriend}
                creating={creating}
                setCreating={setCreating}
              />
            </div>
            <div onClick={back} className='fuLLwidthandHeight' />
          </div>
        </div >
      )
    }
    if (status === "loading") {
      return (
        <div className="tempMeetingPage">
          <SwipeInUser back={back} />
          <div style={{ zIndex: '900' }} className="meetingPageCont">
            <div onClick={back} className='fuLLwidthandHeight' />
            <div style={{ width: 'calc(100% - 40px' }} >
              <FakeMeetUpCard
                myMainid={myinfo.mainid}
                meetingid={info.meetingid}
                creatorid={info.creatorid}
                creatorname={info.creatorname}
                taggedid={info.taggedid}
                taggedname={info.taggedname}
                createtime={info.createtime}
                sport={info.sport}
                state={info.state}
                notifytime={info.notifytime}
                meetingtime={info.meetingtime}
                finishedtime={info.finishedtime}
              />
            </div>
            <div onClick={back} className='fuLLwidthandHeight' />
          </div>
        </div >
      )
    }
    if (status === "done") {
      return (

        <div className="tempMeetingPage">
          <div style={{ zIndex: '900' }} className="meetingPageCont">
            <SwipeInUser back={back} />
            <div onClick={back} className='fuLLwidthandHeight' />
            <div style={{ width: 'calc(100% - 40px' }} >
              <MeetUpCard
                myMainid={myinfo.mainid}
                meetingid={info.meetingid}
                creatorid={info.creatorid}
                creatorname={info.creatorname}
                taggedid={info.taggedid}
                taggedname={info.taggedname}
                createtime={info.createtime}
                sport={info.sport}
                state={info.state}
                notifytime={info.notifytime}
                meetingtime={info.meetingtime}
                finishedtime={info.finishedtime}
              />
            </div>
            <div onClick={back} className='fuLLwidthandHeight' />
          </div>
        </div >
      );
    }
  } else {
    return (
      ""
    )
  }

};

