import React, { useState, useEffect } from "react";
import { getTimeOptions, calculateDelay } from "./intFuncs";

export const TimeInputNew = ({ setTime, maxTime }) => {
    const [selectedTime, setSelectedTime] = useState((new Date(Date.now() - ((new Date()).getTimezoneOffset() * 60000) + (60 * 60 * 1000))).toISOString().slice(0, 16));
    const [delay, setDelay] = useState()

    const resetSelectedTime = () => {
        const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
        const localISOTime = (new Date(Date.now() - tzoffset + (60 * 60 * 1000))).toISOString().slice(0, 16);
        setSelectedTime(localISOTime)
        const hours = new Date(localISOTime).getHours().toString().padStart(2, '0');
        const minutes = new Date(localISOTime).getMinutes().toString().padStart(2, '0');
        const day = new Date(localISOTime).getDate().toString().padStart(2, '0');
        const month = (new Date(localISOTime).getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = new Date(localISOTime).getFullYear().toString().slice(2);
        const timeStringToSave = `${hours}:${minutes} ${day}/${month}/${year}`;
        const initialDelay = (new Date(localISOTime).getTime()) - Date.now()
        setDelay(initialDelay)
        setTime({ selectedTime: timeStringToSave, delay: initialDelay });
    }

    useEffect(() => {
        try {
            resetSelectedTime()
        } catch (e) {

        }
    }, [])

    useEffect(() => {
        if (!selectedTime) {
            try {
                resetSelectedTime()
            } catch (e) {

            }
        }
    }, [selectedTime])


    const handleTimeChange = (event) => {
        const maxMsTime = 1 * 7 * 24 * 60 * 60 * 1000
        const selectedDateTime = new Date(event.target.value);
        // Format the date and time as "hh:mm dd/mm/yy"
        const hours = selectedDateTime.getHours().toString().padStart(2, '0');
        const minutes = selectedDateTime.getMinutes().toString().padStart(2, '0');
        const day = selectedDateTime.getDate().toString().padStart(2, '0');
        const month = (selectedDateTime.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = selectedDateTime.getFullYear().toString().slice(2);

        const timeStringToSave = `${hours}:${minutes} ${day}/${month}/${year}`;

        // Calculate the delay in milliseconds
        const delayToSave = selectedDateTime.getTime() - Date.now();
        if (delayToSave <= 0) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Make sure you select time after now`);
            } catch (e) {

            }
            resetSelectedTime()
            return
        } else if (delayToSave > maxMsTime) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Keep it within this week`);
            } catch (e) {

            }
            resetSelectedTime()
            return
        }
        setSelectedTime(event.target.value);
        setTime({ selectedTime: timeStringToSave, delay: delayToSave });
    };

    return (
        <div className="timeCont">
            <label htmlFor="time">TIME</label>
            <input
                style={{ width: 'fit-content' }}
                type="datetime-local"
                id="time"
                input="time"
                inputMode="time"
                value={selectedTime}
                onChange={handleTimeChange}
            />
        </div>
    );
};
