import React from "react";
import { Link } from "react-router-dom";
import { Fav1 } from '../profile/Fav1'
import { Fav2 } from '../profile/Fav2'
import { Clock } from '../profile/Clock'
import { ShowTo } from "../profile/ShowTo";
import { ChangePP } from '../profile/changePP'
import { ImageLinkChanger } from '../profile/ImageLinkChanger'
import not from "../../Pics/notif_.png";
import connects from "../../Pics/connects2.png";
import oneuser from '../../Pics/oneuser.png'
import Logos from "../../Pics/Logos.png"
import { PublicProfEdit } from "../profile/PublicProfEdit";

const data = { showto: 'all', facebooklink: null, instagramlink: null, fav1: '', fav2: '' }


export function LoadingHome({ }) {
    return (
        <div className="allHome">
            <div className="mainHome">
                <div className="homeTrans" >
                    <div className="homeTrans">
                        <div className="mainProfile">
                            <div className="profileCont">
                                <div className="workOutButotn">
                                    <div className="workOutButotn">
                                        FIND <br /> FITBUDDY
                                    </div>
                                </div>
                                <h3 className="fake welcome"> WELCOME BACK, <br />Loading...</h3>
                                <div className="infoCont" >
                                    <div className="fakeProfilePic">

                                    </div>
                                    <Clock />
                                    <div className="profileText">
                                        <p className="fake fullName">Loadin...</p>
                                        <div style={{ display: 'flex', marginLeft: '0px', marginBottom: '5px' }}>
                                            <p className="fake gender">Loadin...</p>
                                        </div>
                                        <div style={{ display: 'flex', width: '100%', paddingRight: '10px' }}  >
                                            <p style={{ textAlign: 'left' }} className="fake bio">Loading...</p>
                                        </div>
                                    </div>
                                    <div className="publicStatusCont" >
                                        <PublicProfEdit public_profile={true} />
                                    </div>
                                </div>
                                <div className="favsCont">
                                    <div className="favs">
                                        <Fav1 fav={''} />
                                        <Fav2 fav={''} />
                                    </div>
                                </div>
                                <div className="visbToCont">
                                    <ShowTo data={data} />
                                </div>
                                <Link className="connectsbtn" to="/home/friends">
                                    <div>
                                        <img src={connects} />
                                    </div>
                                    <p className="fake" style={{ lineHeight: '1.1' }}>BUDDIES<br /> ( ... )</p>
                                </Link>
                                <ImageLinkChanger data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}