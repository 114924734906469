import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectData, disableRefresh } from '../home/homeSlice';
import { MiniFriends } from '../minifriends/MiniFriends'
import { SportPicker } from './SportPicke';
import { Post } from './Post';
import changePP from '../../Pics/changePP.png'
import logo from '../../Pics/Logos.png'
import AppleDownload from '../../Pics/AppleDownload.png'
import GoogleDownload from '../../Pics/GoogleDownload.png'
import { NewVersion } from '../root/NewVersion';
import './postCreator.css'


export function PostCreator({ }) {
  const myinfo = useSelector(selectData)
  const [askTopUpdate, setAskTopUpdate] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [thumb, setThumb] = useState(null)
  const [showfirendsList, setShowFriendslist] = useState(false)
  const [selectedSport, setSelectedSport] = useState(null);
  const [taggedFriend, setTaggedFriend] = useState(null)
  const [publicPost, setPublicPost] = useState(true)
  const [creating, setCreating] = useState(false)
  const [version, setVersion] = useState(null);
  const [platform, setPlatform] = useState('');
  const [caption, setCaption] = useState('');
  const [photo64, setPhoto64] = useState('');
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const likes = [1, 2]

  const timestamp = Date.now().toString(); // Get current timestamp in milliseconds and convert to string
  const timeToSend = new Date(parseInt(timestamp)); // Convert timestamp string to Date object


  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage("MaxVidDuration:30");
    } catch (e) {

    }
  }, [])


  function handleMessageEvent(event) {
    const data = event.data;
    try {
      if (data.length > 0 && data.startsWith('SelectedPhoto:')) {
        const data64 = data.substring(14);
        setSelectedPhoto(`data:image/jpeg;base64,${data64}`)
        setSelectedVideo(null)
        setPhoto64(data64)
      }
    } catch (e) {

    }
    try {
      if (data.length > 0 && data.startsWith('SelectedVideo:')) {
        const vidueUrl = data.substring(14);
        setSelectedVideo(vidueUrl)
        setThumb(`https://fitbuddystorage.blob.core.windows.net/images/${myinfo.mainid}/TempThumb.jpeg?date=${Date.now()}`)
        setSelectedPhoto(null)
        setPhoto64(null)
      }
    } catch (e) {

    }
    try {
      if (data.length > 0 && data.startsWith('Platform:')) {
        const parts = data.split(' ');
        const appPlatform = parts[0].substring(9); // Extract the platform
        const appVersion = parts[1].substring(8); // Extract the version
        setVersion(appVersion)
        setPlatform(appPlatform)
      }
    } catch (e) {
      console.log('no data')
    }
  }

  useEffect(() => {
    // Add event listeners when component mounts
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleMessageEvent);
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('message', handleMessageEvent);
    }
    // Clean up event listeners when component unmounts
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('message', handleMessageEvent);
      }
      if (typeof document !== 'undefined') {
        document.removeEventListener('message', handleMessageEvent);
      }
    };
  }, []);

  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage("Version");
    } catch (e) {

    }
  }, [])

  const openImagePicker = () => {
    try {
      window.ReactNativeWebView.postMessage("PickPhoto");
      if (/Android/.test(navigator.userAgent)) {
        dispatch(disableRefresh());
      }
    } catch (e) {

    }
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const handleCreatePost = async () => {
    setCreating(true)
    if (!selectedPhoto && !caption && !selectedVideo) {
      setCreating(false)
      console.error('Please select a photo and add a caption');
      return;
    }

    //if (publicPost && !selectedPhoto) {
    //  try {
    //    window.ReactNativeWebView.postMessage(`Alert:Select a photo sharing your captured sport moment`);
    //    setCreating(false)
    //    return
    //  } catch (e) {
    //    alert(`Select a photo shwoing your captured sport moment`)
    //    setCreating(false)
    //    return
    //  }
    //}

    // Create a Blob from the base64-encoded photo
    const byteCharacters = window.atob(photo64);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const formData = new FormData();
    let type
    if (selectedPhoto) {
      type = 'image/jpeg'
      const blob = new Blob([new Uint8Array(byteArrays)], { type: type });
      formData.append('image', blob, 'post.jpg'); // 'post.jpg' is a placeholder, you can change it
    } else if (selectedVideo) {
      formData.append('videourl', selectedVideo);
    }
    const timestamp = Date.now().toString(); // Get current timestamp in milliseconds and convert to string
    const timeToSend = new Date(parseInt(timestamp)); // Convert timestamp string to Date object
    formData.append('time', timeToSend.toISOString()); // Use ISO format for sending timestamp
    formData.append('caption', caption);
    formData.append('sport', selectedSport);
    formData.append('public', publicPost);
    try {
      formData.append('taggedId', taggedFriend.mainid);
      formData.append('taggedName', taggedFriend.name);
    } catch (e) {
      formData.append('taggedId', null);
      formData.append('taggedName', null);

    }

    try {
      // Send the FormData to the server using the fetch API
      const response = await fetch('/api/post/createpost', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        setCreating(false)
        navigate(`/home/feed`)

      } else {
        setCreating(false)
        console.error('Post creation failed:', response.status);
      }
    } catch (error) {
      setCreating(false)
      console.error('Error creating post:', error);
    }
  };

  const openFriendsList = () => {
    setShowFriendslist((state) => !state)
  }

  const openDownloadLink = (link) => {
    try {
      window.ReactNativeWebView.postMessage(`link:${link}`);
    } catch (e) {
    }
  }

  return (
    <div style={{ zIndex: showfirendsList === true ? '10' : '9', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
      {creating ? <div style={{ zIndex: '200', alignItems: 'center', display: 'flex' }} className='fullScreenEvent'>
        <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Creating Post . . .</p>
      </div> : ""}
      {askTopUpdate ?
        <NewVersion text={"A new version is now available, allowing users to upload videos."} setAskTopUpdate={setAskTopUpdate} />
        : ""
      }
      <div style={{ marginTop: '0px', marginBottom: '0px', paddingBottom: '59px' }} className="scrollList">
        <div style={{ borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
          <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">CREATE POST</label>
        </div>
        <div className='creatPostElements'>
          <h4 className='PostPreview'>SHARE YOUR SPORT MOMENT !</h4>
          <div className="CreateCard">
            <p className="objectiveName">Add Photo or Video</p>
            <img className='pickPostPhoto' src={changePP} onClick={openImagePicker} />
          </div>
          <div className="CreateCard">
            <p className="objectiveName">Tag Buddy</p>
            <p className='commanButtons' onClick={openFriendsList} >{taggedFriend ? taggedFriend.name : "Tag your buddy"}</p>
          </div>
          <div className="CreateCard ">
            <p className="objectiveName">Select Sport</p>
            <div style={{ width: '100%', padding: '0px 10px', paddingTop: '5px' }}>
              <SportPicker selectedSport={selectedSport} setSelectedSport={setSelectedSport} />
            </div>
          </div>
        </div>
        <div className='PostCreateText'>
          <input
            max={100}
            value={caption}
            onChange={handleCaptionChange}
            placeholder="Caption... "
          />
        </div>
        {publicPost ? <p className='soloPostP2'>Your Post is <span style={{ fontWeight: '500' }}>PUBLIC,</span><span style={{ fontWeight: '500' }}> ONLY share sports related posts</span></p> : <p className='soloPostP2'>All of your friends can see this post</p>}
        {taggedFriend ? <p className='soloPostP'>Your Post will be published but {taggedFriend.name} must accept the tag for there name to appear in this post. {taggedFriend.name}'s' friends will be able to see your profile but not your posts through this post.</p> : ""}
        {showfirendsList ? <MiniFriends setTaggedFriend={setTaggedFriend} setShowFriendslist={setShowFriendslist} /> : ""}
        <div className='confirmPostCont'>
          <div className='twoInOneButton'>
            <p onClick={() => setPublicPost(false)} style={{ borderTopRightRadius: '0px', borderBottomRightRadius: '0px', backgroundColor: publicPost === true ? 'hsla(0, 0%, 10%, 0.9)' : "", color: publicPost === true ? 'grey' : "" }}>Friends</p>
            <p onClick={() => setPublicPost(true)} style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', backgroundColor: publicPost === false ? 'hsla(0, 0%, 10%, 0.9)' : "", borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', color: publicPost === false ? 'grey' : "" }}>Public</p>
          </div>
          <p onClick={handleCreatePost}>Create Post</p>
        </div>
        <h4 className='PostPreview'>POST PREVIEW</h4>
        <div className='postInCreatorCont'>
          <Post thumb={thumb} postid={1} image={selectedPhoto ? selectedPhoto : selectedVideo} creatorid={myinfo.mainid} creatorname={myinfo.name} creatorpicture={myinfo.picture_url} createTime={timeToSend.toISOString()} caption={caption} sport={selectedSport} taggedid={taggedFriend ? taggedFriend.mainid : null} taggedname={taggedFriend ? taggedFriend.name : null} likes={likes} myMainid={myinfo.mainid} showtagged={true} hiddenby={['0000']} commentscount={2} likescount={10} />
        </div>
      </div>
    </div>
  );
}


