import React, { useState, useEffect } from "react";
import { SingleMember } from "./SingleMember";

export function MembersList({ groupName, members, status, groupid, myMainid, setMembersFitler }) {
    const [selectedFilterList, setSelectedFilterList] = useState(null);
    const [creating, setCreating] = useState(false)

    useEffect(() => {
        if (members) {
            if (status === 'request') {
                const filteredMembers = members.filter(member => member.status === 'request' || member.status === 'invited');
                if (filteredMembers.length === 0) {
                    setMembersFitler(null)
                }
                setSelectedFilterList(filteredMembers);
            } else {
                const filteredMembers = members.filter(member => member.status === status);
                if (filteredMembers.length === 0) {
                    setMembersFitler(null)
                }
                setSelectedFilterList(filteredMembers);
            }
        } else if (members === null) {
            setMembersFitler(null)
        }
    }, [members, status]);

    return (
        <div className="dropdownContentContainer">
            {creating ? <div style={{ zIndex: '5000', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
            </div> : ""}
            <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)", padding: '10px 0px', width: '90%', height: '80%' }} className="dropdownContent">
                <h3 style={{ color: 'white', marginBottom: '10px', marginTop: '10px' }}>{groupName}</h3>
                <div style={{ height: 'calc(100% - 90px)', overflow: 'scroll' }}>
                    <div style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="">
                        {selectedFilterList ? selectedFilterList.map(member => (
                            <SingleMember key={member.mainid} member={member} myMainid={myMainid} groupid={groupid} setUpdating={setCreating} />
                        ))
                            :
                            ""
                        }
                    </div>
                </div>
                <p onClick={() => { setMembersFitler(null) }} style={{ marginTop: '10px' }} className="activeBlack singleButton2">Close</p>
            </div>
        </div>
    );
}
