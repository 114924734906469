import React, { useState, useEffect, useRef } from 'react';
import Chart from 'chart.js/auto'; // Import Chart.js
import { useSelector } from 'react-redux';
import { selectData } from '../home/homeSlice';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

export const CountdownTimer = ({ fixedTime, reps, countHelper, setCountHelper, handleIncreaseCount, setShowStartCountDown, workout, vibrate, countSoundAudio, stopAudio, stopBeepAudio, tickingAudio, handleCompleteWorkout }) => {
    const myData = useSelector(selectData)
    const [countdown, setCountdown] = useState(3);
    const [counter, setCounter] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false)
    const [chartInterval, setChartInterval] = useState(null); // Use state for the interval
    const [filterValue, setFilterValue] = useState('invert(.82)');
    const chartRef = useRef(null);

    useEffect(() => {
        try {
            if (myData && workout) {
                if (workout.gifurl === myData.picture_url) {
                    setFilterValue('none')
                }
            }
        } catch (e) {

        }
    }, [myData])

    useEffect(() => {
        //vibrate(100);
        countSoundAudio.currentTime = 0
        countSoundAudio.play();

    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (countdown > 1) {
                setCountdown(countdown - 1);
            } else if (countdown === 1) {
                vibrate(100)
                setCountdown('Go!');
                setIsPlaying(true)
                setTimeout(() => {
                    countSoundAudio.currentTime = 0
                    countSoundAudio.pause()
                    setCountdown(null);
                }, 1000);
            }
        }, 1000);
        return () => clearTimeout(timer);
    }, [countdown]);

    const handleTimeUpdate = (remainingTime) => {
        setCounter(fixedTime - remainingTime)
        if (remainingTime === fixedTime / 2 || remainingTime === ((fixedTime / 2) + 0.5)) {
            tickingAudio.currentTime = 0
            tickingAudio.play()
            setTimeout(() => {
                tickingAudio.pause()
                tickingAudio.currentTime = 0
            }, 1500);
        }
        console.log(remainingTime)
    }

    const handleCompleteTimer = () => {
        handleIncreaseCount();
        vibrate(100)
        stopBeepAudio.currentTime = 0
        stopBeepAudio.play()
        setTimeout(() => {
            stopBeepAudio.pause()
            stopBeepAudio.currentTime = 0
        }, 1000);
    }

    useEffect(() => {
        if (counter >= fixedTime) {
            setTimeout(() => {
                setShowStartCountDown(false);
                if (countHelper < reps - 1) {
                    setCountHelper((count) => count + 1)
                } else {
                    setCountHelper(0)
                }
            }, 1500);
        }
    }, [counter])


    const stopProgressAnimation = () => {
        if (chartInterval) {
            clearInterval(chartInterval);
            setChartInterval(null); // Clear the interval from state
        }
    };
    const handleConfirmSet = () => {
        if (counter > (fixedTime / 2)) {
            stopProgressAnimation()
            handleIncreaseCount();
            setShowStartCountDown(false);
        }
    }

    const handleAbort = () => {
        setCountHelper(0)
        stopProgressAnimation()
        setShowStartCountDown(false);
        try {
            stopAudio()
        } catch {

        }
    }

    return (
        <div className="countdown-timer">
            <h3 style={{ display: countdown === null ? "none" : '' }} className="countdown-display">
                {countdown}
            </h3>
            <CountdownCircleTimer
                isPlaying={isPlaying}
                duration={fixedTime}
                colors={['hsl(72, 100%, 47%)']}
                size={320}
                strokeWidth={20}
                onUpdate={(remainingTime) => handleTimeUpdate(remainingTime)}
                onComplete={handleCompleteTimer}
                trailColor={'hsla(0, 0%, 13%, 1)'}
            >
                {({ remainingTime }) => remainingTime}
            </CountdownCircleTimer>
            <div style={{ paddingTop: '70px' }} className='pieButtons'>
                <p onClick={handleAbort} style={{ height: '30px' }} className='activeBlack singleButton2'>CANCEL</p>
            </div>
        </div >
    );
};
