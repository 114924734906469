import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { NoPlayers } from "../list/NoPlayers";
import { selectData, selectLog, editData } from "../home/homeSlice";
import closeGreen from '../../Pics/closeGreen.png'
import { SwipeInUser } from '../user/SwipeInUser'
import { loadCommon } from "../intButtons/intButtonsSlice";
import { clearInfo } from "../user/userSlice";
import "./shareinteres.css";

export function ShareInterest({ time, preflocation, interest, confirmNow, show, setShow, handleShowShare, askshare, setCanShare }) {
    const componentRef = useRef(null);
    const data = useSelector(selectData);
    const log = useSelector(selectLog);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const page = useLocation();
    const pagePath = page.pathname

    useEffect(() => {
        if (interest) {
            setCanShare(true)
        }
    }, [interest])

    useEffect(() => {
        try {
            const handleOutsideClick = (event) => {
                if (componentRef.current && !componentRef.current.contains(event.target)) {
                    setShow(false);
                }
            };

            const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
            const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

            document.addEventListener(clickEvent, handleOutsideClick);

            return () => {
                document.removeEventListener(clickEvent, handleOutsideClick);
            };
        } catch (e) {

        }
    }, []);

    const askMeLater = () => {
        setShow(false)
        confirmNow()
    }

    const updateAskShare = (state) => {
        fetch(`/api/profile/updateaskshare`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ askshare: state }),
        })
            .then((response) => {
                if (response.ok) {
                    dispatch(editData({ askshare: state }));
                } else {
                }
            })
            .catch((error) => {
                console.error("Error updating age:", error);
            });
    }

    const close = () => {
        handleShowShare()
    }

    if (show && interest) {
        return (
            <div ref={componentRef} className="openShareCont">
                <div className="ShareBanner">
                    <img className="shareCloseButton" src={closeGreen} onClick={close} />
                    <h2 className="bigShare" style={{ letterSpacing: '1px' }}>SHARE !</h2 >
                    <p className="shareMotivate">Would you like to share this and Inspire your friends to join you?</p>
                    <NoPlayers time={time.selectedTime} preflocation={preflocation} interest={interest} />
                    <div className="severalShareResponse">
                        {askshare === true ?
                            <button style={{ height: '35px', width: '120px' }} className="shareButton singleButton2" onClick={() => updateAskShare(false)}>Don't ask me <br /> to Share</button>
                            :
                            <button style={{ height: '35px', width: '120px' }} className="shareButton singleButton2" onClick={() => updateAskShare(true)}>Ask me <br /> to Share</button>
                        }
                        <button style={{ backgroundColor: 'hsla(72, 100%, 47%, 67%)', marginTop: '0px', marginBottom: '0px', height: '45px', width: '75%', fontSize: '16px' }} className="shareButton singleButton" onClick={confirmNow}>Don't Share & just go {interest.toUpperCase()}</button>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            ""
        )
    }


}
