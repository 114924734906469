import React, { useState, useEffect, useRef } from "react";
import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { web } from '../root/Root'
import facebook from '../../Pics/facebook.png'
import apple from '../../Pics/Apple.png'
import google from '../../Pics/Google.png'
import logo from '../../Pics/Logos.png'
import { isMobile } from 'react-device-detect';
import { appleRegister, googleRegister, facebookRegister, checkCode } from './loginFuncs'
import { useDispatch } from "react-redux";
import { disableRefresh } from "../home/homeSlice";
import greentick from '../../Pics/greentick.png'

export function Login({ }) {
  const navigate = useNavigate();
  const [creating, setCreating] = useState(false)
  const [mobile, setMobilte] = useState(false)
  const [checked, setChecked] = useState(false);
  const [fullLink, setFullLink] = useState("")
  const [refCode, setRefCode] = useState(null)
  const [valid, setValid] = useState(false)
  const inputRef = useRef(null)
  const dispatch = useDispatch();


  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(`Stack:${-20}`);
    } catch (e) {
      navigate('/download')
    }
  }, [])

  function handleMessageEvent(event) {
    const data = event.data;
    try {
      if (data.length > 0 && data.startsWith('AppleLogin:')) {
        const appleCredString = data.substring(11);
        const appleCred = JSON.parse(appleCredString);
        dispatch(disableRefresh())
        appleRegister(appleCred, navigate);
      }
    } catch (e) {
      console.log('no data')
    }
    try {
      if (data.length > 0 && data.startsWith('GoogleLogin:')) {
        const googleCredString = data.substring(12);
        const googleCred = JSON.parse(googleCredString);
        dispatch(disableRefresh())
        googleRegister(googleCred, setCreating, navigate);
      }
    } catch (e) {
      console.log('no data')
    }
    try {
      if (data.length > 0 && data.startsWith('FacebookLogin:')) {
        const facebookCredString = data.substring(14);
        const facebookCred = JSON.parse(facebookCredString);
        dispatch(disableRefresh())
        facebookRegister(facebookCred, navigate);
      }
    } catch (e) {
      console.log('no data')
    }
  }

  useEffect(() => {
    // Add event listeners when component mounts
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleMessageEvent);
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('message', handleMessageEvent);
    }
    // Clean up event listeners when component unmounts
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('message', handleMessageEvent);
      }
      if (typeof document !== 'undefined') {
        document.removeEventListener('message', handleMessageEvent);
      }
    };
  }, []);

  useEffect(() => {
    try {
      if (isMobile) {
        setMobilte(true)
      }
    } catch (e) {
      setMobilte(false)
    }
  }, [])

  const facebookLogin = async () => {
    try {
      if (isMobile) {
        if (checked) {
          setCreating(true)
          setTimeout(() => {
            try {
              setCreating(false)
            } catch (e) {

            }
          }, 5000);
          dispatch(disableRefresh())
          window.ReactNativeWebView.postMessage("facebooklogin");
          return
        } else {
          window.ReactNativeWebView.postMessage("plzCheck");
          return
        }
      }
    } catch (error) {
      console.log('on Browser')
    }
  }

  const appleLogin = async () => {
    try {
      if (isMobile) {
        if (checked) {
          setCreating(true)
          setTimeout(() => {
            try {
              setCreating(false)
            } catch (e) {

            }
          }, 5000);
          dispatch(disableRefresh())
          window.ReactNativeWebView.postMessage("appleLogin");
          return
        } else {
          window.ReactNativeWebView.postMessage("plzCheck");
          return
        }

      }
    } catch (error) {
      console.log('on Browser')
    }
  }

  const googleLogin = async () => {
    try {
      if (isMobile) {
        if (checked) {
          setCreating(true)
          setTimeout(() => {
            try {
              setCreating(false)
            } catch (e) {

            }
          }, 5000);
          dispatch(disableRefresh())
          window.ReactNativeWebView.postMessage("googleLogin");
          return
        } else {
          window.ReactNativeWebView.postMessage("plzCheck");
          return
        }

      }
    } catch (error) {
      console.log('on Browser')
    }
  }



  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleFounder = () => {
    if (checked) {
      return
    } else {
      window.alert('Confirm that you agree to our terms to use the App!');
      navigate('/login')

    }
  }
  const handlePasteRefLink = async (inputRef, setRefCode, setValid, setFullLink) => {
    if (/Android/.test(navigator.userAgent)) {

      try {
        // Temporarily make the input visible and focus it
        inputRef.current.style.opacity = '1';
        inputRef.current.style.pointerEvents = 'auto';
        inputRef.current.focus();
        setTimeout(async () => {

          // Get clipboard text
          const text = await navigator.clipboard.readText();

          if (text.startsWith(`https://fitbuddy.fit/download?ref=`)) {
            inputRef.current.value = text;
            setFullLink(text);
          } else {
            setRefCode(null);
            setValid(false);
            setFullLink("");
            try {
              window.ReactNativeWebView.postMessage(`Alert:Invalid reference link`);
            } catch (e) {
              alert('Invalid Link');
            }
          }
          console.log(text);

          // Blur the input to hide the keyboard and make it invisible again
          inputRef.current.blur();
          inputRef.current.style.opacity = '0';
          inputRef.current.style.pointerEvents = 'none';
        }, 200);
      } catch (error) {
        console.error("Error pasting link:", error);
      }
    } else {
      try {
        navigator.clipboard.readText().then((text) => {
          console.log(text)
          if (text.startsWith(`https://fitbuddy.fit/download?ref=`)) {
            const textToEdit = text;
            setFullLink(textToEdit)
          } else {
            setRefCode(null)
            setValid(null)
            try {
              window.ReactNativeWebView.postMessage(`Alert:Inavlid reference link`);
            } catch (e) {
              alert('Invalid Link')
            }
          }
          console.log(text)
        })
      } catch (error) {

      }
    }
  };


  useEffect(() => {
    try {
      if (fullLink !== "") {
        if (fullLink.startsWith(`https://fitbuddy.fit/download?ref=`)) {
          const textToEdit = fullLink;
          const code = textToEdit.replace(`https://fitbuddy.fit/download?ref=`, '');
          setRefCode(code);
        } else {
          setRefCode(null)
          setValid(null)
          setFullLink('')
          try {
            window.ReactNativeWebView.postMessage(`Alert:Inavlid reference link`);
          } catch (e) {
            alert('Invalid Link')
          }
        }
      }
    } catch (error) {

    }
  }, [fullLink, inputRef])

  useEffect(() => {
    if (refCode) {
      checkCode(refCode, setRefCode, setValid, setFullLink)
    }
  }, [refCode])

  useEffect(() => {
    try {
      const storedRefCode = localStorage.getItem('refCode');
      if (storedRefCode) {
        const parsedRefCode = JSON.parse(storedRefCode);
        setRefCode(parsedRefCode); // Update the state with the parsed value
      }
    } catch (error) {
      console.error('Failed to parse refCode from localStorage', error);
    }
  }, []);

  const handleClearCode = async () => {
    try {
      localStorage.removeItem('refCode');
      setRefCode(null);
      setValid(false);
      setFullLink("");
    } catch (error) {

    }
  }

  return (
    <div style={{ top: '0px' }} className="containerLogin">
      {creating ? <div style={{ zIndex: '5000', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
        <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
      </div> : ""}
      <div className="boxLogin">
        <img className="loginLogo" src={logo} />
        <p className="disclaimer" >As an aim to Limit fake acounts for your safety and others, We dont offer e-mail signup/login <span className="tickBox"></span> </p>
        <p className="disclaimer" >To use the App Confirm that you are above 17 and you agree to terms <Link to='/eula'>EULA</Link> & <Link to='/policy'>Policy</Link> and there is no tolerance for objectionable content or abusive users. <span className="tickBox"></span> </p>
        <label className="tickCont">
          <input
            type="checkbox"
            checked={checked}
            onChange={handleCheckboxChange}
          />
          I agree
        </label>
        <a onClick={() => { appleLogin() }} className="appleButton activeBlack">
          <img src={apple} alt="Apple Icon" className="appleIcon" />
          Sign in with Apple
        </a>
        <a onClick={() => { googleLogin() }} className="googleButton activeBlack">
          <img src={google} alt="Apple Icon" className="googleIcon" />
          Continue With Google
        </a>
        <a onClick={() => { facebookLogin() }} href={mobile ? '' : `${web}${process.env.REACT_APP_API_URL}/login/facebook`} className="facebookButton activeBlack">
          <img src={facebook} alt="Facebook Icon" className="facebookIcon" />
          Sign in with Facebook
        </a>
        <div style={{ position: 'relative', width: '100%' }}>
          <input type="text" style={{ width: '10px', position: 'absolute', zIndex: '-10', top: '-25px', right: '20%' }} ref={inputRef} value={fullLink} onChange={(e) => { setFullLink(e.target.value) }} />
          {!refCode && (
            <div style={{ gap: '10px', marginTop: '10px', display: 'grid', gridTemplateColumns: '1fr 1fr', width: '100%' }}>
              <Link style={{ marginTop: '0px' }} to="/founders" className="foundersButton acviteBlack">
                Founders
              </Link>
              <button onClick={() => handlePasteRefLink(inputRef, setRefCode, setValid, setFullLink)} style={{ width: '100%', borderRadius: '20px', height: '46px', fontSize: '14px' }} className="singleButton acviteBlack">Paste Ref. Link</button>
            </div>
          )}
          {refCode && (
            <div style={{ display: 'flex' }}>
              <p onClick={handleClearCode} className="refLink acviteBlack">Using Reference Link:<br /> {refCode}</p>
              {valid && (<img style={{ marginLeft: '10px', width: '20px' }} src={greentick} />)}
            </div>
          )}
        </div>
      </div>
    </div >
  );
}

/*
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/construction">CONSTRUCTION</NavLink> 
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/architecture">ARCHITECTURE</NavLink>    
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/furniture">FURNITURE</NavLink>
*/
