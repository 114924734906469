import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadConfirmedCustom, selectConfirmedCustom, selectConfirmedCustomStatus, addToConfirmedCustom } from './workoutsSlice';
import { CustomWorkoutSetup } from './CustomWorkoutSetup';
import { ConfirmedCustomWorkoutCard } from './ConfirmedCustomWorkoutCard'
import './featuredWorkout.css'
import { AddCustomWorkout } from './AddCustomWorkout';
import { useNavigate } from 'react-router-dom';
import { AskPrompet } from '../popUps/AskPrompet';
import { selectData } from '../home/homeSlice';

export const CustomWorkouts = ({ }) => {
    const [count, setCount] = useState(0)
    const [reps, setReps] = useState(0)
    const [fixedTime, setFixedTime] = useState("")
    const [restTime, setRestTime] = useState("")
    const [updating, setUpdating] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState(localStorage.getItem('CustWorkoutFilter') || 'Add');
    const [workout, setWorkout] = useState({ name: "", gifurl: "", hint: "", datetime: (new Date()).toISOString().slice(0, 16) })
    const myData = useSelector(selectData)
    const ConfirmedCustom = useSelector(selectConfirmedCustom);
    const ConfirmedCustomStatus = useSelector(selectConfirmedCustomStatus)
    const [askYesOrNo, setAskYesOrNo] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (selectedFilter === 'Confirmed') {
            dispatch(loadConfirmedCustom())
        }
    }, [selectedFilter])

    const resetCustomWorkout = () => {
        setReps("")
        setFixedTime("")
        setRestTime("")
        setWorkout({ name: "", gifurl: "", hint: "", datetime: (new Date()).toISOString().slice(0, 16) })
        setCount(0)
    }

    const handleCompleteWorkout = async () => {
        if (!myData.activesub) {
            setAskYesOrNo('Subscribe to Pure Experience pack or Coach pack to save workout and get more benifits. Would you like to see pricing?')
            return
        }
        try {
            if (workout.name === "" && workout.hint === "") {
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Name your workout or add a hint.`);
                    return
                } catch (e) {

                }
            }
        } catch (e) {

        }

        setUpdating(true)
        function getCurrentTime() {
            try {
                const date = new Date(workout.datetime);

                // Format the timestamp as HH:MM am/pm
                const formattedTime = date.toLocaleString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: true, // Use 12-hour time format
                    day: '2-digit',
                    month: 'short', // Use abbreviated month name (e.g., "Jan")
                    year: '2-digit',
                });

                return formattedTime;
            } catch (e) {

            }
        }
        const selectedTime = getCurrentTime()

        fetch(`/api/profile/confrimcustomworkout`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: workout.name, sets: reps, date: selectedTime, hint: workout.hint }),
        })
            .then((response) => {
                if (response.ok) {
                    setAskYesOrNo('Workout Saved, Go to Logs ?')
                    const newWorkout = { ...workout, sets: reps, date: selectedTime, gifurl: workout.gifurl, hint: workout.hint }
                    dispatch(addToConfirmedCustom(newWorkout))
                    resetCustomWorkout()
                    try {

                    } catch (e) {

                    }
                    setUpdating(false)
                } else {
                    setUpdating(false)

                }
            })
            .catch((error) => {
                console.error("Error confirming");
                setUpdating(false)
            });
    }

    const handleYesPicPrompet = () => {
        if (askYesOrNo.includes('Subscribe')) {
            navigate('/home/subs')
        } else {
            setSelectedFilter('Confirmed');
            localStorage.setItem('CustWorkoutFilter', 'Confirmed');
            setAskYesOrNo(null)
        }
    };

    const handleNoPicPrompet = () => {
        setAskYesOrNo(null)
    };


    return (
        <div>
            {askYesOrNo ? <AskPrompet text={askYesOrNo} handleYes={handleYesPicPrompet} handleNo={handleNoPicPrompet} buttonY={"Yes"} /> : ""}
            <div style={{ height: '40px', padding: '0px 50px', gap: '25px', borderRadius: '0px' }} className="feedFilter">
                <label className="activeBlack" onClick={() => { setSelectedFilter('Add'); localStorage.setItem('CustWorkoutFilter', 'Add'); }} style={{ color: selectedFilter === 'Add' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Log a workout</label>
                <label className="activeBlack" onClick={() => { setSelectedFilter('Confirmed'); localStorage.setItem('CustWorkoutFilter', 'Confirmed'); }} style={{ color: selectedFilter === 'Confirmed' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Completed</label>
            </div>
            {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                <p className='fake'> Confirming...</p>
            </div> : ""}
            {selectedFilter === 'Add' ? (
                <AddCustomWorkout count={count} setCount={setCount} workout={workout} setWorkout={setWorkout} reps={reps} setReps={setReps} fixedTime={fixedTime} setFixedTime={setFixedTime} restTime={restTime} setRestTime={setRestTime} resetCustomWorkout={resetCustomWorkout} handleCompleteWorkout={handleCompleteWorkout} setSelectedFilter={setSelectedFilter} />
            ) : null}
            {selectedFilter === 'Confirmed' && ConfirmedCustomStatus === 'done' && ConfirmedCustom ?
                <div style={{ padding: '0px 15px' }}>
                    {ConfirmedCustom.length > 0 ?
                        <div>
                            {
                                ConfirmedCustom.map((workout, index) => (
                                    <ConfirmedCustomWorkoutCard key={index} workout={workout} />
                                ))
                            }
                        </div>
                        :
                        <div onClick={() => setSelectedFilter('Add')} className='noPrevWorkout'>
                            <p>
                                No Custom Workouts Saved,<br /> Log your first workout?
                            </p>
                            <img src={localStorage.getItem('logWorkouts')} />
                        </div>
                    }
                </div>
                :
                ""
            }

        </div >
    );
};
