import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectData, selectStatus, loadReqs } from "./reqsSlice";
import { PromoEvent } from "./PromoEvent";
import Logos from "../../Pics/Logos.png";
import close from "../../Pics/close.png";
import { Link, useLocation } from "react-router-dom";
import socket from "../../app/io";
import { deleteRequest } from "./reqsFuncs";
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import "./reqs.css";
import { loadHome } from "../home/homeSlice";
import { loadUser } from "../user/userSlice";
import { loadFriends } from "../friends/friendsSlice";
import { SmallRequest } from "../chatreqs/SmallRequest";

export function Reqs({ id, showEvent, setShowEvent }) {
  const componentRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [notification, setNotification] = useState(false);
  const data = useSelector(selectData);
  const status = useSelector(selectStatus);
  const dispatch = useDispatch();
  const page = useLocation();
  const pagePath = page.pathname;


  const vibrate = (time) => {
    try {
      window.ReactNativeWebView.postMessage(`Vib:${time}`);
    } catch (e) {
    }
  }

  useEffect(() => {
    try {
      const handleOutsideClick = (event) => {
        if (componentRef.current && !componentRef.current.contains(event.target)) {
          setShow(false);
        }
      };

      const isTouchDevice = 'ontouchstart' in window || navigator.msMaxTouchPoints;
      const clickEvent = isTouchDevice ? 'touchstart' : 'mousedown';

      document.addEventListener(clickEvent, handleOutsideClick);

      return () => {
        document.removeEventListener(clickEvent, handleOutsideClick);
      };
    } catch (e) {

    }
  }, []);

  function playNotificationSound() {
    const sound = new Audio('./notif.mp3');
    sound.play();
  }

  useEffect(() => {
    socket.on("friendRequestReceivedreq", ({ senderId }) => {
      vibrate(200)
      dispatch(loadReqs(id)).then(() => {
        setNotification(true);
        //playNotificationSound()
        try {
          //window.ReactNativeWebView.postMessage("ping");
        } catch (e) {
          //console.log('on Browser')
        }
      });
    });

    return () => {
      socket.off("friendRequestReceivedreq");
    };
  }, [dispatch]);

  useEffect(() => {
    socket.on("friendRequestAcceptedreq", ({ senderId }) => {
      vibrate(200)
      dispatch(loadReqs(id)).then(() => {
        setNotification(true);
        //playNotificationSound();
        try {
          //window.ReactNativeWebView.postMessage("ping");
        } catch (e) {
          // console.log('on Browser')
        }
      });
    });

    return () => {
      socket.off("friendRequestAcceptedreq");
    };
  }, []);

  useEffect(() => {
    socket.on("friendRemovedreq", ({ senderId }) => {

    });

    return () => {
      socket.off("friendRemovedreq");
    };
  }, []);

  useEffect(() => {
    socket.on("blockireq", ({ senderId }) => {
      try {
        if (window.location.pathname === `/home/:${senderId}`) {
          dispatch(loadUser(senderId));
          dispatch(loadFriends())
        } else {
          dispatch(loadHome())
        }
      } catch (e) {
        //console.log('on Browser')
      }

    });

    return () => {
      socket.off("blockireq");
    };
  }, [dispatch]);

  useEffect(() => {
    try {
      if (data.requests.length > 0 && show === false) {
        setNotification(true);
        try {
          if (showEvent === true) {
            setNotification(true)
          }
        } catch (e) {

        }

      } else if (data.requests.length === 0 && show === false) {
        setNotification(false);
        try {
          if (showEvent === true) {
            setNotification(true)
          }
        } catch (e) {

        }
      }
    } catch (e) {
      console.log("no requests");
    }
  }, [data]);

  const handleNoti = () => {
    if (notification === true) {
      setNotification(false);
    }
    setShow((prevState) => !prevState);
  };


  if (status === "idle") {
    return (
      <div className="notibutton">
        <img src={localStorage.getItem('notif')} />
      </div>
    );
  } else if (status === "loading") {
    return (
      <div className="notibutton">
        <img src={localStorage.getItem('notif')} />
      </div>
    );
  } else if (status === "done") {
    return (
      <div ref={componentRef} className="notList">
        {showFullScreen ? <PromoEvent setShowFullScreen={setShowFullScreen} /> : ""}
        <div className="notibutton">
          <img onClick={handleNoti} src={notification ? localStorage.getItem('rednotif') : localStorage.getItem('notif')} />
        </div>
        {show ? (
          <div className="reqs">
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '3px', height: 'fit-content', overflowY: 'scroll' }}>
              {data.requests && data.requests.length > 0 ? (
                data.requests.map((request) => (
                  <SmallRequest myId={id} key={request.id} request={request} setNotification={setNotification} />
                ))
              ) : (
                <div style={{ width: '100%' }}>
                  {showEvent
                    ? <div style={{ display: 'flex' }} className="smallCard">
                      <div onClick={() => { setShowEvent(false); setShow(false); setShowFullScreen(true) }} className="smallLink" >
                        <img className="smallProfPic" src={Logos} />
                      </div>
                      <div style={{ marginLeft: '6px' }}>
                        <div onClick={() => { setShowEvent(false); setShow(false); setShowFullScreen(true) }} style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', color: 'black', textDecoration: 'none' }} className="smallLink" >
                          <p style={{ textAlign: 'left' }}>Set your favorite sports to get notified when nearby...</p>
                        </div>
                      </div>
                    </div> :
                    <div style={{ display: 'flex', height: '46px' }} className="smallCard">
                      <p style={{ color: 'white' }}>No new notifications</p>
                    </div>
                  }
                </div>
              )}
            </div>
          </div>
        ) : (
          ""
        )
        }
      </div >
    );
  }
}
