import React, { useEffect, useState } from 'react';
import { pics } from '../intButtons/sportPics'; // Import the images for different sports
import { useDispatch } from "react-redux";
import { editData } from "../home/homeSlice";
import { interestData } from '../intButtons/sportPics'

export function Fav2({ fav, height, backGrounColor }) {
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSport, setSelectedSport] = useState(null);

  useEffect(() => {
    try {
      setSelectedSport(fav)
    } catch (e) {

    }
  }, [fav])

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  const handleFav1 = (selectedSport) => {
    fetch(`/api/profile/updatefav2`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fav: selectedSport }),
    })
      .then((response) => {
        if (response.ok) {
          dispatch(editData({ fav2: selectedSport }))
        } else {
          alert("Failed to update fav");
        }
      })
      .catch((error) => {
        console.error("Error updating fav:", error);
      });
  };

  const handleSportSelection = (sport) => {
    handleFav1(sport)
  };

  return (
    <div style={{ height: height ? height : "", backgroundColor: backGrounColor ? backGrounColor : "" }} className="fav1" onClick={toggleDropdown}>
      {selectedSport !== null ? <img loading="lazy" src={localStorage.getItem(selectedSport.toLowerCase()) || pics[selectedSport.toLowerCase()]} alt={selectedSport} /> : <p style={{ color: backGrounColor ? "grey" : "black", fontWeight: '500' }}>Fav.2</p>}
      {isOpen && (
        <div className="dropdownContentContainer">
          <div className="dropdownContent">
            <h3 style={{ color: 'white' }} className="dropdownItem" > Set Favorite Sport 2</h3>
            <div className='favsButtons'>
              {interestData.map((interest) => (
                <div
                  key={interest.name}
                  type="button"
                  onClick={() => handleSportSelection(interest.name)}
                  style={
                    selectedSport === interest.name
                      ? { backgroundColor: 'hsla(72, 100%, 47%, 50%)', color: 'white' }
                      : {}
                  }
                >
                  <img src={localStorage.getItem(interest.name.toLowerCase()) || interest.img} alt={interest.name} />
                </div>
              ))}
            </div>
            {fav ? <p style={{ color: 'black', fontWeight: '500' }} onClick={() => handleSportSelection(null)} className="removeFav dropdownItem" > Remove Favorite</p> : <p style={{ height: '10px' }}></p>}
          </div>
        </div>
      )}
    </div>
  );
};

