import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ReportCoach } from './ReportCoach';
import repImg from '../../Pics/repImg.png'
import edit from '../../Pics/edit.png'
import StarRatings from 'react-star-ratings';
import './coachCard.css'

export function CoachCard({ coach, myMainid, setOpenCoachProfileEdit, isMyCoach }) {
    const [ratingObj, setRatingObj] = useState(coach.rating)
    const [rating, setRating] = useState(0)
    const [ratingCount, setRatingCount] = useState(0)
    const [coachHidden, setCoachHidden] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [showReport, setShowReport] = useState(false)
    const [likedByMe, setLickedByMe] = useState(false)
    const [dynamicCount, setDynamicCount] = useState(0)
    const dispatch = useDispatch();

    useEffect(() => {
        if (coach && myMainid) {
            try {
                if (coach.hiddenby.includes(myMainid)) {
                    setCoachHidden(true)
                }
            } catch (e) {
            }
        }
    }, []);

    const photoPicker = (int) => {
        const picked = int.toLowerCase()
        return localStorage.getItem(picked)
    }

    useEffect(() => {
        try {
            if (Object.keys(ratingObj).length > 0) {
                let ratingCounts = 0;
                let totalRating = 0;
                for (const rating in ratingObj) {
                    ratingCounts++;
                    const ratingString = ratingObj[rating].rating;
                    const ratingValue = parseInt(ratingString);
                    totalRating += ratingValue;
                }
                const averageRating = (totalRating / ratingCounts);
                setRating(averageRating);
                setRatingCount(ratingCounts)
            } else {
                setRating(0)
                setRatingCount(0)
            }
        } catch (e) {

        }
    }, [coach, ratingObj])



    const setNewRating = async (rating) => {
        if (window.location.pathname === `/home/coaches/coach/:${coach.coachid}`) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/ratecoach`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ coachid: coach.coachid, rating: rating }),
                });
                if (response.ok) {
                    const data = await response.json()
                    setRatingObj(data)
                }
            } catch (error) {

            }
        } else {
            return
        }
    }

    if (coachHidden === false) {
        return (
            <div className='coachCardCont'>
                {coach.coachid === myMainid && window.location.pathname === '/home/coaches/coachaccount' ? < img style={{ marginRight: '10px', marginTop: '106px', padding: '5px', width: "30px", zIndex: '500' }} onClick={() => setOpenCoachProfileEdit(coach)} className='editExperience' src={edit} /> : ""}
                {coach.coachid !== myMainid ? <img style={{ marginRight: '-5px', marginTop: '115px' }} onClick={() => setShowReport(true)} className='repImgInPost' src={repImg} /> : ""}
                {showReport ? <ReportCoach coachname={coach.name} coachid={coach.coachid} setShowReport={setShowReport} setCoachHidden={setCoachHidden} myMainid={myMainid} /> : ""}
                <img className='coachCardProfilePic' src={coach.picture_url} />
                <div className='coachMainInfoCont'>
                    <h3>{coach.name.toUpperCase()}</h3>
                    <div className='SportCont'>
                        {coach.sport ? <img className="otherProfileFav" src={photoPicker(coach.sport)} /> : ""}
                        <h4>{coach.sport}</h4>
                    </div>
                    <h5>{coach.bio}</h5>

                </div>
                <div className='starsCont'>
                    <StarRatings
                        changeRating={window.location.pathname === `/home/coaches/coach/:${coach.coachid}` && coach.coachid !== myMainid && isMyCoach === true ? setNewRating : null}
                        rating={rating ? rating : 0}
                        starRatedColor="hsla(72, 100%, 47%, 40%)"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="3px"
                        starHoverColor="hsl(72, 100%, 47%)"
                    />
                    <p><span style={{ color: 'hsl(72, 100%, 47%)', width: '100px' }}>{rating > 0 ? rating.toFixed(1) : ""}</span> ({ratingCount})</p>
                </div>
            </div>
        )
    } else {
        return (
            ""
        )
    }
}