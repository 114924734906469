import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSwipeable } from "react-swipeable";
import { loadHome } from "./homeSlice";
import { RootContext } from "../root/RootContextProvider";

export function Swipe({ }) {
    const { isinSwipeUser } = useContext(RootContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const page = useLocation();
    const pagePath = page.pathname;

    /*
    const handleSwipeRight = () => {
        if (pagePath === '/home/workout' || pagePath === '/home/friends') {
            try {
                dispatch(loadHome())
                navigate('/home/profile');
            } catch (e) {

            }
        } else {
            navigate(-1)
        }
    };
    */

    const handleSwipeRight = () => {
        navigate(-1)

    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const swipeBackHandlers = useSwipeable({
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    const swipeForwardHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });
    if (isinSwipeUser === false) {
        return (
            <div >
                <div className="swipeBack" {...swipeBackHandlers} />
                <div className="swipeForward" {...swipeForwardHandlers} />
            </div>
        );
    }
}