import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { disableRefresh, selectData } from '../home/homeSlice'
import socket from "../../app/io";
import edit from '../../Pics/edit.png'
import deletePic from '../../Pics/deletePic.png'
import deleteRedPic from '../../Pics/delete.png'
import closePic from '../../Pics/close.png'
import recordPic from '../../Pics/record.png'
import stopRecordPic from '../../Pics/stopRecord.png'
import changePP from '../../Pics/changePP.png'
import { AskPrompet } from "../popUps/AskPrompet";
import { sendAudio, sendMessage, sendPhoto, sendVideo } from "./userFuncs";
import "./chat.css";
import SingleChatMessage from "./SingleChatMessage";
import SingleChatInput from "./SingleChatInput";

export function Chat({ back, friend, status, friendid, setIsTyping, setIsRecording }) {
  const [recording, setRecording] = useState(false)
  const [record, setRecord] = useState(null)
  const [record64, setRecord64] = useState(null)
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photo64, setPhoto64] = useState('');
  const [uploadingPhoto, setUploadingPhoto] = useState(false)
  const [uploadingRecord, setUploadingRecord] = useState(false)
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [editingChat, setEditingChat] = useState(false);
  const [askToConfirmDeleteAll, setAskToConfirmDeleteAll] = useState(false)
  const [bigPic, setBigPic] = useState(false)
  const data = useSelector(selectData)
  const [aspectRatio, setAspectRatio] = useState('0.5625')
  const profileid = data.mainid
  const chatBoxRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const inputBox = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      window.ReactNativeWebView.postMessage(`LoadChat:${friendid}`);
    } catch (e) {

    }
  }, [])

  useEffect(() => {
    // Listen for typing events from the server
    socket.on('typing', ({ userId }) => {
      try {
        if (userId === friendid) {
          setIsTyping(true)
        }
      } catch (e) {

      }
    });

    // Clean up the event listener on component unmount
    return () => {
      socket.off('typing');
    };
  }, []);

  useEffect(() => {
    // Listen for typing events from the server
    socket.on('stopTyping', ({ userId }) => {
      try {
        if (userId === friendid) {
          setIsTyping(false)
        }
      } catch (e) {

      }
    });
    // Clean up the event listener on component unmount
    return () => {
      socket.off('stopTyping');
    };
  }, []);

  useEffect(() => {
    // Listen for typing events from the server
    socket.on('recording', ({ userId }) => {
      try {
        if (userId === friendid) {
          setIsRecording(true)
        }
      } catch (e) {

      }
    });

    // Clean up the event listener on component unmount
    return () => {
      socket.off('recording');
    };
  }, []);

  useEffect(() => {
    // Listen for typing events from the server
    socket.on('stopRecording', ({ userId }) => {
      try {
        if (userId === friendid) {
          setIsRecording(false)
        }
      } catch (e) {

      }
    });
    // Clean up the event listener on component unmount
    return () => {
      socket.off('stopRecording');
    };
  }, []);

  useEffect(() => {
    if (inputText) {
      adjustTextareaHeight();
    }
  }, [inputText]);

  const adjustTextareaHeight = () => {
    if (inputBox.current) {
      inputBox.current.style.height = 'auto'; // Reset the height
      inputBox.current.style.height = inputBox.current.scrollHeight + 'px'; // Set the height to the scrollHeight
    }
  };

  const vibrate = (time) => {
    try {
      window.ReactNativeWebView.postMessage(`Vib:${time}`);
    } catch (e) {
    }
  }

  useEffect(() => {
    socket.on("msg", ({ content, senderId, type }) => {
      vibrate(200)
      if (senderId === friendid) {
        const message = {
          content,
          sender: senderId === profileid ? "me" : "other",
          type: type
        };
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
    return () => {
      socket.off("msg");
    };
  }, [socket, messages]);

  useEffect(() => {
    if (status === "done" && friend === true) {
      fetchSavedMessages(friendid);
    }
  }, [status, friend]);

  const fetchSavedMessages = (receiverId) => {
    fetch("/api/messages", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ receiverId }),
    })
      .then((response) => response.json())
      .then((data) => {
        const fetchedMessages = data.messages.rows.map((message) => {
          return {
            content: message.content,
            sender: message.senderid === profileid ? "me" : "other",
            type: message.type
          };
        }).reverse();
        try {
          const chatDetails = {
            id: receiverId,
            messages: fetchedMessages
          }
          window.ReactNativeWebView.postMessage(`SaveChat:${JSON.stringify(chatDetails)}`);
        } catch (e) {
          const chatDetails = {
            id: receiverId,
            messages: fetchedMessages
          }

        }
        setMessages(fetchedMessages);
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  };

  useEffect(() => {
    // Scroll the chat box to the bottom on initial load
    scrollToBottom();
    try {
      const chatDetails = {
        id: friendid,
        messages: messages
      }
      window.ReactNativeWebView.postMessage(`SaveChat:${JSON.stringify(chatDetails)}`);
    } catch (e) {

    }
  }, [messages]);

  const handleSendMessage = () => {



    inputBox.current.focus();
    if (inputText.trim() === "") return;
    sendMessage(inputText, friendid, 'text')
    const sentMessage = {
      content: inputText,
      sender: "me",
      type: 'text'
    };
    setMessages((prevMessages) => [...prevMessages, sentMessage]);
    setInputText("");
    inputBox.current.style.height = 'auto';
    setTimeout(() => {
      scrollToBottom()
    }, 500);
  };

  const handleSendAudio = () => {
    if (record64 === null) return;
    inputBox.current.focus();
    sendAudio(friendid, 'audio', record64, setMessages, setRecord, setRecord64, setInputText, scrollToBottom, setUploadingRecord)
  };

  const handleSendPhoto = () => {
    if (photo64 === null) return;
    sendPhoto(friendid, 'image', photo64, setMessages, setSelectedPhoto, setPhoto64, setInputText, scrollToBottom, setUploadingPhoto)
  };

  const handleSendVideo = () => {
    if (selectedVideo === null) return;
    sendVideo(friendid, 'video', selectedVideo, setMessages, setSelectedVideo, setInputText, scrollToBottom, setUploadingPhoto)
  };

  const scrollToBottom = () => {
    try {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    } catch (e) {

    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent the default "Enter" key behavior
      handleSendMessage(); // Call the function to send the message
    }
  };
  const handleDeleteAllChat = () => {
    fetch("/api/deleteMessage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ friendid }),
    })
      .then((response) => response.json())
      .then((data) => {
        fetchSavedMessages(friendid);
        setEditingChat(false)
        setAskToConfirmDeleteAll(false)
      })
      .catch((error) => {
        console.error("Error fetching messages:", error);
      });
  }

  function handleMessageEvent(event) {
    const data = event.data;
    try {
      if (data.length > 0 && data.startsWith('SelectedPhoto:')) {
        const data64 = data.substring(14);
        setSelectedPhoto(`data:image/jpeg;base64,${data64}`)
        setPhoto64(data64)
      }
    } catch (e) {

    }
    try {
      if (data.length > 0 && data.startsWith('SelectedVideo:')) {
        const vidueUrl = data.substring(14);
        setSelectedVideo(vidueUrl)
        //setThumb(`https://fitbuddystorage.blob.core.windows.net/images/${myinfo.mainid}/TempThumb.jpeg`)
        setSelectedPhoto(null)
        setPhoto64(null)
      }
    } catch (e) {

    }
    try {
      if (data.length > 0 && data.startsWith('RecordUrl:')) {
        const base64Audio = data.substring(10);
        setRecord(`data:audio/mpeg;base64,${base64Audio}`);
        setRecord64(base64Audio)
      }
    } catch (e) {
      console.error('Error processing audio from React Native:', e);
    }
    try {
      if (data.length > 0 && data === 'recordingNow') {
        setRecording(true)
      }
    } catch (e) {
    }
    try {
      if (data.length > 0 && data.startsWith('LocalChat:')) {
        const rawMessage = data.substring(10);
        const messages = JSON.parse(rawMessage);
        setMessages(messages);

      }
    } catch (e) {
      console.error('Error processing audio from React Native:', e);
    }



  }

  useEffect(() => {
    // Add event listeners when component mounts
    if (typeof window !== 'undefined') {
      window.addEventListener('message', handleMessageEvent);
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('message', handleMessageEvent);
    }
    // Clean up event listeners when component unmounts
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('message', handleMessageEvent);
      }
      if (typeof document !== 'undefined') {
        document.removeEventListener('message', handleMessageEvent);
      }
    };
  }, []);

  const openImagePicker = () => {
    try {
      window.ReactNativeWebView.postMessage("PickPhoto");
      if (/Android/.test(navigator.userAgent)) {
        dispatch(disableRefresh());
      }
    } catch (e) {

    }
  };

  const handleStartRecording = () => {
    inputBox.current.focus();
    setInputText("")
    socket.emit('recording', friendid);
    try {
      window.ReactNativeWebView.postMessage(`startRecording`);
    } catch (e) {

    }
  }
  const handleStopRecording = () => {
    inputBox.current.focus();
    setInputText("")
    socket.emit('stopRecording', friendid);
    setRecording(false)
    try {
      window.ReactNativeWebView.postMessage(`stopRecording`);
    } catch (e) {

    }
  }

  const handleCancel = () => {
    try {
      inputBox.current.focus();
    } catch (e) {

    }
    setRecording(false)
    setRecord(null)
    setRecord64(null)
    setSelectedPhoto(null)
    setPhoto64(null)
    setSelectedVideo(null)
  }

  const handleYes = () => {
    handleDeleteAllChat(friendid)
  }

  const handleNo = () => {
    setAskToConfirmDeleteAll(false)
    setEditingChat(false)
  }

  useEffect(() => {
    return () => {
      setSelectedPhoto(null)
      setPhoto64(null)
      socket.emit('stopRecording', friendid);
      try {
        window.ReactNativeWebView.postMessage(`stopRecording`);
      } catch (e) {

      }
    };
  }, [])

  return (
    <div style={{ marginTop: '160px' }} className="chat-container">
      {askToConfirmDeleteAll ?
        <AskPrompet text={"Delete all sent messages between you and this friend."} handleYes={handleYes} handleNo={handleNo} />
        : ""
      }
      {bigPic ?
        <div style={{ zIndex: '60' }} onClick={() => setBigPic(false)} className="fullScreenBlack">
          <img style={{ width: 'auto', maxHeight: '100%', borderRadius: '0px', maxWidth: '100%' }} src={bigPic} />
        </div>
        :
        ''
      }
      <div className="editChatCont">
        {editingChat ? <img onClick={() => setAskToConfirmDeleteAll(true)} src={deletePic} /> : ""}
        {!editingChat ? <img onClick={() => (setEditingChat(true))} src={edit} /> : ""}
        {editingChat ? <img onClick={() => (setEditingChat(false))} src={deleteRedPic} /> : ""}
      </div>
      <div ref={chatBoxRef} style={{ height: 'calc(100% - 160px)' }} className="chat-box">
        <div style={{ height: '600px' }} />
        {/*
          <div className="boxchatwarn">
          <h3>ALERT !</h3>
          <h5>Before you connect, make sure you follow these rules for your safety & safety of others</h5>
          <p>* Dont meet in dark areas.</p>
          <p>* Dont meet in empty places.</p>
          <p>* Dont give out your phone-number until you feel its safe to.</p>
          <p>* Dont give your exact training location until you feel its safe to.</p>
        </div>
        */}
        {messages.map((message, index) => (
          <SingleChatMessage
            key={index}
            message={message}
            setBigPic={setBigPic}
            aspectRatio={aspectRatio}
            setAspectRatio={setAspectRatio}
          />
        ))}
      </div>
      <SingleChatInput
        record={record}
        uploadingRecord={uploadingRecord}
        photo64={photo64}
        selectedVideo={selectedVideo}
        inputText={inputText}
        setInputText={setInputText}
        adjustTextareaHeight={adjustTextareaHeight}
        scrollToBottom={scrollToBottom}
        handleSendAudio={handleSendAudio}
        handleStartRecording={handleStartRecording}
        handleSendMessage={handleSendMessage}
        handleStopRecording={handleStopRecording}
        handleCancel={handleCancel}
        handleSendPhoto={handleSendPhoto}
        handleSendVideo={handleSendVideo}
        openImagePicker={openImagePicker}
        recording={recording}
        selectedPhoto={selectedPhoto}
        uploadingPhoto={uploadingPhoto}
        closePic={closePic}
        changePP={changePP}
        stopRecordPic={stopRecordPic}
        back={back}
        inputBox={inputBox}
        friendid={friendid}
      />
    </div>
  );
}