import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const loadMeeting = createAsyncThunk(
  'tempMeetingPage/loadMeeting',
  async (mainid) => {
    const data = await fetch(`/api/meetings/singlemeeting/${mainid}`)
    const json = await data.json()
    return json;
  }
);

export const tempMeetingPageSlice = createSlice({
  name: 'tempMeetingPage',
  initialState: {
    mainid: '',
    info: '',
    status: 'idle',
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearInfo: (state, action) => {
      state.mainid = '';
      state.info = '';
      state.status = 'idle';
    },
    setMeetingID: (state, action) => {
      state.mainid = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loadMeeting.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadMeeting.fulfilled, (state, action) => {
        state.status = 'done';
        state.info = action.payload;
      })
      .addCase(loadMeeting.rejected, (state, action) => {
        state.status = 'failed';
        console.log('failed')
      })
  },
});

export const selectMainId = (state) => state.tempMeetingPage.mainid;
export const selectInfo = (state) => state.tempMeetingPage.info;
export const selectStatus = (state) => state.tempMeetingPage.status;
export const { clearInfo, setMeetingID } = tempMeetingPageSlice.actions;
export default tempMeetingPageSlice.reducer;



