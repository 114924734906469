import React from "react";
import { editData } from "../home/homeSlice";
import { useDispatch } from "react-redux";

function Radius({ radius, setRadius }) {
  const dispatch = useDispatch()

  const handleRadiusChange = (event) => {
    try {
      const newRadius = event.target.value;
      setRadius(newRadius);
      dispatch(editData({ radius: newRadius }));
      localStorage.setItem('Radius', newRadius);
    } catch (error) {
      console.error("Error occurred while saving radius:", error);
    }
  };

  return (
    <div className="radiusCont">
      <label htmlFor="radius">RADIUS: {radius} KM</label>
      <input
        className="radiusInput"
        type="range"
        id="radius"
        name="radius"
        min="0"
        max={window.location.pathname === '/home/events/discoverevents' ? '800' : '70'}
        value={radius}
        onChange={handleRadiusChange}
      />
    </div>
  );
}

export default Radius;