import React, { useState, useEffect } from 'react';
import { ConfirmedGroupMembers } from './ConfirmedGroupMembers';


export function ConfirmedGroupWorkoutCard({ workout, setCreating, setConfirmedGroupMembers }) {

    const photoPicker = (int) => {
        try {
            const picked = int.toLowerCase()
            return localStorage.getItem(picked)
        } catch (e) {

        }
    }

    const loadConfirmedGroupMembers = async () => {
        try {
            setCreating(true)
            const response = await fetch(`${process.env.REACT_APP_API_URL}/groups/loadconfirmedgroupmembers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ workout }),
            });
            if (response.ok) {
                const data = await response.json()
                setConfirmedGroupMembers(data)
                setCreating(false)
            } else {
                setCreating(false)
            }
        } catch (error) {
            setCreating(false)

        }
    }

    return (
        <div onClick={loadConfirmedGroupMembers} style={{ border: '1px solid hsl(72, 100%, 47%)' }} className='meetingCardCont' >
            <div className='meetingCardInfo'>
                <div className='doubleTagInMeeting'>
                    <p style={{ color: 'white' }} >{workout.groupname} </p>
                    <p style={{ color: 'white', marginLeft: '0px' }}> {workout.grouplocation ? `Location: ${workout.grouplocation}` : "Location Not Logged"}</p>
                </div>
                <div className='timeInMeeting'>
                    <p>{(new Date(workout.grouptime * 1000).toLocaleString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true, // Use 12-hour time format
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric' // Use abbreviated month name (e.g., "Jan")
                    }))}</p>
                </div>
                <img className='bigCardFav' style={{ height: '30px', width: '30px' }} src={photoPicker(workout.sport)} />
            </div>
        </div>
    )
}