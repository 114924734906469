import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadReqs = createAsyncThunk("reqs/loadReqs", async () => {
  const data = await fetch(`${process.env.REACT_APP_API_URL}/profile/requests`);
  const json = await data.json();
  return json;
});

export const reqsSlice = createSlice({
  name: "reqs",
  initialState: {
    data: [],
    status: "idle",
  },
  reducers: {
    reset: (state) => {
      state.data = [];
      state.status = "idle";
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadReqs.pending, (state) => {
        if (state.data.length < 1) {
          state.status = "loading";
        }
      })
      .addCase(loadReqs.fulfilled, (state, action) => {
        state.log = "logged";
        state.status = "done";
        state.data = action.payload;
      })
      .addCase(loadReqs.rejected, (state, action) => {
        state.log = "notlogged";
        console.log(state.log)
        state.status = "failed";
        console.log(state.status)
        state.data = null;
      });
  },
});

export const selectData = (state) => state.reqs.data;
export const selectStatus = (state) => state.reqs.status;
export const selectLog = (state) => state.reqs.log;
export const { reset } = reqsSlice.actions;



export default reqsSlice.reducer;
