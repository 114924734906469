import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadConfirmedCustom, selectConfirmedCustom, selectConfirmedCustomStatus, addToConfirmedCustom } from './workoutsSlice';
import { CustomWorkoutSetup } from './CustomWorkoutSetup';
import { ConfirmedCustomWorkoutCard } from './ConfirmedCustomWorkoutCard'
import { useSwipeable } from "react-swipeable";
import { useNavigate } from 'react-router-dom';
import { selectData } from '../home/homeSlice';
import { AskPrompet } from '../popUps/AskPrompet';


export const StopWatchOnly = ({ }) => {
    const [count, setCount] = useState(0)
    const [reps, setReps] = useState("")
    const [fixedTime, setFixedTime] = useState("")
    const [restTime, setRestTime] = useState("")
    const [updating, setUpdating] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState("Setup");
    const [workout, setWorkout] = useState({ name: "", gifurl: "", hint: "" })
    const [initialHeight, setInitialHeight] = useState(0);
    const [askYesOrNo, setAskYesOrNo] = useState(null)
    const myData = useSelector(selectData)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleSwipeRight = () => {
        if (window.smallListOpend !== true) {
            navigate(-1)
        }
    };

    const handleSwipeLeft = () => {
        navigate(+1)
    };

    const swipeBackHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true, // This will prevent scrolling while swiping
    });

    const resetCustomWorkout = () => {
        setReps("")
        setFixedTime("")
        setRestTime("")
        setWorkout({ name: "", gifurl: "", hint: "" })
        setCount(0)
    }

    const handleCompleteWorkout = async () => {
        if (count === 0) {
            try {
                window.ReactNativeWebView.postMessage("Alert:At least finsih 1 set to save this workout");
                return
            } catch (e) {

            }
        }
        if (!myData.activesub) {
            setAskYesOrNo('Subscribe to Pure Experience pack or Coach pack to save workout and get more benifits. Would you like to see pricing?')
            return
        }
        setUpdating(true)
        function getCurrentTime() {
            const timestamp = Date.now(); // Get the current timestamp in milliseconds
            const date = new Date(timestamp);

            // Format the timestamp as HH:MM am/pm
            const formattedTime = date.toLocaleString('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                hour12: true, // Use 12-hour time format
                day: '2-digit',
                month: 'short', // Use abbreviated month name (e.g., "Jan")
                year: '2-digit',
            });

            return formattedTime;
        }

        const currentTime = getCurrentTime()

        fetch(`/api/profile/confrimcustomworkout`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ name: workout.name, sets: count, date: currentTime, hint: workout.hint }),
        })
            .then((response) => {
                if (response.ok) {
                    setAskYesOrNo('Workout Saved, Go to Logs ?')
                    const newWorkout = { ...workout, sets: count, date: currentTime, gifurl: workout.gifurl, hint: workout.hint }
                    dispatch(addToConfirmedCustom(newWorkout))
                    resetCustomWorkout()
                    try {

                    } catch (e) {

                    }
                    setUpdating(false)
                } else {
                    setUpdating(false)

                }
            })
            .catch((error) => {
                console.error("Error confirming");
                setUpdating(false)
            });
    }

    useEffect(() => {
        setTimeout(() => {
            const infoContElement = document.getElementById('profileCont');
            if (infoContElement) {
                setInitialHeight(infoContElement.clientHeight);
            }
        }, 830);
    }, []);

    const handleYesPicPrompet = () => {
        if (askYesOrNo.includes('Subscribe')) {
            navigate('/home/subs')
        } else {
            setSelectedFilter('Confirmed');
            localStorage.setItem('CustWorkoutFilter', 'Confirmed');
            setAskYesOrNo(null)
        }
    };

    const handleNoPicPrompet = () => {
        setAskYesOrNo(null)
    };

    const playRewarded = () => {
        try {
            window.ReactNativeWebView.postMessage("showRewardedAd");
            window.activateRefresh = 'adVeiw'
            return
        } catch (e) {

        }
    }

    return (

        <div style={{ zIndex: '12', padding: '0px 0px 59px 0px', height: initialHeight !== 0 ? initialHeight : '' }} id="profileCont" className="friendsList">
            <div {...swipeBackHandlers} id="feedsCont" style={{ marginTop: '0px', marginBottom: '0px', height: '100%' }} className="scrollList">
                {askYesOrNo ? <AskPrompet text={askYesOrNo} handleYes={handleYesPicPrompet} handleNo={handleNoPicPrompet} buttonY={"Yes"} /> : ""}
                {<div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ marginLeft: 'auto', fontSize: '24px' }} htmlFor="comment">FIT-TIMER</label>
                </div>}
                {updating ? <div style={{ zIndex: '900' }} className='CreatingPost'>
                    <p className='fake'> Logging Workout...</p>
                </div> : ""}
                <CustomWorkoutSetup count={count} setCount={setCount} workout={workout} setWorkout={setWorkout} reps={reps} setReps={setReps} fixedTime={fixedTime} setFixedTime={setFixedTime} restTime={restTime} setRestTime={setRestTime} resetCustomWorkout={resetCustomWorkout} handleCompleteWorkout={handleCompleteWorkout} setSelectedFilter={setSelectedFilter} />
            </div>
        </div>
    );
};
