const responseOK = (navigate) => {
  navigate('/home/profile')
  setTimeout(() => {
    try {
      window.ReactNativeWebView.postMessage(`Stack:${-20}`);
    } catch (e) {
    }
  }, 200);
}

export const appleRegister = async (appleCred, navigate) => {
  let refCode = null;
  try {
    window.ReactNativeWebView.postMessage(`Stack:${50}`);

    const storedRefCode = localStorage.getItem('refCode'); // No need to await JSON.parse here

    if (storedRefCode) {
      refCode = JSON.parse(storedRefCode); // Parse the stored JSON string to a JavaScript object
    }
  } catch (e) {

  }
  try {
    console.log(appleCred)
    const response = await fetch(`/api/applelogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ appleCred: appleCred, refCode: refCode }),
    });
    if (response.ok) {
      try {
        localStorage.removeItem('refCode');
      } catch (error) {

      }
      responseOK(navigate)
    } else {
      alert("Somthing went wrong");
      try {
        window.ReactNativeWebView.postMessage(`Stack:${-20}`);
      } catch (e) {

      }
    }
  } catch (error) {
    alert("Somthing went wrong");
    try {
      window.ReactNativeWebView.postMessage(`Stack:${-20}`);
    } catch (e) {

    }
  }
};

export const googleRegister = async (googleCred, setCreating, navigate) => {
  let refCode = null;
  try {
    window.ReactNativeWebView.postMessage(`Stack:${50}`);

    const storedRefCode = localStorage.getItem('refCode'); // No need to await JSON.parse here

    if (storedRefCode) {
      refCode = JSON.parse(storedRefCode); // Parse the stored JSON string to a JavaScript object
    }
  } catch (e) {

  }
  try {
    setCreating(true)
    const response = await fetch(`/api/googlelogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ googleCred: googleCred, refCode: refCode }),
    });

    if (response.ok) {
      try {
        localStorage.removeItem('refCode');
      } catch (error) {

      }
      responseOK(navigate)
    }
  } catch (error) {
    alert("Something went wrong");
    try {
      window.ReactNativeWebView.postMessage(`Stack:${-20}`);
    } catch (e) {

    }
  }
};

export const facebookRegister = async (facebookCred, navigate) => {
  let refCode = null;
  try {
    window.ReactNativeWebView.postMessage(`Stack:${50}`);

    const storedRefCode = localStorage.getItem('refCode'); // No need to await JSON.parse here

    if (storedRefCode) {
      refCode = JSON.parse(storedRefCode); // Parse the stored JSON string to a JavaScript object
    }
  } catch (e) {

  }
  const token = facebookCred;
  try {
    const response = await fetch(`/api/facebooklogin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: token, refCode: refCode }),
    });

    if (response.ok) {
      try {
        localStorage.removeItem('refCode');
      } catch (error) {

      }
      responseOK(navigate)
    } else {
      alert("Something went wrong");
      try {
        window.ReactNativeWebView.postMessage(`Stack:${-20}`);
      } catch (e) {

      }
    }
  } catch (error) {
    alert("Something went wrong");
    try {
      window.ReactNativeWebView.postMessage(`Stack:${-20}`);
    } catch (e) {

    }
  }
};


export function DeviceCheck(AppleDownload, GoogleDownload) {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    <a style={{ marginBottom: '20px' }} href='https://apps.apple.com/us/app/fit-buddy/id6450426723'>
      <img style={{ height: '80px' }} src={AppleDownload} />
    </a>
  } else if (/Android/.test(navigator.userAgent)) {

    return (
      <a href='https://play.google.com/store/apps/details?id=com.fitbuddy.fitbuddy'>
        <img style={{ height: '80px' }} src={GoogleDownload} />
      </a>
    )
  } else {
    // Code to run for other devices or browsers
    return (
      <div>
        <a style={{ marginBottom: '20px' }} href='https://apps.apple.com/us/app/fit-buddy/id6450426723'>
          <img style={{ height: '80px' }} src={AppleDownload} />
        </a>
        <a href='https://play.google.com/store/apps/details?id=com.fitbuddy.fitbuddy'>
          <img style={{ height: '80px' }} src={GoogleDownload} />
        </a>
      </div>
    )
  }
}

export const checkCode = async (refCode, setRefCode, setValid, setFullLink) => {
  try {
    const response = await fetch(`/api/check-refcode`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refCode: refCode }),
    });

    if (response.ok) {
      const data = await response.json();
      setRefCode(data.refCode);
      setValid(true);
      localStorage.setItem('refCode', JSON.stringify(refCode))
    } else {
      localStorage.removeItem('refCode');
      setRefCode(null);
      setValid(false);
      setFullLink("")
      try {
        window.ReactNativeWebView.postMessage(`Alert:Invalid Reference link`);
      } catch (e) {
        console.error("Error sending message to ReactNativeWebView:", e);
      }
    }
  } catch (error) {
    console.error("Error checking referral code:", error);
    setRefCode(null);
    setValid(false);
    try {
      window.ReactNativeWebView.postMessage(`Alert:Invalid Reference link`);
    } catch (e) {
      console.error("Error sending message to ReactNativeWebView:", e);
    }
  }
};
