import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { disableRefresh, enableRefresh } from '../home/homeSlice';
import './customWorkouts.css'
import { useNavigate } from 'react-router-dom';



export function AddCustomWorkout({ count, setCount, workout, setWorkout, reps, setReps, fixedTime, setFixedTime, restTime, setRestTime, resetCustomWorkout, handleCompleteWorkout, setSelectedFilter }) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(disableRefresh())

    }, [])


    useEffect(() => {
        return () => {
            dispatch(enableRefresh())
        }
    }, []);


    return (
        <div className='postCont' style={{ marginTop: '10px', width: '100%', height: '800px' }}>
            <div style={{ gridTemplateColumns: 'auto auto', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">WORKOUT NAME</p>
                <div style={{ display: 'flex', width: 'fit-content', marginRight: '20px', paddingRight: '0px' }} >
                    <input
                        style={{ width: '160px', backgroundColor: "hsl(72, 100%, 47%)" }}
                        placeholder='My Workout'
                        type='text'
                        maxLength={25}
                        value={workout.name}
                        onChange={(e) => {
                            const updatedWorkout = { ...workout, name: e.target.value };
                            setWorkout(updatedWorkout);
                        }}
                    />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto auto', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">WORKOUT HINT</p>
                <div style={{ display: 'flex', width: 'fit-content', marginRight: '20px', paddingRight: '0px' }} >
                    <input
                        style={{ width: '160px', backgroundColor: "hsl(72, 100%, 47%)" }}
                        placeholder='Workout hint'
                        type='text'
                        maxLength={25}
                        value={workout.hint}
                        onChange={(e) => {
                            const updatedWorkout = { ...workout, hint: e.target.value };
                            setWorkout(updatedWorkout);
                        }}
                    />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto auto', height: '40px' }} className="customWorkoutSetupCard">
                <p style={{ textAlign: 'left' }} className="CusotmWorkoutSetupName">WORKOUT DATE</p>
                <div style={{ display: 'flex', width: 'fit-content', marginRight: '20px', paddingRight: '0px' }} >
                    <input
                        style={{ width: '200px', backgroundColor: "hsl(72, 100%, 47%)" }}
                        type="datetime-local"
                        value={workout.datetime}
                        onChange={(e) => {
                            const updatedWorkout = { ...workout, datetime: e.target.value };
                            setWorkout(updatedWorkout);
                            console.log(workout.datetime)
                        }}
                    />
                </div>
            </div>
            <div style={{ gridTemplateColumns: 'auto 120px', height: '40px' }} className="customWorkoutSetupCard">
                <p className="CusotmWorkoutSetupName">SETS</p>
                <div >
                    <input
                        className='WorkoutNumberInput'
                        pattern="[0-9]*"
                        inputMode="numeric"
                        type="number"
                        placeholder='0'
                        value={reps}
                        onChange={(e) => {
                            let value = parseInt(e.target.value, 10); setReps(value);
                        }}
                    />
                    <p>Sets</p>
                </div>
            </div>
            <div style={{ display: 'flex' }}>
                <p onClick={() => { handleCompleteWorkout() }} style={{ marginTop: 'px', fontSize: '22px', fontWeight: '900', fontStyle: 'italic' }} className='activeBlack singleButton'>SAVE</p>
            </div>
        </div >
    )
}