import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FriendButton } from '../friendButton/FriendButton'
import "./adminBigCard.css"
import { disableRefresh } from "../home/homeSlice";
import closeGreen from '../../Pics/closeGreen.png'
import { SwipeInUser } from '../user/SwipeInUser'
import facebook from "../../Pics/facebook.png"
import instagram from "../../Pics/instagram.png"
import TimeView from "../list/TimeView";
import { calculateAge } from "../profile/proFuncs";


export function AdminBigCard({ info, back }) {
  const dispatch = useDispatch();
  const [bigPic, setBigPic] = useState(false)
  const [showReps, setShowReps] = useState(false)
  const [banned, setBanned] = useState(false)

  useEffect(() => {
    try {
      if (info) {
        setBanned(info.banned)
      }
    } catch (e) {

    }
  }, [info])

  const photoPicker = (int) => {
    const picked = int.toLowerCase()
    return localStorage.getItem(picked)
  }

  const openLinkOnMob = (link) => {
    dispatch(disableRefresh());
    try {
      window.ReactNativeWebView.postMessage(`link:${link}`);
    } catch (e) {
    }
  };

  const handleBan = async (mainid) => {
    try {
      const response = await fetch(`/api/admin/banuser?mainid=${mainid}`);
      if (response.ok) {
        setBanned(true)
        console.log(`User with mainid ${mainid} has been banned`);
        // You can update your UI or state here to reflect the user's ban status
      } else {
        console.error("Failed to ban user");
      }
    } catch (err) {
      console.error("Error banning user:", err);
    }
  };

  const handleUnBan = async (mainid) => {
    try {
      const response = await fetch(`/api/admin/unbanuser?mainid=${mainid}`);
      if (response.ok) {
        setBanned(false)
        console.log(`User with mainid ${mainid} has been unbanned`);
        // You can update your UI or state here to reflect the user's unban status
      } else {
        console.error("Failed to unban user");
      }
    } catch (err) {
      console.error("Error unbanning user:", err);
    }
  };


  try {
    return (
      <div style={{ zIndex: '30' }} className="bigCardContInAdmin">
        <SwipeInUser back={back} />
        <div className="ban">
          {banned ?
            <p onClick={() => { handleUnBan(info.mainid) }}>UnB</p> :
            <p onClick={() => { handleBan(info.mainid) }}>Ban</p>
          }
        </div>
        <div className="reps" onClick={() => setShowReps(!showReps)}>
          {showReps ? (
            <div className="repsContent">
              {info.reports.map((report, index) => (
                report.reporterid !== null && report.content !== null ? (
                  <li key={report.reporterid}>
                    Reporter ID: {report.reporterid}, Content: {report.content}
                  </li>
                ) : null
              ))}
            </div>
          ) : (
            <p>
              {info.reports && info.reports.length > 0
                ? info.reports[0].reporterid === null && info.reports[0].content === null
                  ? "0"
                  : info.reports.length
                : "None"}
            </p>
          )}
        </div>
        <img className="bigCardBackArrow" src={closeGreen} onClick={back} />
        <h3 className="bigCardName">{info.name.toUpperCase()}</h3>
        <img onClick={() => setBigPic(true)} className="bigCardPic" src={info.picture_url} alt="Profile picture" />
        <div className="bigCardgendandage">
          <p>{info.gender.toUpperCase()},</p>
          {calculateAge(info.age)}
          {info.facebooklink ? <img onClick={() => openLinkOnMob(info.facebooklink)} style={{ width: '30px', borderRadius: '10px' }} src={facebook} /> : ''}
          {info.instagramlink ? <img onClick={() => openLinkOnMob(info.instagramlink)} style={{ width: '30px', borderRadius: '10px' }} src={instagram} /> : ''}
        </div>
        <p className="bigCardBio">{info.bio}</p>
        <div className="bigCardFavs">
          {info.fav1 || info.fav2 ? <p>Fav(s) </p> : <p>No Favorite Sport</p>}
          {info.fav1 ? <img loading="lazy" className="bigCardFav" src={photoPicker(info.fav1)} /> : ''}
          {info.fav2 ? <img loading="lazy" className="bigCardFav" src={photoPicker(info.fav2)} /> : ''}
        </div>
        {info.interest ?
          <div className="currentPlanBigCard">
            <img loading="lazy" className="bigCardFav" src={photoPicker(info.interest)} />
            <TimeView time={info.time} />
            <p>
              {info.preflocation ? info.preflocation : 'None'}
            </p>
            <p>
              {info.comment ? info.comment : 'None'}
            </p>
          </div>
          :
          <div className="currentPlanBigCard">
            <p style={{ fontWeight: '600' }}>No Plan</p>
          </div>
        }
        {bigPic ?
          <div onClick={() => setBigPic(false)} className="otherBigPic">
            <img src={info.picture_url} />
          </div>
          :
          ''
        }
      </div>

    )
  } catch (e) {
    return (
      <p> no user found</p>
    )
  }

}