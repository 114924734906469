import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editData, selectData } from '../home/homeSlice';
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router-dom';


export const MobileVerification_ = () => {
    const [creating, setCreating] = useState(false)
    const myInfo = useSelector(selectData)
    const [mobileNumber, setMobileNumber] = useState('');
    const [showSendButton, setShowSendButton] = useState(false)
    const [verificationCode, setVerificationCode] = useState('');
    const [isCodeSent, setIsCodeSent] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            var phoneno = isValidPhoneNumber(mobileNumber)
            if (phoneno === true) {
                setShowSendButton(true)
            } else {
                setShowSendButton(false)
            }
        } catch (e) {

        }
    }, [mobileNumber])

    const handleVerifyCode = async () => {
        if (showSendButton === false) {
            return
        }
        setCreating(true)
        try {
            const response = await fetch('/api/phonenumber/justaddnumber', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ mobileNumber }),
            });
            if (response.ok) {
                setIsVerified(false);
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Mobile number Added successfully.`);
                } catch (e) {
                    alert('Mobile number verified successfully.');
                }
                dispatch(editData({ phone_number: mobileNumber }))
                setCreating(false)                
            } else {
                try {
                    window.ReactNativeWebView.postMessage(`Alert:Verification code is incorrect.`);
                } catch (e) {
                    alert('Verification code is incorrect.');
                }
                setCreating(false)
            }
        } catch (error) {
            setCreating(false)
            try {
                window.ReactNativeWebView.postMessage(`Alert:Error verifying code.`);
            } catch (e) {
                alert('Error verifying code.');
            }
        }
    };

    return (
        <div style={{ zIndex: '5', backgroundColor: 'rgba(23, 23, 23, 0.906)' }} className="friendsList">
            <div style={{ marginTop: '0px', marginBottom: '25px', paddingBottom: '100px' }} className="scrollList">
                {creating ? <div style={{ zIndex: '9990', alignItems: 'center', display: 'flex', width: '' }} className='fullScreenEvent'>
                    <p style={{ fontSize: '18px', fontWeight: '500' }} className='fake'> Loading . . .</p>
                </div> : ""}
                <div style={{ display: 'flex', borderRadius: '0px', gridTemplateColumns: '100%', marginTop: '10px' }} className="hintCont">
                    <label style={{ margin: 'auto', fontSize: '24px' }} htmlFor="comment">Add Mobile Number</label>
                </div>
                <div className="phoneNumberCont">
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={mobileNumber}
                        onChange={setMobileNumber}
                        international
                        
                    />
                </div>
                <p onClick={handleVerifyCode} className='activeBlack singleButton' style={{ marginTop: '30px', height: '30px', backgroundColor: showSendButton ? "hsl(72, 100%, 47%)" : "hsla(0, 0%, 13%, 1)" }}>CONFIRM</p>
            </div>
        </div >
    );
};
