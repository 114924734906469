import React from "react";
import { useDispatch } from "react-redux";
import { setInfo } from "../root/repChoiceSlice";

import repImg from '../../Pics/repImg.png'

export function ReportButton({ info }) {  
  const dispatch = useDispatch(); 

  const openReport = () => {
    dispatch(setInfo(info))
  };

  return (
    <div className="repCont">      
        <img className="repButton" onClick={openReport} src={repImg} />      
    </div>
  );
}
