import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const loadFriends = createAsyncThunk("friends/loadFriends", async (status) => {
  let link
  if (!status || status === 'friends') {
    link = 'friends'
  } else if (status === 'sent') {
    link = 'friendssent'
  } else if (status === 'request') {
    link = 'friendsrequest'
  } else if (status === 'iblock') {
    link = 'iblock'
  }
  const data = await fetch(`${process.env.REACT_APP_API_URL}/profile/${link}`);
  const json = await data.json();
  return json;
});

export const friendsSlice = createSlice({
  name: "friends",
  initialState: {
    data: [],
    status: "idle",
    results: [],
  },
  reducers: {
    reset: (state) => {
      state.data = [];
      state.status = "idle";
    },
    filterUsers: (state, action) => {
      if (action.payload === '') {
        state.results = state.data.requests
      } else {
        state.results = state.data.requests.filter(user => {
          try {
            return user.name.toLowerCase().includes(action.payload.toLowerCase())
          } catch (e) {
          }
        })
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadFriends.pending, (state) => {
        state.status = "loading";
        //if (Array.isArray(state.data) && state.data.length === 0) {
        //  state.status = "loading";
        //}
      })
      .addCase(loadFriends.fulfilled, (state, action) => {
        state.log = "logged";
        state.status = "done";
        state.data = action.payload;
        state.results = state.data.requests;
      })
      .addCase(loadFriends.rejected, (state, action) => {
        state.log = "notlogged";
        console.log(state.log)
        state.status = "failed";
        console.log(state.status)
        state.data = null;
      });
  },
});

export const selectData = (state) => state.friends.data;
export const selectStatus = (state) => state.friends.status;
export const selectLog = (state) => state.friends.log;
export const selectResults = (state) => state.friends.results;
export const { reset, filterUsers } = friendsSlice.actions;



export default friendsSlice.reducer;
