import React, { useEffect, useState } from "react";
import "./nav.css";
import { Reqs } from "../reqs/Reqs";
import { InfoTab } from "./InfoTab";
import { useSelector, useDispatch } from "react-redux";
import { ChatReqs } from "../chatreqs/ChatReqs";
import { selectData, selectLog, loadHome } from "../home/homeSlice";
import { useNavigate } from "react-router-dom";
import { clearInfo } from "../user/userSlice";
import homeLogo from '../../Pics/home.png'
import meetup2 from '../../Pics/meetup2.png'
import trophy from '../../Pics/trophy.png'
import stopwatch from '../../Pics/stopwatch.png'
import searchUser from '../../Pics/searchUser.png'
import searchGroup from '../../Pics/searchGroup.png'
import { setMeetingID } from "../workoutsManager/tempMeetingPageSlice";
import './bottomNav.css'

export function BottomNav({ data, showBottomNav }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const log = useSelector(selectLog);


    const navClean = () => {

        if (log !== "logged") {
            //navigate("/login");
        } else {
            dispatch(clearInfo());
            //dispatch(loadHome())
           
            if (window.location.pathname === '/home/feed') {
                window.postMessage('backToTop')
                setTimeout(() => {
                    window.postMessage('backToTop')
                }, 100);
            } else {
                navigate("/home/feed");
            }
        }
    };
    if (log === "logged") {
        return (
            <div className="bottomNavCont" style={{ bottom: showBottomNav && !window.location.pathname.includes('/home/:') && !window.location.pathname.includes('/home/groups/group/:') ? "0px" : '-59px', zIndex: '1000' }}>
                <div style={{ backgroundColor: window.location.pathname === '/home/feed' ? "hsla(0, 0%, 10%, 0.9)" : "" }} onClick={() => navClean()} className="activeBlack bottomNavButton">
                    <img src={localStorage.getItem('homeLogo')} />
                    <p>HOME</p>
                </div>
                <div style={{ backgroundColor: window.location.pathname === '/home/stopwatchonly' ? "hsla(0, 0%, 10%, 0.9)" : "" }} onMouseUp={() => navigate('/home/stopwatchonly')} className="activeGrey bottomNavButton">
                    <img src={localStorage.getItem('stopwatch')} />
                    <p>TIMER</p>
                </div>
                <div style={{ backgroundColor: window.location.pathname.includes('/home/workout') ? "hsla(0, 0%, 10%, 0.9)" : "" }} onMouseUp={() => navigate('/home/workout')} className="activeGrey bottomNavButton">
                    <img src={localStorage.getItem('duoGreen')} />
                    <p>DUO</p>
                </div>
                <div style={{ backgroundColor: window.location.pathname.includes('/home/groups') ? "hsla(0, 0%, 10%, 0.9)" : "" }} onMouseUp={() => navigate('/home/groups')} className="activeGrey bottomNavButton">
                    <img src={localStorage.getItem('friends2')} />
                    <p>GROUPS</p>
                </div>
                <div style={{ backgroundColor: window.location.pathname === '/home/profile' ? "hsla(0, 0%, 10%, 0.9)" : "" }} onMouseUp={() => navigate('/home/profile')} className="activeGrey bottomNavButton">
                    <img src={data.picture_url} style={{ borderRadius: '50%' }} />
                    <p >PROFILE</p>
                </div>
            </div >
        )
    }
}