import React, { useEffect, useState, useRef } from 'react';
import { ReportReview } from './ReportReview';
import repImg from '../../Pics/repImg.png';

export function AddReview({ setCreating, myname, reviews, setReviews, setAddingReview, coachid }) {
    const [review, setReview] = useState('');
    const textareaRef = useRef(null);

    const handleReviewSubmit = async () => {
        if (review.length < 5) {
            try {
                window.ReactNativeWebView.postMessage(`Alert:Please write a review `);
            } catch (e) {
                // Handle the error
            }
            return;
        }
        setCreating(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/coaches/addcoachreview`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ coachid: coachid, review: review }),
            });
            if (response.ok) {
                const rev = await response.json();
                if (!reviews) {
                    setReviews([rev]);
                } else {
                    setReviews([...reviews, rev]);
                }
                setReview('');
                setAddingReview(false);
                setCreating(false);
            } else {
                setCreating(false);
            }
        } catch (error) {
            setCreating(false);
            console.error(error);
        }
    };

    const handleTextareaChange = (e) => {
        setReview(e.target.value);
        textareaRef.current.style.height = "13px";  // Reset the height
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;  // Set the new height
    };

    // Ensure the textarea height is set correctly when the component mounts
    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = "13px";  // Initial height
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;  // Adjust to content
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px' }} className='singleReviewCont'>
            <p><span style={{ color: 'hsl(72, 100%, 47%)' }}>- {myname}: </span></p>
            <textarea
                className='addRevTextara'
                ref={textareaRef}
                value={review}
                onChange={handleTextareaChange}
                maxLength={200}
                style={{ overflow: 'hidden', resize: 'none' }} // Optional: disable manual resizing
            />
            <div style={{ display: 'flex', color: 'black', gap: '30px' }}>
                <p onClick={handleReviewSubmit} style={{ height: '20px', color: 'black' }} className='activeBlack singleButton'>
                    Submit
                </p>
                <p onClick={() => setAddingReview(false)} style={{ height: '20px', color: 'black' }} className='activeBlack singleButton2'>
                    Cancel
                </p>
            </div>
        </div>
    );
}
