import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchUser } from '../user/userFuncs'

export const loadUser= createAsyncThunk(
  'tempProfile/loadUser',
  async (mainid) => {     
    const data = await fetchUser(mainid)   
    const json = await data
    return json;
  }
);

export const tempProfileSlice = createSlice({
  name: 'tempProfile',
  initialState: {  
    mainid: '', 
    info: '',    
    status: 'idle',    
  },
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {  
    clearInfo: (state, action) => {      
      state.mainid = '';
      state.info = '';
      state.status = 'idle';
    },
    setMainId: (state, action) => {
      state.mainid = action.payload;       
    },    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder      
      .addCase(loadUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.status = 'done';
        state.info = action.payload;    
        console.log(state.info)    
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.status = 'failed';
        console.log('failed')
      })
  },
});

export const selectMainId = (state) => state.tempProfile.mainid;
export const selectInfo = (state) => state.tempProfile.info;
export const selectStatus = (state) => state.tempProfile.status;
export const { clearInfo, setMainId } = tempProfileSlice.actions;
export default tempProfileSlice.reducer;



