import React, { useRef, useEffect, useState } from 'react';
import { handleShowBottomNav, handleShowTopNav } from '../home/homeSlice';
import { useDispatch } from 'react-redux';

export const VideoPlayer = ({ link, thumb, setMediaHeight, didVidFail, setDidVidFail }) => {
    const videoRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [aspectRatio, setAspectRatio] = useState('0.5625')
    const [didViset, setDidVisit] = useState(false)
    const [videoReady, setVideoReady] = useState(false)
    const [height, setHeight] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            if (videoRef.current) {
                videoRef.current.setAttribute('playsinline', '');

                videoRef.current.play()

            }
        } catch (e) {

        }
    }, [videoRef, didViset]);

    useEffect(() => {
        try {
            const video = videoRef.current;

            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5,
            };

            const handleIntersection = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        video.play();
                    } else {
                        video.pause();
                    }
                });
            };

            const observer = new IntersectionObserver(handleIntersection, options);

            if (video) {
                observer.observe(video);
            }

            return () => {
                if (video) {
                    observer.unobserve(video);
                }
            };
        } catch (e) {

        }
    }, [aspectRatio, didViset, videoReady]);

    const handleImageLoad = (event) => {
        try {
            const { naturalWidth, naturalHeight } = event.target;
            const aspectRatio = naturalHeight / naturalWidth;
            const width = event.target.clientWidth; // Get the actual width applied to the image
            const calculatedHeight = width * aspectRatio; // Calculate the height based on the aspect ratio
            setAspectRatio("")
            setHeight(calculatedHeight);
        } catch (e) {

        }
    };

    useEffect(() => {
        try {
            const updateProgress = () => {
                if (videoRef.current) {
                    const value = (videoRef.current.currentTime / videoRef.current.duration) * 100;
                    setProgress(value);
                }
            };

            videoRef.current.addEventListener('timeupdate', updateProgress);

            return () => {

                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', updateProgress);
                }
            };
        } catch (error) {

        }

    }, [didViset]);

    const handleProgressChange = (event) => {
        try {
            const newTime = (event.target.value / 100) * videoRef.current.duration;
            videoRef.current.currentTime = newTime;
            setProgress(event.target.value);
        } catch (e) {

        }
    };


    function handlePlayingVideo(e, link) {
        try {
            e.preventDefault();

            const targetElement = document.getElementById(link);

            if (targetElement) {
                // Find the closest scrollable parent container
                let parentElement = targetElement.parentElement;
                while (parentElement) {
                    if (parentElement.scrollHeight > parentElement.clientHeight) {
                        // Parent is scrollable
                        break;
                    }
                    parentElement = parentElement.parentElement;
                }

                // Default to document if no scrollable parent is found
                if (!parentElement) {
                    parentElement = document.documentElement;
                }

                // Calculate position relative to the parent container
                const targetRect = targetElement.getBoundingClientRect();
                const parentRect = parentElement.getBoundingClientRect();
                const topPosition = targetRect.top - parentRect.top + parentElement.scrollTop;
                setDidVisit(true)
                if (window.location.pathname === '/home/feed' || window.location.pathname.includes('/home/posts/:') || window.location.pathname === '/home/leadboards') {
                    setTimeout(() => {
                        dispatch(handleShowBottomNav(false));
                        dispatch(handleShowTopNav(false));
                    }, 500);
                }
                parentElement.scrollTo({
                    top: topPosition,
                    behavior: 'smooth',
                });
            }
        } catch (error) {

        }
    }

    return (
        <div id={link} className="video-container" style={{ width: '100%', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {didViset ?
                <video
                    onLoadedData={() => {
                        {
                            setAspectRatio("")
                            setVideoReady(true)
                            if (videoRef.current && videoRef.current.currentTime === 0) {
                                videoRef.current.currentTime = 0.05; // 50ms
                            }
                        }
                    }}
                    style={{ width: '100%', backgroundColor: "black", height: height, display: 'flex' }}
                    loop
                    src={link}
                    ref={videoRef}
                    allowFullScreen
                    onLoad={(event) => setMediaHeight(event.target.clientHeight)}
                    onError={() => setDidVidFail(true)}
                />
                :
                <div onClick={(e) => handlePlayingVideo(e, link)} style={{ height: height, aspectRatio: aspectRatio, backgroundColor: "black", width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        onLoad={handleImageLoad}
                        src={thumb}
                        style={{ width: '100%', display: 'flex' }}
                        alt="Thumbnail"
                    />
                    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.40)', position: 'absolute', width: '100%', height: '100%' }} />
                    <img style={{ width: '100px', position: 'absolute' }} src="https://fitbuddystorage.blob.core.windows.net/main/play2.png" alt="Loading..." />
                </div>
            }
            {
                !videoReady && didViset && (
                    <div style={{ width: '100%', height: '100%', position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto' }}>
                        <img
                            src={thumb}
                            style={{ width: '100%', position: 'absolute', display: 'flex' }}
                            alt="Thumbnail"
                        />
                        <img style={{ filter: 'invert(1)', width: '60px', margin: 'auto auto' }} src="https://i.gifer.com/ZKZg.gif" alt="Loading..." />
                    </div>
                )
            }
            {videoReady && (
                <div
                    onTouchStart={(e) => {
                        try {
                            videoRef.current.paused ? handlePlayingVideo(e, link) : console.log('1')
                        } catch (error) {

                        }
                    }}
                    onClick={() => {
                        try {
                            videoRef.current.paused ? videoRef.current.play() : videoRef.current.pause()
                        } catch (error) {

                        }
                    }}

                    style={{ position: 'absolute', width: "100%", height: height }} />
            )}
            <div
                className="progress-bar"
                style={{ position: 'absolute', bottom: -1, left: 0, right: 0, height: '3px', backgroundColor: 'hsla(0, 0%, 13%, 1)' }}
            >
                <div
                    className="progress-bar-fill"
                    style={{ marginLeft: '0px', width: `${progress}%`, height: '3px', backgroundColor: 'grey' }}
                />
            </div>
            <input
                type="range"
                className="progress-bar-input"
                value={progress}
                onChange={handleProgressChange}
                style={{
                    position: 'absolute',
                    bottom: 2,
                    left: 0,
                    right: 0,
                    height: '2px',
                    width: '100%',
                    opacity: 0, // Make the input invisible
                    cursor: 'pointer',
                    margin: '0px 0px'
                }}
            />
        </div>
    );
};
