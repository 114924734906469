import React, { useState, useEffect } from 'react';
import { ProgressBar } from './ProgressBar'
import { CountdownTimer } from './CountdownTimer';
import countSound from './countDown.mp3';
import ticking from './ticking.mp3'
import stopBeep from './stopBeep.mp3';
import { useDispatch } from 'react-redux';
import { disableRefresh, enableRefresh } from '../home/homeSlice';

let countSoundAudio
const checkLocalCountSound = localStorage.getItem('countSoundAudio')
if (checkLocalCountSound) {
    countSoundAudio = new Audio(checkLocalCountSound)
} else {
    countSoundAudio = new Audio(countSound)
}
let tickingAudio
const checkTickingSound = localStorage.getItem('tickingAudio')
if (checkTickingSound) {
    tickingAudio = new Audio(checkTickingSound)
} else {
    tickingAudio = new Audio(ticking)
}
let stopBeepAudio
const checkStopSound = localStorage.getItem('stopBeepSoundAudio')
if (checkStopSound) {
    stopBeepAudio = new Audio(checkStopSound)
} else {
    stopBeepAudio = new Audio(stopBeep)
}

export function StartSetTimer({ workout, setShowStartSetTimer, count, handleCompleteWorkout, handleIncreaseCount }) {
    const [level, setLevel] = useState(2)
    const [reps, setReps] = useState(5)
    const [countHelper, setCountHelper] = useState(0)
    const [fixedTime, setFixedTime] = useState(40)
    const [restTime, setRestTime] = useState(20)
    const [isResting, setIsResting] = useState(null)
    const [showStartCountDown, setShowStartCountDown] = useState(false)
    const dispatch = useDispatch()

    const vibrate = (time) => {
        try {
            window.ReactNativeWebView.postMessage(`Vib:${time}`);
        } catch (e) {

        }
    }

    const playAudio = () => {
        if (count === 10) {
            return
        }
        if (/Android/.test(navigator.userAgent)) {
            //countSoundAudio.loop = true
            //countSoundAudio.play();
            //stopBeepAudio.volume = 0
            //stopBeepAudio.loop = true
            //stopBeepAudio.play();
            //tickingAudio.volume = 0
            //tickingAudio.loop = true
            //tickingAudio.play();
        }
    };

    const stopAudio = () => {
        countSoundAudio.pause();
        countSoundAudio.currentTime = 0;
        stopBeepAudio.pause();
        stopBeepAudio.currentTime = 0;
        tickingAudio.pause();
        tickingAudio.currentTime = 0;
    };

    useEffect(() => {
        if (level === 1) {
            setReps(3)
            setFixedTime(40)
            setRestTime(20)
        } else if (level === 2) {
            setReps(5)
            setFixedTime(40)
            setRestTime(20)
        } else if (level === 3) {
            setReps(7)
            setFixedTime(40)
            setRestTime(20)
        }
    }, [level])

    useEffect(() => {
        try {
            if (countHelper > 0 && countHelper < reps && count !== 10) {
                setIsResting(true)
            }
        } catch (e) {
        }
    }, [countHelper])

    const handleStartTime = () => {
        if (count === 10) {
            stopAudio()
            try {
                window.ReactNativeWebView.postMessage("Alert: Well Played Champ! Confirm this Workout & Try another");
            } catch (e) {
                // Handle the error if needed
                console.error(' Well Played Champ! Confirm this Workout & Try another');
            }
            return
        } else {
            dispatch(disableRefresh())
            setShowStartCountDown(true)
            playAudio();

        }
    }

    useEffect(() => {
        return () => {
            stopAudio()
            dispatch(enableRefresh())
        }
    }, []);


    return (
        <div style={{ marginTop: '55px', width: '100%', display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ marginBottom: '10px' }}><span style={{ color: 'hsl(72, 100%, 47%)' }}>{workout.name.toUpperCase()}</span></h3>
            <div style={{ display: 'flex', gap: '20px', marginBottom: '10px' }}>
                <p onClick={() => setLevel(1)} style={{ padding: "0px", height: '18px', width: '80px', fontSize: '12px' }} className={`activeBlack ${level === 1 ? 'singleButton' : 'singleButton2'}`}>Beginner</p>
                <p onClick={() => setLevel(2)} style={{ padding: "0px", height: '18px', width: '80px', fontSize: '12px' }} className={`activeBlack ${level === 2 ? 'singleButton' : 'singleButton2'}`}>mid-level</p>
                <p onClick={() => setLevel(3)} style={{ padding: "0px", height: '18px', width: '80px', fontSize: '12px' }} className={`activeBlack ${level === 3 ? 'singleButton' : 'singleButton2'}`}>Advanced</p>
            </div>
            <h6 style={{ fontSize: '14px', width: '95%', marginBottom: '5px' }}>Do <span style={{ color: 'hsl(72, 100%, 47%)' }}>{reps}</span> sets. Each set is <span style={{ color: 'hsl(72, 100%, 47%)' }}>{fixedTime}</span> sec, followed by a rest period of <span style={{ color: 'hsl(72, 100%, 47%)' }}>{restTime}</span> sec.</h6>
            <img style={{ width: '80%', marginBottom: '10px', filter: 'invert(.82)', borderRadius: '15px', border: '1px solid hsl(257, 100%, 47%)' }} src={workout.gifurl} />
            <div style={{ display: 'flex', gap: '20px' }}>
                {isResting ? <p onClick={() => { setIsResting(false); setCountHelper(0); stopAudio(); }} style={{ height: '20px' }} className='activeBlack singleButton2'>Cancel</p> : <p onClick={() => { handleStartTime() }} style={{ height: '20px' }} className='activeBlack singleButton'>{count === 0 ? 'Start Set' : 'Start Another'}</p>}
                <p onClick={() => { stopAudio(); setShowStartSetTimer(null) }} style={{ height: '20px' }} className='activeBlack singleButton2'>Back</p>
            </div>
            <h4 style={{ zIndex: '750', marginTop: '10px' }}>SETS DONE: <span style={{ fontSize: '20px', color: 'hsl(72, 100%, 47%)' }}>{count}</span></h4>
            {showStartCountDown ?
                <div style={{ zIndex: '700', position: 'fixed', right: '0px' }} className="tempMeetingPage">
                    <div style={{ height: '100%', zIndex: '900', backgroundColor: 'rgba(17, 17, 17, 0.99)' }} className="meetingPageCont">
                        <CountdownTimer fixedTime={fixedTime} reps={reps} countHelper={countHelper} setCountHelper={setCountHelper} handleIncreaseCount={handleIncreaseCount} setShowStartCountDown={setShowStartCountDown} workout={workout} vibrate={vibrate} countSoundAudio={countSoundAudio} stopAudio={stopAudio} stopBeepAudio={stopBeepAudio} tickingAudio={tickingAudio} />
                    </div>
                </div>
                :
                ""
            }
            {isResting ?

                < ProgressBar duration={restTime} onComplete={handleStartTime} setIsResting={setIsResting} />
                :
                <div style={{ height: '20px' }}>
                </div>
            }
            {count === 0 ? "" : <p onClick={handleCompleteWorkout} style={{ marginTop: '10px', height: '20px' }} className='activeBlack singleButton'>Confirm </p>}

        </div>
    )
}