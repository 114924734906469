import React from "react";
import { useNavigate } from "react-router-dom";

export function MutualCard({ friend, setLoading }) {
    const navigate = useNavigate()

    const goToUser = (id) => {
        setLoading(null)
        navigate(`/home/:${id}`)
    }

    return (
        <div onClick={() => goToUser(friend.mainid)} className="chat-card">
            <img src={friend.picture_url} alt={friend.name} className="user-picture" />
            <div className="user-details">
                <p className="smallText1">{friend.name}</p>
            </div>
        </div>
    );
}
