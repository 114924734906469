import React, { useState, useEffect } from 'react';
import { MutualCard } from '../user/MutualCard'
import { FakeChatCard } from '../chats/FakeChatCard'
import { SwipeInUser } from '../user/SwipeInUser'
import { MiniFriendCard } from './MiniFriendCard';

export function MiniFriends({ setTaggedFriend, setShowFriendslist }) {
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(null);
  const [friendsList, setFriendsList] = useState([]);
  const [filteredFriendsList, setFilteredFriendsList] = useState([]);



  const fetchMutualFriendsList = async () => {
    setLoading('loading');
    try {
      const response = await fetch('/api/minifriends');
      if (response.ok) {
        const data = await response.json();
        setFriendsList(data.friendDetailsWithStatus);
        setLoading('done');
      } else {
        setLoading(null);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(null);
    }
  };

  useEffect(() => {
    fetchMutualFriendsList(); // This will run when the component mounts
  }, []); //

  useEffect(() => {
    try {
      if (loading === 'done' && friendsList.length > 0) {
        const filteredList = friendsList.filter(friend => friend.name.toLowerCase().includes(search.toLowerCase()));
        setFilteredFriendsList(filteredList);
      }
    } catch (e) {

    }
  }, [friendsList, search]);



  const back = () => {
    setLoading(null);
    setShowFriendslist(false)
  }

  if (loading === 'done' && friendsList.length === 0) {
    return (
      <div >
        {loading === 'loading' || loading === 'done' ?
          <SwipeInUser back={back} /> : ''}
        <div className="mutualPage">
          <div className="mutuallist">
            <h2>No Friends Found</h2>
          </div>
        </div>
      </div>
    )
  }

  function handleClick() {
    setTaggedFriend("")
    setShowFriendslist(false)
    setLoading(null)
  }

  return (
    <div >
      {loading === 'loading' || loading === 'done' ?
        <SwipeInUser back={back} /> : ''}
      {loading === 'loading' ? (
        <div className="mutualPage">
          <div className="mutuallist">
            <h2>FRIENDS</h2>
            <input
              style={{ margin: '20px ', width: '250px' }}
              className="miniSerchInput"
              type="text"
              id="search"
              maxLength={17}
              placeholder="search"
            />
            <FakeChatCard />
          </div>
        </div>
      ) : null}
      {loading === 'done' && friendsList.length > 0 && filteredFriendsList.length > 0 ? (
        <div className="mutualPage">
          <div className="mutuallist">
            <h2>FRIENDS</h2>
            <input
              style={{ margin: '20px ', width: '250px' }}
              className="miniSerchInput"
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              maxLength={17}
              placeholder="search"
            />
            {filteredFriendsList.map((friend) => (
              <MiniFriendCard setTaggedFriend={setTaggedFriend} setShowFriendslist={setShowFriendslist} setLoading={setLoading} key={friend.mainid} friend={friend} />
            ))}
            <div onClick={handleClick} style={{ gridTemplateColumns: '100%' }} className="minicard">
              <p style={{ textAlign: 'center', color: 'white', margin: '5px 0px', fontWeight: '500' }} >Remove</p>
            </div>
          </div>
        </div>
      ) :
        ""
      }
    </div>
  );
}
