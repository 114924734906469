import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Radius from "./Radius";
import { loadCommon, setLoading } from "./intButtonsSlice";
import { editData, selectData } from '../home/homeSlice'
import "./intButtons.css";
import { updateInterest, notifyNearBy } from "./intFuncs";
import { useNavigate } from "react-router-dom";
import { interestData } from "./sportPics";
import { ShareInterest } from "./ShareInterest";
import share from '../../Pics/share.png'
import { TimeInputNew } from "./TimeInputNew";
import { RootContext } from "../root/RootContextProvider";

export function IntButtons({ }) {
  const data = useSelector(selectData);
  const {
    location,
    setLocation,
    canShare,
    setCanShare,
    showShare,
    setShowShare,
    handleShowShare,
    subColor,
    setSubColor,
    didSeeInt,
    setDidSeeInt,
    radius,
    setRadius
  } = useContext(RootContext);
  const [selectedInt, setSelectedInt] = useState("");
  const [time, setTime] = useState("");
  const [comment, setComment] = useState("");
  const [preflocation, setPreflocation] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    try {
      if (window.location.search !== "") {
        const tobeEdited = window.location.search
        const selectedShortCut = tobeEdited.substring(1)
        setSelectedInt(selectedShortCut)
      }
    } catch (e) {

    }
  }, [])

  const handleInt = (interest) => {
    setSelectedInt(interest);
  };

  const handleConfirm = () => {
    if (data.askshare === true) {
      handleShowShare()
    } else {
      confirmNow()
    }
  };

  const confirmNow = () => {
    setShowShare(false)
    if (selectedInt !== "") {
      dispatch(setLoading())
      updateInterest(selectedInt, time, comment, preflocation, radius, dispatch, editData, loadCommon, setCanShare)
      setTimeout(() => {
        try {
          if (data.interest !== selectedInt) {
            notifyNearBy(selectedInt, 'user')
          }
        } catch (e) {

        }
      }, 3000);
      navigate('/home/workout/findduo')
    }
  };

  if (location === 'waiting' || location === null) {
    return ''
  }


  return (
    <div className="choiceCont">
      <div className="tahCont">
        <TimeInputNew setTime={setTime} maxTime={48} />
        <div className="hintCont">
          <label htmlFor="comment">LOCATION</label>
          <input
            className="hint"
            type="text"
            id="preflocation"
            value={preflocation}
            onChange={(event) => setPreflocation(event.target.value)}
            maxLength={17}
            placeholder="Location"
          />
        </div>
        <div className="hintCont">
          <label htmlFor="comment">DETAILS</label>
          <input
            className="hint"
            type="text"
            id="comment"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            maxLength={17}
            placeholder="Details"
          />
        </div>
      </div>
      <div className="radandconf">
        <Radius radius={radius} setRadius={setRadius} />
        <div style={{ backgroundColor: selectedInt ? "hsla(72, 100%, 47%, 80%)" : "hsla(72, 100%, 47%, 50%)" }} className="confirmInts" type="button" onClick={handleConfirm}>
          {selectedInt ? `CONFIRM ${selectedInt.toUpperCase()}` : 'CHOOSE WORKOUT'}
        </div>
      </div>
      {canShare ? <img src={share} className="openShareBanner" /> : ""}
      <ShareInterest time={time} preflocation={preflocation} interest={selectedInt} confirmNow={confirmNow} show={showShare} setShow={setShowShare} handleShowShare={handleShowShare} askshare={data.askshare} setCanShare={setCanShare} />
      <div style={{ marginTop: '10px' }} className="scrollHelper" >
        <form style={{ marginTop: '0px' }} className="intButtons">
          {interestData.map((interest) => (
            <div
              key={interest.name}
              type="button"
              onClick={() => handleInt(interest.name)}
              style={
                selectedInt === interest.name
                  ? { backgroundColor: 'hsla(72, 100%, 47%, 80%)', color: '#ffffff' }
                  : {}
              }
            >
              <img src={localStorage.getItem(interest.name.toLocaleLowerCase()) || interest.img} alt={interest.name} />
              <p>{interest.name}</p>
            </div>
          ))}
        </form>
      </div>
    </div>
  );
}
