export async function fetchNonActive(setNonActiveStatus, setNonActiveList, setLastActiveHelper, location, currentInterest, radius) {
    setNonActiveStatus('loading')
    try {
        const response = await fetch('/api/discover/loadbyfav',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currentInterest: currentInterest, radius: radius, location: location }),
            }
        ); // Adjust the API endpoint as needed
        if (response.ok) {
            const data = await response.json();
            setNonActiveList(data);
            setNonActiveStatus('done')
            try {
                if (data.length > 0) {
                    if (data.length < 10) {
                        setLastActiveHelper(0)
                    } else {
                        setLastActiveHelper(data[data.length - 1].lastactivity)
                    }
                } else {
                    setLastActiveHelper(0)
                }
            } catch (e) {
                setLastActiveHelper(0)
            }
        } else {
            console.error('Failed to fetch posts');
            setNonActiveStatus(null)
        }
    } catch (error) {
        setNonActiveStatus(null)
        console.error('Error fetching posts:', error);
    }
}



export async function fetchMoreNonActive(setMoreNonActiveStatus, setNonActiveList, lastActiveHelper, setLastActiveHelper, location, currentInterest, radius) {
    setMoreNonActiveStatus('loading')
    try {
        const response = await fetch('/api/discover/loadmorebyfav',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ currentInterest: currentInterest, radius: radius, location: location, lastActiveHelper: lastActiveHelper }),
            }
        ); // Adjust the API endpoint as needed
        if (response.ok) {
            const data = await response.json();
            setNonActiveList(prevList => [...prevList, ...data]);
            try {
                if (data.length > 0) {
                    if (data.length < 10) {
                        setLastActiveHelper(0)
                    } else {
                        setLastActiveHelper(data[data.length - 1].lastactivity)
                    }
                } else {
                    setLastActiveHelper(0)
                }
            } catch (e) {
                setLastActiveHelper(0)
            }
            setMoreNonActiveStatus('done')
        } else {
            console.error('Failed to fetch posts');
            setMoreNonActiveStatus(null)
        }
    } catch (error) {
        setMoreNonActiveStatus(null)
        console.error('Error fetching posts:', error);
    }
}