import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const loadAchievements = createAsyncThunk(
  'achievements/loadAchievements',
  async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/profile/achievements`);
    const json = await data.json();
    return json;
  }
);

export const achievementsSlice = createSlice({
  name: 'achievements',
  initialState: {
    achievements: null,
    status: 'idle',
  },
  reducers: {
    editAchievementsData: (state, action) => {
      try {
        state.achievements = { ...state.achievements, ...action.payload };
      } catch (e) {

      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadAchievements.pending, (state) => {
        if (state.data === null) {
          state.status = 'loading';
        }
      })
      .addCase(loadAchievements.fulfilled, (state, action) => {
        state.achievements = action.payload;
        console.log(action.payload)
        state.status = 'done';
      })
      .addCase(loadAchievements.rejected, (state, action) => {
        state.status = 'failed';
        state.achievements = null;
      })
  },
});

export const selectAchievements = (state) => state.achievements.achievements;
export const selectStatus = (state) => state.achievements.status;
export const { editAchievementsData } = achievementsSlice.actions;
export default achievementsSlice.reducer;


