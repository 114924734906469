import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, useOutlet } from "react-router-dom";
import { RepChoice } from './RepChoice'
import { TempProfile } from '../list/TempProfile'
import { TempMeetingPage } from '../workoutsManager/TempMeetingPage'
import { Nav } from "./Nav";
import "./root.css";
import { BottomNav } from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
import { loadHome, selectAppVersion, selectData, selectLog, selectShowBottomNav, selectShowTopNav, selectStatus, setStatus } from "../home/homeSlice";
import socket from "../../app/io";
import { motion, AnimatePresence } from "framer-motion"
import { RootContext } from "./RootContextProvider";



const AnimatedOutlet = () => {
  const o = useOutlet();
  const [outlet] = useState(o);

  return <>{outlet}</>;
};

export let web = "";
if (process.env.REACT_APP_DEPLOY_MODE === "production") {
  web = process.env.REACT_APP_WEB_PAGE;
} else {
  web = process.env.REACT_APP_LOCALHOST;
}



export function Root({ }) {
  const {
    canShare,
    setCanShare,
    handleShowShare,
    subColor,
  } = useContext(RootContext);
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const currentLocation = useLocation();
  const data = useSelector(selectData);
  const log = useSelector(selectLog)
  const status = useSelector(selectStatus)
  const appVersion = useSelector(selectAppVersion);
  const showTopNav = useSelector(selectShowTopNav)
  const showBottomNav = useSelector(selectShowBottomNav)

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        try {
          window.ReactNativeWebView.postMessage(`Stack:${-20}`);
        } catch (e) {
          console.error(e);
        }
        if (window.activateRefresh === true) {
          if (window.location.pathname !== '/login' || window.location.pathname !== '/download') {
            setTimeout(() => {
              window.postMessage('refreshApp')
            }, 100);
          }
        } else if (window.activateRefresh === false) {
          window.activateRefresh = true
          setTimeout(() => {
            window.postMessage('refreshApp')
          }, 100);
        } else if (window.activateRefresh === 'adVeiw') {
          setTimeout(() => {
            window.activateRefresh = true
          }, 1000);
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_DEPLOY_MODE === "production") {
      try {
        window.ReactNativeWebView.postMessage("Random");
        if (currentLocation.pathname === "/") {
          navigate("/home/feed");
        }
      } catch (e) {
        if (currentLocation.pathname === '/policy' || currentLocation.pathname === '/eula') {

        } else {
          navigate('/download')
        }
      }
    } else {
      if (currentLocation.pathname === "/") {
        navigate("/home/feed");
      }
    }
  }, [window.location.pathname])

  //useEffect(() => {
  //  if (currentLocation.pathname === "/") {
  //    navigate("/home/feed");
  //  }
  //}, [window.location.pathname])

  useEffect(() => {
    socket.on("loadHome", () => {
      dispatch(loadHome())
    })

    return () => {
      socket.off("loadHome");
    };
  }, []);


  useEffect(() => {
    if (currentLocation.pathname !== "/home/workout") {
      setCanShare(false)
    }
  });




  return (
    <div className="outLet">
      <Nav data={data} appVersion={appVersion} canShare={canShare} handleShowShare={handleShowShare} subColor={subColor} showTopNav={showTopNav} />
      <RepChoice />
      <TempProfile />
      <TempMeetingPage />
      <div style={{ width: '100%' }}>
        <AnimatePresence mode="popLayout">
          <motion.div
            key={currentLocation.key}
            initial={{ opacity: 0, x: -300 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 300 }}
            transition={{
              duration: .3,
              ease: 'easeInOut',
            }}
            style={{ position: 'absolute', width: '100%', left: '0px', top: '0px', paddingTop: '45px' }}
          >
            <AnimatedOutlet key={currentLocation.key} />
          </motion.div>
        </AnimatePresence>
      </div>
      <BottomNav data={data} showBottomNav={showBottomNav} />
    </div>
  );
}

/*
        <AnimatedOutlet key={location} />

            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/construction">CONSTRUCTION</NavLink> 
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/architecture">ARCHITECTURE</NavLink>    
            <NavLink style={({ isActive }) => isActive ? activeStyle : undefined } className='singleLink' to="/furniture">FURNITURE</NavLink>
*/
