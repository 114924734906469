import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectData, disableRefresh } from '../home/homeSlice';
import { MeetUpCreator } from './MeetUpCreator';

import { FakeMeetUpCard } from './FakeMeetUpCard'
import { MeetUpCard } from './MeetUpCard'



export function MeetManager({ showfirendsList, setShowFriendslist, meetings, loading, fetchMyMeetings }) {
    const [selectedFilter, setSelectedFilter] = useState("Pending");
    const [showInvite, setShowInvite] = useState(null);
    const [selectedSport, setSelectedSport] = useState(null);
    const [taggedFriend, setTaggedFriend] = useState(null)
    const [creating, setCreating] = useState(false)
    const [filteredMeeting, setFilteredMeetings] = useState(null)
    const myinfo = useSelector(selectData)


    useEffect(() => {
        setShowInvite(false)
    }, [meetings]);


    useEffect(() => {
        if (!meetings) return;
        try {
            const updatedFilteredPosts = meetings.filter((meeting) => {
                if (selectedFilter === "All") {
                    return true; // Show all posts
                } else if (selectedFilter === "Confirmed") {
                    return meeting.state === 'confirmed';
                } else if (selectedFilter === "Pending") {
                    return meeting.state !== 'confirmed';
                }
                return false;
            });
            setFilteredMeetings(updatedFilteredPosts);
        } catch (e) {

        }
    }, [selectedFilter, meetings]);

    useEffect(() => {
        setShowInvite(false)
    }, [selectedFilter]);

    return (

        <div>
            <div style={{ height: '40px', padding: '0px 20px', gap: '10px', borderRadius: '0px' }} className="feedFilter">
                <label className="activeBlack" onClick={() => setSelectedFilter('All')} style={{ color: selectedFilter === 'All' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">All</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Confirmed')} style={{ color: selectedFilter === 'Confirmed' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Confirmed</label>
                <label className="activeBlack" onClick={() => setSelectedFilter('Pending')} style={{ color: selectedFilter === 'Pending' ? "" : '#f5f5f59c', fontSize: '12px', height: '30px' }} htmlFor="comment">Pending</label>
                <img onClick={() => { setShowInvite((invite) => !invite) }} style={{ width: '35px' }} src={localStorage.getItem('plus')} />
            </div>
            <div style={{ padding: '0px 15px' }}>
                {showInvite ?
                    <MeetUpCreator
                        myInfo={myinfo}
                        showfirendsList={showfirendsList}
                        setShowFriendslist={setShowFriendslist}
                        selectedSport={selectedSport}
                        setSelectedSport={setSelectedSport}
                        taggedFriend={taggedFriend}
                        setTaggedFriend={setTaggedFriend}
                        creating={creating}
                        setCreating={setCreating}
                        fetchMyMeetings={fetchMyMeetings}
                    />
                    :
                    ""
                }
            </div>
            {loading === 'done' && filteredMeeting ? (
                <div style={{ marginTop: '10px', width: 'calc(100% - 20px)' }} >
                    {
                        filteredMeeting.length > 0 ?
                            <div>
                                {filteredMeeting.map((info) => (
                                    <MeetUpCard
                                        key={info.meetingid}
                                        myMainid={myinfo.mainid}
                                        meetingid={info.meetingid}
                                        creatorid={info.creatorid}
                                        creatorname={info.creatorname}
                                        taggedid={info.taggedid}
                                        taggedname={info.taggedname}
                                        createtime={info.createtime}
                                        sport={info.sport}
                                        state={info.state}
                                        notifytime={info.notifytime}
                                        meetingtime={info.meetingtime}
                                        finishedtime={info.finishedtime}
                                        fetchMyMeetings={fetchMyMeetings}
                                    />
                                ))}
                            </div>
                            :
                            <div className='noPrevWorkout'>
                                {selectedFilter === 'Pending' && !showInvite ?
                                    <div onClick={() => { setShowInvite((invite) => !invite) }}>
                                        <p>
                                            No Pending Duo Workouts<br />
                                            Invite a friend to a workout?
                                        </p>
                                        <img src={localStorage.getItem('meetUpGreen')} />
                                    </div>
                                    :
                                    ""
                                }
                                {selectedFilter === 'Confirmed' || selectedFilter === 'All' ?
                                    <div onClick={() => { setShowInvite((invite) => !invite) }}>
                                        <p>
                                            No Duo workouts saved, <br />
                                            Invite a friend to a workout?
                                        </p>
                                        <img src={localStorage.getItem('meetUpGreen')} />
                                    </div>
                                    :
                                    ""
                                }
                            </div>
                    }

                </div>
            ) : (
                <div style={{ marginTop: '10px', width: 'calc(100% - 20px)' }} >
                    <FakeMeetUpCard />
                    <FakeMeetUpCard />
                </div>
            )}
        </div>
    );
}


