import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { calculateAge, handleEditAge } from "./proFuncs";
import { editData } from "../home/homeSlice";

export function Age({ data }) {
  const [newAge, setNewAge] = useState(data && data.age ? JSON.parse(data.age) : { day: null, month: null, year: null });
  const [ageMod, setAgeMod] = useState(false);
  const dispatch = useDispatch();

  const openEditAge = () => {
    setAgeMod(!ageMod);
  };

  const confirmAge = () => {
    if (newAge && (newAge.day && newAge.month && newAge.year)) {
      handleEditAge(newAge, dispatch, editData, setAgeMod);
    } else {
      try {
        window.ReactNativeWebView.postMessage(`Alert:Please Select Day, Month, Year`);
      } catch (e) {
        console.log('Please Select Day, Month, Year')
      }
      return
    }
  };

  const renderDayOptions = () => {
    const days = Array.from({ length: 31 }, (_, i) => i + 1); // Generate an array of numbers from 1 to 12
    return days.map((day) => (
      <option key={day} value={day}>
        {day}
      </option>
    ));
  };

  const renderMonthOptions = () => {
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // Generate an array of numbers from 1 to 12
    return months.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  };

  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 1900; // Set the minimum year
    const years = Array.from(
      { length: currentYear - 16 - startYear + 1 },
      (_, i) => startYear + i
    ); // Generate an array of years from startYear to currentYear
    return years
      .map((year) => (
        <option key={year} value={year}>
          {year}
        </option>
      ))
      .reverse(); // Reverse the order of the years
  };

  return (
    <div className="ageCont">
      {ageMod === false ? (
        <div>
          {data.age ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {calculateAge(data.age)}
              <img style={{ marginLeft: '10px' }} src={localStorage.getItem('edit')} className="editButton" onClick={openEditAge} />
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'space-between' }} >
              <p >Set Age</p>
              <img style={{ marginLeft: '10px' }} src={localStorage.getItem('edit')} className="editButton" onClick={openEditAge} />
            </div>
          )}
        </div>
      ) : (
        <div className="editInfoCont">
          <div style={{ display: 'flex' }}>
            <select
              className="ageInput"
              value={newAge.day || ""}
              onChange={(event) =>
                setNewAge({ ...newAge, day: parseInt(event.target.value) })
              }
            >
              <option value={null} >Day</option>
              {renderDayOptions()}
            </select>
            <select
              className="ageInput"
              value={newAge.month || ""}
              onChange={(event) =>
                setNewAge({ ...newAge, month: parseInt(event.target.value) })
              }
            >
              <option value={null}>Month</option>
              {renderMonthOptions()}
            </select>
            <select
              className="ageInput"
              value={newAge.year || ""}
              onChange={(event) =>
                setNewAge({ ...newAge, year: parseInt(event.target.value) })
              }
            >
              <option value={null}>Year</option>
              {renderYearOptions()}
            </select>
          </div>
          <div style={{ display: 'flex', marginTop: '15px' }}>
            <button className="activeBlack confirmButton" onClick={confirmAge}>
              Confirm
            </button>
            <button className="activeBlack cancelButton" onClick={openEditAge}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
