export const fetchUser = async (id) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    const data = await response.json();
    return data
  } catch (error) {
    console.error(error);
  }
};

export const handleReport = (newReport, id, name, dispatch, clearInfo, postid) => {
  if (newReport) {
    fetch(`/api/profile/report`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ report: newReport, reported: id, postid: postid }),
    })
      .then((response) => {
        window.alert(`Reported ${name} ${newReport === 'abusive user' ? 'as' : 'for '} ${newReport} `)
        dispatch(clearInfo())
        console.log('Reported')
      })
      .catch((error) => {
        //console.error("Error updating bio:", error);
        //alert("Failed to update bio1");
      });
  }
};

export const sendMessage = async (content, receiverid, type) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/sendmessage`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ content: content, receiverid: receiverid, type: type }),
    });
    if (response.ok) {
      console.log('Group Message Sent')
    } else {
      console.log('Failed to send Group Message')
    }
  } catch (error) {
    console.error(error);
  }
}

export const sendAudio = async (receiverid, type, record64, setMessages, setRecord, setRecord64, setInputText, scrollToBottom, setUploadingRecord) => {
  try {
    setUploadingRecord(true)
    const formData = new FormData();
    formData.append('receiverid', receiverid);
    formData.append('type', type);
    const byteCharacters = window.atob(record64);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    const blob = new Blob([new Uint8Array(byteArrays)], { type: 'audio/wav' });
    formData.append('audio', blob, 'vocieAudio');

    const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/sendmessage`, {
      method: "POST",
      body: formData
    });
    if (response.ok) {
      const data = await response.json()
      const voiceLink = await data.content
      const sentMessage = {
        content: voiceLink,
        sender: "me",
        type: 'audio'
      };
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setUploadingRecord(false)
      setInputText("");
      setRecord(null);
      setRecord64(null);
      setTimeout(() => {
        scrollToBottom()
      }, 500);
    } else {
      setUploadingRecord(false)
      console.log('Failed to send Group Message')
    }
  } catch (error) {
    setUploadingRecord(false)
    console.error(error);
  }
}

export const sendPhoto = async (receiverid, type, photo64, setMessages, setSelectedPhoto, setPhoto64, setInputText, scrollToBottom, setUploadingPhoto) => {
  try {
    setUploadingPhoto(true)
    const formData = new FormData();
    formData.append('receiverid', receiverid);
    formData.append('type', type);
    const byteCharacters = window.atob(photo64);
    const byteArrays = [];
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }
    type = 'image/jpeg'
    const blob = new Blob([new Uint8Array(byteArrays)], { type: type });
    formData.append('image', blob, 'chatimage'); // 'post.jpg' is a placeholder, you can change it

    const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/sendmessage`, {
      method: "POST",
      body: formData
    });
    if (response.ok) {
      const data = await response.json()
      const imageLink = await data.content
      const sentMessage = {
        content: imageLink,
        sender: "me",
        type: 'image'
      };
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setUploadingPhoto(false)
      setInputText("");
      setSelectedPhoto(null);
      setPhoto64(null);
      setTimeout(() => {
        scrollToBottom()
      }, 700);
    } else {
      setUploadingPhoto(false)
      console.log('Failed to send Group Message')
    }
  } catch (error) {
    setUploadingPhoto(false)
    console.error(error);
  }
}




export const sendVideo = async (receiverid, type, selectedVideo, setMessages, setSelectedVideo, setInputText, scrollToBottom, setUploadingPhoto) => {
  try {
    setUploadingPhoto(true)
    const formData = new FormData();
    formData.append('receiverid', receiverid);
    formData.append('type', type);
    formData.append('videourl', selectedVideo);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/profile/sendmessage`, {
      method: "POST",
      body: formData
    });
    if (response.ok) {
      const data = await response.json()
      const videoLink = await data.content
      const sentMessage = {
        content: videoLink,
        sender: "me",
        type: 'video'
      };
      setMessages((prevMessages) => [...prevMessages, sentMessage]);
      setUploadingPhoto(false)
      setInputText("");
      setSelectedVideo(null);
      setTimeout(() => {
        scrollToBottom()
      }, 300);
    } else {
      setUploadingPhoto(false)
      console.log('Failed to send Group Message')
    }
  } catch (error) {
    setUploadingPhoto(false)
    console.error(error);
  }
}
